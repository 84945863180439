import { ResourceType, ResourceSubType, CompanyInfo } from '../../types';
import { Checkbox, FormControl, FormLabel, Grid, ListItemText, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'; //eslint-disable-line import/named
import { useCallback, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

export interface EquipmentFiltersType {
  companies: CompanyInfo[];
  subtypes: ResourceSubType[];
  dailyPriceMax: number;
  dailyPriceMin: number;
  distanceMax: number;
  distanceMin: number;
}

interface Props {
  resourceSubTypes: ResourceSubType[];
  resourceTypes: ResourceType[];
  companies: CompanyInfo[];
  equipmentFilters: EquipmentFiltersType
  setFilters: React.Dispatch<React.SetStateAction<EquipmentFiltersType>>;
}


export const EquipmentFilters: React.FC<Props> = ({ resourceSubTypes, resourceTypes, companies, equipmentFilters, setFilters }) => {

  const { t } = useTranslation();
  const equipmentTypeId = resourceTypes.find((type) => ["Machinery", "Kalusto", "Inventarier"].includes(type.name))?.id
  const filteredResourceSubTypes = resourceSubTypes.filter((subtype) => subtype.resource_type === equipmentTypeId)
  const handleEquipmentFilterChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const newFilters = { ...equipmentFilters };
      (newFilters as any)[e.target.name as keyof EquipmentFiltersType] = Number(e.target.value);
      setFilters(newFilters);
    },
    [equipmentFilters, setFilters],
  )
  const handleEquipmentSelectFilterChange = useCallback(
    (e: SelectChangeEvent<string[]>): void => {
      const newFilters = { ...equipmentFilters };
      // Company filter
      if (e.target.name === 'company') {
        const companiesArray: CompanyInfo[] = []
        // From database CompanyInfos find the ones that match current filter selection
        for (const companyName of e.target.value) {
          companiesArray.push(companies.find((company) => company.name === companyName) as CompanyInfo)
        }
        newFilters.companies = companiesArray
          // Subtype filter
      } else if (e.target.name === 'subtype') {
        const subtypeArray: ResourceSubType[] = []
        // From database ResouceSubTypes find the ones that match current filter selection
        for (const subtypeName of e.target.value) {
          subtypeArray.push(resourceSubTypes.find((subtype) => subtype.name === subtypeName) as ResourceSubType)
        }
        newFilters.subtypes = subtypeArray
      }
      setFilters(newFilters);
    },
    [equipmentFilters, resourceSubTypes, companies, setFilters],
  )
  return (
    <Grid container spacing={2} columns={{ xs: 2, sm: 4, md: 12}}>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <FormLabel id="subtype-label">{t('resourceSearch.filters.subType')}</FormLabel>
          <Select
            labelId="subtype-select-label"
            id="subtype-select"
            label="subtype"
            onChange={handleEquipmentSelectFilterChange}
            name="subtype"
            value={equipmentFilters.subtypes.map((subtype) => { return subtype.name as string })}
            multiple
            renderValue={(selected): string => selected.join(", ")}
            size="small"
          >
            {filteredResourceSubTypes.map((subtype) => (
              <MenuItem key={subtype.name} value={subtype.name}>
                <Checkbox checked={equipmentFilters.subtypes.indexOf(subtype) !== -1} />
                <ListItemText primary={subtype.name} disableTypography={true} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <FormLabel id="company-label">{t('resourceSearch.resourceList.company')}</FormLabel>
          <Select
            labelId="company-select-label"
            id="company-select"
            label="company"
            onChange={handleEquipmentSelectFilterChange}
            name="company"
            value={equipmentFilters.companies.map((company) => { return company.name as string })}
            multiple
            renderValue={(selected): string => selected.join(", ")}
            size="small"
          >
            {companies.map((company) => (
              <MenuItem key={company.name} value={company.name}>
                <Checkbox checked={equipmentFilters.companies.indexOf(company) !== -1} />
                <ListItemText primary={company.name} disableTypography={true} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={1.5}>
        <FormControl fullWidth>
          <FormLabel id="daily-price-min-label">{t('resourceSearch.filters.dailyPriceMin')}</FormLabel>
          <TextField
            id="daily-price-min-textfield"
            onChange={handleEquipmentFilterChange}
            name="dailyPriceMin"
            value={(equipmentFilters.dailyPriceMin > 0) ? equipmentFilters.dailyPriceMin : ""}
            size="small"
          />
        </FormControl>
      </Grid>
      <Grid item xs={1.5}>
        <FormControl fullWidth>
          <FormLabel id="daily-price-max-label">{t('resourceSearch.filters.dailyPriceMax')}</FormLabel>
          <TextField
            id="daily-price-max-textfield"
            onChange={handleEquipmentFilterChange}
            name="dailyPriceMax"
            value={(equipmentFilters.dailyPriceMax > 0) ? equipmentFilters.dailyPriceMax : ""}
            size="small"
          />
        </FormControl>
      </Grid>
      <Grid item xs={1.5}>
        <FormControl fullWidth>
          <FormLabel id="distance-min-label">{t('resourceSearch.filters.distanceMin')}</FormLabel>
          <TextField
            id="distance-min-textfield"
            onChange={handleEquipmentFilterChange}
            name="distanceMin"
            value={(equipmentFilters.distanceMin > 0) ? equipmentFilters.distanceMin : ""}
            size="small"
          />
        </FormControl>
      </Grid>
      <Grid item xs={1.5}>
        <FormControl fullWidth>
          <FormLabel id="distance-max-label">{t('resourceSearch.filters.distanceMax')}</FormLabel>
          <TextField
            id="distance-max-textfield"
            onChange={handleEquipmentFilterChange}
            name="distanceMax"
            value={(equipmentFilters.distanceMax > 0) ? equipmentFilters.distanceMax : ""}
            size="small"
          />
        </FormControl>
      </Grid>
    </Grid>
  )

}
