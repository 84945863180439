import {
  Box,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Resource, Error, AvailabilityPeriod} from '../../types';
import { colors } from '../../theme';
import 'react-calendar/dist/Calendar.css';
import {Calendar} from 'react-calendar';
import { useResourceContext } from '../../context';
import lodash from 'lodash';
import { SimpleDialog } from '../common';
import DeleteIcon from '@mui/icons-material/Delete';
import { format } from 'date-fns';

interface Props {
  formValues: Resource;
  formErrors: Error;
  renderFormErrorHelperText: any;
  handleChange: any;
  hasError: any;
  setLoading:any;
  setFormValues: any;
  setIsDefaultValueChanged: any;
}
export const CalendarAvailability: React.FC<Props> = ({ renderFormErrorHelperText, hasError, formValues, setFormValues, setIsDefaultValueChanged}) => {
  const { t } = useTranslation();
  const userLang = localStorage.getItem('user_language') ?? 'fi';
  const { selectedResourceId } = useResourceContext();
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);

  const getSelectedAvailabilityPeriods = useCallback(
    (): AvailabilityPeriod[] =>{
      return formValues.availability_periods;
    },[formValues.availability_periods]);

  const formatDate = useCallback(
    (date: string): string => {
      return format(new Date(date),'dd.MM.yyyy');
    },[]
  );

  const handleCalendar = useCallback(
    (value:Date[])=>{
      const begin = (value[0]).toISOString();
      const end = (value[1]).toISOString();
      const newFormValues = JSON.parse(JSON.stringify(formValues));
      const dateAlreadyAvailable = formValues.availability_periods.find((item:AvailabilityPeriod) =>{
        const beginDate = format(new Date(value[0]),'yyyy-MM-dd'),
          endDate = format(new Date(value[1]),'yyyy-MM-dd'),
          itemBeginDate = format(new Date(item.begin),'yyyy-MM-dd'),
          itemEndDate = format(new Date(item.end),'yyyy-MM-dd'); 
        return ((new Date(beginDate) >= new Date(itemBeginDate) && new Date(beginDate) <= new Date(itemEndDate)) || 
        (new Date(endDate) >= new Date(itemBeginDate) && new Date(endDate) <= new Date(itemEndDate)))
      }
      );

      if(!!dateAlreadyAvailable){
        setErrorDialogOpen(true);
      }else{
        const newAvailabilityPeriods = formValues.availability_periods.concat([{
          id: 0,
          begin,
          end,
          resource: Number(selectedResourceId)
        }]);
        if(!lodash.isEqual(formValues.availability_periods,newAvailabilityPeriods)){
          newFormValues.availability_periods = newAvailabilityPeriods;
          setFormValues(newFormValues);
          setIsDefaultValueChanged(true);
        }
    }     
  },[selectedResourceId, formValues, setFormValues, setIsDefaultValueChanged]);
  
  const handlePeriodDelete = useCallback(
    (item: AvailabilityPeriod): void =>{
      const newFormValues = JSON.parse(JSON.stringify(formValues));
      const availablePeriods = formValues.availability_periods;
      const newAvailablePeriods = availablePeriods.filter(
        (period: AvailabilityPeriod)=> Number(period.id) > 0 ? 
        period.id !== item.id :
        !(formatDate(period.begin)===formatDate(item.begin) && formatDate(period.end)===formatDate(item.end))
      );
      newFormValues.availability_periods = newAvailablePeriods;
      setFormValues(newFormValues);
      setIsDefaultValueChanged(true);
  },[formValues, setFormValues, setIsDefaultValueChanged, formatDate]);

  const closeErrorDialog = useCallback((): void => {
    setErrorDialogOpen(false);
  }, [setErrorDialogOpen]);

  const handleDateChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, itemId:string|number): void => {
      const fieldName = e.target.name,
        fieldValue = e.target.value,
        newFormValues = JSON.parse(JSON.stringify(formValues));
      const selectedDate = newFormValues.availability_periods.find((item: AvailabilityPeriod) => 
      Number(item.id) > 0 ? 
      Number(item.id)===Number(itemId):
      (fieldName ==="begin" ? formatDate(item.begin) === fieldValue : formatDate(item.end) ===fieldValue)
      );
      const formatedFieldValue = fieldValue.split('.').reverse().join('-');
      if(fieldName === 'begin'){
        selectedDate.begin = (new Date(formatedFieldValue)).toISOString()
        const combinedPeriods = newFormValues.availability_periods.map(
          (item:AvailabilityPeriod) => Number(item.id) === Number(selectedDate.id) ? selectedDate : item);
        newFormValues.availability_periods = [...combinedPeriods]
        setFormValues(newFormValues);
        setIsDefaultValueChanged(true);
      }
      if(fieldName === 'end'){
        selectedDate.end = (new Date(formatedFieldValue)).toISOString()
        const combinedPeriods = newFormValues.availability_periods.map(
          (item:AvailabilityPeriod) => Number(item.id) === Number(selectedDate.id) ? selectedDate : item);
        newFormValues.availability_periods = [...combinedPeriods]
        setFormValues(newFormValues);
        setIsDefaultValueChanged(true);
      }
    },
    [formValues, formatDate, setFormValues, setIsDefaultValueChanged],
  );

  const renderDates = useMemo(
    () => (
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ flexWrap: 'wrap', gap: 1 }} >
        {
          getSelectedAvailabilityPeriods().map((item: AvailabilityPeriod, index:number)=>(
            <FormControl 
              key={`${item.id}_${index}`}
              sx={{
                display:"inline",
              }}
            >
              <TextField
                defaultValue={formatDate(item.begin)}
                name="begin"
                onInput={(event: ChangeEvent<HTMLInputElement> ): void =>handleDateChange(event, item?.id ?? "")}
                fullWidth={false}
                size="small"
                variant='outlined'
                sx={{width:125, borderRadius:25}}
              />
              <Box component="span" sx={{m:1}}>{" - "}</Box>
              <TextField
                defaultValue={formatDate(item.end)}
                name="end"
                onInput={(event: ChangeEvent<HTMLInputElement> ): void =>handleDateChange(event, item?.id ?? "")}
                fullWidth={false}
                size="small"
                variant='outlined'
                sx={{width:125, borderRadius:25}}
              />
              <DeleteIcon onClick={():void=> handlePeriodDelete(item)} sx={{color: colors.darkBlue, pt:0.5}}/>
            </FormControl>
        ))
        }
      </Box>
    ),[getSelectedAvailabilityPeriods, handleDateChange, formatDate, handlePeriodDelete]);
  
  const renderCalendarAvailability = useMemo(
    () => (
      <FormControl error={hasError('availability_periods')}>
        <Grid container display="flex" m={1} spacing={3}>
          <Grid item xs={4} alignItems="start">
            <Typography variant="body1">{t('myResources.availabilityCalendar')}</Typography>
          </Grid>
          <Grid item xs={8} pr={1}>
            <Calendar
              value={
                !!formValues.availability_periods[0] ? 
                [new Date(formValues.availability_periods[0].begin), new Date(formValues.availability_periods[0].end)]:
                [new Date(),new Date()]
              }
              onChange={handleCalendar}
              locale={userLang}
              selectRange={true}
              returnValue={"range" || "start"}
            />
            <SimpleDialog text={t('myResources.availabilityAlreadyExists')} isOpen={errorDialogOpen} handleClose={closeErrorDialog} severity="error" />
          </Grid> 
        </Grid>
        <Grid container display="flex" m={1} spacing={3}>
          <Grid item xs={4} alignItems="start"/>
          <Grid item xs={8} alignItems="end">
            <Typography variant="body1" >{t('myResources.availableDates')}</Typography>
            {renderDates}
          </Grid>
        </Grid>
        {renderFormErrorHelperText('availability_periods')}
      </FormControl>  
    ),
    [hasError, renderFormErrorHelperText, t, userLang, handleCalendar,
      renderDates, closeErrorDialog, errorDialogOpen, formValues.availability_periods]);
  return <>{renderCalendarAvailability}</>;
};
