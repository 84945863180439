import React, {ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState} from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import fiLocale from 'date-fns/locale/fi';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {NavLink, useLocation, useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Paper,
  styled,
  TextField,
  Typography,
  Link
} from '@mui/material';

import {colors} from '../theme';
import Api from '../api';
import {endpoints, numberToLocale, urls} from '../utils';
import {useProgress} from '../hooks';
import {
  CompanyContactsResponse,
  FormError,
  OfferFormValues,
  OfferLineFormValues,
  RequestForProposal,
  Resource,
} from '../types';
import {Main} from '../layout';
import {useAuthContext, useCompanyContext, usePaginationContext} from "../context";
import {useResourceContext} from "../context/resource";
import DeleteIcon from "@mui/icons-material/Delete";
import {format} from 'date-fns';


const StyledButton = styled(Button)(() => ({
  marginRight: '0.5em',
}));

const StyledNavLink = styled(NavLink)(() => ({
  textDecoration: 'none',
  color: colors.secondaryTextGray,
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  marginBottom: '0.2em',
}));

const DEFAULT_FORM_VALUES: OfferFormValues = {
  extra_info: '',
  contact_person: 0,
  offer_lines: [{
    resource: 0,
    end: new Date(),
    begin: new Date(),
    unit_price: 0,
    availability_dates: [],
    total_price: 0
  }],
  total_price: 0,

}

export const OfferFormView: React.FC = () => {
  const { t } = useTranslation();
  const { StyledProgress } = useProgress();
  const { enqueueSnackbar } = useSnackbar();
  const { profileData } = useAuthContext()
  const navigate = useNavigate();
  const { id } = useParams();
  const [formValues, setFormValues] = useState<OfferFormValues>(DEFAULT_FORM_VALUES);
  const [formErrors, setFormErrors] = useState<FormError>({});
  const [loading, setLoading] = useState<boolean>(false);
  const { companyContacts, setCompanyContacts } = useCompanyContext();
  const { resources, fetchResources } = useResourceContext();
  const { limit, offset } = usePaginationContext();
  const defaultCompany = profileData.company;
  const [updateAvailabilityDays, setUpdateAvailabilityDays] = useState(false);
  const requestForProposal = useLocation()?.state as RequestForProposal;
  const [ownResources, setOwnResources] = useState<Resource[]>([]);
  const [selectedOwnResource, setSelectedOwnResource] = useState<number[]>([]);

  const editing = id ? true : false;

  // get all company contacts
  useEffect(() => {
    (async (): Promise<void> => {
      setLoading(true);
      const { data } = await Api.get<CompanyContactsResponse>(endpoints.contacts, {
        params: {
          company: `${defaultCompany}`,
        },
      });
      const { results } = data;
      setLoading(false);
      setCompanyContacts(results);
    })();
  }, [defaultCompany, setCompanyContacts]);

  // fetch resources
  useEffect(()=>{
    if(!!defaultCompany){      
      fetchResources(Number(defaultCompany), offset, limit,0);
    }
  },[defaultCompany, limit, offset, fetchResources]); 

  // get own resources
  useEffect(()=>{
    if(resources){
      const ownResources = resources.filter((item: Resource) => Number(item.company) === Number(defaultCompany));
      setOwnResources(ownResources);
    }
  },[resources, defaultCompany]);

  // calculate availability days to offer_lines
  useEffect(() => {
    if (updateAvailabilityDays && formValues) {
        const updatedOfferLines = (formValues.offer_lines ?? []).map((item: OfferLineFormValues) => {
          if (ownResources) {
            const resource = ownResources.find((r) => r.id === Number(item.resource));
            if (!resource || resource === undefined) {
              return {...item};
            }

            const availability_dates: string[] = resource.availability_periods.flatMap((obj) => {
              const begin = new Date(obj.begin);
              const end = new Date(obj.end);
              const flatDatesArray = [];
              const currentDate = new Date(begin);
              while (currentDate <= end) {
                flatDatesArray.push((new Date(currentDate)).toISOString().substring(0, 10));
                currentDate.setDate(currentDate.getDate() + 1);
              }
              return flatDatesArray;
            });

            // Use the daily price of the resource as default unit_price
            if (item.unit_price === 0) {
              item.unit_price = Number(resource.product.price);
            }

            const begin = new Date(item.begin);
            const end = new Date(item.end);
            const days: number = Math.ceil((end.getTime() - begin.getTime()) / (1000 * 60 * 60 * 24)) + 1;
            const total_price = days * item.unit_price;

            return {...item, availability_dates, total_price};
          }
        }) as OfferLineFormValues[];

        const total: number = updatedOfferLines.reduce((accumulator: number, offer_line: any) => {
          return accumulator + offer_line.total_price;
        }, 0);

        setFormValues({ ...formValues, offer_lines: updatedOfferLines as OfferLineFormValues[], total_price: total});
        setUpdateAvailabilityDays(false);
    }
  }, [ownResources, formValues, updateAvailabilityDays]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (id) {
        setLoading(true);
        const { data } = await Api.get<OfferFormValues>(endpoints.offer(Number(id)));
        setFormValues(data);
        setUpdateAvailabilityDays(true);
        setLoading(false);
      }
    })();
  }, [setFormValues, id]);

  const hasError = useCallback(
    (key: string): boolean => {
      return Object.keys(formErrors).some((item) => item === key);
    },
    [formErrors],
  );

  const renderFormErrorHelperText = useMemo(
    () =>
      (key: string): JSX.Element | void => {
        if (hasError(key)) {
          return <FormHelperText>{formErrors[key]}</FormHelperText>;
        }
      },
    [formErrors, hasError],
  );

  const handleFormChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const { name, value } = e.target;
      const fieldNameParts = name.split('.');
      if (fieldNameParts.length > 1 ) {
        const fieldIndex = Number(fieldNameParts[1]);
        const updatedOfferLines = [...formValues.offer_lines];
        updatedOfferLines[fieldIndex] = {
          ...updatedOfferLines[fieldIndex],
          [fieldNameParts[2]]: value,
        };
        setFormValues({ ...formValues, offer_lines: updatedOfferLines });
      }  else {
        setFormValues({ ...formValues, [name]: value });
      }
      setUpdateAvailabilityDays(true);
    },
    [formValues],
  );

  const handleDateChange = useCallback(
    (newValue: Date | null , name: string, index: number): void => {
      if (newValue) {
        const updatedOfferLines = [...formValues.offer_lines];
        updatedOfferLines[index] = {
          ...updatedOfferLines[index],
          [name]: newValue,
        };
        setFormValues({...formValues, offer_lines: updatedOfferLines});
        setUpdateAvailabilityDays(true);
      }
    },
    [formValues],
  );

  const handleAddOfferLine = useCallback(
    (item: string | null): void =>{
      if (item) {
        const newFormValues = JSON.parse(JSON.stringify(formValues));
        const offerLines = formValues.offer_lines;
        const date = new Date();
        date.setDate(date.getDate() + 1);
        const newOfferLine = {
          offer: id,
          resource: Number(item),
          end: date,
          begin: date,
          unit_price: 0,
        }
        const newOfferLines = [...offerLines, newOfferLine];
        newFormValues.offer_lines = newOfferLines;
        setSelectedOwnResource([...selectedOwnResource, Number(item)]);
        setFormValues(newFormValues);
        setUpdateAvailabilityDays(true);
      }
  },[formValues, setFormValues, id, selectedOwnResource]);

  const handleDeleteOfferLine = useCallback(
    (item: OfferLineFormValues): void =>{
      const newFormValues = JSON.parse(JSON.stringify(formValues));
      const offerLines = formValues.offer_lines;
      const newOfferLines = offerLines.filter(
        (line: OfferLineFormValues)=> line.resource !== item.resource
      );
      newFormValues.offer_lines = newOfferLines;
      const remainingOwnResource = selectedOwnResource.filter((resource) => resource !== Number(item.resource));
      setSelectedOwnResource(remainingOwnResource);
      setFormValues(newFormValues);
  },[formValues, setFormValues, selectedOwnResource]);

  const handleCancel = useCallback(
    async (e: SyntheticEvent): Promise<void> => {
      e.preventDefault();
      setFormValues(DEFAULT_FORM_VALUES);
      setFormErrors({});
      if (editing) {
        navigate(urls.offer(Number(id)));
      } else {
        navigate(urls.offers);
      }
    },
    [navigate, editing, id],
  );

 const handleUpdate = useCallback(
    async (e: SyntheticEvent): Promise<void> => {
      e.preventDefault();
      try {
        const formData = {
          extra_info: formValues.extra_info,
          contact_person: formValues.contact_person,
          offer_lines: formValues.offer_lines.map((offerLine: OfferLineFormValues) => ({
            resource: offerLine.resource,
            end: offerLine.end,
            begin: offerLine.begin,
            unit_price: offerLine.unit_price,
          })),
        };
        const { data } = await Api.patch<OfferFormValues>(endpoints.offer(Number(id)), formData);
        if (data.id) {
          enqueueSnackbar(t('misc.updateSuccess'), {
            variant: 'success',
          });
          navigate(urls.offer(data.id));
          setFormValues(DEFAULT_FORM_VALUES);
        }
      } catch (err: any) {
        setFormErrors(err.response.data);
      }
    },
    [formValues, navigate, enqueueSnackbar, t, id],
  );

  // Custom function to determine which dates should be disabled
  const shouldDisableDate = useCallback(
    (date: Date, availability_dates: any) => {
      if (availability_dates.length === 0){
        return true;
      }
      const availabilityDates = new Set(availability_dates);
      return !availabilityDates.has(date.toISOString().substring(0, 10));

    },
    []
  );

  // render RFP details
  const renderRfpDetails = useMemo(
    ()=>(
      <Grid container item direction="row" xs={12} pb={2}>
        <Grid item xs={4}>
          <Typography variant="body2">{t('requestsForProposal.generalDetailsTitle')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container pb={2}>
            <Grid item xs={6}>
              <Typography variant="h6" mb={1}>{t('requestsForProposal.title')}</Typography>
              <Typography variant="subtitle1">{requestForProposal.title}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" mb={1}>{t('requestsForProposal.location')}</Typography>
              <Typography variant="subtitle1">{requestForProposal.work_location}</Typography>
            </Grid>
          </Grid>
          <Grid container pb={2}>
            <Grid item xs={6}>
              <Typography variant="h6" mb={1}>{t('requestsForProposal.accommodationBy')}</Typography>
              <Typography variant="subtitle1">{requestForProposal.accommodation_by}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" mb={1}>{t('offers.workPeriod')}</Typography>
              <Typography variant="subtitle1">
                {!!requestForProposal.work_start_date && format(new Date(requestForProposal.work_start_date), 'dd.MM.yyyy')}
                &nbsp;&ndash;&nbsp;
                {!!requestForProposal.work_end_date && format(new Date(requestForProposal.work_end_date), 'dd.MM.yyyy')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container pb={2} pr={4}>
            <Grid item>
              <Typography variant="h6" mb={1}>{t('requestsForProposal.workDescription')}</Typography>
              <Typography variant="subtitle1">{requestForProposal.work_description}</Typography>
            </Grid>
          </Grid>
          { requestForProposal.extra_info && (
            <Grid container pb={2} pr={4}>
              <Grid item>
                <Typography variant="h6" mb={1}>{t('requestsForProposal.extraInfo')}</Typography>
                <Typography variant="subtitle1">{requestForProposal.extra_info}</Typography>
              </Grid>
            </Grid>
          )}
          { requestForProposal.link_to_material && (
            <Grid container pb={2} pr={4}>
              <Grid item>
                <Typography variant="h6" mb={1}>{t('requestsForProposal.linkToMaterial')}</Typography>
                <Typography variant="subtitle1">
                  <Link
                    href={requestForProposal.link_to_material}
                    underline="hover"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {requestForProposal.link_to_material}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    ),[requestForProposal, t]);

  const calculateTotalPrice = useCallback(
    (formValues: OfferFormValues) : number => {
      const offerLinePrices = formValues.offer_lines.map(item => item.total_price);
      const totalPrice = offerLinePrices.reduce((prev, next) => prev + next, 0);
      return totalPrice;
    },
    []
  );

  const getOfferLineResourceName = useCallback(
    (resouceID: number | string): string => {
      const selectedResource = ownResources.find((item) => item.id === Number(resouceID));
      return `${selectedResource?.product.title} - ${selectedResource?.internal_name}`;
    },[ownResources]);

  const render = useMemo(
    () => (
      <>
        <Grid container direction="row" justifyContent="space-between" pr={1}>
          <Grid item>
            <StyledNavLink to={urls.offers}>
              <Grid container direction="row" alignItems="center">
                <ArrowBackIosIcon sx={{ fontSize: "0.8em", marginRight: "0.5em" }} ></ArrowBackIosIcon>
                <Typography variant="h6">
                  {t('offers.returnLinkLabel')}
                </Typography>
              </Grid>
            </StyledNavLink>
          </Grid>
        </Grid>
        <Grid item pb={0.5} pr={1} xs={12}>
          <Paper>
            {loading && <StyledProgress size={24} />}
            <Box p={1} pt={3} pb={3} m={2}>
              <Grid container direction="row" justifyContent="space-between" pb={2} mb={4}>
                <Grid item>
                  <Typography variant="h5">{t('offers.offerFormTitle')}</Typography>
                </Grid>
                <Grid item>
                  <>
                    <StyledButton variant="contained" size="small" onClick={handleCancel}>
                      {t('misc.cancelButtonLabel')}
                    </StyledButton>
                    {editing && (
                      <StyledButton variant="contained" size="small" onClick={handleUpdate}>
                        {t('misc.saveButtonLabel')}
                      </StyledButton>
                    )}
                  </>
                </Grid>
              </Grid>
              {renderRfpDetails}
              <Divider variant="fullWidth" />
              <Grid container item direction="row" xs={12} pb={2} pt={4}>
                <Grid item xs={4}>
                  <Typography variant="body2">{t('offers.generalDetailsTitle')}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Grid container pb={2}>
                    <Grid item xs={9}>
                      <FormControl error={hasError('extra_info')}>
                        <>
                          <StyledFormLabel>{t('requestsForProposal.extraInfo')}</StyledFormLabel>
                          <TextField
                            name="extra_info"
                            value={formValues.extra_info}
                            onChange={handleFormChange}
                            size="small"
                            multiline
                            minRows={4}
                          />
                          {renderFormErrorHelperText('extra_info')}
                        </>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Divider variant="fullWidth" />

              <Grid container item direction="row" xs={12} pt={4}>
                <Grid item xs={4}>
                  <Typography variant="body2">{t('requestsForProposal.contactDetailsTitle')}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Grid container pb={2}>
                    <Grid item xs={9}>
                      <FormControl error={hasError('contact_person')}>
                        <>
                          <StyledFormLabel required>{t('offers.contactPerson')}</StyledFormLabel>
                          <TextField
                            data-testid="contact_person-wrapper"
                            name="contact_person"
                            select
                            size="small"
                            onChange={handleFormChange}
                            value={formValues.contact_person}
                            inputProps={{
                              'data-testid': 'contact_person',
                            }}
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{
                              native: true,
                              displayEmpty: true,
                              required: true,
                            }}
                          >
                            <option value={''}>-- {t('offers.selectContactPerson')} --</option>
                            {companyContacts.map((option) => (
                              <option key={option.id} value={option.id}>
                                {`${option.first_name} ${option.last_name}`}
                              </option>
                            ))}
                          </TextField>
                          {renderFormErrorHelperText('contact_person')}
                        </>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Divider variant="fullWidth" />

              <Grid container item direction="row" xs={12} pt={4}>
                <Grid item xs={4}>
                  <Typography variant="body2">{t('offers.resourcesTitle')}</Typography>
                </Grid>
                {formValues.offer_lines.map((offer_line: OfferLineFormValues, index) => (
                  <Grid container pb={2}>
                    <Grid item xs={14} key={index}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <FormControl error={hasError('offer_lines')}>
                            <>
                              <StyledFormLabel>{t('offers.resource')}</StyledFormLabel>
                              <TextField
                                data-testid={`offer_lines-${index}`}
                                name={`offer_lines.${index}.resource`}
                                size="small"
                                value={getOfferLineResourceName(offer_line.resource)}
                                inputProps={{
                                  'data-testid': `offer_lines-${index}`,
                                }}
                                InputLabelProps={{shrink: true}}
                              />
                            </>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <StyledFormLabel required>{t('requestsForProposal.workDates')}</StyledFormLabel>
                          <Grid container item xs={12}>
                            <Grid item xs={5}>
                              <FormControl error={hasError('begin')}>
                                <>
                                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fiLocale}>
                                    <DatePicker
                                      disableMaskedInput
                                      renderInput={(params): any => <TextField size="small" {...params} />}
                                      value={offer_line.begin}
                                      onChange={(newValue): void => handleDateChange(newValue, "begin", index)}
                                      shouldDisableDate={(date): boolean => {
                                        if (offer_line.availability_dates === undefined || offer_line.availability_dates.length === 0) {
                                          return true;
                                        } else {
                                          return shouldDisableDate(date, offer_line.availability_dates);
                                        }
                                      }}
                                    />
                                  </LocalizationProvider>
                                  {renderFormErrorHelperText('begin')}
                                </>
                              </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                              <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                              >
                                <ArrowRightAltIcon sx={{fontSize: "2.2em", paddingTop: "0.1em"}}></ArrowRightAltIcon>
                              </Box>
                            </Grid>
                            <Grid item xs={5}>
                              <FormControl error={hasError('end')}>
                                <>
                                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fiLocale}>
                                    <DatePicker
                                      disableMaskedInput
                                      renderInput={(params): any => <TextField size="small" {...params} />}
                                      value={offer_line.end}
                                      onChange={(newValue): void => handleDateChange(newValue, "end", index)}
                                      shouldDisableDate={(date): boolean => {
                                        if (offer_line.availability_dates === undefined || offer_line.availability_dates.length === 0) {
                                          return true;
                                        } else {
                                          return shouldDisableDate(date, offer_line.availability_dates);
                                        }
                                      }}
                                    />
                                  </LocalizationProvider>
                                  {renderFormErrorHelperText('end')}
                                </>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid container item xs={12}>
                            <Grid item xs={4} mr={2}>
                              <StyledFormLabel required>{t('offers.dailyPrice')}</StyledFormLabel>
                              <FormControl error={hasError('unit_price')}>
                                <>
                                  <TextField
                                    name={`offer_lines.${index}.unit_price`}
                                    value={formValues.offer_lines[index].unit_price}
                                    onChange={handleFormChange}
                                    size="small"
                                  />
                                  {renderFormErrorHelperText('unit_price')}
                                </>
                              </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                              <StyledFormLabel>{t('offers.totalPrice')}</StyledFormLabel>
                              <Typography pt={1}>{offer_line.total_price} €</Typography>
                            </Grid>
                            <Grid item xs={2} mt={4}>
                              <Box
                                  display="flex"
                                  justifyContent="right"
                                  alignItems="right"
                              >
                                <DeleteIcon onClick={(): void => handleDeleteOfferLine(offer_line)}
                                            sx={{color: colors.darkBlue, pt: 0.5}}/>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <FormControl error={hasError('offer_lines')}>
                        <>
                          <StyledFormLabel>{t('offers.resource')}</StyledFormLabel>
                          <TextField
                            data-testid={`offer_lines__add`}
                            name={`offer_lines__add`}
                            select
                            size="small"
                            onChange={(event): void => {
                              handleAddOfferLine(event.target.value);
                              event.target.value = '';
                            }}
                            value={''}
                            InputLabelProps={{shrink: true}}
                            SelectProps={{
                              native: true,
                              displayEmpty: true,
                            }}
                          >
                            <option value={''}>-- {t('offers.addResource')} --</option>
                            {ownResources.filter((item) => !selectedOwnResource.includes(Number(item.id))).map((option) => (
                              <option key={option.id} value={option.id}>
                                {`${option.product.title} - ${option.internal_name}`}
                              </option>
                            ))}
                          </TextField>
                        </>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container pt={4} justifyContent="flex-end">
                <Typography variant="h5" sx={{fontWeight: 'bold'}}>{t('offers.totalPrice')}:&nbsp;&nbsp;
                  {!!formValues.offer_lines.length && numberToLocale(calculateTotalPrice(formValues))}
                </Typography>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </>
    ),
    [
      t,
      formValues,
      hasError,
      renderFormErrorHelperText,
      StyledProgress,
      loading,
      editing,
      handleFormChange,
      handleCancel,
      handleUpdate,
      companyContacts,
      handleDateChange,
      ownResources,
      handleAddOfferLine,
      handleDeleteOfferLine,
      shouldDisableDate,
      renderRfpDetails,
      selectedOwnResource,
      calculateTotalPrice,
      getOfferLineResourceName
    ],
  );

  return <Main>{render}</Main>;
};
