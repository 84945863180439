import {Button, Grid, styled, Typography, Divider, Paper, Box, Link, Chip} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { useAuthContext } from '../context';
import { colors } from '../theme';
import Api from '../api';
import {endpoints, requestForProposalStates, urls} from '../utils';
import { useProgress } from '../hooks';
import {RequestForProposal, Offer} from '../types';
import { Main } from '../layout';
import {useSnackbar} from "notistack";
import {ConfirmDialog} from "../components/common/ConfirmDialog";

const StyledButton = styled(Button)(() => ({
  marginRight: '0.5em',
}));

const StyledNavLink = styled(NavLink)(() => ({
  textDecoration: 'none',
  color: colors.secondaryTextGray,
}));

export const defaultRFP: RequestForProposal = {
  id: '',
  created_at: new Date(),
  title: '',
  company_id: 0,
  company_name: '',
  work_description: '',
  work_location: '',
  work_start_date: new Date(),
  work_end_date: new Date(),
  start_time: '',
  accommodation_by: '',
  contact_name: '',
  contact_phone: '',
  street: '',
  zip_code: '',
  municipality: '',
  valid_until: new Date(),
  user: 0,
  extra_info: '',
  is_valid: true,
  is_own: true,
  is_editable: true,
  state: '',
  link_to_material: '',
}

export const RequestForProposalDetailView: React.FC = () => {
  const { t } = useTranslation();
  const { StyledProgress } = useProgress();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { profileData } = useAuthContext()
  const navigate = useNavigate();

  const [requestForProposal, setRequestForProposal] = useState<RequestForProposal>(defaultRFP);
  const [loading, setLoading] = useState<boolean>(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false);

  const canEdit = requestForProposal.is_own && requestForProposal.is_editable;
  const canMakeOffer = requestForProposal.company_id !== profileData.company && requestForProposal.state === requestForProposalStates.OPEN;

  useEffect(() => {
    (async (): Promise<void> => {
      setLoading(true);
      const { data } = await Api.get<RequestForProposal>(endpoints.requestForProposal(Number(id)));
      setRequestForProposal(data)
      setLoading(false);
    })();
  }, [setRequestForProposal, id]);

  const handleEdit = useCallback(
    (id: string): void => {
      navigate(urls.requestForProposalEdit(id));
    },
    [navigate],
  );

  const handleCreateOffer = useCallback(
    async (): Promise<void> => {
      try {
        const offer = {
          request_for_proposal: requestForProposal.id,
          offer_lines: []
        }
        const { data } = await Api.post<Offer>(endpoints.offers, offer);
        if (data.id) {
          navigate(urls.offerEdit(data.id), {state: requestForProposal});
        }
        enqueueSnackbar(t('offers.createSuccess'), {
          variant: 'success',
        });
      } catch (err: any) {
        enqueueSnackbar(t('offers.createFail'), {
          variant: 'error',
        });
      }
    },
    [navigate, requestForProposal, enqueueSnackbar, t],
  );

  const handleCancelRFP = useCallback(
    async (): Promise<void> => {
      try {
        await Api.get<RequestForProposal>(endpoints.requestForProposalCancel(Number(id)));
        enqueueSnackbar(t('misc.updateSuccess'), {
          variant: 'success',
        });
        const { data: offerData } = await Api.get<RequestForProposal>(endpoints.requestForProposal(Number(id)));
        setRequestForProposal(offerData)
      } catch (err: any) {
        enqueueSnackbar(t('misc.updateFailed'), {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, t, id]
  );

  const openCancelDialog = useCallback((): void => {
    setCancelDialogOpen(true);
  }, [setCancelDialogOpen]);

  const closeCancelDialog = useCallback((): void => {
    setCancelDialogOpen(false);
    handleCancelRFP();
  }, [setCancelDialogOpen, handleCancelRFP]);

  const cancelCancelDialog = useCallback((): void => {
    setCancelDialogOpen(false);
  }, [setCancelDialogOpen]);

  const renderRFPState = useCallback(
    (status: string) => {
      switch (status) {
        case requestForProposalStates.CLOSED:
          return <Chip label={t(`requestsForProposal.states.${status}`)} color="error" />
        case requestForProposalStates.OPEN:
        case requestForProposalStates.WAITING_FOR_BUYER:
          return <Chip label={t(`requestsForProposal.states.${status}`)} color="info" />
        case requestForProposalStates.EXPIRED:
          return <Chip label={t(`requestsForProposal.states.${status}`)} color="warning" />
        default:
          return <Chip label={t(`requestsForProposal.states.${status}`)} />
      }
    },
    [t],
  );

  const render = useMemo(
    () => (
      <>
        <Grid container direction="row" justifyContent="space-between" pr={1}>
          <Grid item>
            <StyledNavLink to={urls.requestsForProposal}>
              <Grid container direction="row" alignItems="center">
                <ArrowBackIosIcon sx={{ fontSize: "0.8em", marginRight: "0.5em" }} ></ArrowBackIosIcon>
                <Typography variant="h6">
                  {t('requestsForProposal.returnLinkLabel')}
                </Typography>
              </Grid>
            </StyledNavLink>
          </Grid>
        </Grid>
        <Grid pb={0.5} pr={1} xs={12}>
          <Paper>
            {loading && <StyledProgress size={24} />}
            <Box p={1} pt={3} pb={3} m={2}>
              <Grid container direction="row" justifyContent="space-between" pb={2} mb={4}>
                <Grid item>
                  <Typography variant="h5">{requestForProposal.title}</Typography>
                </Grid>
                <Grid item>
                  <>
                    {canEdit && (
                      <Grid container direction="column" alignItems="flex-end">
                        <Grid item container direction="row" justifyContent="flex-end">
                          {requestForProposal.state === requestForProposalStates.OPEN && (
                            <Grid item mr={1}>
                              <Button variant="contained" onClick={openCancelDialog} size="small">
                                {t('requestsForProposal.cancelRfpButtonLabel')}
                              </Button>
                            </Grid>
                          )}
                          <StyledButton variant="contained" onClick={(): void => handleEdit(`${requestForProposal.id}`)} size="small">
                            {t('misc.editButtonLabel')}
                          </StyledButton>
                        </Grid>
                      </Grid>
                    )}
                    {canMakeOffer && (
                      <Button variant="contained" onClick={handleCreateOffer} size="small">
                        {t('requestsForProposal.makeOfferButtonLabel')}
                      </Button>
                    )}
                  </>
                </Grid>
              </Grid>
              <Grid container direction="row" xs={12} pb={2}>
                <Grid item xs={4}>
                  <Typography variant="body2">{t('requestsForProposal.generalDetailsTitle')}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Grid container pb={2}>
                    <Grid item xs={12}>
                      {renderRFPState(requestForProposal.state)}
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('requestsForProposal.createdAt')}</Typography>
                      <Typography variant="subtitle1">{format(new Date(requestForProposal.created_at), 'dd.MM.yyyy')}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('requestsForProposal.validUntil')}</Typography>
                      <Typography variant="subtitle1">{format(new Date(requestForProposal.valid_until), 'dd.MM.yyyy')}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('requestsForProposal.title')}</Typography>
                      <Typography variant="subtitle1">{requestForProposal.title}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('requestsForProposal.company')}</Typography>
                      <Typography variant="subtitle1">{requestForProposal.company_name}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('requestsForProposal.workDates')}</Typography>
                      <Typography variant="subtitle1">
                        {!!requestForProposal.work_start_date && format(new Date(requestForProposal.work_start_date), 'dd.MM.yyyy')}
                        &nbsp;&ndash;&nbsp;
                        {!!requestForProposal.work_end_date && format(new Date(requestForProposal.work_end_date), 'dd.MM.yyyy')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('requestsForProposal.workStartTime')}</Typography>
                      <Typography variant="subtitle1">{requestForProposal.start_time}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('requestsForProposal.location')}</Typography>
                      <Typography variant="subtitle1">{requestForProposal.work_location}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('requestsForProposal.accommodationBy')}</Typography>
                      <Typography variant="subtitle1">{requestForProposal.accommodation_by}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container pb={2} pr={4}>
                    <Grid item>
                      <Typography variant="h6" mb={1}>{t('requestsForProposal.workDescription')}</Typography>
                      <Typography variant="subtitle1">{requestForProposal.work_description}</Typography>
                    </Grid>
                  </Grid>
                  { requestForProposal.extra_info && (
                    <Grid container pb={2} pr={4}>
                      <Grid item>
                        <Typography variant="h6" mb={1}>{t('requestsForProposal.extraInfo')}</Typography>
                        <Typography variant="subtitle1">{requestForProposal.extra_info}</Typography>
                      </Grid>
                    </Grid>
                  )}
                  { requestForProposal.link_to_material && (
                    <Grid container pb={2} pr={4}>
                      <Grid item>
                        <Typography variant="h6" mb={1}>{t('requestsForProposal.linkToMaterial')}</Typography>
                        <Typography variant="subtitle1">
                          <Link
                            href={requestForProposal.link_to_material}
                            underline="hover"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {requestForProposal.link_to_material}
                          </Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Divider variant="fullWidth" />

              <Grid container direction="row" xs={12} pt={4}>
                <Grid item xs={4}>
                  <Typography variant="body2">{t('requestsForProposal.contactDetailsTitle')}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Grid container pb={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('requestsForProposal.contactName')}</Typography>
                      <Typography variant="subtitle1">{requestForProposal.contact_name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('requestsForProposal.contactPhone')}</Typography>
                      <Typography variant="subtitle1">{requestForProposal.contact_phone}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('requestsForProposal.street')}</Typography>
                      <Typography variant="subtitle1">{requestForProposal.street}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('requestsForProposal.postalArea')}</Typography>
                      <Typography variant="subtitle1">{requestForProposal.zip_code} {requestForProposal.municipality}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </>
    ),
    [
      t,
      requestForProposal.id,
      requestForProposal.title,
      requestForProposal.company_name,
      requestForProposal.work_description,
      requestForProposal.work_start_date,
      requestForProposal.work_end_date,
      requestForProposal.work_location,
      requestForProposal.valid_until,
      requestForProposal.start_time,
      requestForProposal.accommodation_by,
      requestForProposal.contact_name,
      requestForProposal.contact_phone,
      requestForProposal.street,
      requestForProposal.zip_code,
      requestForProposal.municipality,
      requestForProposal.extra_info,
      requestForProposal.created_at,
      requestForProposal.state,
      requestForProposal.link_to_material,
      StyledProgress,
      loading,
      canEdit,
      canMakeOffer,
      handleCreateOffer,
      handleEdit,
      openCancelDialog,
      renderRFPState,
    ],
  );

  if (cancelDialogOpen) {
    return (
      <ConfirmDialog
        text={t('requestsForProposal.cancelRfpConfirmation', {title: requestForProposal.title})}
        isOpen={cancelDialogOpen}
        handleClose={closeCancelDialog}
        handleCancel={cancelCancelDialog}
        severity="warning"
      />
    );
  }

  return <Main>{render}</Main>;
};
