import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { 
    Grid,
    Tab,
    Typography,
    TableBody,
    TableCell,
    TableRow,
    Button,
    TableFooter,
    Chip,
    TextField,
    InputAdornment,
    styled,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { format } from 'date-fns';

import { useAuthContext, useRequestForProposalContext, usePaginationContext } from '../../context';
import { RequestsForProposalListResponse, RequestForProposal, FilterFunc, Record } from '../../types';
import Api from '../../api';
import { endpoints, requestForProposalStates, urls } from '../../utils';
import { colors } from '../../theme';
import { useProgress, useTable } from '../../hooks';


const StyledButton = styled(Button)(() => ({
  marginRight: '0.4em',
}));

export const RFPList: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState<string>('1');
  const { allRequestsForProposal, setAllRequestsForProposal, countAllRequestsForProposal, setCountAllRequestsForProposal } = useRequestForProposalContext();
  const [openRequestsForProposal, setOpenRequestsForProposal] = useState<RequestForProposal[]>([]);
  const [countOpenRequestsForProposal, setCountOpenRequestsForProposal] = useState<number>(0);
  const [waitingRequestsForProposal, setWaitingRequestsForProposal] = useState<RequestForProposal[]>([]);
  const [countWaitingRequestsForProposal, setCountWaitingRequestsForProposal] = useState<number>(0);
  const [ownRequestsForProposal, setOwnRequestsForProposal] = useState<RequestForProposal[]>([]);
  const [countOwnRequestsForProposal, setCountOwnRequestsForProposal] = useState<number>(0);
  const { profileData } = useAuthContext();
  const { limit, offset } = usePaginationContext();
  const [loading, setLoading] = useState<boolean>(false);
  const { StyledProgress } = useProgress();

  useEffect(() => {
    (async (): Promise<void> => {
      setLoading(true);
      const { data } = await Api.get<RequestsForProposalListResponse>(endpoints.requestsForProposal, {
        params:{
          offset,
          limit
        }
      });
      const { results, count } = data;
      const resultsCopy = JSON.parse(JSON.stringify(results));
      const open = resultsCopy.filter((item: RequestForProposal) => item.state === requestForProposalStates.OPEN);
      const waiting = resultsCopy.filter((item: RequestForProposal) => item.state === requestForProposalStates.WAITING_FOR_BUYER);
      const own = resultsCopy.filter((item: RequestForProposal) => Number(item.company_id) === Number(profileData.company));

      setOpenRequestsForProposal(open);
      setWaitingRequestsForProposal(waiting);
      setCountWaitingRequestsForProposal(waiting.length);
      setCountOpenRequestsForProposal(open.length);
      setAllRequestsForProposal(results);
      setOwnRequestsForProposal(own);
      setCountAllRequestsForProposal(count);
      setCountOwnRequestsForProposal(own.length);
      setLoading(false);
    })();
  }, [
    profileData,
    setOpenRequestsForProposal,
    setCountOpenRequestsForProposal,
    setWaitingRequestsForProposal,
    setCountWaitingRequestsForProposal,
    setAllRequestsForProposal,
    setCountAllRequestsForProposal,
    setOwnRequestsForProposal,
    setCountOwnRequestsForProposal,
    offset,
    limit
  ]);

  const handleTabChange = useCallback(
    (event: SyntheticEvent, newValue: string): void => {
      setTabValue(newValue);
    },
    [setTabValue],
  );

  const headCells = [
    { id: 'company_name', label: t('requestsForProposal.company'), sortable: true },
    { id: 'title', label: t('requestsForProposal.title'), sortable: true },
    { id: 'valid_until', label: t('requestsForProposal.validUntil'), sortable: true },
    { id: 'state', label: t('requestsForProposal.state'), sortable: true },
    { id: 'work_location', label: t('requestsForProposal.location'), sortable: true },
    { id: 'work_start_date', label: t('requestsForProposal.workPeriod'), sortable: true },
    { id: 'actions', label: t('requestsForProposal.actions'), sortable: false },
  ];

  const [filterFunction, setFilterFunction] = useState<FilterFunc>({
    filterRecord: (items: Record[]): Record[] => items,
  });

  const tableOpen = useTable(
    openRequestsForProposal,
    headCells,
    filterFunction,
    countOpenRequestsForProposal
  );

  const tableWaiting = useTable(
    waitingRequestsForProposal,
    headCells,
    filterFunction,
    countWaitingRequestsForProposal
  );

  const tableAll = useTable(
    allRequestsForProposal,
    headCells,
    filterFunction,
    countAllRequestsForProposal
  );

  const tableOwn = useTable(
    ownRequestsForProposal,
    headCells,
    filterFunction,
    countOwnRequestsForProposal
  );

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const searchText = event.target.value;
      setFilterFunction({
        filterRecord: (items: Record[]): Record[] => {
          if (!searchText.length) {
            return items;
          } else {
            return items.filter((item) => {
              return (
                `${item.title}`.toLocaleLowerCase().includes(searchText.toLowerCase()) ||
                `${item.company_name}`.toLocaleLowerCase().includes(searchText.toLowerCase()) ||
                `${item.work_location}`.toLocaleLowerCase().includes(searchText.toLowerCase())
              );
            });
          }
        },
      });
    },
    [],
  );

  const handleOpen = useCallback(
    (id: string): void => {
      navigate(urls.requestForProposal(id));
    },
    [navigate],
  );

  const handleEdit = useCallback(
    (id: string): void => {
      navigate(urls.requestForProposalEdit(id));
    },
    [navigate],
  );

  const getColorByStatus = (state: string): any => {
    switch (state) {
      case requestForProposalStates.OPEN:
      case requestForProposalStates.WAITING_FOR_BUYER:
        return "info";
      case requestForProposalStates.CLOSED:
        return "error";
      case requestForProposalStates.EXPIRED:
        return "warning";
      default:
        return "default";
    }
  };
  
  const renderTable = useCallback(  
    (items: RequestForProposal[], table: any) => {
      return (
        <>
        {loading && <StyledProgress size={24} />}
        <table.TableContainer>
          <table.TblHead />
          <TableBody>   
              {items.map((item: RequestForProposal) => (
              <TableRow key={item.id} hover>
                <TableCell>{item.company_name}</TableCell>
                <TableCell>{item.title}</TableCell>
                <TableCell>{format(new Date(item.valid_until), 'dd.MM.yyyy')}</TableCell>
                <TableCell>
                  <Chip label={t(`requestsForProposal.states.${item.state}`)} color={getColorByStatus(item.state)} key={`${item.id}`} />
                </TableCell>
                <TableCell>{item.work_location}</TableCell>
                <TableCell>{!!item.work_start_date && format(new Date(item.work_start_date), 'dd.MM.yyyy')} - {!!item.work_end_date && format(new Date(item.work_end_date), 'dd.MM.yyyy')}</TableCell>
                <TableCell>
                  <StyledButton onClick={(): void => handleOpen(`${item.id}`)} size="small" variant="contained">
                    {t('requestsForProposal.open')}
                  </StyledButton>
                  {item.is_own && item.is_editable && (
                    <StyledButton onClick={(): void => handleEdit(`${item.id}`)} size="small" variant="contained">
                      <EditIcon />
                    </StyledButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <table.TblPagination />
            </TableRow>
          </TableFooter>
        </table.TableContainer>
        </>
      )
    },
    [t, loading, StyledProgress, handleOpen, handleEdit],
  );

  const renderTabs = useMemo(
    ()=>(
      <TabList
        onChange={handleTabChange}
        aria-label="tabs"
        TabIndicatorProps={{
            style: {
            backgroundColor: colors.darkBlue,
          },
        }}
      >
        <Tab key={1} value="1" label={t('requestsForProposal.tabLabelOpen')} icon={<Chip label={countOpenRequestsForProposal} variant="filled" />} iconPosition="end" />
        <Tab key={2} value="2" label={t('requestsForProposal.tabLabelWaitingForBuyer')} icon={<Chip label={countWaitingRequestsForProposal} variant="filled" />} iconPosition="end" />
        <Tab key={3} value="3" label={t('requestsForProposal.tabLabelAll')} icon={<Chip label={countAllRequestsForProposal} variant="filled" />} iconPosition="end" />
        <Tab key={4} value="4" label={t('requestsForProposal.tabLabelOwn')} icon={<Chip label={countOwnRequestsForProposal} variant="filled" />} iconPosition="end" />
      </TabList>
    ), [
      t,
      handleTabChange,
      countAllRequestsForProposal,
      countOwnRequestsForProposal,
      countOpenRequestsForProposal,
      countWaitingRequestsForProposal
    ]
  );
 
  return (
    <TabContext value={tabValue}>
      <Grid>
        {renderTabs}
      </Grid>
      <Grid container mt={2}>
        <Grid item xs>
          <TextField
            data-testid="search-wrapper"
            name="search"
            size="small"
            fullWidth={false}
            placeholder={t('misc.search')}
            onChange={handleSearch}
            inputProps={{
              'data-testid': 'search',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              width: '50%',
            }}
          /> 
        </Grid>
      </Grid>

      {/* Open RFPs */}
      <TabPanel key="1" value="1" sx={{ pl: 0, pr: 0 }}>
        <Grid container mt={2}>
          <Grid item xs>
            <Typography variant="h4" mb={2}>{t('requestsForProposal.headerOpen')}</Typography>
            {renderTable(tableOpen.recordsAfterPagingAndSorting(), tableOpen)}
          </Grid>
        </Grid>
      </TabPanel>

      {/* RFPs waiting for buyer */}
      <TabPanel key="2" value="2" sx={{ pl: 0, pr: 0 }}>
        <Grid container mt={2}>
          <Grid item xs>
            <Typography variant="h4" mb={2}>{t('requestsForProposal.headerWaitingForBuyer')}</Typography>
            {renderTable(tableWaiting.recordsAfterPagingAndSorting(), tableWaiting)}
          </Grid>
        </Grid>
      </TabPanel>

      {/* All RFPs */}
      <TabPanel key="3" value="3" sx={{pl:0, pr:0}}>
        <Grid container mt={2}>
          <Grid item xs>
            <Typography variant="h4" mb={2}>{t('requestsForProposal.headerAll')}</Typography>
            {renderTable(tableAll.recordsAfterPagingAndSorting(), tableAll)}
          </Grid>
        </Grid>
      </TabPanel>

      {/* Own RFPs */}
      <TabPanel key="4" value="4" sx={{pl:0, pr:0}}>
        <Grid container mt={2}>
          <Grid item xs>
            <Typography variant="h4" mb={2}>{t('requestsForProposal.headerOwn')}</Typography>
            {renderTable(tableOwn.recordsAfterPagingAndSorting(), tableOwn)}
          </Grid>
        </Grid>
      </TabPanel>

    </TabContext>
  )
}
