export const numberToLocale = 
(number: string | number): string =>{
  return Number(number).toLocaleString('fi-FI', {style:"currency", currency:"EUR"});
};

export const formatNumber = (number: string | number): number => {
  const [integral, fractional] = `${number}`.split(',');
  return !!fractional ? Number(`${integral}.${fractional}`) : Number(`${integral}`);
}

export const formatToCommaSeparated = (number: string | number): string => {
  const [integral, fractional] = `${number}`.split('.');
  return !!fractional ? `${integral},${fractional}` : `${integral}`;
}

export const resourceTypeIsPerson = (name: string): boolean => {
  const personnelNames = ["Henkilö", "Montör", "Person"];
  return personnelNames.includes(name);
}

export const resourceTypeIsMachinery = (name: string): boolean => {
  const machineryNames = ["Kalusto", "Inventarier", "Machinery"];
  return machineryNames.includes(name);
}

export const getEarliestPossibleOrderDate = (): Date => {
  const today = new Date();
  const date = new Date();
  date.setDate(today.getDate() + 1);
  return date;
}
