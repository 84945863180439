import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Main } from '../layout';
import { ResourceSearch } from '../components/resources/';
import { Grid, Typography, Button } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useTranslation } from 'react-i18next';
import { useAllCompaniesContext, 
  useAllResourceAttributeOptionsContext,
  useAllResourceAttributesContext, 
  useAllResourceSubTypesContext,
  useCartContext, 
  useAdvertsContext } from '../context';
import { CartItemsResponse } from '../types';
import Api from '../api';
import { endpoints, urls } from '../utils';

export const ResourceSearchPage: React.FC = () => {
  const { t } = useTranslation();
  const { cart, cartItems, setCartItems } = useCartContext();
  const { fetchAllCompanies } = useAllCompaniesContext();
  const { fetchAllResourceAttributeOptions } = useAllResourceAttributeOptionsContext();
  const { fetchAllResourceAttributes } = useAllResourceAttributesContext();
  const { fetchAllResourceSubTypes } = useAllResourceSubTypesContext();
  const {fetchAdverts} = useAdvertsContext();

  const navigate = useNavigate();

  // Fetch the cart items
  useEffect(() => {
    (async (): Promise<void> => {
      const { data } = await Api.get<CartItemsResponse>(endpoints.cartItems);
      const { results } = data;
      setCartItems(results);
    })();
  }, [setCartItems]);

  // fetch all companies 
  useEffect(()=>{
    fetchAllCompanies();
  },[fetchAllCompanies]);

  // fetch all resource attribute options
  useEffect(()=>{
    fetchAllResourceAttributeOptions();
  },[fetchAllResourceAttributeOptions]);

  // fetch all resource attributes
  useEffect(()=>{
    fetchAllResourceAttributes();
  },[fetchAllResourceAttributes]);

  // fetch all resourceSubTypes
  useEffect(()=>{
    fetchAllResourceSubTypes();
  },[fetchAllResourceSubTypes]);

  // fetch sidenav adverts
  useEffect(()=>{
    fetchAdverts(false, true);
  },[fetchAdverts]);
  
  const handleCartButtonClick = useCallback(
    async (): Promise<void> => {
      navigate(urls.cart);
    },
    [navigate],
  );

  const render = useMemo(
    () => (
      <>
        <Grid container display="flex" m={2} mb={5} spacing={2}>
          <Grid item xs={10}>
            <Grid item>
              <Typography variant="h3">{t('resourceSearch.title')}</Typography>
              <Typography variant="subtitle1">{t('resourceSearch.subTitle')}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={2}>
            <Grid item xs={12} container justifyContent="flex-end">
              <Button
                onClick={handleCartButtonClick}
                variant="contained"
                size="small"
                sx={{
                  padding: 1
                }}
              >
                <ShoppingCartIcon />
                {`${t('resourceSearch.shoppingCartButtonLabel')}`} ({cartItems.length})
              </Button>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
              <Typography
                variant="subtitle2"
                sx={{
                  ml: 4,
                  padding: 1,
                }}>
                {(cartItems.length > 0) && `${t('resourceSearch.shoppingCartExpiresAt')} ${cart.expires_at}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <ResourceSearch />
      </>
    ),
    [t, cart, cartItems, handleCartButtonClick],
  );
  return <Main>{render}</Main>;
};
