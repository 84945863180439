import { Box, Checkbox, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Api from '../../api';
import { useAllResourceAttributeOptionsContext, useAllResourceAttributesContext, useResourceContext } from '../../context';
import { Resource, Error, ResourceAttribute, ResourceAttributeTemplateResponse, ResourceAttributeLine, ResourceAttributeOption } from '../../types';
import { endpoints } from '../../utils';

interface Props {
  formValues: Resource;
  formErrors: Error;
  renderFormErrorHelperText: any;
  handleChange: any;
  hasError: any;
  setLoading: any;
}

export const ResourceAttributes: React.FC<Props> = ({setLoading, formValues, hasError, renderFormErrorHelperText, handleChange}) => {
  const { selectedResourceSubTypeId } = useResourceContext();
  const {allResourceAttributeOptions} = useAllResourceAttributeOptionsContext();
  const {allResourceAttributes} = useAllResourceAttributesContext();
  const [attributesForSubType, setAttributesForSubType] = useState<ResourceAttribute[]>([])
  const { t } = useTranslation();

  // get attributes for subtype
  useEffect(() => {
    if (Number(selectedResourceSubTypeId) > 0) {
      (async (): Promise<void> => {
        setLoading(true);
        const { data } = await Api.get<ResourceAttributeTemplateResponse>(endpoints.resourceAttributeTemplates,{
          params: {
            resource_subtype: `${selectedResourceSubTypeId}`,
        },
        });
        const { results } = data;
        const attributeIds = results[0].resource_attributes;
        const attributes = allResourceAttributes.filter((attr: ResourceAttribute)=> attributeIds.includes(Number(attr.id)));
        setLoading(false);
        setAttributesForSubType(attributes);
      })();
    }
  }, [setAttributesForSubType, selectedResourceSubTypeId, setLoading, allResourceAttributes ]);
  
  const getAttributeOptions = useCallback(
    (attrId: number|string): ResourceAttributeOption [] => {
      const selectedAttributeOptions = allResourceAttributeOptions.filter((option:ResourceAttributeOption) => Number(option.attribute) === Number(attrId));
      return selectedAttributeOptions;
    },
    [allResourceAttributeOptions],
  );

  const getAttributeLine = useCallback(
    (attrId: number|string): ResourceAttributeLine | undefined => {
      const selectedAttrLine = formValues.attribute_lines.find((attr:ResourceAttributeLine) => Number(attr.attribute.id) === Number(attrId));
      return selectedAttrLine;
    },
    [formValues],
  );

  const getNumericValue = useCallback(
    (attrId: number|string): number | undefined => {
      const selectedAttrLine = formValues.attribute_lines.find((attr:ResourceAttributeLine) => Number(attr.attribute.id) === Number(attrId));
      const attribute = allResourceAttributes.find((item: ResourceAttribute) => Number(attrId) ===Number(item.id));
      if(selectedAttrLine?.numeric_value){
        return attribute?.is_integer ? Math.trunc(selectedAttrLine?.numeric_value) : selectedAttrLine?.numeric_value;
      }else{
        return undefined
      }
      
    },
    [formValues, allResourceAttributes],
  );
  
  const renderResourceAttribute = useMemo(
    () => (
      <>
    {
      attributesForSubType.map((attr:ResourceAttribute) => (
        <FormControl error={hasError('attribute_lines')} key={attr.id}>
          <Grid container display="flex" m={1} spacing={3}>
            {attr.type==='number' && (
              <>
                <Grid item xs={3} alignItems="start">
                <Typography variant="body1">{attr.name}</Typography>
                </Grid>
                <Grid item xs={9}>
                <Box  display="flex" alignItems="flex-end" sx={{ gap: 2 }}>
                <TextField
                  type="text"
                  name={`attribute_lines__numeric_value__${attr.id}`}
                  size="small"
                  onChange={handleChange}
                  autoFocus
                  value={getNumericValue(attr.id) ?? ''}
                  required={getAttributeLine(attr.id)?.attribute.required}
                />
                <Typography variant='body1'>{attr.unit}</Typography>
                </Box>
                </Grid>
              </>
            )}
            {attr.type==='string' && (
              <>
                <Grid item xs={3} alignItems="start">
                  <Typography variant="body1">{attr.name}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Box  display="flex" alignItems="flex-end" sx={{ gap: 2 }}>
                  <TextField
                    type="text"
                    name={`attribute_lines__string_value__${attr.id}`}
                    size="small"
                    onChange={handleChange}
                    autoFocus
                    value={getAttributeLine(attr.id)?.string_value ?? ''}
                    required={getAttributeLine(attr.id)?.attribute.required}
                  />
                  <Typography variant='body1'>{attr.unit}</Typography>
                  </Box>
                </Grid>
              </>   
            )}
            {attr.type==='boolean' && (
              <>
                <Grid item xs={3} alignItems="start">
                  <Typography variant="body1">{attr.name}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <RadioGroup 
                    row  
                    name={`attribute_lines__boolean_value__${attr.id}`} 
                    defaultValue={Number(getAttributeLine(attr.id)?.boolean_value) || 0} 
                    onChange={handleChange}
                  >
                    <Box display="flex" sx={{ gap: 2 }}> 
                      <FormControlLabel
                        value={1}
                        control={<Radio color="info" />}
                        label={`${t('misc.yes')}`}
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value={0}
                        control={<Radio color="info" />}
                        label={`${t('misc.no')}`}
                        labelPlacement="start"
                      />
                    </Box>
                  </RadioGroup>
                </Grid>
              </>   
            )}
            {attr.type==='choice' && (
              <>
                <Grid item xs={3} alignItems="start">
                  <Typography variant="body1">{attr.name}</Typography>
                </Grid>
                <Grid item xs={9} container>
                  <Grid item xs={6} container>
                    {
                      getAttributeOptions(attr.id).filter((item: ResourceAttributeOption)=> Number(item.id)%2!==0).map((option: ResourceAttributeOption)=>(
                        <Grid item xs={12} key={option.id}>
                          <FormControlLabel
                            labelPlacement="end"
                            label={option.value}
                            key={option.id}
                            name={`attribute_lines__choice__${attr.id}__${option.id}`}
                            control={
                              <Checkbox
                                onChange={handleChange}
                                checked={getAttributeLine(attr.id)?.selected_options?.includes(Number(option.id)) ?? false}
                              />
                            }
                          />
                        </Grid>
                      ))
                    }
                  </Grid>
                  <Grid item xs={6} container>
                    {
                      getAttributeOptions(attr.id).filter((item: ResourceAttributeOption)=> Number(item.id)%2===0).map((option: ResourceAttributeOption)=>(
                        <Grid item xs={12} key={option.id}>
                          <FormControlLabel
                            labelPlacement="end"
                            label={option.value}
                            key={option.id}
                            name={`attribute_lines__choice__${attr.id}__${option.id}`}
                            control={
                              <Checkbox
                                onChange={handleChange}
                                checked={getAttributeLine(attr.id)?.selected_options?.includes(Number(option.id)) ?? false}
                              />
                            }
                          />
                        </Grid>
                      ))
                    }
                  </Grid>
                </Grid>
              </>   
            )}
            {renderFormErrorHelperText('attribute_lines')}
          </Grid>
          <Divider variant="fullWidth" />
        </FormControl>
      ))
    }
    </>),
    [attributesForSubType, hasError,renderFormErrorHelperText, handleChange, getAttributeLine, t, getAttributeOptions, getNumericValue],
  );
  return <>{renderResourceAttribute}</>;
};
