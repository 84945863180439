import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import { OfferListResponse, OfferListItem } from '../types';
import Api from '../api';
import { endpoints } from '../utils';

const initialSelectedOffer = 0;
const initialAllOffers : OfferListItem[] = [];

const initialState = {
  selectedOffer: initialSelectedOffer,
  setSelectedOffer: (): void => {},
  allOffers: initialAllOffers,
  setAllOffers: (): void => {},
  fetchAllOffers: (): void => { },
  allOffersLoading: false,
  setAllOffersLoading: (): void => { },
};

interface OfferContextType {
  selectedOffer: number;
  setSelectedOffer: Dispatch<SetStateAction<number>>;
  allOffers: OfferListItem[] ;
  setAllOffers: Dispatch<SetStateAction<OfferListItem[]>>;
  fetchAllOffers: () => void;
  allOffersLoading: boolean;
  setAllOffersLoading: Dispatch<SetStateAction<boolean>>;
}

interface Props {
  children: React.ReactNode;
}
const OfferContext = createContext<OfferContextType>(initialState);

export const useOfferContext = (): OfferContextType => useContext(OfferContext);

export const OfferContextProvider: React.FC<Props> = ({ children }) => {
  const [selectedOffer, setSelectedOffer] = useState<number>(initialSelectedOffer);
  const [allOffers, setAllOffers] = useState<OfferListItem[]>(initialAllOffers);
  const [allOffersLoading, setAllOffersLoading] = useState<boolean>(false);

  const fetchAllOffers = useCallback(
    ()=> {
      (async (): Promise<void> => {
        setAllOffersLoading(true);
        const { data } = await Api.get<OfferListResponse>(endpoints.offers,{
          params:{
            limit:2000
          }
        });
        const {results} = data;
        setAllOffers(results);
        setAllOffersLoading(false);
      })();
  },[]);
  
  return (
    <OfferContext.Provider
      value={{
        selectedOffer,
        setSelectedOffer,
        setAllOffers,
        allOffers,
        fetchAllOffers,
        allOffersLoading,
        setAllOffersLoading
      }}
    >
      {children}
    </OfferContext.Provider>
  );
};
