import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAccessToken, signOutUser } from '../firebase';
import { endpoints, urls } from '../utils';
import { UserProfile } from '../types';
import Api from '../api';

const initialProfileData = {
  first_name: '',
  last_name: '',
  email: '',
  company: '',
  phone: '',
  user: {
    email: '',
    is_staff: false,
    is_company_admin: false
  }
};

let accessToken = '',
  expirationTime = '';
const sessionData = sessionStorage.getItem(`firebase:authUser:${process.env.REACT_APP_AUTH_API_KEY}:[DEFAULT]`);
if (sessionData) {
  const { stsTokenManager } = JSON.parse(sessionData);
  accessToken = stsTokenManager.accessToken;
  expirationTime = stsTokenManager.expirationTime;
}

const { token: initialToken = '', expiresIn: initialExpiresIn = '' } =
  { token: accessToken, expiresIn: expirationTime } ?? {};

const initialState = {
  authData: {
    token: initialToken,
    expiresIn: initialExpiresIn,
    loading: false,
    error: false,
  },
  setAuthData: (): void => {},
  profileData: initialProfileData,
  setProfileData: (): void => {},
};

interface AuthData {
  token: string;
  expiresIn: Date | string;
  loading: boolean;
  error: boolean;
}

interface AuthContextType {
  authData: AuthData;
  setAuthData: Dispatch<SetStateAction<AuthData>>;
  profileData: UserProfile;
  setProfileData: Dispatch<SetStateAction<UserProfile>>;
}

interface Props {
  children: React.ReactNode;
}
const AuthContext = createContext<AuthContextType>(initialState);

interface UseAuthContext extends AuthData {
  login: (email: string, password: string) => void;
  logout: () => void;
  getProfileData: () => void;
  profileData: UserProfile;
}

export const useAuthContext = (): UseAuthContext => {
  const navigate = useNavigate();
  const { setAuthData, authData, profileData, setProfileData } = useContext(AuthContext);

  const logout = useCallback((): void => {
    signOutUser();
    setAuthData({
      ...authData,
      token: '',
      expiresIn: '',
      error: false,
    });
    sessionStorage.clear();
    navigate(urls.login);
  }, [authData, setAuthData, navigate]);

  const login = useCallback(
    async (email: string, password: string): Promise<void> => {
      setAuthData({ ...authData, loading: true });
      try {
        const { token: token, expirationTime: expirationTime } = await getAccessToken(email, password);
        const newAuthData = {
          token,
          expirationTime,
        };

        setAuthData({
          ...authData,
          ...newAuthData,
          loading: false,
        });
        if (token.length) {
          navigate(urls.resourceSearch);
        }
      } catch {
        setAuthData({ ...authData, error: true, loading: false });
      }
    },
    [authData, navigate, setAuthData],
  );

  const getProfileData = useCallback(async (): Promise<void> => {
    const { data } = await Api.get<UserProfile>(endpoints.profile);
    const isStaff = data.user.is_staff,
      hasCompany = !!Number(data.company),
      isCompanyAdmin = data.user.is_company_admin;
      
    // persist user profile info
    sessionStorage.setItem('current_user',JSON.stringify({
      is_staff: `${Number(isStaff)}`,
      has_company: `${Number(hasCompany)}`,
      is_company_admin: `${Number(isCompanyAdmin)}`
    }));

    setProfileData(data);
  }, [setProfileData]);
  return {
    login,
    logout,
    ...authData,
    getProfileData,
    profileData,
  };
};

export const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const [authData, setAuthData] = useState<AuthData>({
    token: initialToken,
    expiresIn: initialExpiresIn,
    loading: false,
    error: false,
  });
  const [profileData, setProfileData] = useState<UserProfile>(initialProfileData);

  return (
    <AuthContext.Provider value={{ authData, setAuthData, profileData, setProfileData }}>
      {children}
    </AuthContext.Provider>
  );
};
