import * as zod from 'zod';

const passwordComplexity = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/);
export const loginSchema = zod.object({
  email: zod.string().email(),
  password: zod.string(),
});

export const registrationSchema = zod.object({
  email: zod.string().email(),
  password: zod.string().min(8).regex(passwordComplexity, {
    message:
      'Salasanan tulee sisältää vähintään yksi iso kirjain, yksi pieni kirjain ja yksi numero sekä pituuden tulee olla vähintään 8 merkkiä.',
  }),
});
