import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import Api from '../api';
import { ResourceSubTypeResponse, ResourceSubType} from '../types';
import { endpoints } from '../utils';

const initialAllResourceSubTypes : ResourceSubType[] = [];

const initialState = {
  allResourceSubTypes: initialAllResourceSubTypes,
  setAllResourceSubTypes: (): void => {},
  fetchAllResourceSubTypes: (): void => { },
  allResourceSubTypesLoading: false,
  setAllResourceSubTypesLoading: (): void => { },
}

interface AllResourceSubTypesContextType {
  allResourceSubTypes: ResourceSubType[] ;
  setAllResourceSubTypes: Dispatch<SetStateAction<ResourceSubType[]>>;
  fetchAllResourceSubTypes: () => void;
  allResourceSubTypesLoading: boolean;
  setAllResourceSubTypesLoading: Dispatch<SetStateAction<boolean>>;
}

interface Props {
  children: React.ReactNode;
}
const AllResourceSubTypesContext = createContext<AllResourceSubTypesContextType>(initialState);

export const useAllResourceSubTypesContext = (): AllResourceSubTypesContextType => useContext(AllResourceSubTypesContext);

export const AllResourceSubTypesContextProvider: React.FC<Props> = ({ children }) => {
  const [allResourceSubTypes, setAllResourceSubTypes] = useState<ResourceSubType[]>(initialAllResourceSubTypes);
  const [allResourceSubTypesLoading, setAllResourceSubTypesLoading] = useState<boolean>(false);

  const fetchAllResourceSubTypes = useCallback(
    ()=> {
      (async (): Promise<void> => {
        setAllResourceSubTypesLoading(true);
        const { data } = await Api.get<ResourceSubTypeResponse>(endpoints.resourceSubTypes,{
          params:{
            limit:2000
          }
        });
        const {results} = data;
        setAllResourceSubTypes(results);
        setAllResourceSubTypesLoading(false);
      })();
  },[]);

  return (
    <AllResourceSubTypesContext.Provider
      value={{
        setAllResourceSubTypes,
        allResourceSubTypes,
        fetchAllResourceSubTypes,
        allResourceSubTypesLoading,
        setAllResourceSubTypesLoading
      }}
    >
      {children}
    </AllResourceSubTypesContext.Provider>
  );

}
