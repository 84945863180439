import React, { useMemo, useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ContactList, ContactCreateDialog } from './index';
import { useCompanyContext, usePaginationContext } from '../../../context';
import Api from '../../../api';
import { endpoints } from '../../../utils';
import { CompanyContactsResponse, CompanyInfo } from '../../../types';
import { useProgress } from '../../../hooks';

interface Props {
  companyId: number | string;
}

export const Contacts: React.FC<Props> = ({ companyId }) => {
  const { t } = useTranslation();
  const { StyledProgress } = useProgress();
  const { companyContacts, setCompanyContacts } = useCompanyContext();
  const [currentCompany, setCurrentCompany] = useState<CompanyInfo>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(30);
  const { limit, offset } = usePaginationContext();
  
  //contacts count, previous , next 
  useEffect(() => {
    (async (): Promise<void> => {
      setLoading(true);
      const { data } = await Api.get<CompanyContactsResponse>(endpoints.contacts, {
        params: {
          company: `${companyId}`,
          offset,
          limit
        },
      });
      const { results, count } = data;
      setLoading(false);
      setCompanyContacts(results);
      setTotalCount(count);
    })();
  }, [companyId, limit, offset, setCompanyContacts]);

  // fetch company data
  useEffect(() => {
    if (Number(companyId) > 0) {
      (async (): Promise<void> => {
        setLoading(true);
        const { data } = await Api.get<CompanyInfo>(endpoints.company(Number(companyId)));
        setLoading(false);
        setCurrentCompany(data);
        setTotalCount(companyContacts.length);
      })();
    }
  }, [companyId, companyContacts]);

  const renderContacts = useMemo(
    () => (
      <Grid container item>
        {loading && <StyledProgress size={24} />}
        <Grid item xs={10}>
          <Typography variant="h4"> {t('companyContacts.subTitle')}</Typography>
          <Typography variant="h6" mt={3}>
            {`${t('companyContacts.company')}:   ${currentCompany.name}`}
          </Typography>
        </Grid>
        <Grid item xs={2} >
          <Grid item xs={12} container justifyContent="flex-end">
            <ContactCreateDialog companyId={companyId} loading={loading} setLoading={setLoading} setTotalCount={setTotalCount}/>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={5}>
          <ContactList contacts={companyContacts} loading={loading} totalCount={totalCount} />
        </Grid>
      </Grid>
    ),
    [t, companyContacts, companyId, currentCompany, StyledProgress, loading, totalCount],
  );

  return <>{renderContacts}</>;
};
