import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, NavLink } from 'react-router-dom';
import { Main } from '../layout';
import { Box, Button, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Link, Paper, Radio, RadioGroup, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { endpoints, numberToLocale, urls } from '../utils';
import { colors } from '../theme';
import { useCartContext } from '../context';
import { CartItem, CartItemsResponse, ResourceType, ResourceTypesResponse, Cart, OrderFormValues, FormError } from '../types';
import Api from '../api';
import { useSnackbar } from 'notistack';

const StyledNavLink = styled(NavLink)(() => ({
  textDecoration: 'none',
  color: colors.secondaryTextGray,
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  marginBottom: '0.2em',
}));

const initialCartValues = {
  id: '',
  state: '',
  title: '',
  work_description: '',
  work_location: '',
  work_start_date: '',
  work_end_date: '',
  start_time: '',
  accommodation_by: '',
  contact_name: '',
  contact_phone: '',
  street: '',
  zip_code: '',
  municipality: '',
  expires_at: '',
  user: 0,
  orders: []
}

export const CartPage: React.FC = () => {
  const { cart, cartItems, setCart, setCartItems, removeFromCart, discardCart } = useCartContext();
  const [resourceTypes, setResourceTypes] = useState<ResourceType[]>([]);
  const [formErrors, setFormErrors] = useState<FormError>({});
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const orderButtonEnabled = cartItems.length > 0
  const sumTotal = cartItems.reduce((accumulator, item) => {
    return accumulator + item.total_price;
  }, 0);

  const handleDiscardCart = useCallback(
    async (): Promise<void> => {
      discardCart().then(() => {
        setCartItems([]);
        setCart(initialCartValues);
        navigate(urls.resourceSearch);
        enqueueSnackbar(t('cart.discardSuccessMessage'), {
          variant: 'success',
        });
      }).catch(() => {
        enqueueSnackbar(t('cart.discardFailedMessage'), {
          variant: 'error',
        });
      });
    },
    [t, navigate, enqueueSnackbar, discardCart, setCartItems, setCart],
  );

  // Fetch the cart items
  useEffect(() => {
    (async (): Promise<void> => {
      const { data } = await Api.get<CartItemsResponse>(endpoints.cartItems);
      const { results } = data;
      setCartItems(results);
    })();
  }, [setCartItems]);

  // Fetch the resource types
  useEffect(() => {
    (async (): Promise<void> => {
      const { data } = await Api.get<ResourceTypesResponse>(endpoints.resourceTypes);
      const { results } = data;
      setResourceTypes(results);
    })();
  }, [setResourceTypes]);

  const handleOrderDetailsChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const newFormValues = { ...cart };
      newFormValues[e.target.name as keyof OrderFormValues] = e.target.value;
      setCart(newFormValues);
    },
    [cart, setCart],
  );

  const handleSubmit = useCallback(
    async (e: SyntheticEvent): Promise<void> => {
      //* Save the cart and navigate to the order summary page */
      e.preventDefault();
      setFormErrors({});
      try {
        const { data } = await Api.patch<Cart>(endpoints.cart, cart);
        setCart(data);
        navigate(urls.orderSummary);
      } catch (err: any) {
        setFormErrors(err.response.data);
      }
    },
    [cart, setCart, navigate],
  );

  const hasError = useCallback(
    (key: string): boolean => {
      return Object.keys(formErrors).some((item) => item === key);
    },
    [formErrors],
  );

  const renderFormErrorHelperText = useMemo(
    () =>
      (key: string): JSX.Element | void => {
        if (hasError(key)) {
          return <FormHelperText>{formErrors[key]}</FormHelperText>;
        }
      },
    [formErrors, hasError],
  );

  const renderCart = useMemo(
    () => (
      <>
        <Grid container direction="row" justifyContent="space-between" pr={1}>
          <Grid item>
            <StyledNavLink to={urls.resourceSearch}>
              <Grid container direction="row" alignItems="center">
                <ArrowBackIosIcon sx={{ fontSize: "0.8em", marginRight: "0.5em" }} ></ArrowBackIosIcon>
                <Typography variant="h6">
                  {t('cart.returnLinkLabel')}
                </Typography>
              </Grid>
            </StyledNavLink>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">
              {t('cart.expiresAt')} {(cartItems.length > 0) && `${cart.expires_at}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid pb={0.5} pr={1} item xs={12}>
          <Paper>
            <Box p={1} pt={3} pb={3} m={2}>
              <Grid container direction="row" justifyContent="space-between" pb={2}>
                <Grid item>
                  <Typography variant="h3">{t('cart.order')}</Typography>
                </Grid>
                <Grid item>
                  <Button variant="text" size="small" sx={{ marginRight: 2 }} onClick={handleDiscardCart}>
                    {t('cart.discardButtonLabel')}
                  </Button>
                  <Button variant="contained" form="order-form" onClick={handleSubmit} disabled={!orderButtonEnabled} size="small">
                    {t('cart.makeOrderButtonLabel')}
                  </Button>
                </Grid>
              </Grid>

              {/* Order details form */}

              <Grid item>
                <Grid container direction="row" pt={4} pb={4}>
                  <Grid item xs={4}>
                    <Typography variant="h5">{t('cart.orderDetails.title')}</Typography>
                  </Grid>
                  <Grid container direction="column" xs={6} spacing={3} item>
                    <Grid item>
                      <FormControl error={hasError('title')}>
                        <>
                          <StyledFormLabel required>{t('cart.orderDetails.orderTitle')}</StyledFormLabel>
                          <TextField
                            name="title"
                            size="small"
                            value={cart.title}
                            onChange={handleOrderDetailsChange}
                            required
                          />
                          {renderFormErrorHelperText('title')}
                        </>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl error={hasError('work_location')}>
                        <>
                          <StyledFormLabel required>{t('cart.orderDetails.workLocation')}</StyledFormLabel>
                          <TextField
                            name="work_location"
                            value={cart.work_location}
                            onChange={handleOrderDetailsChange}
                            size="small"
                            required
                          />
                          {renderFormErrorHelperText('work_location')}
                        </>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl error={hasError('work_description')}>
                        <>
                          <StyledFormLabel required>
                            {t('cart.orderDetails.workDescription')}
                          </StyledFormLabel>
                          <TextField
                            name="work_description"
                            placeholder={t('cart.orderDetails.workDescriptionPlaceholder')}
                            value={cart.work_description}
                            onChange={handleOrderDetailsChange}
                            multiline
                            minRows={3}
                            required
                          />
                          {renderFormErrorHelperText('work_description')}
                        </>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <Grid container justifyContent="space-between">
                        <Grid item xs={3}>
                          <FormControl error={hasError('start_time')}>
                            <>
                              <StyledFormLabel required>
                                {t('cart.orderDetails.startTime')}
                              </StyledFormLabel>
                              <TextField
                                name="start_time"
                                type="time"
                                value={cart.start_time}
                                onChange={handleOrderDetailsChange}
                                size="small"
                                required
                              />
                              {renderFormErrorHelperText('start_time')}
                            </>
                          </FormControl>
                        </Grid>
                        <Grid item>
                          <FormControl error={hasError('accommodation_by')}>
                            <>
                              <StyledFormLabel id="accommodation-radio-buttons-group-label" required>
                                {t('cart.orderDetails.accommodation')}
                              </StyledFormLabel>
                              <RadioGroup
                                aria-labelledby="accommodation-radio-buttons-group-label"
                                name="accommodation_by"
                                value={cart.accommodation_by}
                                onChange={handleOrderDetailsChange}
                                row
                              >
                                <FormControlLabel value="seller" control={<Radio />} label={t('cart.orderDetails.sellerOrganizes')} />
                                <FormControlLabel value="buyer" control={<Radio />} label={t('cart.orderDetails.buyerOrganizes')} />
                              </RadioGroup>
                              {renderFormErrorHelperText('accommodation_by')}
                            </>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Divider variant="fullWidth" />

              {/* Contact details form */}

              <Grid item>
                <Grid container direction="row" pt={4} pb={4}>
                  <Grid item xs={4}>
                    <Typography variant="h5">{t('cart.contactDetails.title')}</Typography>
                  </Grid>
                  <Grid container direction="column" item xs={6} spacing={3}>
                    <Grid item>
                      <FormControl error={hasError('contact_name')}>
                        <>
                          <StyledFormLabel required>{t('cart.contactDetails.name')}</StyledFormLabel>
                          <TextField
                            name="contact_name"
                            value={cart.contact_name}
                            onChange={handleOrderDetailsChange}
                            size="small"
                            required
                          />
                          {renderFormErrorHelperText('contact_name')}
                        </>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl error={hasError('contact_phone')}>
                        <>
                          <StyledFormLabel required>{t('cart.contactDetails.phone')}</StyledFormLabel>
                          <TextField
                            name="contact_phone"
                            value={cart.contact_phone}
                            onChange={handleOrderDetailsChange}
                            size="small"
                            required
                          />
                          {renderFormErrorHelperText('contact_phone')}
                        </>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl error={hasError('street')}>
                        <>
                          <StyledFormLabel required>{t('cart.contactDetails.street')}</StyledFormLabel>
                          <TextField
                            name="street"
                            value={cart.street}
                            onChange={handleOrderDetailsChange}
                            size="small"
                            required
                          />
                          {renderFormErrorHelperText('street')}
                        </>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <Grid container justifyContent="space-between" spacing={4}>
                        <Grid item xs={6}>
                          <FormControl error={hasError('zip_code')}>
                            <>
                              <StyledFormLabel required>{t('cart.contactDetails.zipCode')}</StyledFormLabel>
                              <TextField
                                name="zip_code"
                                value={cart.zip_code}
                                onChange={handleOrderDetailsChange}
                                size="small"
                                required
                              />
                              {renderFormErrorHelperText('zip_code')}
                            </>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl error={hasError('municipality')}>
                            <>
                              <StyledFormLabel required>{t('cart.contactDetails.municipality')}</StyledFormLabel>
                              <TextField
                                name="municipality"
                                value={cart.municipality}
                                onChange={handleOrderDetailsChange}
                                size="small"
                                required
                              />
                              {renderFormErrorHelperText('municipality')}
                            </>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Divider variant="fullWidth" />

              {/* Resources */}

              <Grid item>
                <Grid container pt={4} pb={4}>
                  <Typography variant="h5">{t('cart.resources.title')}</Typography>
                  {resourceTypes.map((res_type: ResourceType) => (
                    (cartItems.some(item => item.resource_type == res_type.id)) && (
                      <Grid container pt={4} key={res_type.id}>
                        <Typography variant="h5">{res_type.name}</Typography>
                        <TableContainer sx={{ marginTop: "1em" }}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ width: '15%' }}>{t('cart.resources.name')}</TableCell>
                                <TableCell style={{ width: '15%' }}>{t('cart.resources.company')}</TableCell>
                                <TableCell style={{ width: '10%' }}>{t('cart.resources.location')}</TableCell>
                                <TableCell style={{ width: '20%' }}>{t('cart.resources.dates')}</TableCell>
                                <TableCell style={{ width: '10%' }}>{t('cart.resources.days')}</TableCell>
                                <TableCell style={{ width: '15%' }}>{t('cart.resources.dailyPrice')} (€)</TableCell>
                                <TableCell style={{ width: '15%' }}></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {cartItems.filter((item: CartItem) => (item.resource_type == res_type.id)).map(item => (
                                <TableRow key={item.id}>
                                  <TableCell>{item.product_name}</TableCell>
                                  <TableCell>{item.seller_company}</TableCell>
                                  <TableCell>{item.location}</TableCell>
                                  <TableCell>
                                    {item.reservation?.begin && new Date(item.reservation?.begin).toLocaleDateString('fi-FI')}
                                    &nbsp;&ndash;&nbsp;
                                    {item.reservation?.end && new Date(item.reservation?.end).toLocaleDateString('fi-FI')}
                                  </TableCell>
                                  <TableCell>{item.reservation?.duration_in_days}</TableCell>
                                  <TableCell>{numberToLocale(item.daily_price)}</TableCell>
                                  <TableCell>
                                    <Link
                                      variant="h6"
                                      underline="hover"
                                      onClick={(): void => removeFromCart(item)}
                                    >
                                      {t('cart.resources.removeButtonLabel')}
                                    </Link>
                                  </TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell colSpan={5} sx={{ borderBottom: '0' }} />
                                <TableCell sx={{ borderBottom: '0', fontWeight: 'bold' }}>{t('cart.resources.totalPrice')}:</TableCell>
                                <TableCell colSpan={2} sx={{ borderBottom: '0', fontWeight: 'bold' }}>
                                  {numberToLocale(cartItems
                                    .filter(item => item.resource_type == res_type.id)
                                    .map(item => item.total_price)
                                    .reduce((prev, next) => prev + next))}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )
                  ))}

                </Grid>
              </Grid>

              <Divider variant="fullWidth" />

              <Grid container pt={4} pb={2}>
                <Typography variant="h5">{t('cart.resources.orderTotalPrice')}: {numberToLocale(sumTotal)}</Typography>
                <Typography variant="h5" sx={{ marginLeft: 1.5, fontWeight: "normal" }}> + {t('cart.resources.extraCosts')}</Typography>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </>
    ),
    [t, cart, cartItems, handleOrderDetailsChange, removeFromCart, handleDiscardCart, handleSubmit, resourceTypes, orderButtonEnabled, sumTotal, hasError, renderFormErrorHelperText],
  );
  return <Main>{renderCart}</Main>;
};
