import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import fiLocale from 'date-fns/locale/fi';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Button,
  Grid,
  styled,
  Typography,
  Divider,
  Paper,
  Box,
  TextField,
  FormLabel,
  FormHelperText,
  FormControl
} from '@mui/material';

import { colors } from '../theme';
import Api from '../api';
import { endpoints, urls } from '../utils';
import { useProgress } from '../hooks';
import { FormError, RequestForProposalFormValues } from '../types';
import { Main } from '../layout';
import { format } from 'date-fns';

const StyledButton = styled(Button)(() => ({
  marginRight: '0.5em',
}));

const StyledNavLink = styled(NavLink)(() => ({
  textDecoration: 'none',
  color: colors.secondaryTextGray,
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  marginBottom: '0.2em',
}));

const DEFAULT_FORM_VALUES: RequestForProposalFormValues = {
  title: '',
  work_description: '',
  work_location: '',
  work_start_date: null,
  work_end_date: null,
  start_time: '',
  accommodation_by: '',
  contact_name: '',
  contact_phone: '',
  street: '',
  zip_code: '',
  municipality: '',
  valid_until: new Date(),
  extra_info: '',
  link_to_material: '',
}

export const RequestForProposalFormView: React.FC = () => {
  const { t } = useTranslation();
  const { StyledProgress } = useProgress();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  const [formValues, setFormValues] = useState<RequestForProposalFormValues>(DEFAULT_FORM_VALUES);
  const [formErrors, setFormErrors] = useState<FormError>({});
  const [loading, setLoading] = useState<boolean>(false);
  
  const editing = id ? true : false;

  const getEarliestPossibleWorkDate = (): Date => {
    const today = new Date();
    today.setDate(today.getDate() + 2);
    return today;
  }

  useEffect(() => {
    (async (): Promise<void> => {
      if (id) {
        setLoading(true);
        const { data } = await Api.get<RequestForProposalFormValues>(endpoints.requestForProposal(Number(id)));
        setFormValues(data)
        setLoading(false);
      }
    })();
  }, [setFormValues, id]);

  const hasError = useCallback(
    (key: string): boolean => {
      return Object.keys(formErrors).some((item) => item === key);
    },
    [formErrors],
  );

  const renderFormErrorHelperText = useMemo(
    () =>
      (key: string): JSX.Element | void => {
        if (hasError(key)) {
          return <FormHelperText>{formErrors[key]}</FormHelperText>;
        }
      },
    [formErrors, hasError],
  );

  const handleFormChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setFormValues({ ...formValues, [e.target.name]: e.target.value });
    },
    [formValues],
  );

  const handleDateChange = useCallback(
    (newValue: Date | null, name: string): void => {
      if (newValue && ['work_start_date', 'work_end_date'].includes(name)) {
        const value = format(new Date(newValue),'yyyy-MM-dd');
        setFormValues({ ...formValues, [name]: value });
      } else {
        setFormValues({ ...formValues, [name]: newValue });
      }
    },
    [formValues],
  );

  const handleCancel = useCallback(
    async (e: SyntheticEvent): Promise<void> => {
      e.preventDefault();
      setFormValues(DEFAULT_FORM_VALUES);
      setFormErrors({});
      if (editing) {
        navigate(urls.requestForProposal(Number(id)));
      } else {
        navigate(urls.requestsForProposal);
      }
    },
    [navigate, editing, id],
  );

  const handleUpdate = useCallback(
    async (e: SyntheticEvent): Promise<void> => {
      e.preventDefault();
      try {
        const { data } = await Api.put<RequestForProposalFormValues>(endpoints.requestForProposal(Number(id)), formValues);
        if (data.id) {
          enqueueSnackbar(t('misc.updateSuccess'), {
            variant: 'success',
          });
          navigate(urls.requestForProposal(data.id));
          setFormValues(DEFAULT_FORM_VALUES);
        }
      } catch (err: any) {
        setFormErrors(err.response.data);
      }
    },
    [formValues, navigate, enqueueSnackbar, t, id],
  );

  const handleCreate = useCallback(
    async (e: SyntheticEvent): Promise<void> => {
      e.preventDefault();
      try {
        const { data } = await Api.post<RequestForProposalFormValues>(endpoints.requestsForProposal, formValues);
        if (data.id) {
          enqueueSnackbar(t('misc.createSuccess'), {
            variant: 'success',
          });
          navigate(urls.requestForProposal(data.id));
          setFormValues(DEFAULT_FORM_VALUES);
        }
      } catch (err: any) {
        setFormErrors(err.response.data);
      }
    },
    [formValues, navigate, enqueueSnackbar, t],
  );

  const render = useMemo(
    () => (
      <>
        <Grid container direction="row" justifyContent="space-between" pr={1}>
          <Grid item>
            <StyledNavLink to={urls.requestsForProposal}>
              <Grid container direction="row" alignItems="center">
                <ArrowBackIosIcon sx={{ fontSize: "0.8em", marginRight: "0.5em" }} ></ArrowBackIosIcon>
                <Typography variant="h6">
                  {t('requestsForProposal.returnLinkLabel')}
                </Typography>
              </Grid>
            </StyledNavLink>
          </Grid>
        </Grid>
        <Grid pb={0.5} pr={1} xs={12}>
          <Paper>
            {loading && <StyledProgress size={24} />}
            <Box p={1} pt={3} pb={3} m={2}>
              <Grid container direction="row" justifyContent="space-between" pb={2} mb={4}>
                <Grid item>
                  <Typography variant="h5">{formValues.title}</Typography>
                </Grid>
                <Grid item>
                  <>
                    <StyledButton variant="contained" size="small" onClick={handleCancel}>
                      {t('misc.cancelButtonLabel')}
                    </StyledButton>
                    {editing ? (
                      <StyledButton variant="contained" size="small" onClick={handleUpdate}>
                        {t('misc.saveButtonLabel')}
                      </StyledButton>
                    ) : (
                      <StyledButton variant="contained" size="small" onClick={handleCreate}>
                        {t('requestsForProposal.addButtonLabel')}
                      </StyledButton>
                    )}
                  </>
                </Grid>
              </Grid>
              <Grid container direction="row" xs={12} pb={2}>
                <Grid item xs={4}>
                  <Typography variant="body2">{t('requestsForProposal.generalDetailsTitle')}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Grid container pb={2}>
                    <Grid item xs={9}>
                      <FormControl error={hasError('valid_until')}>
                        <>
                          <StyledFormLabel required>{t('requestsForProposal.validUntil')}</StyledFormLabel>
                          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fiLocale}>
                            <DateTimePicker
                              disableMaskedInput
                              renderInput={(params): any => <TextField size="small" {...params} />}
                              value={formValues.valid_until}
                              onChange={(newValue): void => handleDateChange(newValue, "valid_until")}
                            />
                          </LocalizationProvider>
                          {renderFormErrorHelperText('valid_until')}
                        </>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={9}>
                      <FormControl error={hasError('title')}>
                        <>
                          <StyledFormLabel required>{t('requestsForProposal.title')}</StyledFormLabel>
                          <TextField
                            name="title"
                            size="small"
                            required
                            value={formValues.title}
                            onChange={handleFormChange}
                            inputProps={{
                              'data-testid': 'title',
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                          {renderFormErrorHelperText('title')}
                        </>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={12}>
                      <StyledFormLabel required>{t('requestsForProposal.workDates')}</StyledFormLabel>
                      <Grid container xs={12}>
                        <Grid item xs={4}>
                          <FormControl error={hasError('work_start_date')}>
                            <>
                              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fiLocale}>
                                <DatePicker
                                  disableMaskedInput
                                  renderInput={(params): any => <TextField size="small" {...params} />}
                                  value={formValues.work_start_date}
                                  minDate={getEarliestPossibleWorkDate()}
                                  onChange={(newValue): void => handleDateChange(newValue, "work_start_date")}
                                />
                              </LocalizationProvider>
                              {renderFormErrorHelperText('work_start_date')}
                            </>
                          </FormControl>
                        </Grid>
                        <Grid item xs={1}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <ArrowRightAltIcon sx={{ fontSize: "2.2em", paddingTop: "0.1em" }} ></ArrowRightAltIcon>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl error={hasError('work_end_date')}>
                            <>
                              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fiLocale}>
                                <DatePicker
                                  disableMaskedInput
                                  renderInput={(params): any => <TextField size="small" {...params} />}
                                  value={formValues.work_end_date}
                                  minDate={getEarliestPossibleWorkDate()}
                                  onChange={(newValue): void => handleDateChange(newValue, "work_end_date")}
                                />
                              </LocalizationProvider>
                              {renderFormErrorHelperText('work_end_date')}
                            </>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={9}>
                      <FormControl error={hasError('start_time')}>
                        <>
                          <StyledFormLabel required>{t('requestsForProposal.workStartTime')}</StyledFormLabel>
                          <TextField
                            name="start_time"
                            type="time"
                            value={formValues.start_time}
                            onChange={handleFormChange}
                            size="small"
                            required
                          />
                          {renderFormErrorHelperText('start_time')}
                        </>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={9}>
                      <FormControl error={hasError('work_location')}>
                        <>
                          <StyledFormLabel required>{t('requestsForProposal.location')}</StyledFormLabel>
                          <TextField
                            name="work_location"
                            value={formValues.work_location}
                            onChange={handleFormChange}
                            size="small"
                            required
                          />
                          {renderFormErrorHelperText('work_location')}
                        </>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={9}>
                      <FormControl error={hasError('accommodation_by')}>
                        <>
                          <StyledFormLabel required>{t('requestsForProposal.accommodationBy')}</StyledFormLabel>
                          <TextField
                            name="accommodation_by"
                            value={formValues.accommodation_by}
                            onChange={handleFormChange}
                            size="small"
                            required
                          />
                          {renderFormErrorHelperText('accommodation_by')}
                        </>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={9}>
                      <FormControl error={hasError('work_description')}>
                        <>
                          <StyledFormLabel required>{t('requestsForProposal.workDescription')}</StyledFormLabel>
                          <TextField
                            name="work_description"
                            value={formValues.work_description}
                            onChange={handleFormChange}
                            size="small"
                            multiline
                            minRows={5}
                            required
                          />
                          {renderFormErrorHelperText('work_description')}
                        </>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={9}>
                      <FormControl error={hasError('extra_info')}>
                        <>
                          <StyledFormLabel>{t('requestsForProposal.extraInfo')}</StyledFormLabel>
                          <TextField
                            name="extra_info"
                            value={formValues.extra_info}
                            onChange={handleFormChange}
                            size="small"
                            multiline
                            minRows={4}
                          />
                          {renderFormErrorHelperText('extra_info')}
                        </>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={9}>
                      <FormControl error={hasError('link_to_material')}>
                        <>
                          <StyledFormLabel>{t('requestsForProposal.linkToMaterial')}</StyledFormLabel>
                          <TextField
                            name="link_to_material"
                            value={formValues.link_to_material}
                            onChange={handleFormChange}
                            size="small"
                            helperText={t('requestsForProposal.linkToMaterialHelpText')}
                            FormHelperTextProps={{ style: { marginLeft: 0, fontSize: "0.9em" } }}
                          />
                          {renderFormErrorHelperText('link_to_material')}
                        </>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Divider variant="fullWidth" />

              <Grid container direction="row" xs={12} pt={4}>
                <Grid item xs={4}>
                  <Typography variant="body2">{t('requestsForProposal.contactDetailsTitle')}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Grid container pb={2}>
                    <Grid item xs={9}>
                      <FormControl error={hasError('contact_name')}>
                        <>
                          <StyledFormLabel required>{t('requestsForProposal.contactName')}</StyledFormLabel>
                          <TextField
                            name="contact_name"
                            value={formValues.contact_name}
                            onChange={handleFormChange}
                            size="small"
                            required
                          />
                          {renderFormErrorHelperText('contact_name')}
                        </>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={9}>
                      <FormControl error={hasError('contact_phone')}>
                        <>
                          <StyledFormLabel required>{t('requestsForProposal.contactPhone')}</StyledFormLabel>
                          <TextField
                            name="contact_phone"
                            value={formValues.contact_phone}
                            onChange={handleFormChange}
                            size="small"
                            required
                          />
                          {renderFormErrorHelperText('contact_phone')}
                        </>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={9}>
                      <FormControl error={hasError('street')}>
                        <>
                          <StyledFormLabel required>{t('requestsForProposal.street')}</StyledFormLabel>
                          <TextField
                            name="street"
                            value={formValues.street}
                            onChange={handleFormChange}
                            size="small"
                            required
                          />
                          {renderFormErrorHelperText('street')}
                        </>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={4}>
                      <FormControl error={hasError('zip_code')}>
                        <>
                          <StyledFormLabel required>{t('requestsForProposal.zipCode')}</StyledFormLabel>
                          <TextField
                            name="zip_code"
                            value={formValues.zip_code}
                            onChange={handleFormChange}
                            size="small"
                            required
                          />
                          {renderFormErrorHelperText('zip_code')}
                        </>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={4}>
                      <FormControl error={hasError('municipality')}>
                        <>
                          <StyledFormLabel required>{t('requestsForProposal.postalArea')}</StyledFormLabel>
                          <TextField
                            name="municipality"
                            value={formValues.municipality}
                            onChange={handleFormChange}
                            size="small"
                            required
                          />
                          {renderFormErrorHelperText('municipality')}
                        </>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </>
    ),
    [
      t,
      formValues,
      hasError,
      renderFormErrorHelperText,
      StyledProgress,
      loading,
      editing,
      handleFormChange,
      handleDateChange,
      handleCancel,
      handleCreate,
      handleUpdate,
    ],
  );

  return <Main>{render}</Main>;
};
