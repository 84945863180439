import {
  Button,
  Grid,
  Typography,
  styled,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TextField,
  InputAdornment,
  Box,
} from '@mui/material';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Main } from '../layout';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { useTranslation } from 'react-i18next';
import { colors } from '../theme';
import { urls, endpoints } from '../utils';
import { useNavigate } from 'react-router-dom';
import Api from '../api';
import { CompanyInfo, CompanyResponse, Record, FilterFunc } from '../types';
import { useProgress, useTable } from '../hooks';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { usePaginationContext } from '../context';

const StyledButton = styled(Button)(() => ({
  '&:hover': {
    backgroundColor: colors.darkBlue,
  },
}));

export const CompaniesAndUsers: React.FC = () => {
  const { t } = useTranslation();
  const { StyledProgress } = useProgress();
  const [companies, setCompanies] = useState<CompanyInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(30);
  const [filterFunction, setFilterFunction] = useState<FilterFunc>({
    filterRecord: (items: Record[]): Record[] => items,
  });
  const { limit, offset } = usePaginationContext();
  
  const headCells = [
    { id: 'name', label: `${t('companyDetails.name')}`, sortable: true },
    { id: 'vat_id', label: `${t('companyDetails.vatId')}`, sortable: true },
    { id: 'city', label: `${t('companyDetails.city')}`, sortable: true },
    { id: 'licence_valid_until', label: `${t('companyDetails.licenceValidUntil')}`, sortable: true },
    { id: 'edit', label: `${t('companyDetails.edit')}`, sortable: false },
  ];
  const { TableContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } = useTable(
    companies,
    headCells,
    filterFunction,
    totalCount
  );
  const navigate = useNavigate();

  useEffect(() => {
    (async (): Promise<void> => {
      setLoading(true);
      const { data } = await Api.get<CompanyResponse>(endpoints.companies,{
        params:{
          offset,
          limit
        }
      });
      const { results, count } = data;
      setLoading(false);
      setCompanies(results);
      setTotalCount(count);
    })();
  }, [limit, offset]);
  
  const handleClick = (): void => {
    navigate(`${urls.companySettings}/new`);
  };

  const handleEdit = useCallback(
    (companyId: number | string): void => {
      navigate(`${urls.companySettings}/${companyId}`);
    },
    [navigate],
  );

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    const searchText = event.target.value;
    setFilterFunction({
      filterRecord: (items: Record[]): Record[] => {
        if (!searchText.length) {
          return items;
        } else {
          return items.filter((item) => `${item.name}`.toLocaleLowerCase().includes(searchText.toLowerCase()));
        }
      },
    });
  }, []);

  return (
    <Main>
      <Grid container display="flex" justifyContent="space-between" m={2} spacing={2}>
        {loading && <StyledProgress size={24} />}
        <Grid item xs={10}>
          <Typography variant="h3">{t('companies.title')}</Typography>
          <Typography variant="subtitle1">{t('companies.subTitle')}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Grid item xs={12} container justifyContent="flex-end">
            <Button
              variant="contained"
              size="small"
              disabled={loading}
              onClick={handleClick}
            >
              {<AddBoxOutlinedIcon />} {` ${t('companies.addCompany')}`}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={4} display="flex" m={2} spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ mb: 2 }}>
            <TextField
              data-testid="search-wrapper"
              name="search"
              size="small"
              fullWidth={false}
              disabled={loading}
              placeholder={t('companies.search')}
              onChange={handleSearch}
              inputProps={{
                'data-testid': 'search',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: '50%',
              }}
            />
          </Box>
          <TableContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item: CompanyInfo) => (
                <TableRow key={item.id} hover>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.vat_id}</TableCell>
                  <TableCell>{item.city}</TableCell>
                  <TableCell>{item.licence_valid_until}</TableCell>
                  <TableCell>
                    <StyledButton onClick={(): void => handleEdit(item.id)} size="small" variant="contained">
                      <EditIcon />
                    </StyledButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TblPagination />
              </TableRow>
            </TableFooter>
          </TableContainer>
        </Grid>
      </Grid>
    </Main>
  );
};
