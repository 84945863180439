import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CompanyLocationList, CompanyLocationCreateDialog } from '..';
import { useCompanyContext } from '../../../context';

interface Props {
  companyId: number | string;
}

export const CompanyLocations: React.FC<Props> = ({ companyId }) => {
  const { t } = useTranslation();
  const { companyLocations } = useCompanyContext();

  const renderCompanyLocations = useMemo(
    () => (
      <Grid container display="flex" m={2}>
        <Grid item xs={10}>
          <Typography variant="h4"> {t('companyLocations.subTitle')}</Typography>
        </Grid>
        <Grid item xs={2} container>
          <Grid item xs={12}>
            <CompanyLocationCreateDialog companyId={companyId} />
          </Grid>
        </Grid>
        <Grid item xs={12} mt={5}>
          <CompanyLocationList locations={companyLocations} />
        </Grid>
      </Grid>
    ),
    [t, companyLocations, companyId],
  );

  return <>{renderCompanyLocations}</>;
};
