import * as i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    fallbackLng: 'fi', // use fi if detected lng is not available
    react: {
      useSuspense: false,
    },
  });

export default i18n;
