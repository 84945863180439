import { Box, Button, Divider, FormControl, FormHelperText, Grid, styled, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Api from '../../api';
import { useCompanyContext } from '../../context';
import { useProgress } from '../../hooks';
import { colors } from '../../theme';
import { CompanyInvoicingFormValues, FormError, ContactPerson } from '../../types';
import { endpoints } from '../../utils';

const StyledButton = styled(Button)(() => ({
  '&:hover': {
    backgroundColor: colors.darkBlue,
  },
}));

interface Props {
  companyId: number | string;
}
const DEFAULT_FORM_VALUES: CompanyInvoicingFormValues = {
  id: '',
  invoicing_street: '',
  invoicing_zip_code: '',
  invoicing_municipality: '',
  einvoice_address: '',
  einvoice_operator_name: '',
  einvoice_operator_code: '',
  email_invoice_address: '',
  contact_person: '',
  invoicing_contact_person: '',
};
export const InvoiceDetails: React.FC<Props> = ({ companyId }) => {
  const { t } = useTranslation();
  const { StyledProgress } = useProgress();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [formValues, setFormValues] = useState<CompanyInvoicingFormValues>(DEFAULT_FORM_VALUES);
  const [formErrors, setFormErrors] = useState<FormError>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [contactPerson, setContactPerson] = useState<ContactPerson | undefined>(undefined);
  const { companyContacts } = useCompanyContext();
  const [isDefaultValueChanged, setIsDefaultValueChanged] = useState<boolean>(false);
  // disable save button before all required fields are filled
  useEffect(() => {
    setButtonDisabled(
      !isDefaultValueChanged ||
        loading ||
        !(
          Number(formValues.invoicing_contact_person) > 0
        ),
    );
  }, [
    formValues.invoicing_contact_person,
    loading,
    isDefaultValueChanged,
  ]);

  // fetch company data
  useEffect(() => {
    if (Number(companyId) > 0) {
      (async (): Promise<void> => {
        setLoading(true);
        const { data } = await Api.get<CompanyInvoicingFormValues>(endpoints.company(companyId));
        setLoading(false);
        setFormValues(data);
      })();
    }
  }, [companyId]);

  const updateCompanyInfo = useCallback(
    async (e: SyntheticEvent): Promise<void> => {
      e.preventDefault();
      try {
        await Api.patch<CompanyInvoicingFormValues>(endpoints.company(companyId), formValues);
      } catch (err: any) {
        setFormErrors(err.response.data);
      }
    },
    [formValues, companyId],
  );

  useEffect(() => {
    const contact = companyContacts.find(
      (contact) => Number(contact.id) === Number(formValues.invoicing_contact_person),
    );
    setContactPerson(contact);
  }, [companyContacts, formValues.invoicing_contact_person]);

  const hasError = useCallback(
    (key: string): boolean => {
      return Object.keys(formErrors).some((item) => item === key);
    },
    [formErrors],
  );
  const renderFormErrorHelperText = useMemo(
    () =>
      (key: string): JSX.Element | void => {
        if (hasError(key)) {
          return <FormHelperText>{formErrors[key]}</FormHelperText>;
        }
      },
    [formErrors, hasError],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const newFormValues = { ...formValues };
      if (e.target.value !== newFormValues[e.target.name as keyof CompanyInvoicingFormValues]) {
        setIsDefaultValueChanged(true);
      }
      newFormValues[e.target.name as keyof CompanyInvoicingFormValues] = e.target.value;
      setFormValues(newFormValues);
    },
    [formValues],
  );

  const renderInvoiceDetails = useMemo(
    () => (
      <form data-testid="invoicing">
        <Grid container item>
          {loading && <StyledProgress size={24} />}
          <Grid container item m={2}>
            <Grid item xs={10}>
              <Typography variant="h4"> {t('invoiceDetails.subTitle')}</Typography>
            </Grid>
            <Grid item xs={2} container>
              <Grid item xs={12}>
                <StyledButton
                  variant="contained"
                  disabled={buttonDisabled}
                  size="small"
                  onClick={updateCompanyInfo}
                  sx={{
                    ml: 5,
                  }}
                >
                  {` ${t('invoiceDetails.save')}`}
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <FormControl error={hasError('invoicing_contact_person')}>
          <Grid container item m={2}>
            <Grid item xs={3} alignItems="start">
              <Typography variant="body1">{t('invoiceDetails.contactPerson')}</Typography>
            </Grid>
            <Grid item xs={6} alignItems="end">
              <>
                <TextField
                  data-testid="invoicing_contact_person-wrapper"
                  name="invoicing_contact_person"
                  select
                  size="small"
                  onChange={handleChange}
                  value={formValues.invoicing_contact_person || ''}
                  inputProps={{
                    'data-testid': 'invoicing_contact_person',
                  }}
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{
                    native: true,
                    displayEmpty: true,
                  }}
                >
                  <option value={''}>-- {t('myResources.selectContactPersonPlaceholder')} --</option>
                  {companyContacts.map((option) => (
                    <option key={option.id} value={option.id}>
                      {`${option.first_name} ${option.last_name}`}
                    </option>
                  ))}
                </TextField>
                {renderFormErrorHelperText('invoicing_contact_person')}
                {contactPerson && (
                  <>
                    <Box mt={3}>
                      <Typography variant="body2">{t('invoiceDetails.contactName')}</Typography>
                      <TextField
                        data-testid="contact_name-wrapper"
                        name="contact_name"
                        size="small"
                        value={`${contactPerson?.first_name} ${contactPerson?.last_name}`}
                        inputProps={{ readOnly: true }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Box>
                    <Box mt={3}>
                      <Typography variant="body2">{t('invoiceDetails.contactEmail')}</Typography>
                      <TextField
                        data-testid="contact_email-wrapper"
                        name="contact_email"
                        size="small"
                        value={contactPerson.email}
                        inputProps={{ readOnly: true }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Box>
                    <Box mt={3}>
                      <Typography variant="body2">{t('invoiceDetails.contactPhoneNumber')}</Typography>
                      <TextField
                        data-testid="contact_phone-wrapper"
                        name="contact_phone"
                        size="small"
                        value={contactPerson.phone}
                        inputProps={{ readOnly: true }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Box>
                  </>
                )}
              </>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" />
        </FormControl>
        <FormControl
          error={
            hasError('email_invoice_address') ||
            hasError('einvoice_operator_name') ||
            hasError('einvoice_operator_code') ||
            hasError('einvoice_address')
          }
        >
          <Grid container item m={2}>
            <Grid item xs={3} alignItems="start">
              <Typography variant="body1">{t('invoiceDetails.eInvoicing')}</Typography>
            </Grid>
            <Grid item xs={6} alignItems="end">
              <>
                <Typography variant="body2">{t('invoiceDetails.invoiceEmail')}</Typography>
                <TextField
                  data-testid="email_invoice_address-wrapper"
                  name="email_invoice_address"
                  size="small"
                  type="email"
                  onChange={handleChange}
                  value={formValues.email_invoice_address}
                  inputProps={{
                    'data-testid': 'email_invoice_address',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
                {renderFormErrorHelperText('email_invoice_address')}
              </>
              <Box mt={3}>
                <>
                  <Typography variant="body2">{t('invoiceDetails.eInvoiceOperator')}</Typography>
                  <TextField
                    data-testid="einvoice_operator_name-wrapper"
                    name="einvoice_operator_name"
                    size="small"
                    onChange={handleChange}
                    value={formValues.einvoice_operator_name}
                    inputProps={{
                      'data-testid': 'einvoice_operator_name',
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  {renderFormErrorHelperText('einvoice_operator_name')}
                </>
              </Box>
              <Box mt={3}>
                <>
                  <Typography variant="body2">{t('invoiceDetails.eInvoiceOperatorCode')}</Typography>
                  <TextField
                    data-testid="einvoice_operator_code-wrapper"
                    name="einvoice_operator_code"
                    size="small"
                    onChange={handleChange}
                    value={formValues.einvoice_operator_code}
                    inputProps={{
                      'data-testid': 'einvoice_operator_code',
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  {renderFormErrorHelperText('einvoice_operator_code')}
                </>
              </Box>
              <Box mt={3}>
                <>
                  <Typography variant="body2">{t('invoiceDetails.eInvoiceAddress')}</Typography>

                  <TextField
                    data-testid="einvoice_address-wrapper"
                    name="einvoice_address"
                    size="small"
                    onChange={handleChange}
                    value={formValues.einvoice_address}
                    inputProps={{
                      'data-testid': 'einvoice_address',
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  {renderFormErrorHelperText('einvoice_address')}
                </>
              </Box>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" />
        </FormControl>
        <FormControl
          error={hasError('invoicing_street') || hasError('invoicing_zip_code') || hasError('invoicing_municipality')}
        >
          <Grid container item m={2}>
            <Grid item xs={3} alignItems="start">
              <Typography variant="body1">{t('invoiceDetails.paperInvoice')}</Typography>
            </Grid>
            <Grid item xs={6} alignItems="end">
              <>
                <Typography variant="body2">{t('invoiceDetails.invoicingAddress')}</Typography>
                <TextField
                  data-testid="invoicing_street-wrapper"
                  name="invoicing_street"
                  size="small"
                  onChange={handleChange}
                  value={formValues.invoicing_street}
                  inputProps={{
                    'data-testid': 'invoicing_street',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
                {renderFormErrorHelperText('invoicing_street')}
              </>
              <Grid item container mt={3} spacing={3}>
                <Grid item xs={6} alignItems="start">
                  <>
                    <Typography variant="body2">{t('invoiceDetails.zipCode')}</Typography>
                    <TextField
                      data-testid="invoicing_zip_code-wrapper"
                      name="invoicing_zip_code"
                      size="small"
                      onChange={handleChange}
                      value={formValues.invoicing_zip_code}
                      inputProps={{
                        'data-testid': 'invoicing_zip_code',
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                    {renderFormErrorHelperText('invoicing_zip_code')}
                  </>
                </Grid>
                <Grid item xs={6} alignItems="end">
                  <>
                    <Typography variant="body2">{t('invoiceDetails.municipality')}</Typography>
                    <TextField
                      data-testid="invoicing_municipality-wrapper"
                      name="invoicing_municipality"
                      size="small"
                      onChange={handleChange}
                      value={formValues.invoicing_municipality}
                      inputProps={{
                        'data-testid': 'invoicing_municipality',
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                    {renderFormErrorHelperText('invoicing_municipality')}
                  </>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      </form>
    ),
    [
      t,
      buttonDisabled,
      updateCompanyInfo,
      contactPerson,
      companyContacts,
      formValues,
      handleChange,
      hasError,
      renderFormErrorHelperText,
      StyledProgress,
      loading,
    ],
  );
  return <>{renderInvoiceDetails}</>;
};
