import React, {ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState} from "react";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {
  Button,
  Chip,
  Grid,
  InputAdornment,
  Tab,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {FilterFunc, OfferListItem, Record,} from "../../types";
import {format} from "date-fns";
import {numberToLocale, offerStates, urls} from "../../utils";
import {useTranslation} from "react-i18next";
import {useProgress, useTable} from "../../hooks";
import {useAuthContext, useOfferContext} from "../../context";
import {colors} from "../../theme";
import {useNavigate} from "react-router-dom";


export const OfferList: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState<string>('1');
  const {allOffers, allOffersLoading} = useOfferContext();
  const [ownOffers, setOwnOffers] = useState<OfferListItem[]>([]);
  const [offersForMe, setOffersForMe] = useState<OfferListItem[]>([]);
  const [draftOffers, setDraftOffers] = useState<OfferListItem[]>([]);
  const [ownOffersCount, setOwnOffersCount] = useState<number>(0);
  const [offersForMeCount, setOffersForMeCount] = useState<number>(0);
  const [draftOffersCount, setDraftOffersCount] = useState<number>(0);
  const { profileData } = useAuthContext();
  const { StyledProgress } = useProgress();

  useEffect(() => {
    (():void => {
      // exclude drafts
      const ownOffers = allOffers.filter(
        (item: OfferListItem) => Number(item.seller) === Number(profileData.company) && item.offer_date);
      const draftOffers = allOffers.filter(
        (item: OfferListItem) => Number(item.seller) === Number(profileData.company) && !item.offer_date);
      // exclude drafts and cancelled offers
      const offersForMe = allOffers.filter((item: OfferListItem) => {
        return Number(item.buyer_company) === Number(profileData.company) && item.state != offerStates.CANCELLED && item.offer_date;
      });

      setOwnOffers(ownOffers);
      setOwnOffersCount(ownOffers.length);

      setOffersForMe(offersForMe);
      setOffersForMeCount(offersForMe.length);

      setDraftOffers(draftOffers);
      setDraftOffersCount(draftOffers.length);
    })();
  }, [profileData, setOffersForMe, setOffersForMeCount, setOwnOffers, setOwnOffersCount, allOffers]);

  const handleTabChange = useCallback(
    (event: SyntheticEvent, newValue: string): void => {
      setTabValue(newValue);
    },
    [setTabValue],
  );

  const headCells = [
    { id: 'offer_date', label: t('offers.offerDate'), sortable: true },
    { id: 'rfp_title', label: t('offers.RFPTitle'), sortable: true },
    { id: 'seller', label: t('offers.seller'), sortable: true },
    { id: 'work_location', label: t('offers.workLocation'), sortable: true },
    { id: 'work_start_date', label: t('offers.workPeriod'), sortable: true },
    { id: 'total_price', label: t('offers.price'), sortable: true },
    { id: 'status', label: t('offers.status'), sortable: true },
    { id: 'open', label: t('offers.open'), sortable: false },
  ];

  const [filterFunction, setFilterFunction] = useState<FilterFunc>({
    filterRecord: (items: Record[]): Record[] => items,
  });

  const ownOffersTable = useTable(
    ownOffers,
    headCells,
    filterFunction,
    ownOffersCount
  );

  const offersForMeTable = useTable(
    offersForMe,
    headCells,
    filterFunction,
    offersForMeCount
  );

  const draftOffersTable = useTable(
    draftOffers,
    headCells,
    filterFunction,
    draftOffersCount
  )
  const handleOpenOffer = useCallback(
     (offerId: string | number):void => {
       navigate(urls.offer(offerId));
  }, [navigate]);


  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const searchText = event.target.value;
      setFilterFunction({
        filterRecord: (items: Record[]): Record[] => {
          if (!searchText.length) {
            return items;
          } else {
            return items.filter((item) => {
              return (
                `${item.rfp_title}`.toLocaleLowerCase().includes(searchText.toLowerCase()) ||
                `${item.seller_name}`.toLocaleLowerCase().includes(searchText.toLowerCase()) ||
                `${item.work_location}`.toLocaleLowerCase().includes(searchText.toLowerCase())
              );
            });
          }
        },
      });
    },
    [],
  );

  const getColorByStatus = (status: string): any => {
    switch (status) {
      case offerStates.FAULTY:
      case offerStates.CANCELLED:
      case offerStates.REJECTED:
        return "error";
      case offerStates.ACCEPTED:
        return "success";
      case offerStates.OPEN:
      case offerStates.WAITING_FOR_BUYER:
        return "info";
      case offerStates.EXPIRED:
        return "warning";
      default:
        return "default";
    }
  };

  const renderTable = useCallback(
    (items: OfferListItem[], table: any) => {
      return (
        <>
        {allOffersLoading && <StyledProgress size={24} />}
        <table.TableContainer>
          <table.TblHead />
          <TableBody>
            {items.map((item: OfferListItem) => (
              <TableRow key={item.id} hover>
                <TableCell>{!!item.offer_date ? format(new Date(item.offer_date), 'dd.MM.yyyy') : (t('offers.draft')).toUpperCase()}</TableCell>
                <TableCell>{item.rfp_title}</TableCell>
                <TableCell>{item.seller_name}</TableCell>
                <TableCell>{item.work_location}</TableCell>
                <TableCell>{!!item.work_start_date && format(new Date(item.work_start_date), 'dd.MM.yyyy')} - {!!item.work_end_date && format(new Date(item.work_end_date), 'dd.MM.yyyy')}</TableCell>
                <TableCell>{!!item.total_price && numberToLocale(item.total_price)}</TableCell>
                <TableCell>
                    <Chip label={t(`offers.states.${item.state}`)} color={getColorByStatus(item.state)} />
                </TableCell>
                <TableCell>
                  <Button onClick={(): void => handleOpenOffer(`${item.id}`)} size="small" variant="contained">
                    {t('offers.open')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <table.TblPagination />
            </TableRow>
          </TableFooter>
        </table.TableContainer>
        </>
      )
    },
    [t, handleOpenOffer, StyledProgress, allOffersLoading],
  );

  const renderAllTabs = useMemo(
    ()=>(
      <TabList
        onChange={handleTabChange}
        aria-label="offer tabs"
        TabIndicatorProps={{
            style: {
            backgroundColor: colors.darkBlue,
          },
        }}
      >
       <Tab key={1} value="1" label={t('offers.drafts')} icon={<Chip label={draftOffersCount} variant="filled" />} iconPosition="end" />
       <Tab key={2} value="2" label={t('offers.ownOffers')} icon={<Chip label={ownOffersCount} variant="filled" />} iconPosition="end" />
       <Tab key={3} value="3" label={t('offers.offersToOwnRFP')} icon={<Chip label={offersForMeCount} variant="filled" />} iconPosition="end" />
      </TabList>
    ), [t, handleTabChange, ownOffersCount, offersForMeCount, draftOffersCount]
  );

    return (
      <TabContext value={tabValue}>
      <Grid>
        {renderAllTabs}
      </Grid>
      <Grid container mt={2}>
        <Grid item xs>
          <TextField
            data-testid="search-wrapper"
            name="search"
            size="small"
            fullWidth={false}
            placeholder={t('misc.search')}
            onChange={handleSearch}
            inputProps={{
              'data-testid': 'search',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              width: '50%',
            }}
          />
        </Grid>
      </Grid>
      {/* Draft offers */}
      <TabPanel key="1" value="1" sx={{pl:0, pr:0}}>
        <Grid container mt={2}>
          <Grid item xs>
            <Typography variant="h4" mb={2}>{t('offers.drafts')}</Typography>
           {renderTable(draftOffersTable.recordsAfterPagingAndSorting(), draftOffersTable)}
          </Grid>
        </Grid>
      </TabPanel>

      {/* Own offers */}
      <TabPanel key="2" value="2" sx={{pl:0, pr:0}}>
        <Grid container mt={2}>
          <Grid item xs>
            <Typography variant="h4" mb={2}>{t('offers.ownOffers')}</Typography>
           {renderTable(ownOffersTable.recordsAfterPagingAndSorting(), ownOffersTable)}
          </Grid>
        </Grid>
      </TabPanel>

      {/* Offers to own request for proposal */}
      <TabPanel key="3" value="3" sx={{pl:0, pr:0}}>
        <Grid container mt={2}>
          <Grid item xs>
            <Typography variant="h4" mb={2}>{t('offers.offersToOwnRFPHeader')}</Typography>
            {renderTable(offersForMeTable.recordsAfterPagingAndSorting(), offersForMeTable)}
          </Grid>
        </Grid>
      </TabPanel>

    </TabContext>
    )
}
