import { Dialog, Button, DialogContent, DialogActions, DialogContentText, Alert } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  text: string;
  isOpen: boolean;
  handleClose: () => void;
  handleCancel: () => void;
  severity: any;
}

export const ConfirmDialog: React.FC<Props> = ({ text, isOpen, handleClose, handleCancel, severity }) => {
  // Note: Material ui severity types 'error','info','success', 'warning'
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Alert severity={severity}>{text}</Alert>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCancel} autoFocus size="small">
          {t('misc.cancelButtonLabel')}
        </Button>
        <Button variant="contained" onClick={handleClose} autoFocus size="small">
          {t('misc.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
