import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './i18n';
import * as serviceWorker from './serviceWorker';
import { 
  AuthContextProvider, 
  CartContextProvider, 
  CompanyContextProvider, 
  ResourceContextProvider, 
  PaginationContextProvider, 
  OrderContextProvider,
  RequestForProposalContextProvider,
  AllCompaniesContextProvider, 
  AllResourceAttributeOptionsContextProvider, 
  AllResourceAttributesContextProvider, 
  AllResourceSubTypesContextProvider, 
  AdvertsContextProvider,
  OfferContextProvider} from './context';
import { ThemeProvider, Slide } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { theme } from './theme';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { CompaniesContextProvider } from './context/companies';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Router>
      <AuthContextProvider>
        <PaginationContextProvider>
          <AdvertsContextProvider>
            <AllCompaniesContextProvider>
              <AllResourceAttributeOptionsContextProvider>
                <AllResourceAttributesContextProvider>
                  <AllResourceSubTypesContextProvider>
                    <CompaniesContextProvider>
                      <CompanyContextProvider>
                        <ResourceContextProvider>
                          <OrderContextProvider>
                            <RequestForProposalContextProvider>
                              <OfferContextProvider>
                              <ThemeProvider theme={theme}>
                                <SnackbarProvider
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                  TransitionComponent={Slide}
                                >
                                  <CssBaseline />
                                  <CartContextProvider>
                                    <Routes>
                                      <Route path="/*" element={<App />} />
                                    </Routes>
                                  </CartContextProvider>
                                </SnackbarProvider>
                              </ThemeProvider>
                              </OfferContextProvider>
                            </RequestForProposalContextProvider>
                          </OrderContextProvider>
                        </ResourceContextProvider>
                      </CompanyContextProvider>
                    </CompaniesContextProvider>
                  </AllResourceSubTypesContextProvider>
                </AllResourceAttributesContextProvider>
              </AllResourceAttributeOptionsContextProvider>
            </AllCompaniesContextProvider>
          </AdvertsContextProvider>
        </PaginationContextProvider>
        
      </AuthContextProvider>
    </Router>
  </React.StrictMode>,
);

serviceWorker.register();
