import { Grid, styled } from '@mui/material';
import React from 'react';
import { SideNavBar } from './SideNavbar';
import { colors } from '../theme';
import { SimpleDialog } from '../components/common';
import { useCartContext } from '../context';
import { useTranslation } from 'react-i18next';

interface Props {
  children: React.ReactNode;
}

const StyledSideBarGrid = styled(Grid)(({ theme }) => ({
  width: '15%',
  backgroundColor: theme.palette.common.white,
  borderRight: '1px solid',
  borderColor: colors.lightgray,
  paddingTop: '2.5em',
  paddingBottom: '2.5em',
  paddingLeft: '1.5em',
}));

const StyledContent = styled(Grid)(({ theme }) => ({
  width: '85%',
  height: '100%',
  padding: theme.spacing(1),
}));
export const Main: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const {cartExpiring, cartExpired, closeCartExpirationDialog} = useCartContext();
  return (
    <Grid container direction={'row'}>
      <StyledSideBarGrid item container>
        <SideNavBar />
      </StyledSideBarGrid>
      <StyledContent item container>
        {cartExpiring && <SimpleDialog text={t('cart.orderAboutToExpire')} isOpen={cartExpiring} handleClose={closeCartExpirationDialog} severity="warning" />}
        {cartExpired && <SimpleDialog text={t('cart.orderExpired')} isOpen={cartExpired} handleClose={closeCartExpirationDialog} severity="error" />}
        {children}
      </StyledContent>
    </Grid>
  );
};
