import axios from 'axios';
import { auth } from './firebase';
import { urls } from './utils';

const Api = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/v1`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// track changes in auth state and update sessionStorage
auth.onIdTokenChanged((user) => {
  if (user) {
    sessionStorage.setItem(`firebase:authUser:${process.env.REACT_APP_AUTH_API_KEY}:[DEFAULT]`, JSON.stringify(user));
  }
});

// add request config
Api.interceptors.request.use(
  (config) => {
    const userLang = localStorage.getItem('user_language') || 'fi';
    let accessToken = '';
    const sessionData = sessionStorage.getItem(`firebase:authUser:${process.env.REACT_APP_AUTH_API_KEY}:[DEFAULT]`);
    const location = window.location.href.split('//')[1];
    if (!!sessionData) {
      const { stsTokenManager } = JSON.parse(sessionData);
      accessToken = stsTokenManager.accessToken;
    }
    if (accessToken.length || `${location}` === `${window.location.host}${urls.login}`) {
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
        'Accept-Language': userLang,
      };
      return config;
    } else {
      // if not token available redirect to login
      if(`${location}` !== `${window.location.host}${urls.login}`){
        window.location.href = `${urls.login}`;
      }
    }
  },
  (error) => Promise.reject(error),
);
export default Api;
