import {Box, Button, Chip, Divider, Grid, Paper, styled, Typography} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {format} from 'date-fns';

import {useAuthContext} from '../context';
import {colors} from '../theme';
import Api from '../api';
import {endpoints, numberToLocale, offerStates, urls} from '../utils';
import {useProgress} from '../hooks';
import {ContactPerson, Offer, OfferLine, RequestForProposal} from '../types';
import {Main} from '../layout';
import {defaultRFP} from "../pages/RequestForProposal";
import {useSnackbar} from "notistack";
import {ConfirmDialog} from "../components/common/ConfirmDialog";

const StyledButton = styled(Button)(() => ({
  marginRight: '0.5em',
}));

const StyledNavLink = styled(NavLink)(() => ({
  textDecoration: 'none',
  color: colors.secondaryTextGray,
}));

export const defaultOfferLine: OfferLine = {
  id: '',
  resource: 0,
  quantity: 0,
  end: new Date(),
  begin: new Date(),
  offer: 0,
  unit_price: 0,
  resource_name: '',
  resource_subtype: '',
  total_price: 0,
  validate_reservation_time: true
}

const defaultContact: ContactPerson = {
  id: 0,
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  company: 0,
  user: {
    id: '',
    is_company_admin: undefined,
    is_staff: undefined,
  }
}

const defaultOffer: Offer = {
  id: '',
  created_at: '',
  updated_at: '',
  offer_date: '',
  request_for_proposal: defaultRFP,
  seller: 0,
  offer_lines: [defaultOfferLine],
  rejected_at: '',
  accepted_at: '',
  work_start_date: '',
  work_end_date: '',
  work_location: '',
  rfp_title: '',
  buyer_company: 0,
  seller_name: '',
  total_price: 0,
  extra_info: '',
  contact_person: '',
  state: ''
}

export const OfferDetailView: React.FC = () => {
  const { t } = useTranslation();
  const { StyledProgress } = useProgress();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { profileData } = useAuthContext()
  const navigate = useNavigate();

  const [offer, setOffer] = useState<Offer>(defaultOffer);
  const [loading, setLoading] = useState<boolean>(false);
  const [contactPerson, setContactPerson] = useState<ContactPerson>(defaultContact);
  const [requestForProposal, setRequestForProposal] = useState<RequestForProposal>(defaultRFP);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [acceptOpen, setAcceptDialogOpen] = useState<boolean>(false);
  const [rejectOpen, setRejectDialogOpen] = useState<boolean>(false);
  const [cancelOpen, setCancelDialogOpen] = useState<boolean>(false);

  const canEdit = offer.seller === profileData.company && [offerStates.FAULTY, offerStates.OPEN, offerStates.DRAFT].includes(offer.state);
  const isOwnOffer = offer.buyer_company === profileData.company
  const canAcceptOffer = isOwnOffer && [offerStates.OPEN, offerStates.WAITING_FOR_BUYER].includes(offer.state);
  const canRejectOffer = isOwnOffer && [offerStates.OPEN, offerStates.WAITING_FOR_BUYER, offerStates.FAULTY].includes(offer.state);

  useEffect(() => {
    (async (): Promise<void> => {
      setLoading(true);
      const { data: offerData } = await Api.get<Offer>(endpoints.offer(Number(id)));
      setOffer(offerData)
      if (offerData.contact_person) {
        const { data: contactData } = await Api.get<ContactPerson>(endpoints.contact(Number(offerData.contact_person)));
        setContactPerson(contactData);
      }
      const { data: rfpData } = await Api.get<RequestForProposal>(endpoints.requestForProposal(Number(offerData.request_for_proposal)));
      setRequestForProposal(rfpData);
      setLoading(false);
    })();
  }, [setOffer, id, profileData.company, setContactPerson]);

  const handleEdit = useCallback(
    (id: string): void => {
      navigate(urls.offerEdit(id), {state: requestForProposal});
    },
    [navigate, requestForProposal],
  );

  const handlePublish = useCallback(
    async (): Promise<void> => {
      try {
        const formData = {
          offer_date: new Date(),
        };
        const { data } = await Api.patch<Offer>(endpoints.offer(Number(id)), formData);
        if (data.id) {
          enqueueSnackbar(t('misc.updateSuccess'), {
            variant: 'success',
          });
          navigate(urls.offer(data.id));
          setOffer(data);
        }
      } catch (err: any) {
        enqueueSnackbar(t('misc.updateFailed'), {
          variant: 'error',
        });
      }
    },
    [navigate, enqueueSnackbar, t, id],
  );

  const handleAccept = useCallback(
    async (): Promise<void> => {
      try {
        const { data } = await Api.get<Offer>(endpoints.offerAccept(Number(id)));
        enqueueSnackbar(t('misc.updateSuccess'), {
          variant: 'success',
        });
        navigate(urls.orders);
        setOffer(data);

      } catch (err: any) {
        enqueueSnackbar(t('misc.updateFailed'), {
          variant: 'error',
        });
      }
    },
    [navigate, enqueueSnackbar, t, id],
  );

  const handleReject = useCallback(
    async (): Promise<void> => {
      try {
        await Api.get<Offer>(endpoints.offerReject(Number(id)));
        enqueueSnackbar(t('misc.updateSuccess'), {
          variant: 'success',
        });
        const { data: offerData } = await Api.get<Offer>(endpoints.offer(Number(id)));
        setOffer(offerData)
      } catch (err: any) {
        enqueueSnackbar(t('misc.updateFailed'), {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, t, id]
  );

  const handleCancelOffer = useCallback(
    async (): Promise<void> => {
      try {
        await Api.get<Offer>(endpoints.offerCancel(Number(id)));
        enqueueSnackbar(t('misc.updateSuccess'), {
          variant: 'success',
        });
        const { data: offerData } = await Api.get<Offer>(endpoints.offer(Number(id)));
        setOffer(offerData)
      } catch (err: any) {
        enqueueSnackbar(t('misc.updateFailed'), {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, t, id]
  );

  const openDialog = useCallback((): void => {
    setDialogOpen(true);
  }, [setDialogOpen]);

  const closeDialog = useCallback((): void => {
    setDialogOpen(false);
    handlePublish();
  }, [setDialogOpen, handlePublish]);

  const cancelDialog = useCallback((): void => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  const openAcceptDialog = useCallback((): void => {
    setAcceptDialogOpen(true);
  }, [setAcceptDialogOpen]);

  const closeAcceptDialog = useCallback((): void => {
    setAcceptDialogOpen(false);
    handleAccept();
  }, [setAcceptDialogOpen, handleAccept]);

  const cancelAcceptDialog = useCallback((): void => {
    setAcceptDialogOpen(false);
  }, [setAcceptDialogOpen]);

  const openRejectDialog = useCallback((): void => {
    setRejectDialogOpen(true);
  }, [setRejectDialogOpen]);

  const closeRejectDialog = useCallback((): void => {
    setRejectDialogOpen(false);
    handleReject();
  }, [setRejectDialogOpen, handleReject]);

  const cancelRejectDialog = useCallback((): void => {
    setRejectDialogOpen(false);
  }, [setRejectDialogOpen]);

  const openCancelDialog = useCallback((): void => {
    setCancelDialogOpen(true);
  }, [setCancelDialogOpen]);

  const closeCancelDialog = useCallback((): void => {
    setCancelDialogOpen(false);
    handleCancelOffer();
  }, [setCancelDialogOpen, handleCancelOffer]);

  const cancelCancelDialog = useCallback((): void => {
    setCancelDialogOpen(false);
  }, [setCancelDialogOpen]);

  const showResourceAvailability = useCallback(
    (availablity: boolean, state: string): boolean => {
      return (!availablity && state!==offerStates.ACCEPTED)
    },[]
  )

  const renderOfferState = useCallback(
    (status: string) => {
      switch (status) {
        case offerStates.FAULTY:
        case offerStates.CANCELLED:
        case offerStates.REJECTED:
          return <Chip label={t(`offers.states.${status}`)} color="error" />
        case offerStates.ACCEPTED:
          return <Chip label={t(`offers.states.${status}`)} color="success" />
        case offerStates.OPEN:
        case offerStates.WAITING_FOR_BUYER:
          return <Chip label={t(`offers.states.${status}`)} color="info" />
        case offerStates.EXPIRED:
          return <Chip label={t(`offers.states.${status}`)} color="warning" />
        default:
          return <Chip label={t(`offers.states.${status}`)} />
      }
    },
    [t],
  );

  const render = useMemo(
    () => (
      <>
        <Grid container direction="row" justifyContent="space-between" pr={1}>
          <Grid item>
            <StyledNavLink to={urls.offers}>
              <Grid container direction="row" alignItems="center">
                <ArrowBackIosIcon sx={{fontSize: "0.8em", marginRight: "0.5em"}}></ArrowBackIosIcon>
                <Typography variant="h6">
                  {t('offers.returnLinkLabel')}
                </Typography>
              </Grid>
            </StyledNavLink>
          </Grid>
        </Grid>
        <Grid item pb={0.5} pr={1} xs={12}>
          <Paper>
            {loading && <StyledProgress size={24}/>}
            <Box p={1} pt={3} pb={3} m={2}>
              <Grid container direction="row" justifyContent="space-between" pb={2} mb={4}>
                <Grid item>
                  <Typography variant="h5">
                    {t('offers.offerToRFP')}: {offer.rfp_title} {[offerStates.DRAFT, offerStates.CANCELLED].includes(offer.state) && `(${t(`offers.states.${offer.state}`).toUpperCase()})`}
                  </Typography>
                </Grid>
                <Grid item>
                  <>
                    {canEdit && (
                      <>
                        <Grid container direction="column" alignItems="flex-end">
                          <Grid item container direction="row" justifyContent="flex-end">
                            <Grid item>
                              <StyledButton variant="contained" onClick={openCancelDialog} size="small">
                                {t('offers.cancelOfferButton')}
                              </StyledButton>
                            </Grid>
                            <Grid item>
                              <StyledButton variant="contained" onClick={(): void => handleEdit(`${offer.id}`)}
                                            size="small">
                                {t('misc.editButtonLabel')}
                              </StyledButton>
                            </Grid>
                            <Grid item>
                              <StyledButton variant="contained" onClick={openDialog} size="small"
                                            disabled={!!offer.offer_date}>
                                {t('offers.submitButtonLabel')}
                              </StyledButton>
                            </Grid>
                          </Grid>
                          <Grid item mr={1}>
                            <Typography variant="subtitle1">
                              {!offer.offer_date ? t('offers.submitHelpText') : t('offers.publishedHelpText')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid container direction="column" alignItems="flex-end">
                      <Grid item container direction="row" justifyContent="flex-end">
                        {canRejectOffer && (
                          <Grid item mr={1}>
                            <Button variant="contained" onClick={openRejectDialog} size="small">
                              {t('offers.rejectOfferButtonLabel')}
                            </Button>
                          </Grid>
                        )}
                        {canAcceptOffer && (
                          <Grid item>
                            <Button variant="contained" onClick={openAcceptDialog} size="small">
                              {t('offers.acceptOfferButtonLabel')}
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </>
                </Grid>
              </Grid>
              <Grid container item direction="row" xs={12} pb={2}>
                <Grid item xs={4}>
                  <Typography variant="body2">{t('offers.generalDetailsTitle')}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Grid container pb={2}>
                    <Grid item xs={12}>
                      {renderOfferState(offer.state)}
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('offers.offerDate')}</Typography>
                      {offer.offer_date ? <Typography
                              variant="subtitle1">{format(new Date(offer.offer_date), 'dd.MM.yyyy')}</Typography> :
                          <Typography variant="subtitle1">- {t('offers.notPublished')} -</Typography>}
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('offers.seller')}</Typography>
                      <Typography variant="subtitle1">{offer.seller_name}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container pb={2} pr={4}>
                    <Grid item>
                      <Typography variant="h6" mb={1}>{t('requestsForProposal.extraInfo')}</Typography>
                      <Typography variant="subtitle1">{offer.extra_info}</Typography>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth"/>

                  <Grid container pb={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('offers.contactName')}</Typography>
                      <Typography
                          variant="subtitle1">{contactPerson.first_name} {contactPerson.last_name}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('offers.contactPhone')}</Typography>
                      <Typography variant="subtitle1">{contactPerson.phone}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('offers.contactEmail')}</Typography>
                      <Typography variant="subtitle1">{contactPerson.email}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Divider variant="fullWidth"/>

              <Grid item>
                <Grid container pt={4} pb={4}>
                  <>
                    <Typography variant="body2">{t('offers.resourcesTitle')}</Typography>
                    {offer.offer_lines.map((item: OfferLine) => (
                      <>
                        <Grid container key={item.id} mt={4} pb={2} ml={1}>
                          <Grid item xs={2}>
                            <Typography variant="h6">{item.resource_name}</Typography>
                          </Grid>
                          <Grid item xs={10}>
                            <Grid container>
                              <Grid item xs={2}>
                                <Typography variant="subtitle1">{t('offers.resourceType')}</Typography>
                                <Typography variant="subtitle1">{item.resource_subtype}</Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography variant="subtitle1">{t('offers.workPeriod')}</Typography>
                                <Typography variant="subtitle1"
                                            style={{color: showResourceAvailability(item.validate_reservation_time, offer.state) ? 'red' : 'inherit'}}>{
                                  item.begin && format(new Date(item.begin), 'dd.MM.yyyy')}
                                  &nbsp;&ndash;&nbsp;
                                  {item.end && format(new Date(item.end), 'dd.MM.yyyy')}
                                  <br/>{showResourceAvailability(item.validate_reservation_time, offer.state) && t('offers.resourceUnavailable')}
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant="subtitle1">
                                  {t('offers.dailyPrice')}
                                </Typography>
                                <Typography variant="subtitle1" sx={{width: '15%'}}>
                                  {`${numberToLocale(item.unit_price)}`}
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant="subtitle1">{t('offers.quantity')}</Typography>
                                <Typography variant="subtitle1">{item.quantity}</Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant="subtitle1" sx={{width: '20%'}}>
                                  {t('offers.totalPrice')}
                                </Typography>
                                <Typography variant="subtitle1" sx={{width: '20%'}}>
                                  {!!item.total_price && numberToLocale(item.total_price)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ))}
                    {/* {render offer's TotalPrice} */}
                    <Grid container pt={4} justifyContent="flex-end">
                      <Typography variant="h5" sx={{fontWeight: 'bold'}}>{t('offers.totalPrice')}:&nbsp;&nbsp;
                        {!!offer.total_price && numberToLocale(offer.total_price)}
                      </Typography>
                    </Grid>
                  </>
                </Grid>
              </Grid>

              <Divider variant="fullWidth"/>

              <Grid container item direction="row" xs={12} pt={4}>
                <Grid item xs={4}>
                  <Typography variant="body2">{t('requestsForProposal.generalDetailsTitle')}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Grid container pb={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('requestsForProposal.title')}</Typography>
                      <Typography variant="subtitle1">{offer.rfp_title}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('offers.workLocation')}</Typography>
                      <Typography variant="subtitle1">{offer.work_location}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('requestsForProposal.accommodationBy')}</Typography>
                      <Typography variant="subtitle1">{requestForProposal.accommodation_by}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('offers.workPeriod')}</Typography>
                      <Typography variant="subtitle1">
                        {!!requestForProposal.work_start_date && format(new Date(requestForProposal.work_start_date), 'dd.MM.yyyy')}
                        &nbsp;&ndash;&nbsp;
                        {!!requestForProposal.work_end_date && format(new Date(requestForProposal.work_end_date), 'dd.MM.yyyy')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container pb={2} pr={4}>
                    <Grid item>
                      <Typography variant="h6" mb={1}>{t('requestsForProposal.workDescription')}</Typography>
                      <Typography variant="subtitle1">{requestForProposal.work_description}</Typography>
                    </Grid>
                  </Grid>
                  {offer.id && (<Grid container pb={2} pr={4}><Grid item>
                    <Typography variant="h6" mb={1}>{t('requestsForProposal.extraInfo')}</Typography>
                    <Typography variant="subtitle1">{requestForProposal.extra_info}</Typography>
                  </Grid>
                  </Grid>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </>
    ),
    [
      t,
      offer,
      StyledProgress,
      loading,
      canEdit,
      canAcceptOffer,
      canRejectOffer,
      handleEdit,
      contactPerson,
      requestForProposal,
      openDialog,
      openAcceptDialog,
      openRejectDialog,
      openCancelDialog,
      showResourceAvailability,
      renderOfferState
    ],
  );

  if (dialogOpen) {
    return (
      <ConfirmDialog
        text={`${t('offers.publishOfferConfirmation')}: ${offer.rfp_title}?`}
        isOpen={dialogOpen}
        handleClose={closeDialog}
        handleCancel={cancelDialog}
        severity=""
      />
    );
  }
  if (acceptOpen) {
    return (
      <ConfirmDialog
        text={`${t('offers.acceptOfferConfirmation')} ${offer.rfp_title} (${offer.total_price} €)?`}
        isOpen={acceptOpen}
        handleClose={closeAcceptDialog}
        handleCancel={cancelAcceptDialog}
        severity=""
      />
    );
  }
  if (rejectOpen) {
    return (
      <ConfirmDialog
        text={t('offers.rejectOfferConfirmation', {rfp: offer.rfp_title, price: offer.total_price})}
        isOpen={rejectOpen}
        handleClose={closeRejectDialog}
        handleCancel={cancelRejectDialog}
        severity="warning"
      />
    );
  }
  if (cancelOpen) {
    return (
      <ConfirmDialog
        text={t('offers.cancelOfferConfirmation', {rfp: offer.rfp_title, price: offer.total_price})}
        isOpen={cancelOpen}
        handleClose={closeCancelDialog}
        handleCancel={cancelCancelDialog}
        severity="warning"
      />
    );
  }

  return <Main>{render}</Main>;
};
