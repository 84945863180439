import React from 'react';
import { Stack, Divider } from '@mui/material';
import { CompanyLocation } from '../../../types';
import { CompanyLocationListItem } from '.';

interface Props {
  locations: CompanyLocation[];
}

export const CompanyLocationList: React.FC<Props> = ({ locations }) => {
  return (
    <Stack divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
      {locations.map((location) => (
        <CompanyLocationListItem location={location} key={location.id} />
      ))}
    </Stack>
  );
};
