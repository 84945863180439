import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import Api from '../api';

import { RequestForProposal, RequestsForProposalListResponse } from '../types';
import { endpoints } from '../utils';
import { useAuthContext } from './auth';

const initialRFPs: RequestForProposal[] = [];
const initialCountTotal = 0;
const initialCountValid = 0;
const initialState = {
  allRequestsForProposal: initialRFPs,
  setAllRequestsForProposal: (): void => {},
  validRequestsForProposal: initialRFPs,
  setValidRequestsForProposal: (): void => { },
  countAllRequestsForProposal: initialCountTotal,
  setCountAllRequestsForProposal: (): void => {},
  countValidRequestsForProposal: initialCountValid,
  setCountValidRequestsForProposal: (): void => {},
};

interface RequestForProposalContextType {
  allRequestsForProposal: RequestForProposal[];
  setAllRequestsForProposal: Dispatch<SetStateAction<RequestForProposal[]>>;
  validRequestsForProposal: RequestForProposal[];
  setValidRequestsForProposal: Dispatch<SetStateAction<RequestForProposal[]>>;
  countAllRequestsForProposal: number;
  setCountAllRequestsForProposal: Dispatch<SetStateAction<number>>;
  countValidRequestsForProposal: number;
  setCountValidRequestsForProposal: Dispatch<SetStateAction<number>>;
}

interface Props {
  children: React.ReactNode;
}
const RequestForProposalContext = createContext<RequestForProposalContextType>(initialState);

export const useRequestForProposalContext = (): RequestForProposalContextType => useContext(RequestForProposalContext);

export const RequestForProposalContextProvider: React.FC<Props> = ({ children }) => {
  const { token } = useAuthContext();
  const [allRequestsForProposal, setAllRequestsForProposal] = useState<RequestForProposal[]>(initialRFPs);
  const [validRequestsForProposal, setValidRequestsForProposal] = useState<RequestForProposal[]>(initialRFPs);
  const [countAllRequestsForProposal, setCountAllRequestsForProposal] = useState<number>(initialCountTotal)
  const [countValidRequestsForProposal, setCountValidRequestsForProposal] = useState<number>(initialCountTotal)

  const fetchRFPs = async (): Promise<void> => {
    const { data } = await Api.get<RequestsForProposalListResponse>(endpoints.requestsForProposal);
    const { results, count } = data;

    const resultsCopy = JSON.parse(JSON.stringify(results));
    const valid = resultsCopy.filter((item: RequestForProposal) => item.is_valid == true);

    setAllRequestsForProposal(results);
    setCountAllRequestsForProposal(count);
    setValidRequestsForProposal(valid)
    setCountValidRequestsForProposal(valid.length);
  };

  useEffect(() => {
    if (token) {
      fetchRFPs();
    }
  }, [token]);

  return (
    <RequestForProposalContext.Provider
      value={{
        setAllRequestsForProposal,
        allRequestsForProposal,
        setValidRequestsForProposal,
        validRequestsForProposal,
        setCountAllRequestsForProposal,
        countAllRequestsForProposal,
        setCountValidRequestsForProposal,
        countValidRequestsForProposal,
      }}
    >
      {children}
    </RequestForProposalContext.Provider>
  );
};
