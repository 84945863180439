import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  FormHelperText,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Box,
  Tab,
  Grid,
  Typography,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { Product, Resource, Error, ResourceType, ResourceAttributeLine, ResourceAttribute } from '../../types';
import { useAllResourceAttributesContext, useAuthContext, usePaginationContext, useResourceContext } from '../../context';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { BasicDetail, ResourceAttributes, CalendarAvailability } from './index';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { colors } from '../../theme';
import { formatNumber, initialPagingOffset } from '../../utils';
import { useSnackbar } from 'notistack';
import { DeleteDialog } from '../common/DeleteDialog';
import { DeleteButton } from '../common/DeleteButton';

interface Props {
  resourceId: string;
  resourceTypes: ResourceType[];
  selectedMyResourceType: number;
  company: number;
}

const DEFAULT_FORM_VALUES: Resource = {
  id: '',
  _type: 0,
  internal_name: '',
  category: '',
  status: '',
  location: '',
  distance: '',
  subtype: '',
  contact_person: '',
  company: '',
  company_location: '',
  title: '',
  email: '',
  phone: '',
  tax_number: '',
  cost_per_km: '',
  freeFrom: '',
  attribute_lines: [ {
    id: 0,
    resource: 0,
    attribute_id:0,
    attribute: {
      id: 0,
      name: '',
      unit: '',
      type: '',
      display_type: '',
      required: true
    },
    selected_options: [],
    string_value: '',
    numeric_value: 0,
    datetime_value: new Date(),
    boolean_value: true
  }],
  product: {
    id: '',
    title: '',
    company: '',
    type: 1,
    price: '',
  },
  availability_periods: [],
  max_work_distance: null
};

export const ResourceCreateDialog: React.FC<Props> = ({ resourceId, resourceTypes, selectedMyResourceType, company }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { profileData } = useAuthContext();
  DEFAULT_FORM_VALUES.company = profileData.company;
  DEFAULT_FORM_VALUES.product.company = profileData.company;
  const [formValues, setFormValues] = useState<Resource>(DEFAULT_FORM_VALUES);
  const [formErrors, setFormErrors] = useState<Error>({});
  const { setSelectedResourceId, resources, setOpen, isResourceFormOpen, 
    disableAttrTab, disableCalTab, setDisableAttrTab, setDisableCalTab, 
    setSelectedResourceSubTypeId,selectedResourceId, removeResource,
    resourceLoading, updateResource, createResource, fetchResources } = useResourceContext();
  const { allResourceAttributes } = useAllResourceAttributesContext();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [isDefaultValueChanged, setIsDefaultValueChanged] = useState<boolean>(false);
  const [value, setValue] = useState<string>('1');
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteButtonVisible, setDeleteButtonVisible] = useState<boolean>(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const { setOffset, limit, offset} = usePaginationContext();

  const handleTabChange = useCallback((event: SyntheticEvent, newValue: string): void => {
    setValue(newValue);
    setOffset(initialPagingOffset);
  }, [setOffset]);

  // check field values before submit button is enabled
  useEffect(() => {
    setButtonDisabled(
      !isDefaultValueChanged ||
      loading || resourceLoading ||
      ![formValues._type, formValues.internal_name, formValues.subtype, formValues.product.title, formValues.product.price].every((item) =>
        typeof item === 'string' ? item.length > 0 : item > 0,
      ),
    );
  }, [formValues, loading, isDefaultValueChanged, resourceLoading]);
  
  // set initial formValues for selected resource
  useEffect(()=>{
    const selectedResource = 
      resources.find((item: Resource) => Number(item.id) === Number(resourceId));
    if(!!selectedResource){  
      setFormValues(selectedResource);
      setSelectedResourceSubTypeId(selectedResource?.subtype );
    }else{
      setFormValues(DEFAULT_FORM_VALUES);
      setSelectedResourceSubTypeId('');
    }
  },[resources, resourceId, setSelectedResourceSubTypeId]);

  useEffect(() => {
    if (Number(resourceId) > 0) {    
      setDeleteButtonVisible(true);
      setDisableAttrTab(false);
      setDisableCalTab(false);
    } else {
      setFormErrors({});
      setDeleteButtonVisible(false);
      setDisableAttrTab(true);
      setDisableCalTab(true);
    }
  }, [resources, resourceId, setOpen, setDisableAttrTab, setDisableCalTab]);

  const hasError = useCallback(
    (key: string): boolean => {
      return Object.keys(formErrors).some((item) => item === key);
    },
    [formErrors],
  );

  const renderFormErrorHelperText = useMemo(
    () =>
      (key: string): JSX.Element | void => {
        if (hasError(key)) {
          return <FormHelperText>{formErrors[key]}</FormHelperText>;
        }
      },
    [formErrors, hasError],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      // Take a deep copy to ensure the initial values are not modified
      const newFormValues = JSON.parse(JSON.stringify(formValues));
      const attributeLine: ResourceAttributeLine = {
        resource: 0,
        attribute: {
          id: 0,
          name: '',
          unit: '',
          type: ''
        },
        attribute_id: 0,
        selected_options: []
      };

      if(e.target.name.startsWith('attribute_lines__')){ 
        const attrLineFieldName = e.target.name.split('__')[1];
        const attributeId = Number(e.target.name.split('__')[2])
        const selectedOptionsForAttribute = newFormValues.attribute_lines.find((attr:ResourceAttributeLine) => Number(attr.attribute.id) === attributeId)?.selected_options ?? [];
        attributeLine.selected_options = [...attributeLine.selected_options, ...selectedOptionsForAttribute];
        attributeLine.resource = Number(selectedResourceId);
        attributeLine.attribute_id = attributeId;
        const selectedAttribute = allResourceAttributes?.find((attr: ResourceAttribute) => Number(attr.id) ===attributeId);
        const restValues = newFormValues.attribute_lines.filter((attrLine: ResourceAttributeLine) => Number(attrLine.attribute.id) !== Number(attributeId));
        if(!!selectedAttribute){
          attributeLine.attribute = selectedAttribute;
        }
        switch(attrLineFieldName){
          case 'string_value':
            attributeLine.string_value = e.target.value;
            newFormValues.attribute_lines=[...restValues, attributeLine];
            break;
          case 'numeric_value':
            attributeLine.numeric_value = Number(e.target.value);
            newFormValues.attribute_lines=[...restValues, attributeLine];           
            break;
          case 'choice':
            const optionId = Number(e.target.name.split('__')[3])
            if(attributeLine.selected_options.includes(optionId)){
                // uncheck removes optionId from selected_options
                const newSelectedOptions = attributeLine.selected_options.filter((item:number)=> item !==optionId);
                attributeLine.selected_options = [...newSelectedOptions];
                newFormValues.attribute_lines=[...restValues, attributeLine];
            }else{
              // checked adds optionId to selected_options
              attributeLine.selected_options = [...attributeLine.selected_options,optionId];
              newFormValues.attribute_lines=[...restValues, attributeLine];
            }
            break;
          case 'boolean_value':
            attributeLine.boolean_value = Boolean(Number(e.target.value));
            newFormValues.attribute_lines=[...restValues, attributeLine];
            break;
          case 'datetime_value':
            attributeLine.datetime_value = new Date(e.target.value);
            newFormValues.attribute_lines=[...restValues, attributeLine];
            break;
        }
        
      }
      if (e.target.name.startsWith('product__')) {
        const productFieldName = e.target.name.split('__')[1];
        newFormValues.product[productFieldName as keyof Product] = e.target.value;  
      }

      if (e.target.value !== newFormValues[e.target.name as keyof Resource]) {
        setIsDefaultValueChanged(true);
      }
      newFormValues.availability_periods = formValues.availability_periods;
      (newFormValues as any)[e.target.name as keyof Resource] = e.target.value;
      setFormValues(newFormValues);
    },
    [formValues, selectedResourceId, allResourceAttributes],
  );

  const handleClose = (): void => {
    setOpen(false);
    fetchResources(company, offset, limit, selectedMyResourceType);
  };

  const handleAddResource = (): void => {
    setSelectedResourceId('0');
    setValue('1');
    setOpen(true);
  };

  const handleDeleteDialogOpen = (): void => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = (): void => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = useCallback(async (): Promise<void> => {
    setDeleteDialogOpen(false);
    removeResource(Number(resourceId), company, offset, limit, selectedMyResourceType, enqueueSnackbar);
    setOpen(false);
  }, [resourceId, enqueueSnackbar, setDeleteDialogOpen, setOpen, limit, offset, removeResource, selectedMyResourceType, company]);

  const createResourceTabValues = useCallback(
    async(): Promise<void> => {
      try{
        updateResource(Number(resourceId), formValues, company, offset, limit, selectedMyResourceType);
        if(Number(value) === 3){
          setValue('1');
          setDisableAttrTab(true);
          setDisableCalTab(true);
          setSelectedResourceId("0");
        }
        enqueueSnackbar(t('myResources.resourceUpdateSuccess'), {
          variant: 'success',
        });
      }catch(err: any){
        let errors = err.response.data;
        if ('product' in err.response.data) {
          errors = {
            ...errors,
            ...err.response.data.product,
          };
        }
        setFormErrors(errors);
        enqueueSnackbar(t('myResources.resourceUpdateError'), {
          variant: 'error',
        });
      }
      
  },[formValues, resourceId, setSelectedResourceId, 
    value, setDisableAttrTab, setDisableCalTab,
    company, enqueueSnackbar, limit, offset, selectedMyResourceType, t, updateResource]);
  
  const handleCreate = useCallback(
    async (e: SyntheticEvent): Promise<void> => {
      e.preventDefault();
      setFormErrors({});
      setLoading(true);
      try {
        switch(Number(value)){
          case 1:
            // saving basic details requires attribute_lines to be empty
            formValues.attribute_lines=[];
            formValues.product.price = `${formatNumber(formValues.product.price)}`;
            formValues.cost_per_km = `${formatNumber(formValues.cost_per_km)}`;
            createResource(formValues, enqueueSnackbar);
            break;
          case 2:
            createResourceTabValues();
            break;
          case 3:
            createResourceTabValues();
            setOpen(false); 
            break;
        }
      } catch (err: any) {
        let errors = err.response.data;
        if ('product' in err.response.data) {
          errors = {
            ...errors,
            ...err.response.data.product,
          };
        }
        setFormErrors(errors);
      }
      setLoading(false);
    },
    [formValues, setOpen, value, createResourceTabValues,
    createResource, enqueueSnackbar],
  );

  const handleUpdate = useCallback(
    async (e: SyntheticEvent): Promise<void> => {
      e.preventDefault();
      setFormErrors({});
      try {
        formValues.product.price = `${formatNumber(formValues.product.price)}`;
        formValues.cost_per_km = `${formatNumber(formValues.cost_per_km)}`;
        updateResource(Number(resourceId), formValues, company, offset, limit, selectedMyResourceType);
        setOpen(false);
        enqueueSnackbar(t('myResources.resourceUpdateSuccess'), {
          variant: 'success',
        });
      } catch (err: any) {
        let errors = err.response.data;
          errors = {
            ...errors,
            ...err.response.data?.product,
            ...err.response.data?.attribute_lines,
            ...err.response.data?.availability_periods
          };
        setFormErrors(errors);
        enqueueSnackbar(t('myResources.resourceUpdateError'), {
          variant: 'error',
        });
      }
    },
    [formValues, enqueueSnackbar, resourceId, setOpen, t, company, limit, offset, selectedMyResourceType, updateResource],
  );

  const isCreate = useCallback(
    (resourceId: string | number): boolean => {
      const resource = resources.find((resource: Resource) => Number(resource.id) === Number(resourceId));
      switch(Number(value)){
        case 1:
          return Number(resourceId)===0;
        case 2:
          return resource?.attribute_lines.length === 0;
        case 3:
          return resource?.attribute_lines.length === 0 || resource?.availability_periods.length === 0;
        default:
          return false;
      }
    },[resources, value]);

  const resourceCreateTabs = useMemo(
    () => [
      {
        label: t('myResources.basicDetail'),
        value: '1',
        component: (
          <BasicDetail
            formValues={formValues}
            formErrors={formErrors}
            renderFormErrorHelperText={renderFormErrorHelperText}
            handleChange={handleChange}
            hasError={hasError}
            resourceTypes={resourceTypes}
            resourceId = {resourceId}
          />
        ),
      },
      {
        label: t('myResources.resourceAttributes'),
        value: '2',
        disabled: disableAttrTab,
        component: (
          <ResourceAttributes
            formValues={formValues}
            formErrors={formErrors}
            renderFormErrorHelperText={renderFormErrorHelperText}
            handleChange={handleChange}
            hasError={hasError}
            setLoading={setLoading}
          />
        ),
      },
      {
        label: t('myResources.calendarAvailability'),
        value: '3',
        disabled: disableCalTab,
        component: (
          <CalendarAvailability
            formValues={formValues}
            setFormValues={setFormValues}
            formErrors={formErrors}
            renderFormErrorHelperText={renderFormErrorHelperText}
            handleChange={handleChange}
            hasError={hasError}
            setLoading={setLoading}
            setIsDefaultValueChanged={setIsDefaultValueChanged}
          />
        ),
      },
    ],
    [t, formErrors, handleChange, renderFormErrorHelperText, formValues, hasError, resourceTypes, disableAttrTab, disableCalTab, resourceId],
  );

  const renderAllTabs = useMemo(
    () => (
      <TabContext value={value}>
        <Box>
          <TabList
            onChange={handleTabChange}
            aria-label="resource creation tabs"
            TabIndicatorProps={{
              style: {
                backgroundColor: colors.darkBlue,
              },
            }}
          >
            {resourceCreateTabs.map((item) => (
              <Tab key={item.label} label={item.label} value={item.value} disabled={item.disabled}/>
            ))}
          </TabList>
        </Box>
        {resourceCreateTabs.map((item) => (
          <TabPanel key={item.label} value={item.value}>
            {item.component}
          </TabPanel>
        ))}
      </TabContext>
    ),
    [resourceCreateTabs, handleTabChange, value],
  );
 
  return (
    <div>
      <Button
        variant="contained"
        size="small"
        disabled={loading || resourceLoading}
        onClick={handleAddResource}
      >
        {<AddBoxOutlinedIcon />} {` ${t('myResources.createButtonLabel')}`}
      </Button>
      <Dialog open={isResourceFormOpen} fullWidth maxWidth="md" scroll="body">
        <form onSubmit={isCreate(resourceId) ? handleCreate : handleUpdate }>
          <DialogTitle>
            <Grid container display="flex" sx={{ pt: 2, pb: 2 }}>
              <Grid item xs={6}>
                {Number(resourceId) > 0 ?
                  (<Typography variant="h4">{` ${t('myResources.editDialogTitle')}`}</Typography>)
                  :
                  (<Typography variant="h4">{` ${t('myResources.createDialogTitle')}`}</Typography>)
                }
              </Grid>
              <Grid item xs />
            </Grid>
          </DialogTitle>
          <Divider variant="fullWidth" />
          <DialogContent sx={{ pb: 4, overflowY: 'visible' }}>{renderAllTabs}</DialogContent>
          <Divider variant="fullWidth" />
          <DialogActions sx={{ p: 2 }}>
            {deleteButtonVisible && (
              <Grid container>
                <Grid item alignContent="flex-start" xs={6}>
                  <DeleteButton variant="contained" size="small" onClick={handleDeleteDialogOpen}>
                    {` ${t('misc.deleteButtonLabel')}`}
                  </DeleteButton>
                </Grid>
              </Grid>
            )}
            <div style={{ flex: '1 0 0' }} />
            <Button variant="contained" size="small" onClick={handleClose}>{` ${t('misc.cancelButtonLabel')}`}</Button>
            <Button variant="contained" size="small" type="submit" disabled={buttonDisabled}>{` ${t(
              'misc.saveButtonLabel',
            )}`}</Button>
          </DialogActions>
        </form>
      </Dialog>
      <DeleteDialog
        isOpen={deleteDialogOpen}
        text={`${t('myResources.deleteConfirmation')}`}
        handleClose={handleDeleteDialogClose}
        handleOk={handleDelete}
        severity="error"
      />
    </div>
  );
};
