import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import Api from '../api';

import { OrderListItem, OrderListResponse } from '../types';
import { endpoints } from '../utils';
import { useAuthContext } from './auth';

const initialOrders: OrderListItem[] = [];
const initialOrdersRequiringAttention: OrderListItem[] = [];
const initialTotalOrdersCount = 0;
const initialOrdersRequiringAttentionCount = 0;
const initialSelectedOrder = 0; 
const initialState = {
  orders: initialOrders,
  setOrders: (): void => {},
  ordersRequiringAttention: initialOrdersRequiringAttention,
  setOrdersRequiringAttention: (): void => { },
  totalOrders: initialTotalOrdersCount,
  setTotalOrders: (): void => {},
  ordersRequiringAttentionCount: initialOrdersRequiringAttentionCount,
  setOrdersRequiringAttentionCount: (): void => { },
  selectedOrder: initialSelectedOrder,
  setSelectedOrder: (): void => {},
};

interface OrderContextType {
  orders: OrderListItem[];
  setOrders: Dispatch<SetStateAction<OrderListItem[]>>;
  totalOrders: number;
  setTotalOrders: Dispatch<SetStateAction<number>>;
  ordersRequiringAttention: OrderListItem[];
  setOrdersRequiringAttention: Dispatch<SetStateAction<OrderListItem[]>>;
  ordersRequiringAttentionCount: number;
  setOrdersRequiringAttentionCount: Dispatch<SetStateAction<number>>;
  selectedOrder: number;
  setSelectedOrder: Dispatch<SetStateAction<number>>;
}

interface Props {
  children: React.ReactNode;
}
const OrderContext = createContext<OrderContextType>(initialState);

export const useOrderContext = (): OrderContextType => useContext(OrderContext);

export const OrderContextProvider: React.FC<Props> = ({ children }) => {
  const { token } = useAuthContext();
  const [orders, setOrders] = useState<OrderListItem[]>(initialOrders);
  const [ordersRequiringAttention, setOrdersRequiringAttention] = useState<OrderListItem[]>(initialOrdersRequiringAttention);
  const [totalOrders, setTotalOrders] = useState<number>(initialTotalOrdersCount);
  const [ordersRequiringAttentionCount, setOrdersRequiringAttentionCount] = useState<number>(initialOrdersRequiringAttentionCount);
  const [selectedOrder, setSelectedOrder] = useState<number>(initialSelectedOrder);

  const fetchOrdersRequiringAttention = async (): Promise<void> => {
    const { data } = await Api.get<OrderListResponse>(endpoints.ordersRequiringAttention);
    const { results, count } = data;
    setOrdersRequiringAttention(results);
    setOrdersRequiringAttentionCount(count);
  };

  useEffect(() => {
    if (token) {
      fetchOrdersRequiringAttention();
    }
  }, [token]);

  return (
    <OrderContext.Provider
      value={{
        setOrders,
        orders,
        setTotalOrders,
        totalOrders,
        ordersRequiringAttention,
        setOrdersRequiringAttention,
        ordersRequiringAttentionCount,
        setOrdersRequiringAttentionCount,
        selectedOrder,
        setSelectedOrder
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};
