import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Api from '../api';

import { useAuthContext } from '../context';
import { Cart, CartItem, Resource } from '../types';
import { endpoints } from '../utils';

const initialCart: Cart = {
  id: '',
  state: '',
  title: '',
  work_description: '',
  work_location: '',
  work_start_date: '',
  work_end_date: '',
  start_time: '',
  accommodation_by: '',
  contact_name: '',
  contact_phone: '',
  street: '',
  zip_code: '',
  municipality: '',
  expires_at: '',
  user: 0,
  orders: []
};
const initialCartItems: CartItem[] = [];

const initialState = {
  cart: initialCart,
  cartItems: initialCartItems,
  setCart: (): void => { },
  setCartItems: (): void => { },
  addToCart: (): Promise<Date> => Promise.resolve(new Date()),
  removeFromCart: (): void => { },
  discardCart: (): Promise<void> => Promise.resolve(),
  fetchCart: (): void => { },
  cartExpiring: false,
  setCartExpiring: (): void => {},
  cartExpired: false,
  setCartExpired: (): void => {},
  closeCartExpirationDialog: (): void => {},
};

interface CartContextType {
  cart: Cart;
  cartItems: CartItem[];
  setCart: Dispatch<SetStateAction<Cart>>;
  setCartItems: Dispatch<SetStateAction<CartItem[]>>;
  addToCart: (resource: Resource, startDate: Date, endDate: Date) => Promise<Date>;
  removeFromCart: (cartItem: CartItem) => void;
  discardCart: () => Promise<void>;
  fetchCart: () => void;
  cartExpiring: boolean;
  setCartExpiring: Dispatch<SetStateAction<boolean>>;
  cartExpired: boolean;
  setCartExpired: Dispatch<SetStateAction<boolean>>;
  closeCartExpirationDialog: ()=> void;
}

interface Props {
  children: React.ReactNode;
}
const CartContext = createContext<CartContextType>(initialState);

export const useCartContext = (): CartContextType => useContext(CartContext);

export const CartContextProvider: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { token } = useAuthContext();

  const [cart, setCart] = useState<Cart>(initialCart);
  const [cartItems, setCartItems] = useState<CartItem[]>(initialCartItems);

  const [cartExpiring, setCartExpiring] =  useState<boolean>(false);
  const [cartExpired, setCartExpired] =  useState<boolean>(false);

  const fetchCart = async (): Promise<void> => {
    try {
      const { data } = await Api.get<Cart>(endpoints.cart);
      if (data.expires_at !== null) {
        data.expires_at = new Date(data.expires_at).toLocaleTimeString([], { timeStyle: 'short' });
      }
      setCart(data);
    } catch (err: any) {
      console.log("failed to fetch cart");
    }
  };

  const addToCart = async (resource: Resource, startDate: Date, endDate: Date): Promise<Date> => {
    let lockedUntil = null;
    const post_data = {
      "product": resource.product.id,
      "quantity": 1,
      "reservation": {
        "begin": startDate,
        "end": endDate 
      }
    }

    try {
      const { data } = await Api.post<CartItem>(endpoints.cartItems, post_data);
      const { id } = data;
      if (Number(id) > 0) {
        setCartItems([...cartItems, data]);
        enqueueSnackbar(t('resourceSearch.addToCartSuccessMessage'), {
          variant: 'success',
        });
      }
    } catch (err: any) {
      let message = t('resourceSearch.addToCartFailedMessage');
      if ('message' in err.response.data) {
        message = err.response.data.message;
      }
      if ('locked_until' in err.response.data){
        lockedUntil = err.response.data.locked_until[0];
      }
      enqueueSnackbar(message, {
        variant: 'error',
      });
    }

    return lockedUntil;
  };

  const removeFromCart = async (cartItem: CartItem): Promise<void> => {
    try {
      await Api.delete<CartItem>(endpoints.cartItem(cartItem.id));
      const updatedCartItems = cartItems.filter((item) => item.id !== cartItem.id);
      setCartItems(updatedCartItems);
      enqueueSnackbar(t('resourceSearch.removeFromCartSuccessMessage'), {
        variant: 'success',
      });
    } catch (err: any) {
      enqueueSnackbar(t('resourceSearch.removeFromCartFailedMessage'), {
        variant: 'error',
      });
    }
  };

  const discardCart = async (): Promise<void> => {
    await Api.delete<Cart>(endpoints.cart);
    fetchCart();
  }
 
  const closeCartExpirationDialog = useCallback((): void => {
    fetchCart();
    if(cartExpired){
      setCartExpired(false);
    }
    if(cartExpiring){
      setCartExpiring(false);
    }
  },[setCartExpired, setCartExpiring, cartExpired, cartExpiring]);
  
  useEffect(() => {
    if (token) {
      fetchCart();
    }
  }, [cartItems, token]);

  return (
    <CartContext.Provider
      value={{
        cart,
        setCart,
        cartItems,
        setCartItems,
        fetchCart,
        addToCart,
        discardCart,
        removeFromCart,
        cartExpired,
        cartExpiring,
        setCartExpired,
        setCartExpiring,
        closeCartExpirationDialog
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
