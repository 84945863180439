import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProgress } from '../hooks';
import * as zod from 'zod';
import { registrationSchema, urls } from '../utils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { createUser } from '../firebase';
import { useParams } from 'react-router-dom';
import { SimpleDialog } from '../components/common';

interface RegistrationFormValues {
  email: string;
  password: string;
}
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
}));

const StyledButtonWrap = styled('div')(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(1),
}));

const intialRegistrationValue = {
  email: '',
  password: '',
};
export const Register: React.FC = () => {
  const { t } = useTranslation();
  const { StyledProgress } = useProgress();
  const { email } = useParams();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [uid, setUid] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [formValues, setFormValues] = useState<RegistrationFormValues>({
    ...intialRegistrationValue,
    email: email ?? '',
  });
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<zod.ZodIssue[]>([]);
  const [waitingTime, setWaitingTime] = useState<number>(30000); // 30 second to read popup message
  useEffect(() => {
    setButtonDisabled(loading || !Object.values(formValues).every((item) => item.length > 0));
  }, [loading, formValues]);

  useEffect(() => {
    // registration confirmation sent redirect user to login after 30 seconds(time to read the popup message)
    setTimeout(() => {
      if (!!uid && uid.length && !dialogOpen) {
        window.location.href = `${urls.login}`;
      }
    }, waitingTime);
  }, [dialogOpen, uid, waitingTime]);

  const hasError = useCallback(
    (key: string): boolean => {
      if (formErrors.length > 0 && !error) {
        return formErrors.some((e) => e.path[0] === key);
      } else {
        return false;
      }
    },
    [formErrors, error],
  );

  const renderFormErrorHelperText = useCallback(
    (key: string, translationID: string): JSX.Element | void => {
      if (hasError(key)) {
        return <FormHelperText>{t(translationID)}</FormHelperText>;
      }
    },
    [hasError, t],
  );

  const handleRegister = useCallback(
    async (e: SyntheticEvent): Promise<void> => {
      e.preventDefault();
      setLoading(true);
      try {
        if (formValues.email !== email) {
          setError(true);
          setLoading(false);
        } else {
          registrationSchema.parse(formValues);
          const {
            user: { uid },
          } = await createUser(formValues.email, formValues.password);
          setUid(uid);
          setDialogOpen(true);
          setLoading(false);
        }
      } catch (err) {
        if (err instanceof zod.ZodError) {
          setFormErrors(err.issues);
          setLoading(false);
        }
      }
    },
    [formValues, email],
  );

  const closDialog = useCallback((): void => {
    setDialogOpen(false);
    setWaitingTime(0);
  }, [setDialogOpen]);

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const value = e.target.value;
      setFormValues({ ...formValues, [e.target.name]: value });
    },
    [setFormValues, formValues],
  );

  const toggleVisiblity = useCallback(() => {
    setPasswordVisible(!passwordVisible);
  }, [passwordVisible, setPasswordVisible]);

  const renderRegistrationError = useMemo(
    () =>
      error && (
        <Grid item xs={12} data-testid="registration-error">
          <Box marginBottom={3}>
            <Alert severity="error">{t('misc.registrationError')}</Alert>
          </Box>
        </Grid>
      ),
    [error, t],
  );

  const renderRegister = useMemo(
    () => (
      <Box sx={{ minHeight: '100vh', mt: 10 }}>
        <Container maxWidth="sm" sx={{ maxWidth: 'sm', padding: 3, alignItems: 'center', justifyContent: 'center' }}>
          <StyledPaper>
            <Box mb={4}>
              <Typography variant="h1">{t('misc.registrationTitle')}</Typography>
            </Box>
            <form data-testid="registration">
              <Grid container spacing={4}>
                {renderRegistrationError}
                <Grid item xs={12}>
                  <FormControl error={hasError('email')}>
                    <>
                      <TextField
                        data-testid="registration-email"
                        label={t('misc.email')}
                        name="email"
                        required
                        disabled={loading}
                        value={formValues.email}
                        placeholder={t('misc.emailPlaceHolder')}
                        error={hasError('email')}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          'data-testid': 'email',
                          'readOnly': true,
                        }}
                        onChange={handleInputChange}
                      />
                      {renderFormErrorHelperText('email', 'formValidation.invalidEmail')}
                    </>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl error={hasError('password')}>
                    <TextField
                      data-testid="registration-password"
                      label={t('misc.password')}
                      name="password"
                      type={passwordVisible ? 'text' : 'password'}
                      required
                      disabled={loading}
                      value={formValues.password}
                      InputLabelProps={{ shrink: true }}
                      error={hasError('password')}
                      inputProps={{
                        'data-testid': 'password',
                      }}
                      onChange={handleInputChange}
                      InputProps={{
                        endAdornment: !!formValues.password && (
                          <InputAdornment position="end" onClick={toggleVisiblity}>
                            {passwordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <>{renderFormErrorHelperText('password', 'formValidation.passwordComplexity')}</>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <StyledButtonWrap>
                    <Button
                      data-testid="registration-button"
                      fullWidth
                      type="submit"
                      disabled={buttonDisabled}
                      onClick={handleRegister}
                      variant="contained"
                    >
                      {t('misc.register')}
                    </Button>
                    {loading && <StyledProgress size={24} />}
                  </StyledButtonWrap>
                </Grid>
              </Grid>
            </form>
          </StyledPaper>
        </Container>
      </Box>
    ),
    [
      StyledProgress,
      buttonDisabled,
      handleInputChange,
      handleRegister,
      hasError,
      loading,
      passwordVisible,
      renderFormErrorHelperText,
      renderRegistrationError,
      t,
      toggleVisiblity,
      formValues,
    ],
  );

  if (dialogOpen) {
    return (
      <SimpleDialog
        text={t('misc.registrationConfirmation')}
        isOpen={dialogOpen}
        handleClose={closDialog}
        severity="info"
      />
    );
  }

  return <>{renderRegister}</>;
};
