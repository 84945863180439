import React, { useCallback, useMemo, useState } from 'react';
import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  Collapse,
  Table,
  Link,
  Stack,
  Chip,
  Grid,
  Typography,
} from '@mui/material';
import LockClockIcon from '@mui/icons-material/LockClock';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CompanyInfo, Resource, FilterFunc, Record, ResourceAttributeLine, ResourceAttributeOption, ResourceSubType } from '../../types';
import { useTable } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { useAuthContext, useCartContext } from '../../context';
import { GeneralFilters } from './ResourceSearch';
import { numberToLocale } from '../../utils';

interface Props {
  resources: Resource[];
  companies: CompanyInfo[];
  filters: GeneralFilters;
  resourceSubTypes: ResourceSubType[];
  resourceAttributeOptions: ResourceAttributeOption[];
  totalCount: number;
}

interface RowProps {
  resource: Resource;
  subTypeName: string;
  companyName: string;
  filters: GeneralFilters;
  resourceAttributeOptions: ResourceAttributeOption[];
}


const Row: React.FC<RowProps> = ({ resource, subTypeName, companyName, filters, resourceAttributeOptions }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [lockedUntil, setLockedUntil] = useState<Date>();
  const { cartItems, addToCart, removeFromCart } = useCartContext()
  const { t } = useTranslation();
  const { startDate, endDate } = filters
  const { profileData } = useAuthContext();
  const userCompanyId = profileData.company;
  const addToCartButtonDisabled = resource.company == userCompanyId
  const handleAddToCart = useCallback(() => {
    addToCart(resource, startDate, endDate).then((value) => {
      setLockedUntil(value);
    });
  }, [resource, startDate, endDate, addToCart])

  const clickOpen = useCallback(() => {
    setOpen(!open);
  }, [open])

  // Helper function to map ResourceAttributeLine options to ResourceAttributeOption
  const getOptions = (selected_options: number[]): string[] => {
    const options = []
    for (let i = 0; i < selected_options.length; i++) {
      const option = resourceAttributeOptions.find((attributeOption) => attributeOption.id == selected_options[i])
      if (typeof option !== 'undefined') {
        options.push(option.value)
      }
    }
    return options
  }

  const normalizeDatetimeHours = (d: Date): number => {
    if (!(d instanceof Date)) {
      d = new Date(d);
    }
    return d.setHours(0, 0, 0, 0);
  }

  const matchingCartItem = cartItems.find(item => {
    const selStartDate = normalizeDatetimeHours(startDate);
    const selEndDate = normalizeDatetimeHours(endDate);
    const res = item.reservation;
    const resStartDate = res ? normalizeDatetimeHours(new Date(res.begin)) : Date();
    const resEndDate = res ? normalizeDatetimeHours(new Date(res.end)) : Date();

    const resourceInCart = item.product === resource.product.id ? true : false
    // The reservation date range overlaps with the currently selected dates
    const datesMatch = (resStartDate <= selEndDate && resEndDate >= selStartDate) ? true : false;

    return resourceInCart && datesMatch;
  });

  // Convert the distance from meters to km, with the min being 1 km.
  let distance_km = Math.round(Number(resource.distance) / 1000);
  distance_km = Math.max(distance_km, 1);

  return (
    <>
      <TableRow sx={{ "& td": { borderBottom: 0 } }}>
        <TableCell>{resource.product.title}</TableCell>
        <TableCell>{subTypeName}</TableCell>
        <TableCell>{companyName}</TableCell>
        <TableCell>{resource.location}</TableCell>
        <TableCell>{distance_km} km</TableCell>
        <TableCell>{numberToLocale(resource.product.price)}</TableCell>
        <TableCell>
          {matchingCartItem ?
            <Chip
              label={ t('resourceSearch.removeFromCartButtonLabel') }
              color="primary"
              onClick={(): void => removeFromCart(matchingCartItem)}
            />
            :
            <Chip
              label={t('resourceSearch.addToCartButtonLabel')}
              color="success"
              onClick={(): void => handleAddToCart()}
              disabled={addToCartButtonDisabled}
            />
          }
          {lockedUntil && (
            <Grid container direction="row" alignItems="center" mt={1}>
              <LockClockIcon color="error" sx={{ fontSize: "1.2em", marginRight: "0.2em" }} />
              <Typography variant="subtitle2">
                {t(`resourceSearch.resourceLockedUntil`)} {new Date(lockedUntil).toLocaleTimeString([], { timeStyle: 'short' })}
              </Typography>
            </Grid>
          )
          }
        </TableCell>
        <TableCell>
          <Link
            variant="h5"
            underline="hover"
            onClick={clickOpen}
          >
            <Grid container direction="row" alignItems="center">
              <Grid item>
                {t('resourceSearch.resourceDetailsButtonLabel')}&nbsp;
              </Grid>
              <Grid item>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Grid>
            </Grid>
          </Link>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} unmountOnExit>
            <Table>
              <TableBody>
                {resource.attribute_lines?.map((item: ResourceAttributeLine) => (
                  <TableRow key={item.attribute.id}>
                    <TableCell style={{ border: 0 }}>
                      <Stack 
                        direction={{xs:'column', sm:'row'}} 
                        spacing={{ xs: 1, sm: 2}}
                        sx={{ flexWrap: 'wrap'}}
                        key={item.attribute.name}
                      >
                        <Typography variant='h6'>{item.attribute.name}</Typography>
                        {/* Filter which attribute type we want to show. Only one of these attribute types
                    should non-null */}
                        <>
                          {item.boolean_value != null && <Chip label={`${item.boolean_value ? t('misc.yes') : t('misc.no') }`}></Chip>}
                          {(item.numeric_value && !item.attribute.is_integer) && <Chip label={`${item.numeric_value} ${item.attribute.unit}`}></Chip>}
                          {(item.numeric_value && item.attribute.is_integer) && <Chip label={`${Math.trunc(item.numeric_value)} ${item.attribute.unit}`}></Chip>}
                          {item.string_value && <Chip label={`${item.string_value}`}></Chip>}
                          {getOptions(item.selected_options).map((option: string) => (
                            <React.Fragment key={option}>
                              <Stack direction="row" spacing={4} key={option}>
                                <Chip label={option} />
                              </Stack>
                            </React.Fragment>
                          ))}

                        </>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export const ResourceSearchList: React.FC<Props> = ({ resources, companies, filters, resourceSubTypes, resourceAttributeOptions, totalCount }) => {
  const { t } = useTranslation();
  const headCells = [
    { id: 'product.title', label: `${t('resourceSearch.resourceList.title')}`, sortable: true },
    { id: 'subtype', label: `${t('resourceSearch.resourceList.subType')}`, sortable: true },
    { id: 'product.company', label: `${t('resourceSearch.resourceList.company')}`, sortable: true },
    { id: 'location', label: `${t('resourceSearch.resourceList.location')}`, sortable: true },
    { id: 'distance', label: `${t('resourceSearch.resourceList.distance')}`, sortable: true },
    { id: 'product.price', label: `${t('resourceSearch.resourceList.price')}`, sortable: true },
    { id: '', label: '', sortable: false },
    { id: 'edit', label: '', sortable: false },
  ];

  const [filterFunction] = useState<FilterFunc>({
    filterRecord: (items: Record[]): Record[] => items,
  });
  const orderByColumn = 'distance'
  const { TableContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } = useTable(
    resources,
    headCells,
    filterFunction,
    totalCount,
    orderByColumn
  );

  const getCompany = useCallback(
    (companyId: string | number): string => {
      const company = companies.find((item) => Number(item.id) === Number(companyId));
      return String(company?.name ?? '');
    },
    [companies],
  );

  const getSubTypeName = useCallback(
    (id: string | number): string => {
      const subType = resourceSubTypes.find((item) => Number(item.id) === Number(id));
      return String(subType?.name ?? '');
    },
    [resourceSubTypes],
  );

  const renderList = useMemo(
    () => (
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item: Resource) => (
              <Row 
                key={item.id}
                resource={item}
                subTypeName={getSubTypeName(item.subtype)}
                companyName={getCompany(item.product.company)}
                filters={filters}
                resourceAttributeOptions={resourceAttributeOptions} 
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TblPagination />
            </TableRow>
          </TableFooter>
        </TableContainer>
      </Grid>
    ),
    [getCompany, getSubTypeName, TblPagination, TableContainer, TblHead, recordsAfterPagingAndSorting, filters, resourceAttributeOptions],
  );

  return <>{renderList}</>;
};
