import { Box } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useLanguage } from '../../hooks';
import { LanguageSelector } from '../../layout/LanguageSelector';

export const LanguageNavbar: React.FC = () => {
  const { toggleLanguage, getSelectedLocale } = useLanguage();
  const [selectedLanguage, setSelectedLanguage] = useState<string>('fi');

  useEffect(() => {
    const userLang = localStorage.getItem('user_language');

    if (userLang?.length) {
      toggleLanguage(userLang);
      setSelectedLanguage(userLang);
    }
  }, [toggleLanguage]);
  
  const setLanguage = useCallback(
    (country: string): void => {
      const lang = getSelectedLocale(country);
      setSelectedLanguage(lang);
      localStorage.setItem('user_language', lang);
      toggleLanguage(lang);
    },
    [toggleLanguage, getSelectedLocale],
  );

  return (
    <Box>
      <LanguageSelector selectedLanguage={selectedLanguage} setLaguage={setLanguage} />
    </Box>
  );
};
