import React, { useEffect, useMemo } from 'react';
import { Main } from '../layout';
import { Resources } from '../components/resources';
import { useAllCompaniesContext, useAllResourceAttributeOptionsContext, useAllResourceAttributesContext, useAllResourceSubTypesContext, useAuthContext, useResourceContext } from '../context';
import { ResourceResponse } from '../types';
import Api from '../api';
import { endpoints } from '../utils';
export const MyResources: React.FC = () => {
  const { profileData } = useAuthContext();
  const userCompany = profileData.company;
  const { setResources } = useResourceContext();
  const { fetchAllResourceAttributeOptions } = useAllResourceAttributeOptionsContext();
  const { fetchAllResourceAttributes } = useAllResourceAttributesContext();
  const { fetchAllResourceSubTypes } = useAllResourceSubTypesContext();
  const { fetchAllCompanies } = useAllCompaniesContext();
  // fetch all companies 
  useEffect(()=>{
    fetchAllCompanies();
  },[fetchAllCompanies]);

  // fetch all resource attribute options
  useEffect(()=>{
    fetchAllResourceAttributeOptions();
  },[fetchAllResourceAttributeOptions]);

  // fetch all resource attributes
  useEffect(()=>{
    fetchAllResourceAttributes();
  },[fetchAllResourceAttributes]);

  // fetch all resourceSubTypes
  useEffect(()=>{
    fetchAllResourceSubTypes();
  },[fetchAllResourceSubTypes]);
  
  useEffect(() => {
    (async (): Promise<void> => {
      if(Number(userCompany) > 0){
      const { data } = await Api.get<ResourceResponse>(endpoints.resources, {
        params: {
          company: userCompany,
        },
      });
      const { results } = data;
      setResources(results);
    }
    })();
  }, [setResources, userCompany]);
  const renderMyResources = useMemo(() => <Resources />, []);
  return <Main>{renderMyResources}</Main>;
};
