import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import Api from '../api';

import { Advert, AdvertResponse } from '../types';
import { endpoints } from '../utils';

const initialAdverts: Advert[] = [];

const initialState = {
  adverts: initialAdverts,
  setAdverts: (): void => {},
  fetchAdverts: (): void => {},
};

interface AdvertsContextType {
  adverts: Advert[];
  setAdverts: Dispatch<SetStateAction<Advert[]>>;
  fetchAdverts: (showOnFrontPage:boolean, showOnSideMenu:boolean) => void;
}

interface Props {
  children: React.ReactNode;
}
const AdvertsContext = createContext<AdvertsContextType>(initialState);

export const useAdvertsContext = (): AdvertsContextType => useContext(AdvertsContext);

export const AdvertsContextProvider: React.FC<Props> = ({ children }) => {
  const [adverts, setAdverts] = useState<Advert[]>(initialAdverts);

  const fetchAdverts = useCallback(
    (showOnFrontPage:boolean, showOnSideMenu:boolean)=> {
      (async (): Promise<void> => {
        const { data } = await Api.get<AdvertResponse>(endpoints.adverts,{
          params: {
            ...(showOnFrontPage && {show_on_front_page:  Number(showOnFrontPage)}),
            ...(showOnSideMenu && {show_in_side_menu: Number(showOnSideMenu)})
          }
        });
        const { results } = data;
        setAdverts(results);
    })();
  } ,[]);

  return (
    <AdvertsContext.Provider
      value={{
        adverts,
        setAdverts,
        fetchAdverts
      }}
    >
      {children}
    </AdvertsContext.Provider>
  );
};
