import { Box, Chip, Divider, Grid, Link, styled, Typography } from '@mui/material';
import { topSideBarNavLinks, bottomSideBarNavLinks, restrictedTopSideNavLinks, initialPagingOffset } from '../utils';
import { NavLink } from 'react-router-dom';
import { LanguageSelector } from './LanguageSelector';
import React, { useState, useMemo, useEffect, useCallback, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../hooks';
import { colors } from '../theme';
import { useAuthContext, usePaginationContext, useOrderContext, useAdvertsContext } from '../context';
import { Slider } from '../components/common';

const StyledUl = styled('ul')(() => ({
  listStyleType: 'none',
  padding: 0,
}));
const StyledLink = styled(Link)(() => ({
  fontSize: 16,
  fontWeight: 600,
}));

const StyledContainer = styled(Box)(({}) => ({
  width: "auto",
  height: "auto",
  margin: "0 auto",
}));

const StyledNavLink = styled(NavLink)(() => ({
  fontSize: 16,
  fontWeight: 600,
}));

const activeLinkStyle = {
  textDecoration: 'underline solid 0.15em',
  color: `${colors.darkBlue} !important`,
};
const noneActiveLinkStyle = {
  textDecoration: 'none',
  color: colors.secondaryTextGray,
};

const UserProfileSection: React.FC = () => {
  const { profileData } = useAuthContext();

  return (
    <Grid container pt={2} pb={2}>
      <Grid item>
        <Typography variant="h6">{`${profileData.first_name} ${profileData.last_name}`}</Typography>
        <Typography variant="subtitle1">{profileData.email}</Typography>
      </Grid>
    </Grid>
  );
};

export const SideNavBar: React.FC = () => {
  const { logout } = useAuthContext();
  const { t } = useTranslation();

  const { toggleLanguage, getSelectedLocale } = useLanguage();
  const [selectedLanguage, setSelectedLanguage] = useState<string>('fi');
  const {profileData} = useAuthContext();
  const { setOffset} = usePaginationContext();
  const { ordersRequiringAttentionCount } = useOrderContext();
  const {adverts} = useAdvertsContext();

  // reset OffSet
  useEffect(() => {
   setOffset(initialPagingOffset);
  }, [setOffset]);

  useEffect(() => {
    const userLang = localStorage.getItem('user_language');

    if (userLang?.length) {
      toggleLanguage(userLang);
      setSelectedLanguage(userLang);
    }
  }, [toggleLanguage]);

  const setLanguage = useCallback(
    (country: string): void => {
      const lang = getSelectedLocale(country);
      setSelectedLanguage(lang);
      localStorage.setItem('user_language', lang);
      toggleLanguage(lang);
    },
    [toggleLanguage, getSelectedLocale],
  );
  
  const shouldSeeCompanySettings = useCallback(
    ():boolean =>{
      const isStaff = profileData.user.is_staff,
            hasCompany = profileData.company,
            isCompanyAdmin = profileData.user.is_company_admin;
    return ((isStaff && Boolean(Number(hasCompany))) || isCompanyAdmin) ?? false;
  },[profileData]);
  
  const visibleTopSideBarNavLinks = shouldSeeCompanySettings() ? topSideBarNavLinks : topSideBarNavLinks.filter((elem) => elem.name !== "companySettings");

  const renderTopNavLinks = useMemo(
    () =>
      visibleTopSideBarNavLinks.map((elem) => (
        <li key={elem.name} style={{ marginBottom: '1.5em' }}>
          <StyledNavLink
            to={elem.linkTo}
            style={({ isActive }): CSSProperties => (isActive ? activeLinkStyle : noneActiveLinkStyle)}
          >
            {
              elem.name ==='orders' ? (
                <Box display="flex" alignItems="center" sx={{ gap: 2 }}>
                  <Typography variant="h5">{t(`sideNavLinks.${elem.name}`)}</Typography>
                  { Number(ordersRequiringAttentionCount) > 0 && <Chip label={ordersRequiringAttentionCount} variant='filled' /> }
                </Box>
              ):(
                <Typography variant="h5">{t(`sideNavLinks.${elem.name}`)}</Typography>
              )
            }
            
          </StyledNavLink>
        </li>
      )),
    [t, visibleTopSideBarNavLinks, ordersRequiringAttentionCount],
  );

  const renderRestrictedTopNavLinks = useMemo(
    () =>
      restrictedTopSideNavLinks.map((elem) => (
        <li key={elem.name} style={{ marginBottom: '1.5em' }}>
          <StyledNavLink
            to={elem.linkTo}
            style={({ isActive }): CSSProperties => (isActive ? activeLinkStyle : noneActiveLinkStyle)}
          >
            <Typography variant="h5">{t(`sideNavLinks.${elem.name}`)}</Typography>
          </StyledNavLink>
        </li>
      )),
    [t],
  );

  const renderBottomNavLinks = useMemo(
    () =>
      bottomSideBarNavLinks.map((elem) => (
        <li key={elem.name} style={{ marginBottom: '1em' }}>
          <StyledNavLink
            to={elem.linkTo}
            style={({ isActive }): CSSProperties => (isActive ? activeLinkStyle : noneActiveLinkStyle)}
          >
            <Typography variant="h6">{t(`sideNavLinks.${elem.name}`)}</Typography>
          </StyledNavLink>
        </li>
      )),
    [t],
  );
  const renderLogoutLink = (
    <li style={{ marginBottom: '1em' }}>
      <StyledLink onClick={logout} style={noneActiveLinkStyle}>
        <Typography variant="h6">{t(`sideNavLinks.logout`)}</Typography>
      </StyledLink>
    </li>
  );

  return (
    <Grid item>
      <Box
        component="img"
        alt="Logo"
        src="/images/3Pool_logo.png"
        width={64}
        sx={{
          mb: 6,
        }}
      />
      <StyledUl>
        {renderTopNavLinks}
        {profileData?.user?.is_staff && renderRestrictedTopNavLinks}
      </StyledUl>
      <Grid item sx={{mt:6, mr:2, mb: 2}}>
        <StyledContainer>
          <Slider slides={adverts}/>
        </StyledContainer>
      </Grid>
      <Divider light variant='middle' />
      <StyledUl>
        {renderBottomNavLinks}
        {renderLogoutLink}
      </StyledUl>
      <Divider light variant='middle' />
      <UserProfileSection></UserProfileSection>
      <LanguageSelector selectedLanguage={selectedLanguage} setLaguage={setLanguage} />
    </Grid>
  );
};
