import { styled, Button, Grid, Typography, Divider, Card, TextField, FormHelperText, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CompanyLocation, Error } from '../../../types';
import { colors } from '../../../theme';
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { endpoints } from '../../../utils';
import Api from '../../../api';
import ReactFlagsSelect from 'react-flags-select';
import { useCompanyContext } from '../../../context';
import { useSnackbar } from 'notistack';
import { useProgress } from '../../../hooks';
import { DeleteDialog } from '../../common/DeleteDialog';
import { DeleteButton } from '../../common/DeleteButton';

const SaveButton = styled(Button)(() => ({
  '&:hover': {
    backgroundColor: colors.darkBlue,
  },
}));

interface Props {
  location: CompanyLocation;
}

interface CompanyLocations {
  count: number;
  next: string | null;
  previous: string | null;
  results: CompanyLocation[];
}

export const CompanyLocationListItem: React.FC<Props> = ({ location }) => {
  const { t } = useTranslation();
  const { StyledProgress } = useProgress();
  const [initialValues, setInitialValues] = useState<CompanyLocation>(location);
  const [formValues, setFormValues] = useState<CompanyLocation>(location);
  const [formErrors, setFormErrors] = useState<Error>({});
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const { companyLocations, setCompanyLocations } = useCompanyContext();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    let changed = false;
    for (const [key, val] of Object.entries(formValues)) {
      if (val !== initialValues[key as keyof CompanyLocation]) {
        changed = true;
      }
    }
    setSaveButtonDisabled(loading || !changed);
  }, [formValues, initialValues, loading]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const newFormValues = { ...formValues };
      newFormValues[e.target.name as keyof CompanyLocation] = e.target.value;
      setFormValues(newFormValues);
    },
    [formValues],
  );

  const handleSubmit = useCallback(
    async (e: SyntheticEvent): Promise<void> => {
      e.preventDefault();
      setFormErrors({});
      setLoading(true);
      try {
        const { data } = await Api.put<CompanyLocation>(endpoints.companyLocation(location.id), formValues);
        setLoading(false);
        setInitialValues(data);
        const { id } = data;
        if (Number(id) > 0) {
          enqueueSnackbar(t('misc.updateSuccess'), {
            variant: 'success',
          });
          const updatedLocations = companyLocations.map(l => l.id !== data.id ? l : data);
          setCompanyLocations(updatedLocations);
        }
      } catch (err: any) {
        setLoading(false);
        setFormErrors(err.response.data);
        enqueueSnackbar(t('misc.updateFailed'), {
          variant: 'error',
        });
      }
    },
    [formValues, location, companyLocations, setCompanyLocations, enqueueSnackbar, t],
  );

  const handleDelete = useCallback(
    async (): Promise<void> => {
      setDeleteDialogOpen(false);
      setLoading(true);
      try{
        await Api.delete<CompanyLocation>(endpoints.companyLocation(location.id));
        const {
          data: { results },
        } = await Api.get<CompanyLocations>(endpoints.companyLocations, {
          params: {
            company: `${location.company}`,
          },
        });
        enqueueSnackbar(t('misc.deleteSuccess'), {
          variant: 'success',
        });
        setCompanyLocations(results);
      }catch(err:any){
        enqueueSnackbar(t('companyLocations.nonRemovableLocation'), {
          variant: 'error',
        });
      }
      setLoading(false);
    },
    [location, enqueueSnackbar, t, setCompanyLocations],
  );

  const handleDeleteDialogClose = useCallback(
    (): void =>{
      setDeleteDialogOpen(false);
    },[]);

  const handleDeleteDialogOpen = useCallback(
    (): void => {
    setDeleteDialogOpen(true);
  },[]);

  const hasError = useCallback(
    (key: string): boolean => {
      return Object.keys(formErrors).some((item) => item === key);
    },
    [formErrors],
  );

  const renderFormErrorHelperText = useMemo(
    () =>
      (key: string): JSX.Element | void => {
        if (hasError(key)) {
          return <FormHelperText>{formErrors[key]}</FormHelperText>;
        }
      },
    [formErrors, hasError],
  );

  return (
    <Grid container display="flex">
      {loading && <StyledProgress size={24} />}
      <Grid item xs={3}>
        <Typography>{`${initialValues.name}`}</Typography>
      </Grid>
      <Grid item xs={9} container justifyContent="flex-start">
        <Card sx={{ overflow: 'visible', mr:10 }}>
          {deleteDialogOpen && 
            <DeleteDialog
              isOpen={deleteDialogOpen}
              text={`${t('companyLocations.deleteConfirmation')}`}
              handleClose={handleDeleteDialogClose}
              handleOk={handleDelete}
              severity="error"
              title={`${t('companyLocations.deleteTitle')}`}
            />
          }
          <form onSubmit={handleSubmit}>
            <Grid container sx={{ p: 2 }} spacing={2}>
              <Grid item xs={12}>
                <FormControl error={hasError('name')}>
                  <>
                    <Typography variant="body1">{t('companyLocations.name')}</Typography>
                    <TextField name="name" size="small" onChange={handleChange} defaultValue={location.name} />
                    {renderFormErrorHelperText('name')}
                  </>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl error={hasError('street')}>
                  <>
                    <Typography variant="body1">{t('companyLocations.street')}</Typography>
                    <TextField name="street" size="small" onChange={handleChange} defaultValue={location.street} />
                    {renderFormErrorHelperText('street')}
                  </>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl error={hasError('zip_code')}>
                  <>
                    <Typography variant="body1">{t('companyLocations.zip_code')}</Typography>
                    <TextField name="zip_code" size="small" onChange={handleChange} defaultValue={location.zip_code} />
                    {renderFormErrorHelperText('zip_code')}
                  </>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl error={hasError('municipality')}>
                  <>
                    <Typography variant="body1">{t('companyLocations.municipality')}</Typography>
                    <TextField
                      name="municipality"
                      size="small"
                      onChange={handleChange}
                      defaultValue={location.municipality}
                    />
                    {renderFormErrorHelperText('municipality')}
                  </>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl error={hasError('country')}>
                  <>
                    <Typography variant="body1">{t('companyLocations.country')}</Typography>
                    <ReactFlagsSelect
                      countries={['FI', 'SE']}
                      customLabels={{ FI: t('countries.FI'), SE: t('countries.SE') }}
                      selected={formValues.country}
                      onSelect={(code): void => setFormValues({ ...formValues, country: code })}
                      placeholder={t('companyLocations.countrySelectPlaceholder')}
                    />
                    {renderFormErrorHelperText('country')}
                  </>
                </FormControl>
              </Grid>
              <Divider />
              <Grid container justifyContent="space-between" mt={3} ml={2}>
                <Grid item alignContent="flex-start">
                  <DeleteButton variant="contained" size="small" onClick={handleDeleteDialogOpen}>
                    {` ${t('misc.deleteButtonLabel')}`}
                  </DeleteButton>
                </Grid>
                <Grid item alignContent="flex-end">
                  <SaveButton
                    variant="contained"
                    size="small"
                    type="submit"
                    disabled={saveButtonDisabled}
                  >
                    {` ${t('companyLocations.saveButtonLabel')}`}
                  </SaveButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};
