import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import { Main } from '../layout';
import { Alert, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, List, ListItem, Paper, styled, TextField, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { endpoints, urls, orderStatuses, numberToLocale, formatNumber, formatToCommaSeparated } from '../utils';
import { colors } from '../theme';
import { useAuthContext, useCompaniesContext, useResourceContext } from '../context';
import { CompanyInfo, CompanyResponse, ExpenseFormValues, ExpenseLine, ExpenseProduct, ExpenseProductResponse, Order, OrderLine, Project, ReplacementResource, ResourceType, ResourceTypesResponse } from '../types';
import Api from '../api';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { useProgress } from '../hooks';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const defaultProject : Project = {
  id: 0,
  state: "",
  title: "",
  work_description: "",
  work_location: "",
  work_start_date: "",
  work_end_date: "",
  start_time: "",
  accommodation_by: "",
  contact_name: "",
  contact_phone: "",
  street: "",
  zip_code: "",
  municipality: "",
  expires_at: "",
  user: 0
}
const defaultOrderLine: OrderLine = {
  id: 0,
  product: 0,
  quantity: 0,
  daily_price: 0,
  total_price: 0,
  product_name: "",
  location: "",
  seller_company: "",
  resource_type: 0,
  reservation: {
    id:0,
    begin: new Date(),
    end: new Date(),
    duration_in_days: 0,
    resource: 0,
    user: 0
  },
  total_price_before_expenses: 0,
  resource_name: ''
}
const defaultExpenseLine: ExpenseLine = {
  id:0,
  description: '',
  quantity: 0,
  unit_price: 0,
  order: 0,
  order_line: 0,
  product: 0,
  total_price: 0
}
const defaultOrder: Order = {
  created_at: "",
  updated_at: "",
  state: "",
  status: "",
  project: defaultProject,
  seller: 0,
  buyer_company_name: '',
  contact_person: 0,
  rejection_reason: "",
  cancellation_date: "",
  confirmation_date: "",
  order_lines: [defaultOrderLine],
  expense_lines: [defaultExpenseLine],
  total_price_before_expenses: 0
}

const defaultFormValues: ExpenseFormValues = {
  description: "",
  quantity: 1,
  unit_price: "",
  order: 0,
  order_line: 0,
  product: 0
}
const StyledNavLink = styled(NavLink)(() => ({
  textDecoration: 'none',
  color: colors.secondaryTextGray,
}));

export const OrderView: React.FC = () => {
  const { t } = useTranslation();
  const { profileData } = useAuthContext();
  const {companies, setCompanies} = useCompaniesContext();
  const [order, setOrder] = useState<Order>(defaultOrder);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const {resourceTypes, setResourceTypes} = useResourceContext();
  const { StyledProgress } = useProgress();
  // orderId from request params
  const {orderId} = useParams();
  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);
  const [markAsInvoiceableDialogOpen, setMarkAsInvoiceableDialogOpen] = React.useState(false);
  const [rejectDialogOpen, setRejectDialogOpen] = React.useState(false);
  const [rejectionReason, setRejectionReason] = React.useState("");
  const [replacementDialogOpen, setReplacementDialogOpen] = useState<boolean>(false);
  const [replacementResources, setReplacementResources] = useState<ReplacementResource[] | []>([]);
  const [selectedReplacement, setSelectedReplacement] = useState<number|string>(0);
  const [selectedOrderLine, setSelectedOrderLine] = useState<OrderLine>(defaultOrderLine);
  const [orderEdit, setOrderEdit] = useState<boolean>(false);
  const [expenseProducts, setExpenseProducts] = useState<ExpenseProduct[] | []>([]);
  const [selectedOrderLineId, setSelectedOrderLineId] = useState<number|string>(0);
  const [formValues, setFormValues] = useState<ExpenseFormValues>(defaultFormValues);
  const [expenseDialogOpen, setExpenseDialogOpen] = useState<boolean>(false);
  const [expenseSaveButtonDisabled, setExpenseSaveButtonDisabled] = useState<boolean>(true);
  const [quantityDialogOpen, setQuantityDialogOpen] = useState<boolean>(false);
  const [quantitySaveButtonDisabled, setQuantitySaveButtonDisabled] = useState(true);
  const [quantity, setQuantity] = useState<number>(1);

  // fetch expense products 
  useEffect(() => {
    (async (): Promise<void> => {
      setLoading(true);
      const { data } = await Api.get<ExpenseProductResponse>(endpoints.expenseProducts);
      const {results} = data;
      setLoading(false);
      setExpenseProducts(results);
    })();
  },[]);

  const handleOpenRejectDialog = (): void => {
    setRejectDialogOpen(true);
  };

  const handleCloseRejectDialog = (): void => {
    setRejectDialogOpen(false);
  };

  const handleOpenCancelDialog = (): void => {
    setCancelDialogOpen(true);
  };

  const handleCloseCancelDialog = (): void => {
    setCancelDialogOpen(false);
  };

  const handleOpenMarkAsInvoiceableDialog = (): void => {
    setMarkAsInvoiceableDialogOpen(true);
  };

  const handleCloseMarkAsInvoiceableDialog = (): void => {
    setMarkAsInvoiceableDialogOpen(false);
  };

  const handleRejectionReasonChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setRejectionReason(e.target.value);
    },
    [],
  );

  useEffect(() => {
    (async (): Promise<void> => {
      setLoading(true);
      const { data } = await Api.get<Order>(endpoints.order(Number(orderId)));
      setOrder(data)
      setLoading(false);
    })();
  }, [setOrder, orderId]);

  useEffect(() => {
    (async (): Promise<void> => {
    if(!companies.length){
      setLoading(true);
      const { data } = await Api.get<CompanyResponse>(endpoints.companies);
      const { results } = data;
      setCompanies(results);
      setLoading(false);
    } 
    })();
  }, [setCompanies, companies.length]);

  useEffect(() => {
    (async (): Promise<void> => {
      if(!resourceTypes.length){
        setLoading(true);
        const { data } = await Api.get<ResourceTypesResponse>(endpoints.resourceTypes);
        const { results } = data;
        setResourceTypes(results);
        setLoading(false);
      }
    })();
  }, [setResourceTypes, resourceTypes.length]);
  
  const handleCancelOrder = useCallback(
    async():Promise<void>=>{
      const values = {
        state: "cancelled"
      }
      try{
        setLoading(true);
        const {data} = await Api.patch<Order>(endpoints.order(Number(orderId)), values);
        setOrder(data);
        setLoading(false);
        setCancelDialogOpen(false);
        enqueueSnackbar(t('orders.successCancelOrder'), {
          variant: 'success',
        });      
      }catch(err: any){
        enqueueSnackbar(t('orders.errorCancelOrder'), {
          variant: 'error',
        });
      }
  },[orderId, setOrder, t, enqueueSnackbar]);

  const handleRejectOrder = useCallback(
    async (): Promise<void> => {
      const values = {
        rejection_reason: rejectionReason
      }
      try {
        const { data } = await Api.post<Order>(endpoints.orderReject(Number(orderId)), values);
        setOrder(data);
        setRejectDialogOpen(false);
        enqueueSnackbar(t('orders.successRejectOrder'), {
          variant: 'success',
        });
      } catch (err: any) {
        enqueueSnackbar(t('orders.errorRejectOrder'), {
          variant: 'error',
        });
      }
    }, [orderId, t, enqueueSnackbar, rejectionReason]);

  const handleOrderEdit = useCallback(
    ()=>{
      setOrderEdit(true);
  },[]);

  const cancelOrderEdit = useCallback(
    ()=>{
      setOrderEdit(false);
    },[]);
  
  const handleSaveOrderEdit = useCallback(
    async():Promise<void>=>{
      try{
        setLoading(true);
        const expenseLines = order.expense_lines;
        const formatedExpenseLines = expenseLines.map((item:ExpenseLine) => {
          item.unit_price = formatNumber(item.unit_price)
          return item
        }
        )
        const {data} = await Api.patch<Order>(endpoints.order(Number(orderId)), {expense_lines: formatedExpenseLines});
        setOrder(data);
        setLoading(false);
        setOrderEdit(false);
        enqueueSnackbar(t('orders.successSaveOrder'), {
          variant: 'success',
        }); 
      }catch(err: any){
        enqueueSnackbar(t('orders.errorSaveOrder'), {
          variant: 'error',
        });
      }
    },[enqueueSnackbar, order.expense_lines, orderId, t]);

  const handleConfirmOrder = useCallback(
    async():Promise<void>=>{
      const values = {
        state: "confirmed"
      }
      try{
        const {data} = await Api.patch<Order>(endpoints.order(Number(orderId)), values);
        setLoading(true);
        setOrder(data);
        setLoading(false);
        enqueueSnackbar(t('orders.sucessConfirmOrder'), {
          variant: 'success',
        });   
      }catch(err: any){
        enqueueSnackbar(t('orders.errorConfirmOrder'), {
          variant: 'error',
        });
      }
  },[orderId, setOrder, t, enqueueSnackbar]);

  const handleMarkAsInvoiceable = useCallback(
    async (): Promise<void> => {
      const values = {
        state: "invoiceable"
      }
      try {
        const { data } = await Api.patch<Order>(endpoints.order(Number(orderId)), values);
        setLoading(true);
        setOrder(data);
        setLoading(false);
        setMarkAsInvoiceableDialogOpen(false);
        enqueueSnackbar(t('orders.successMarkingAsInvoiceable'), {
          variant: 'success',
        });
      } catch (err: any) {
        enqueueSnackbar(t('orders.errorMarkingAsInvoiceable'), {
          variant: 'error',
        });
      }
    }, [orderId, setOrder, t, enqueueSnackbar]);

  const handleMarkAsInvoiced = useCallback(
    async (): Promise<void> => {
      const values = {
        state: "invoiced"
      }
      try {
        const { data } = await Api.patch<Order>(endpoints.order(Number(orderId)), values);
        setLoading(true);
        setOrder(data);
        setLoading(false);
        enqueueSnackbar(t('orders.successMarkingAsInvoiced'), {
          variant: 'success'
        });
      } catch (err: any) {
        enqueueSnackbar(t('orders.errorMarkingAsInvoiced'), {
          variant: 'error',
        });
      }
    }, [orderId, setOrder, t, enqueueSnackbar]);

  const handleMarkAsComplete = useCallback(
    async (): Promise<void> => {
      const values = {
        state: "complete"
      }
      try {
        const { data } = await Api.patch<Order>(endpoints.order(Number(orderId)), values);
        setLoading(true);
        setOrder(data);
        setLoading(false);
        enqueueSnackbar(t('orders.successMarkingAsCompleted'), {
          variant: 'success'
        });
      } catch (err: any) {
        enqueueSnackbar(t('orders.errorMarkingAsCompleted'), {
          variant: 'error',
        });
      }
    }, [orderId, setOrder, t, enqueueSnackbar]);

  const renderStates = useMemo(
    ()=>(
      orderStatuses.map((status: string, index: number )=> (
        `${order.status}` === status ?
        <Chip label={t(`orders.statuses.${status}`)} color="info"  key={`${index}`}/>
        :
        <Chip label={t(`orders.statuses.${status}`)} key={`${index}`}/>
    ))
  ),[t, order.status]);

  const getCompanyName = useCallback(
    (companyId: string | number): string => {
      const company = companies.find((item:CompanyInfo) => Number(item.id) === Number(companyId));
      return String(company?.name) ?? '';
    },  
    [companies],
  );

  const handleReplace = useCallback(
    async(orderLine: OrderLine): Promise<void> =>{
      if(!!orderLine.id){
        const {data} = await Api.get<ReplacementResource[]>(endpoints.replacementProducts(orderLine.id));
        setSelectedOrderLine(orderLine);
        setReplacementResources(data);
        setReplacementDialogOpen(true);
      }   
    },[]);

  const handleCloseReplacementDialog = useCallback(
    ()=>{
      setReplacementDialogOpen(false);
  },[]);
  
  const handleReplacementSave = useCallback(
    async (): Promise<void> =>{
      try{
        const replaceResource = replacementResources.find((item: ReplacementResource) => Number(item.id) === Number(selectedReplacement));
        if(Number(selectedOrderLine.id) > 0){
          const values = {
            product: replaceResource?.id,
            reservation: {
              begin: selectedOrderLine?.reservation?.begin,
              end: selectedOrderLine?.reservation?.end,
              resource: replaceResource?.resource
            },
          }
          const {data} = await Api.patch(endpoints.orderLine(Number(selectedOrderLine.id)),values);
          if(!!data.id){
            const response = await Api.get(endpoints.order(Number(order.id)));
            setOrder(response.data);
          }
          setReplacementDialogOpen(false);
          enqueueSnackbar(t('orders.resources.resourceReplacementSuccess'), {
            variant: 'success',
          }); 
        }
      }catch(err: any){
        enqueueSnackbar(t('orders.resources.resourceReplacementError'), {
          variant: 'error',
        });
      }
  },[replacementResources, selectedOrderLine, selectedReplacement, order, t, enqueueSnackbar]);

  const handleReplacementChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void =>{
      if(!!e.target.value){
        setSelectedReplacement(e.target.value);
      }
    },[]);
  
  const handleAddExpenseLine = useCallback(
    (orderLineId: string | number)=>{
      setExpenseDialogOpen(true);
      setSelectedOrderLineId(orderLineId);
  },[]);

  const handleExpenseProductChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void =>{
      const newFormValues = JSON.parse(JSON.stringify(formValues));
      (newFormValues as any)[e.target.name as keyof ExpenseFormValues] = e.target.value;
      if(
        formValues.description !== defaultFormValues.description &&
        formValues.product !== defaultFormValues.product &&
        formValues.unit_price !== defaultFormValues.unit_price 
      ){
        setExpenseSaveButtonDisabled(false);
      }
      setFormValues(newFormValues);
  },[formValues])

  const handleCloseExpenseDialog = useCallback(
    ()=>{
      setExpenseDialogOpen(false);
      setFormValues(defaultFormValues);
      setExpenseSaveButtonDisabled(true);
    },[]);

  const handleExpenseSave = useCallback(
   async():Promise<void> => {
    const expenseLines = [...order.expense_lines, {
      description: formValues.description,
      quantity: formValues.quantity,
      unit_price: formatNumber(formValues.unit_price),
      order: Number(orderId),
      ...(!!selectedOrderLineId && {order_line: Number(selectedOrderLineId)}),
      product: formValues.product
    }]

    try{
      setLoading(true);
      const {data} = await Api.patch<Order>(endpoints.order(Number(orderId)), {expense_lines: expenseLines});
      setOrder(data);
      setLoading(false);
      setExpenseDialogOpen(false);
      setFormValues(defaultFormValues);
      enqueueSnackbar(t('orders.expense.successSaveExpense'), {
        variant: 'success',
      });      
    }catch(err: any){
      setFormValues(defaultFormValues);
      enqueueSnackbar(t('orders.expense.errorSaveExpense'), {
        variant: 'error',
      });
    }
   },[enqueueSnackbar,
    formValues.description,
    formValues.product,
    formValues.quantity,
    formValues.unit_price,
    order.expense_lines,
    orderId,
    selectedOrderLineId,
    t
  ]);

  const handleExpenseLineChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, expenseLine: ExpenseLine): void =>{
      const newOrder = JSON.parse(JSON.stringify(order));
      let newExpenseLines = JSON.parse(JSON.stringify(order.expense_lines));
      const targetExpenseLine = newExpenseLines.find((item: ExpenseLine) => Number(item.id) === Number(expenseLine.id));
      (targetExpenseLine as any)[e.target.name as keyof ExpenseFormValues] = e.target.value;
      if(e.target.name ==="unit_price"){
        targetExpenseLine.total_price = formatNumber(e.target.value) * Number(targetExpenseLine.quantity);
      }
      if(e.target.name ==="quantity"){
        targetExpenseLine.total_price = Number(e.target.value) * formatNumber(targetExpenseLine.unit_price);
      }
      newExpenseLines = newExpenseLines.map((item: ExpenseLine)=> Number(item.id) === Number(expenseLine.id) ? targetExpenseLine : item );
      newOrder.expense_lines = newExpenseLines;
      setOrder(newOrder);
    },[order]);

  const handleDeleteExpenseLine = useCallback(
    (id: string | number): void =>{
      const newOrder = JSON.parse(JSON.stringify(order));
      const newExpenseLines = JSON.parse(JSON.stringify(order.expense_lines));
      const expenseLinesAfterRemove = newExpenseLines.filter((item: ExpenseLine) => Number(item.id) !== Number(id));
      newOrder.expense_lines = expenseLinesAfterRemove;
      setOrder(newOrder);
    },[order]);
  
  const getExpenses = useCallback(
    (orderLineId: string | number) => {
      if(Number(orderLineId) === 0){
        return order.expense_lines.filter((expenseLine: ExpenseLine) => Number(expenseLine.order_line) === 0);
        
      }else{
        return order.expense_lines.filter((expenseLine: ExpenseLine) => (Number(expenseLine.order_line) === Number(orderLineId)));
      }
    },[order.expense_lines])
  
  const hasExpenseLines = useCallback(
    (orderLineId: string |number)=>{
      if(Number(orderLineId)===0){
        return !!order.expense_lines.filter((expenseLine: ExpenseLine) => Number(expenseLine.order_line) === 0).length;
      }else{
        return !!order.expense_lines.filter((expenseLine: ExpenseLine) => (Number(expenseLine.order_line) === Number(orderLineId))).length;
      }
    },[order.expense_lines]);
  
  const handleEditOrderLineQuantity = useCallback(
    (orderLineId: number | string)=>{
      const orderLine = order.order_lines.find((item: OrderLine) => Number(item.id) === Number(orderLineId));
      setSelectedOrderLineId(orderLineId);
      setQuantityDialogOpen(true);
      if(!!orderLine){
        setQuantity(orderLine.quantity);
      }  
    },[order.order_lines]);

  const handleQuantityChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>{
      if(quantity !== Number(e.target.value)){
        setQuantity(Number(e.target.value));
        setQuantitySaveButtonDisabled(false);
      }   
    },[quantity]);

  const handleCloseQuantityDialog = useCallback(
    ()=>{
      setQuantityDialogOpen(false);
    },[]);

  const handleQuantitySave = useCallback(
    async ()=>{
      try{
        setLoading(true);
        const newOrder = JSON.parse(JSON.stringify(order));
        const {data} = await Api.patch(endpoints.orderLine(Number(selectedOrderLineId)),{quantity});
        newOrder.order_lines = newOrder.order_lines.map((item:OrderLine)=> Number(item.id) === Number(data.id) ? data : item);
        setOrder(newOrder);
        setLoading(false);
        setQuantityDialogOpen(false);
        enqueueSnackbar(t('orders.orderLine.successSaveQuantity'), {
          variant: 'success',
        }); 
      }catch(err:any){
        enqueueSnackbar(t('orders.orderLine.errorSaveQuantity'), {
          variant: 'error',
        }); 
      }
    },[enqueueSnackbar, t, quantity, selectedOrderLineId, order]);

  const renderExpenseLines = useCallback(
    (orderLineId: string |number): JSX.Element => {
      if(hasExpenseLines(orderLineId)){
      return (
      <>
      <Grid container mt={4} mb={2} sx={{backgroundColor: colors.lightgray}}>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{t('orders.expense.expense')}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1">{t('orders.expense.description')}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1">{t('orders.expense.unitPrice')}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1">{t('orders.expense.quantity')}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1">{t('orders.expense.totalPrice')}</Typography>
        </Grid>
      </Grid>
      {
        getExpenses(orderLineId).map((item: ExpenseLine) => (
          <Grid container mt={2} mb={2} key={item.id}>
            <Grid item xs={3}>
              <TextField
                  data-testid="product-wrapper"
                  name="product"
                  select
                  size="small"
                  variant="standard"
                  onChange={(e : ChangeEvent<HTMLInputElement>): void=> handleExpenseLineChange(e, item)}
                  inputProps={{
                    'data-testid': 'product',
                  }}
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{
                    native: true,
                    displayEmpty: true,
                  }}
                  defaultValue={item.product}
                >
                  <option value={0}>-- {t('orders.expense.selectPlaceholder')} --</option>
                  {expenseProducts.map((option) => (
                    <option key={option.id} value={option.id}>
                      {`${option.title}`}
                    </option>
                  ))}
                </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                  onChange={(e : ChangeEvent<HTMLInputElement>): void=> handleExpenseLineChange(e, item)}
                  name="description"
                  defaultValue={item.description}
                  size="small"
                  multiline
                  variant="standard"
                />
            </Grid>
            <Grid item xs={2}>
              <TextField
                  onChange={(e : ChangeEvent<HTMLInputElement>): void=> handleExpenseLineChange(e, item)}
                  name="unit_price"
                  defaultValue={formatToCommaSeparated(item.unit_price)}
                  size="small"
                  multiline
                  variant="standard"
                />
            </Grid>
            <Grid item xs={2}>
              <TextField
                  onChange={(e : ChangeEvent<HTMLInputElement>): void=> handleExpenseLineChange(e, item)}
                  name="quantity"
                  defaultValue={item.quantity}
                  size="small"
                  multiline
                  variant="standard"
                />
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6">{`${item.total_price} €`}</Typography>
            </Grid>
            <Grid item xs={1}>
              <DeleteIcon onClick={(): void => handleDeleteExpenseLine(item.id)} sx={{ color: colors.darkBlue }} />
            </Grid>
          </Grid>
        ))
      }
      </>
      )
    }else{
      return (<></>);
    }
    },[t, expenseProducts, handleExpenseLineChange, handleDeleteExpenseLine, getExpenses, hasExpenseLines]);
  
  const renderExpenseLineForm = useMemo(
    ()=>(
      <Dialog 
        open={expenseDialogOpen}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          {t('orders.expense.addExpenseLine')}
        </DialogTitle>
        <DialogContent dividers>
          <Box display="flex" flexDirection={'row'} m={2}>
            <Box sx={{width:'30%'}} mr={2}>
              <Typography variant="subtitle1" >{t('orders.expense.expense')}</Typography>
              <TextField
                data-testid="product-wrapper"
                name="product"
                select
                size="small"
                variant="standard"
                onChange={handleExpenseProductChange}
                inputProps={{
                  'data-testid': 'product',
                }}
                InputLabelProps={{ shrink: true }}
                SelectProps={{
                  native: true,
                  displayEmpty: true,
                }}
                defaultValue={formValues.product}
              >
                <option value={0}>-- {t('orders.expense.selectPlaceholder')} --</option>
                {expenseProducts.map((option) => (
                  <option key={option.id} value={option.id}>
                    {`${option.title}`}
                  </option>
                ))}
              </TextField>
            </Box>
            <Box sx={{width:'25%'}} mr={2}>
              <Typography variant="subtitle1" >{t('orders.expense.description')}</Typography>
              <TextField
                onChange={handleExpenseProductChange}
                name="description"
                value={formValues.description}
                size="small"
                multiline
                variant="standard"
              />
            </Box>
            <Box sx={{width:'15%'}} mr={2}>
              <Typography variant="subtitle1" >{t('orders.expense.unitPrice')}</Typography>
              <TextField
                onChange={handleExpenseProductChange}
                name="unit_price"
                value={formValues.unit_price}
                size="small"
                variant="standard"
              />
            </Box>
            <Box sx={{width:'10%'}} mr={3}>
              <Typography variant="subtitle1" >{t('orders.expense.quantity')}</Typography>
              <TextField
                onChange={handleExpenseProductChange}
                name="quantity"
                value={formValues.quantity}
                size="small"
                type={'number'}
                variant="standard"
                InputProps={{ inputProps: { min: 1} }}
              />
            </Box>
            <Box sx={{width:'20%'}}>
              <Typography variant="subtitle1" >{t('orders.resources.totalPrice')}</Typography>
              <Typography variant="subtitle1" >{formatNumber(formValues.unit_price) > 0 && `${numberToLocale(formatNumber(formValues.unit_price) * Number(formValues.quantity))}`}</Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button variant="contained" size="small" onClick={handleCloseExpenseDialog}>
            {`${t('misc.cancelButtonLabel')}`}
          </Button>
          <Button variant="contained" size="small" onClick={handleExpenseSave} disabled={expenseSaveButtonDisabled}>
            {`${t('misc.saveButtonLabel')}`}
          </Button>
        </DialogActions>
      </Dialog>
    ),[
      expenseProducts,
      t, 
      handleExpenseProductChange, 
      formValues, 
      expenseDialogOpen,
      handleCloseExpenseDialog,
      handleExpenseSave,
      expenseSaveButtonDisabled
    ]);
  
    const renderQuantityEdit = useMemo(
      ()=>(
        <Dialog 
          open={quantityDialogOpen}
        >
          <DialogTitle id="alert-dialog-title">
            {t('orders.orderLine.changeDaysDialogTitle')}
          </DialogTitle>
          <DialogContent dividers>
          <Grid container>
            <Grid item xs={9}>
              <Typography variant="subtitle1" >{t('orders.expense.quantity')}</Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                onChange={handleQuantityChange}
                name="quantity"
                defaultValue={quantity}
                size="small"
                type={'number'}
                variant="standard"
                InputProps={{ inputProps: { min: 1} }}
              />
            </Grid>
          </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 2 }}>
            <Button variant="contained" size="small" onClick={handleCloseQuantityDialog}>
              {`${t('misc.cancelButtonLabel')}`}
            </Button>
            <Button variant="contained" size="small" onClick={handleQuantitySave} disabled={quantitySaveButtonDisabled}>
              {`${t('misc.saveButtonLabel')}`}
            </Button>
          </DialogActions>
        </Dialog>
      ),[
        t, 
        handleQuantitySave,
        handleQuantityChange,
        quantitySaveButtonDisabled,
        handleCloseQuantityDialog,
        quantity,
        quantityDialogOpen
      ]);

  const getGrandSum = useCallback(
    ()=>{
      const orderLinesSum = order.order_lines
        .map(item => item.total_price_before_expenses)
        .reduce((prev, next) => prev + next, 0);
      const totalExpenseLinesSum = order.expense_lines
        .map(item => item.total_price)
        .reduce((prev, next) => prev + next, 0);
      return orderLinesSum + totalExpenseLinesSum;
    },[order.order_lines, order.expense_lines]);

  const getTotalByResourceType = useCallback(
    (resourceTypeId: string | number) => {
      const orderLines = order.order_lines.filter(item => item.resource_type == resourceTypeId);
      const orderLineIds = orderLines.map(item => item.id);
      const orderLinesSum = orderLines
        .map(item => item.total_price_before_expenses)
        .reduce((prev, next) => prev + next, 0);
      const totalExpenseLinesSum = order.expense_lines
        .filter(item => orderLineIds.includes(item.order_line))
        .map(item => item.total_price)
        .reduce((prev, next) => prev + next, 0);
      return orderLinesSum + totalExpenseLinesSum;
    }, [order.order_lines, order.expense_lines]);

  const renderGeneralExpenses = useMemo(
    ()=>(
      <Grid container pt={4}>
        <Grid item xs={12} sx={{backgroundColor: colors.lightgray}}>
          <Typography variant="body1" p={1}>{t('orders.generalExpensesTitle')}</Typography>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={10}>
          {
            renderExpenseLines(0)
          }
          <Grid container mt={1}>
            <Button
              variant="text"
              onClick={(): void => handleAddExpenseLine(0)}
              size="small"
              sx={{
                textDecoration: "underline"
              }}
            >
              + {`${t('orders.expense.addExpenseLine')}`}
            </Button>
          </Grid>
        </Grid>
     </Grid>
    ),[t, handleAddExpenseLine, renderExpenseLines])

  const renderOrderTotalPrice = useMemo(
    () => (
      <Grid container pt={4} justifyContent="flex-end">
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{t('orders.grandSum')}:&nbsp;&nbsp;
          {`${numberToLocale(getGrandSum())}`}
        </Typography>
      </Grid>
    ), [t, getGrandSum])

  const renderExpenseLinesReadOnly = useCallback(
    (orderLineId: string | number): JSX.Element => {
      if (hasExpenseLines(orderLineId)) {
        return (
          <>
            <Grid container mt={4} mb={2} sx={{ backgroundColor: colors.lightgray }}>
              <Grid item xs={3}>
                <Typography variant="subtitle1">{t('orders.expense.expense')}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle1">{t('orders.expense.description')}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle1">{t('orders.expense.unitPrice')}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle1">{t('orders.expense.quantity')}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle1">{t('orders.expense.totalPrice')}</Typography>
              </Grid>
            </Grid>
            {
              getExpenses(orderLineId).map((item: ExpenseLine) => (
                <Grid container mt={2} mb={2} key={item.id}>
                  <Grid item xs={3}>
                    <Typography variant='subtitle1'>
                      {expenseProducts
                        .filter((product) => (product.id == item.product))
                        .map((product) => (product.title))
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant='subtitle1'>{item.description}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant='subtitle1'>{formatToCommaSeparated(item.unit_price)}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant='subtitle1'>{item.quantity}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant='subtitle1'>{`${item.total_price} €`}</Typography>
                  </Grid>
                </Grid>
              ))
            }
          </>
        )
      } else {
        return (<></>);
      }
    }, [t, expenseProducts, getExpenses, hasExpenseLines]);

  const renderOrderEdit = useMemo(
    ()=>(
      <>
        { renderExpenseLineForm }
        { renderQuantityEdit }
        <Box>
        <Typography variant="body2">{t('orders.resources.title')}</Typography>
        <Alert severity='info' sx={{mt:2}}>{t("orders.expense.saveNote")}</Alert>
        </Box>
          {resourceTypes.map((res_type: ResourceType) => (
            (order.order_lines.some(item => item.resource_type == res_type.id)) && (
              <Grid container pt={4} key={res_type.id}>
                <Grid item xs={12} sx={{ backgroundColor: colors.lightgray }}>
                  <Typography variant="body2" p={1}>{res_type.name}</Typography>
                </Grid>
                {order.order_lines.filter((orderLine: OrderLine) => (orderLine.resource_type == res_type.id)).map(item => (
                  <>
                    <Grid container key={item.id} mt={4} pb={2} ml={1} sx={{ borderBottom: "1px solid lightgrey" }}>
                      <Grid item xs={2}>
                        <Typography variant="h6">{item.product_name}</Typography>
                        {Number(order.seller) === Number(profileData.company) && <Typography variant='subtitle1'>{item.resource_name}</Typography>}
                      </Grid>
                      <Grid item xs={10}>
                        <Grid container>
                          <Grid item xs={3}>
                            <Typography variant="subtitle1">{t('orders.resources.dates')}</Typography>
                            <Typography variant="subtitle1">{
                              item?.reservation?.begin && format(new Date(item?.reservation?.begin), 'dd.MM.yyyy')}
                              &nbsp;&ndash;&nbsp;
                              {item?.reservation?.end && format(new Date(item?.reservation?.end), 'dd.MM.yyyy')}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography variant="subtitle1">{t('orders.expense.description')}</Typography>
                            <Typography variant="subtitle1">{t('orders.resources.dailyPrice')}</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography variant="subtitle1">{t('orders.expense.unitPrice')}</Typography>
                            <Typography variant="subtitle1">{numberToLocale(item.daily_price)}</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography variant="subtitle1" sx={{ width: '15%' }}>{t('orders.expense.quantity')}</Typography>
                            <Typography variant="subtitle1" sx={{ width: '15%' }}>{item.quantity}</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography variant="subtitle1" sx={{ width: '20%' }}>{t('orders.resources.basePriceTotal')}</Typography>
                            <Typography variant="subtitle1" sx={{ width: '20%' }}>{`${numberToLocale(item.total_price_before_expenses)}`}</Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <EditIcon onClick={(): void => handleEditOrderLineQuantity(item.id)} sx={{ color: colors.darkBlue, with: "20%" }} />
                          </Grid>
                        </Grid>
                        {
                          !!order.expense_lines.length && renderExpenseLines(item.id)
                        }
                        <Grid container mt={1}>
                          <Button
                            variant="text"
                            onClick={(): void => handleAddExpenseLine(item.id)}
                            size="small"
                            sx={{
                              textDecoration: "underline"
                            }}
                          >
                            + {`${t('orders.expense.addExpenseLine')}`}
                          </Button>
                        </Grid>

                        {/* Total price of the order line */}
                        <Grid container>
                          <Grid item xs={9}></Grid>
                          <Grid item>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{t('orders.resources.totalPrice')}:
                              &nbsp;&nbsp;
                              {
                                numberToLocale(getExpenses(item.id)
                                  .map((item) => item.total_price)
                                  .reduce((prev, next) => prev + next, 0) + item.total_price_before_expenses)
                              }</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )
                )
                }
                {/* Total price of the resource type */}
                <Grid container pt={4} justifyContent="flex-end">
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{res_type.name} {t('orders.resources.totalPrice')}:&nbsp;&nbsp;
                    {numberToLocale(getTotalByResourceType(res_type.id))}
                  </Typography>
                </Grid>
              </Grid>
            )))
          }
          {renderGeneralExpenses}
          {renderOrderTotalPrice}
      </>
    ),[
    t, 
    order.order_lines,
    order.seller,
    profileData.company,
    resourceTypes, 
    handleAddExpenseLine, 
    renderExpenseLines, 
    renderExpenseLineForm,
    order.expense_lines,
    getTotalByResourceType,
    renderGeneralExpenses,
    renderOrderTotalPrice,
    handleEditOrderLineQuantity,
    renderQuantityEdit,
    getExpenses
  ]);

  // Order editing / action permission logic

  const userIsStaff = profileData.user.is_staff
  const canConfirm = (
    order.state === "waiting" &&
    (Number(order.seller) === Number(profileData.company) || userIsStaff)
  )
  const canReject = canConfirm;
  const canCancel = (
    order.state !== "cancelled" &&
    (order.work_start_date && new Date(order.work_start_date) > new Date()) &&
    (order.buyer_company_name === getCompanyName(profileData.company) || userIsStaff)
  )
  const canMarkAsInvoiceable = (
    order.status === "pending_seller_action" &&
    (Number(order.seller) === Number(profileData.company) || userIsStaff)
  )
  const canMarkAsInvoiced = userIsStaff && order.status === "invoiceable";
  const canMarkAsComplete = userIsStaff && order.status === "invoiced";
  const canEditOrder = (
    (order.status === "pending_seller_action" && Number(order.seller) === Number(profileData.company))) ||
    (userIsStaff && ["pending_seller_action", "invoiceable"].includes(order.status)
  )

  const renderOrderView = useMemo(
    () => (
      <>
        <Grid container direction="row" justifyContent="space-between" pr={1}>
          <Grid item>
            <StyledNavLink to={urls.orders}>
              <Grid container direction="row" alignItems="center">
                <ArrowBackIosIcon sx={{ fontSize: "0.8em", marginRight: "0.5em" }} ></ArrowBackIosIcon>
                <Typography variant="h6">
                  {t('orders.returnLinkLabel')}
                </Typography>
              </Grid>
            </StyledNavLink>
          </Grid>
        </Grid>
        <Grid pb={0.5} pr={1}>
          <Paper> 
            {loading && <StyledProgress size={24} />}
            <Box p={1} pt={3} pb={3} m={2}>
              <Grid container direction="row" justifyContent="space-between" pb={2} mb={4}>
                <Grid item>
                  <Typography variant="h5">{order.project.title}</Typography>
                </Grid>
                <Grid item>
                  {
                    orderEdit ? 
                    (
                      <>
                        <Button variant="text" form="order-form" onClick={cancelOrderEdit} size="small">
                          {t('misc.cancelButtonLabel')}
                        </Button>
                        <Button variant="contained" form="order-form" onClick={handleSaveOrderEdit} size="small">
                          {t('misc.saveButtonLabel')}
                        </Button>
                      </>
                    )
                    :
                    (
                      <>
                        {(canConfirm) &&
                        <Chip label={t('orders.confirmOrderLabel')} color="success" onClick={handleConfirmOrder} sx={{ marginRight: 1 }}/> 
                        }
                        {(canReject) &&
                          <Chip label={t('orders.rejectOrderLabel')} color="error" onClick={handleOpenRejectDialog} sx={{ marginRight: 1 }} />
                        }
                        {(canCancel) &&
                          <Chip label={t('orders.cancelOrderLabel')} onClick={handleOpenCancelDialog} sx={{ marginRight: 1 }}/>
                        }
                        {(canMarkAsInvoiceable) &&
                          <Chip label={t('orders.markAsInvoiceableLabel')} color="success" onClick={handleOpenMarkAsInvoiceableDialog} sx={{ marginRight: 1 }} />
                        }
                        {(canMarkAsInvoiced) &&
                          <Chip label={t('orders.markAsInvoicedLabel')} color="success" onClick={handleMarkAsInvoiced} sx={{ marginRight: 1 }} />
                        }
                        {(canMarkAsComplete) &&
                          <Chip label={t('orders.markAsCompleteLabel')} color="success" onClick={handleMarkAsComplete} sx={{ marginRight: 1 }} />
                        }
                        {(canEditOrder) &&
                          <Chip label={t('orders.editOrderLabel')} color="info" onClick={handleOrderEdit} />
                        }
                    </>
                    )
                  }
                </Grid>
              </Grid>
              <Grid container pt={4} pb={4}>
                  <Box display="flex" sx={{ gap: 2 }}>
                    { renderStates }
                  </Box>
              </Grid>
              {(order.state === "rejected") &&
                <Grid container pt={4} pb={4}>
                  <Grid item xs={4}><Typography variant="h6">{t('orders.rejectionReason')}</Typography></Grid>
                  <Grid item>
                    <Typography variant="subtitle1">{ order.rejection_reason }</Typography>
                  </Grid>
                </Grid>
              }
              <Divider variant="fullWidth" />

              <Grid container pt={4} pb={4}>
                <Grid item xs={4}>
                  <Typography variant="body2">{t('orders.orderDetails')}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Grid container pb={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('orders.orderDate')}</Typography>
                      <Typography variant="subtitle1">{!!order.created_at && format(new Date(order.created_at), 'dd.MM.yyyy')}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {!!order.confirmation_date &&
                        <>
                          <Typography variant="h6" mb={1}>{t('orders.confirmationDate')}</Typography>
                          <Typography variant="subtitle1">{format(new Date(order.confirmation_date), 'dd.MM.yyyy')}</Typography>
                        </>
                      }
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('orders.seller')}</Typography>
                      <Typography variant="subtitle1">{getCompanyName(Number(order.seller))}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6"mb={1}>{t('orders.type')}</Typography>
                      <Typography variant="subtitle1">{order.seller === profileData.company ? t('orders.sale') : t('orders.purchase')}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('orders.buyer')}</Typography>
                      <Typography variant="subtitle1">{order.buyer_company_name}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" mb={1}>{t('orders.totalPrice')}</Typography>
                      <Typography variant="subtitle1">{!!order.total_price && `${numberToLocale(order.total_price_before_expenses)}`}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6}>
                      {!!order.cancellation_date &&
                        <>
                          <Typography variant="h6">{t('orders.cancellationDate')}</Typography>
                          <Typography variant="subtitle1">{format(new Date(order.cancellation_date), 'dd.MM.yyyy')}</Typography>
                        </>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Divider variant="fullWidth" />

              <Grid container pt={4} pb={2}>
                <Grid item xs={4}>
                  <Typography variant="body2">{t('orders.additionalDetails')}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Grid container pb={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6" mb={1}>{t('orders.workDescription')}</Typography>
                      <Typography variant="subtitle1">{order.project.work_description}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6"mb={1}>{t('orders.workLocation')}</Typography>
                      <Typography variant="subtitle1">{order.project.work_location}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6"mb={1}>{t('orders.dates')}</Typography>
                      <Typography variant="subtitle1">
                        {!!order.work_start_date && format(new Date(order.work_start_date), 'dd.MM.yyyy')}
                        &nbsp;&ndash;&nbsp;
                        {!!order.work_end_date && format(new Date(order.work_end_date), 'dd.MM.yyyy')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container pb={3}>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('orders.accommodation')}</Typography>
                      <Typography variant="subtitle1">
                        {order.project.accommodation_by == 'seller' ?
                          t('cart.orderDetails.sellerOrganizes') : t('cart.orderDetails.buyerOrganizes')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('orders.startTime')}</Typography>
                      <Typography variant="subtitle1">{order.project.start_time}</Typography>
                    </Grid>
                  </Grid>

                  <Divider light variant="fullWidth" />

                  <Grid container pb={2} pt={3}>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('orders.contactName')}</Typography>
                      <Typography variant="subtitle1">{order.project.contact_name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('orders.contactPhone')}</Typography>
                      <Typography variant="subtitle1">{order.project.contact_phone}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('orders.streetAddress')}</Typography>
                      <Typography variant="subtitle1">{order.project.street}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" mb={1}>{t('orders.postOffice')}</Typography>
                      <Typography variant="subtitle1">{order.project.zip_code} {order.project.municipality}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Divider variant="fullWidth" />

              <Grid item>
                <Grid container pt={4} pb={4}>
                  {
                    orderEdit ? renderOrderEdit : 
                    (
                      <>
                        <Typography variant="body2">{t('orders.resources.title')}</Typography>
                          {resourceTypes.map((res_type: ResourceType) => (
                            (order.order_lines.some(item => item.resource_type == res_type.id)) && (
                              <Grid container pt={4} key={res_type.id}>
                                <Grid item xs={12} sx={{ backgroundColor: colors.lightgray }}>
                                  <Typography variant="body2" p={1}>{res_type.name}</Typography>
                                </Grid>
                                {order.order_lines.filter((orderLine: OrderLine) => (orderLine.resource_type == res_type.id)).map(item => (
                                  <>
                                    <Grid container key={item.id} mt={4} pb={2} ml={1} sx={{ borderBottom: "1px solid lightgrey" }}>
                                      <Grid item xs={2}>
                                        <Typography variant="h6">{item.product_name}</Typography>
                                        {Number(order.seller) === Number(profileData.company) &&
                                          <Typography variant='subtitle1'>{item.resource_name}</Typography>}
                                      </Grid>
                                      <Grid item xs={10}>
                                        <Grid container>
                                          <Grid item xs={3}>
                                            <Typography variant="subtitle1">{t('orders.resources.dates')}</Typography>
                                            <Typography variant="subtitle1">{
                                              item?.reservation?.begin && format(new Date(item?.reservation?.begin), 'dd.MM.yyyy')}
                                              &nbsp;&ndash;&nbsp;
                                              {item?.reservation?.end && format(new Date(item?.reservation?.end), 'dd.MM.yyyy')}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={2}>
                                            <Typography variant="subtitle1">{t('orders.resources.location')}</Typography>
                                            <Typography variant="subtitle1">{item.location}</Typography>
                                          </Grid>
                                          <Grid item xs={2}>
                                            <Typography variant="subtitle1" sx={{ width: '15%' }}>{t('orders.resources.dailyPrice')}</Typography>
                                            <Typography variant="subtitle1" sx={{ width: '15%' }}>{`${numberToLocale(item.daily_price)}`}</Typography>
                                          </Grid>
                                          <Grid item xs={2}>
                                            <Typography variant="subtitle1">{t('orders.resources.quantity')}</Typography>
                                            <Typography variant="subtitle1">{item.quantity}</Typography>
                                          </Grid>
                                          <Grid item xs={2}>
                                            <Typography variant="subtitle1" sx={{ width: '20%' }}>{t('orders.resources.basePriceTotal')}</Typography>
                                            <Typography variant="subtitle1" sx={{ width: '20%' }}>{`${numberToLocale(item.total_price_before_expenses)}`}</Typography>
                                          </Grid>
                                          <Grid item xs={1}>
                                            {(Number(order.seller) === Number(profileData.company) &&
                                              <Button
                                                onClick={(): Promise<void> => handleReplace(item)}
                                                size="small"
                                                variant="contained"
                                                disabled={item?.reservation?.begin && new Date(item.reservation.begin) < new Date()}
                                              >
                                                {t('orders.resources.replace')}
                                              </Button>
                                            )}
                                          </Grid>
                                        </Grid>
                                        {
                                          hasExpenseLines(item.id) && renderExpenseLinesReadOnly(item.id)
                                        }

                                        {/* Total price of the order line */}
                                        <Grid container mt={2}>
                                          <Grid item xs={9}></Grid>
                                          <Grid item>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{t('orders.resources.totalPrice')}:
                                              &nbsp;&nbsp;
                                              {
                                                numberToLocale(getExpenses(item.id)
                                                  .map((item) => item.total_price)
                                                  .reduce((prev, next) => prev + next, 0) + item.total_price_before_expenses)
                                              }</Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </>
                                )
                                )
                                }
                                {/* Total price of the resource type */}
                                <Grid container pt={4} justifyContent="flex-end">
                                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{res_type.name} {t('orders.resources.totalPrice')}:&nbsp;&nbsp;
                                    {numberToLocale(getTotalByResourceType(res_type.id))}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )))
                          }
                        <Grid container pt={4}>
                          <Grid item xs={12} sx={{backgroundColor: colors.lightgray}}>
                            <Typography variant="body2" p={1}>{t('orders.generalExpensesTitle')}</Typography>
                          </Grid>
                          <Grid item xs={2}>
                          </Grid>
                          <Grid item xs={10}>
                            {hasExpenseLines(0) && renderExpenseLinesReadOnly(0)}
                          </Grid>
                        </Grid>
                        {renderOrderTotalPrice}
                      </>
                    )      
                  }     
                </Grid>
              </Grid>
            </Box>
            <Dialog
              open={rejectDialogOpen}
              onClose={handleCloseRejectDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t('orders.rejectDialogTitle')}
              </DialogTitle>
              <DialogContent dividers>
                <DialogContentText>
                  {t('orders.rejectDialogContentText')}
                </DialogContentText>
                <TextField
                  autoFocus
                  required
                  multiline={true}
                  minRows={3}
                  margin="normal"
                  id="rejection-reason"
                  value={rejectionReason}
                  onChange={handleRejectionReasonChange}
                  label={t('orders.rejectionReason')}
                  fullWidth
                  variant="outlined"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseRejectDialog}>{t('orders.backButtonLabel')}</Button>
                <Button onClick={handleRejectOrder} disabled={rejectionReason.length < 5}>
                  {t('orders.rejectOrderLabel')}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={cancelDialogOpen}
              onClose={handleCloseCancelDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t('orders.cancelDialogTitle')}
              </DialogTitle>
              <DialogContent dividers>
                <DialogContentText>
                  <Typography variant="body1">{t('orders.cancelDialogContentText')}</Typography>
                  <Typography variant="h6" pt={2}>{t('orders.cancelDialogTermsTitle')}</Typography>
                  <List dense>
                    <ListItem><Typography variant="body2">&bull; {t('orders.cancelDialogTermsLineOne')}</Typography></ListItem>
                    <ListItem><Typography variant="body2">&bull; {t('orders.cancelDialogTermsLineTwo')}</Typography></ListItem>
                    <ListItem><Typography variant="body2">&bull; {t('orders.cancelDialogTermsLineThree')}</Typography></ListItem>
                  </List>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseCancelDialog}>{t('orders.backButtonLabel')}</Button>
                <Button onClick={handleCancelOrder}>
                  {t('orders.cancelOrderLabel')}
                </Button>
              </DialogActions>
            </Dialog>

            {/* 'Mark as invoiceable' confirmation dialog */}
            <Dialog
              open={markAsInvoiceableDialogOpen}
              onClose={handleCloseMarkAsInvoiceableDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t('orders.markAsInvoiceableDialogTitle')}
              </DialogTitle>
              <DialogContent dividers>
                <DialogContentText>
                  <Typography variant="body1">{t('orders.markAsInvoiceableDialogText')}</Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseMarkAsInvoiceableDialog}>{t('orders.backButtonLabel')}</Button>
                <Button onClick={handleMarkAsInvoiceable}>
                  {t('orders.markAsInvoiceableLabel')}
                </Button>
              </DialogActions>
            </Dialog>

            {/* resource replacement dialog */}
            <Dialog 
              open={replacementDialogOpen}
              fullWidth
              sx={{
                width: '100%'
              }}
            >
              <DialogTitle id="alert-dialog-title">
                {t('orders.resources.replacementTitle')}
              </DialogTitle>
              <DialogContent dividers>
                <Grid container display="flex" spacing={1}>
                  <Grid item xs={6} alignItems="start">
                    <Typography variant="body1">{t('orders.resources.replacementLabel')}</Typography>
                  </Grid>
                  <Grid item xs={6} alignItems="end">
                      <TextField
                        data-testid="replacement_resource-wrapper"
                        name="replacement_resource"
                        select
                        size="small"
                        onChange={handleReplacementChange}
                        inputProps={{
                          'data-testid': 'replacement_resource',
                        }}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                          native: true,
                          displayEmpty: true,
                        }}
                      >
                        <option value={0}>-- {t('orders.resources.selectPlaceholder')} --</option>
                        {replacementResources.map((option) => (
                          <option key={option.id} value={option.id}>
                            {`${option.resource_name}`}
                          </option>
                        ))}
                      </TextField>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ p: 2 }}>
                <Button variant="contained" size="small" onClick={handleCloseReplacementDialog}>
                  {`${t('misc.cancelButtonLabel')}`}
                </Button>
                <Button variant="contained" size="small" onClick={handleReplacementSave} disabled={!selectedReplacement}>
                  {`${t('misc.saveButtonLabel')}`}
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </>
    ),
    [t, handleCancelOrder,
      handleOrderEdit,
      renderStates, 
      order.seller, 
      profileData.company,
      getCompanyName,
      order.project,
      order.total_price,
      order.total_price_before_expenses,
      order.work_end_date,
      order.work_start_date,
      order.cancellation_date,
      order.confirmation_date,
      order.created_at,
      order.order_lines,
      resourceTypes,
      handleConfirmOrder,
      handleMarkAsInvoiceable,
      handleMarkAsInvoiced,
      handleMarkAsComplete,
      order.state,
      StyledProgress,
      loading,
      handleRejectOrder,
      rejectDialogOpen,
      rejectionReason,
      cancelDialogOpen,
      markAsInvoiceableDialogOpen,
      handleRejectionReasonChange,
      order.rejection_reason,
      order.buyer_company_name,
      handleReplace,
      handleCloseReplacementDialog,
      replacementDialogOpen,
      handleReplacementSave,
      handleReplacementChange,
      replacementResources,
      selectedReplacement,
      cancelOrderEdit,
      handleSaveOrderEdit,
      orderEdit,
      renderOrderEdit,
      renderExpenseLinesReadOnly,
      hasExpenseLines,
      getTotalByResourceType,
      renderOrderTotalPrice,
      canConfirm,
      canReject,
      canCancel,
      canMarkAsInvoiceable,
      canMarkAsInvoiced,
      canMarkAsComplete,
      canEditOrder,
      getExpenses,
    ],
  );
  return <Main>{renderOrderView}</Main>;
};
