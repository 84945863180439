import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Box, Card, CardMedia, styled, ButtonBase } from '@mui/material';
import { Advert, AdConversion } from '../../types';
import { colors } from '../../theme';
import Api from '../../api';
import { endpoints, urls } from '../../utils';

interface Props {
  slides: Advert[];
}
const StyledSlider = styled(Box)(({}) => ({
  position: "relative",
  height: "100%",
}));

const StyledArrow = styled(Box)(({}) => ({
  position: "absolute",
  top: "50%",
  transform: "translate(0, -50%)",
  fontSize: "45px",
  color: "#fff",
  zIndex: 1,
  cursor: "pointer",
}));

const StyledSlide = styled(Card)(({}) => ({
  width: "100%",
  height: "100%",
  backgroundSize: "cover",
  backgroundPosition: "center",
}));

const StyledContainer = styled(Box)(({}) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: 6,
}));

const StyledDot = styled(Box)(({}) => ({
  margin: "0 3px",
  cursor: "pointer",
  fontSize: "20px",
}));

export const Slider:  React.FC<Props> = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = useCallback(
    ():void => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  },[currentIndex, slides.length]);

  const handleNext = useCallback(
    (): void => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  },[currentIndex, slides.length]);

  const handleSlide = useCallback(
    (slideIndex: string | number): void => {
    setCurrentIndex(Number(slideIndex));
  },[]);

  const handleSlideClick = useCallback(
    async(advertId: string | number, e: SyntheticEvent): Promise<void> =>{
      e.preventDefault();
      const payload = {
        advert: Number(advertId),
        location: ''
      }
      const location = window.location.href.split('//')[1];
      if(`${location}` === `${window.location.host}${urls.login}`){
        payload.location = 'front page';
      }else{
        payload.location = 'side menu';
      }
     await Api.post<AdConversion>(endpoints.adConversions, payload);
    },[]);
    
  useEffect(()=>{
    const interval = setInterval(() => {
      let newIndex;
      const isLastSlide = currentIndex === slides.length - 1;
      if(isLastSlide){
        newIndex = 0;
      }else{
        newIndex = currentIndex + 1;
      }
      setCurrentIndex(newIndex);
    }, 12000); // ads run for 12 seconds
    return () => clearInterval(interval); 
  },[currentIndex, slides.length]);
  
  return (
    <StyledSlider>
      <Box>
        <StyledArrow onClick={handlePrevious} sx={{left:'25px'}}>
          ❰
        </StyledArrow>
        <StyledArrow onClick={handleNext} sx={{right:'25px'}}>
          ❱
        </StyledArrow>
      </Box>
      
      <StyledSlide>
        <ButtonBase 
          onClick={(e): Promise<void> => handleSlideClick(slides[currentIndex]?.id, e)} 
          sx={{width:'100%', height:'100%'}}
        >
          <CardMedia
            component="img"
            src={slides[currentIndex]?.image}
            height={'100%'}
            alt={slides[currentIndex]?.alt_text}
            onClick={(): Window | null => window.open(slides[currentIndex]?.link_url, "_blank")}
          />
        </ButtonBase>
      </StyledSlide>
      <StyledContainer>
        {
          slides.map((slide: Advert, slideIndex:number)=>(
            <StyledDot
              key={slide.id}
              onClick={():void => handleSlide(slideIndex)}
              sx={{
                color: `${slideIndex === currentIndex ? colors.lightBlue :''}`
              }}
            >
              ●
            </StyledDot>
          ))
        }
      </StyledContainer>
    </StyledSlider>
  )
}
