import { Grid, Typography, List } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Main } from '../layout';
import { InstructionRow } from '../components/instructions/InstructionRow';
import { useAuthContext } from '../context/auth';

export const Instructions: React.FC = () => {
  const { t } = useTranslation();
  const { profileData } = useAuthContext();

  return (
    <Main>
      <>
        <Grid container display="flex" m={2} spacing={2}>
          <Grid item xs={12}>
            <Grid item>
              <Typography variant="h3">{t('instructions.title')}</Typography>
              <Typography variant="subtitle1">{t('instructions.subTitle')}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Typography variant="h4">Resurssipoolin palvelukuvaus</Typography>
            <List disablePadding>
              <InstructionRow
                href="/instructions/Resurssipooli_palvelukuvaus_2023-09-05.pdf"
                text="Palvelukuvaus 5.9.2023"
              />
            </List>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" mb={1}>Resurssipoolin ohjeet</Typography>
            <Typography variant="h5">Resurssihaku</Typography>
            <List disablePadding>
              <InstructionRow
                href="/instructions/Resurssipooli_ohjeet_resurssihaku_kaikki_yhdessa.pdf"
                text="Kaikki yhdessä"
              />
              <InstructionRow
                href="/instructions/Resurssipooli_ohjeet_resurssihaku_haku.pdf"
                text="Resurssien haku ja tilaus"
              />
              <InstructionRow
                href="/instructions/Resurssipooli_ohjeet_resurssihaku_tilaus.pdf"
                text="Tilauskäsittely"
              />
            </List>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Tilaukset</Typography>
            <List disablePadding>
              <InstructionRow
                href="/instructions/Resurssipooli_ohjeet_tilaukset_kaikki_yhdessa.pdf"
                text="Kaikki yhdessä"
              />
              <InstructionRow
                href="/instructions/Resurssipooli_ohjeet_tilaukset_yleista.pdf"
                text="Tilaukset yleisesti"
              />
              <InstructionRow
                href="/instructions/Resurssipooli_ohjeet_tilaukset_kasittelyssa.pdf"
                text="Tilauskäsittely - käsittelyssä"
              />
              <InstructionRow
                href="/instructions/Resurssipooli_ohjeet_tilaukset_vahvistettu.pdf"
                text="Tilauskäsittely - vahvistettu"
              />
              <InstructionRow
                href="/instructions/Resurssipooli_ohjeet_tilaukset_odottaa_myyjaa.pdf"
                text="Tilauskäsittely - odottaa myyjää"
              />
            </List>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Omat resurssit</Typography>
            <List disablePadding>
              <InstructionRow
                href="/instructions/Resurssipooli_ohjeet_omat_resurssit_kaikki_yhdessa.pdf"
                text="Kaikki yhdessä"
              />
              <InstructionRow
                href="/instructions/Resurssipooli_ohjeet_omat_resurssit_yleista.pdf"
                text="Omat resurssit yleisesti"
              />
              <InstructionRow
                href="/instructions/Resurssipooli_ohjeet_omat_resurssit_yleista_uusi_resurssi.pdf"
                text="Uusi resurssi"
              />
              <InstructionRow
                href="/instructions/Resurssipooli_ohjeet_omat_resurssit_yleista_muokkaa_resurssia.pdf"
                text="Muokkaa resurssia"
              />
              <InstructionRow
                href="/instructions/Resurssipooli_ohjeet_omat_resurssit_yleista_resurssin_poisto.pdf"
                text="Poista resurssi"
              />
            </List>
          </Grid>
          {profileData.user.is_company_admin && (
            <Grid item xs={12}>
              <Typography variant="h5">Yrityksen tiedot</Typography>
              <List disablePadding>
                <InstructionRow
                  href="/instructions/Resurssipooli_ohjeet_yrityksen_tiedot_kaikki_yhdessa.pdf"
                  text="Kaikki yhdessä"
                />
                <InstructionRow
                  href="/instructions/Resurssipooli_ohjeet_yrityksen_tiedot_yrityksen_perustiedot.pdf"
                  text="Perustiedot"
                />
                <InstructionRow
                  href="/instructions/Resurssipooli_ohjeet_yrityksen_tiedot_yrityksen_laskutustiedot.pdf"
                  text="Laskutustiedot"
                />
                <InstructionRow
                  href="/instructions/Resurssipooli_ohjeet_yrityksen_tiedot_kontaktit_ja_kayttajat.pdf"
                  text="Kontaktit ja käyttäjät"
                />
                <InstructionRow
                  href="/instructions/Resurssipooli_ohjeet_yrityksen_tiedot_toimipisteet.pdf"
                  text="Toimipisteet"
                />
              </List>
            </Grid>
          )}
        </Grid>
      </>
    </Main>
  );
};
