import { Typography, Grid } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { Main } from '../layout';
import { OfferList } from '../components/offers/'
import { useTranslation } from 'react-i18next';
import { useOfferContext } from '../context';


export const Offers: React.FC = () => {
  const { t } = useTranslation();
  const {fetchAllOffers} = useOfferContext();

  // fetch all offers 
  useEffect(()=>{
    fetchAllOffers();
  },[fetchAllOffers]);

  const renderOffers = useMemo(() => (
    <OfferList />
  ), []);
  return (
    <Main>
      <Grid container display="flex" m={2} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">{t('offers.title')}</Typography>
          <Typography variant="subtitle1">{t('offers.subTitle')}</Typography>
        </Grid>
        <Grid item xs={12} mt={3}>
          {renderOffers}
        </Grid>
      </Grid>
    </Main>
  )
};
