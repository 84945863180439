import React, { ChangeEvent, useCallback, useMemo, useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Button,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
  Typography,
  Alert,
  Divider
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from 'react-i18next';
import fiLocale from 'date-fns/locale/fi';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { endpoints, resourceTypeIsPerson, resourceTypeIsMachinery, getEarliestPossibleOrderDate } from '../../utils';
import { Resource, ResourceType, ResourceResponse, ResourceTypesResponse,  ResourceFilterParams } from '../../types';
import Api from '../../api';
import { ResourceSearchList } from './index';
import { EquipmentFilters, EquipmentFiltersType } from './EquipmentFilters';
import { PersonnelFilters, PersonnelFiltersType } from './PersonnelFilters';
import { useAllCompaniesContext, useAllResourceAttributeOptionsContext, useAllResourceSubTypesContext, usePaginationContext } from '../../context';
import { useProgress } from '../../hooks';

export interface GeneralFilters {
  workLocation: string;
  startDate: Date;
  endDate: Date;
  resourceType?: ResourceType;
}


const initialGeneralFilters: GeneralFilters = {
  workLocation: '',
  startDate: new Date(),
  endDate: new Date()
};


const initialPersonnelFilters: PersonnelFiltersType = {
  languages: [],
  qualifications: [],
  skills: [],
  subtypes: [],
  companies: [],
  driversLicenseClasses: [],
  dailyPriceMax: 0,
  dailyPriceMin: -1,
  distanceMax: 0,
  distanceMin: -1
};

const initialEquipmentFilters: EquipmentFiltersType = {
  companies: [],
  subtypes: [],
  dailyPriceMax: 0,
  dailyPriceMin: -1,
  distanceMax: 0,
  distanceMin: -1
};


export const ResourceSearch: React.FC = ({ }) => {
  const [generalFilters, setGeneralFilters] = useState<GeneralFilters>(initialGeneralFilters);
  const [personnelFilters, setPersonnelFilters] = useState<PersonnelFiltersType>(initialPersonnelFilters);
  const [equipmentFilters, setEquipmentFilters] = useState<EquipmentFiltersType>(initialEquipmentFilters);
  const [resourceType, setResourceType] = useState<ResourceType>();
  const [resources, setResources] = useState<Resource[]>([]);
  const [resourceTypes, setResourceTypes] = useState<ResourceType[]>([]);
  const { allResourceSubTypes } = useAllResourceSubTypesContext();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { StyledProgress } = useProgress();
  const { offset, limit} = usePaginationContext();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [noResults, setNoResults] = useState<boolean>(false);
  const {allCompanies} = useAllCompaniesContext();
  const {allResourceAttributeOptions} = useAllResourceAttributeOptionsContext();

  useEffect(() => {
    const data = window.sessionStorage.getItem('filters');
    if (data !== null) {
      const parsed = JSON.parse(data);
      parsed.startDate = new Date(parsed.startDate);
      parsed.endDate = new Date(parsed.endDate);
      setGeneralFilters(parsed);
    }
  }, [])
  useEffect(() => {
    (async (): Promise<void> => {
      const { data } = await Api.get<ResourceTypesResponse>(endpoints.resourceTypes);
      const { results } = data;
      setResourceTypes(results);
    })();
  }, []);

  const getOptionIds = useCallback((): string => {
    let option_ids = ""

    const qualifications = (personnelFilters.qualifications.map((obj) => obj.id).join().length > 0 ? personnelFilters.qualifications.map((obj) => obj.id).join() + "," : "")
    const languages = (personnelFilters.languages.map((obj) => obj.id).join().length > 0 ? personnelFilters.languages.map((obj) => obj.id).join() + "," : "")
    const skills = (personnelFilters.skills.map((obj) => obj.id).join().length > 0 ? personnelFilters.skills.map((obj) => obj.id).join() + "," : "")
    const driversLicenseClasses = personnelFilters.driversLicenseClasses.map((obj) => obj.id).join()
    option_ids = qualifications + languages + skills + driversLicenseClasses
    option_ids = option_ids.replace(/,+$/, '')

    return option_ids
  }, [personnelFilters])

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const newFilters = { ...generalFilters };
      (newFilters as any)[e.target.name as keyof GeneralFilters] = e.target.value;
      setGeneralFilters(newFilters);
      window.sessionStorage.setItem('filters', JSON.stringify(newFilters))
      if (e.target.name === 'resource-type') {
        setResourceType(resourceTypes.find((type) => type.id === Number(e.target.value)))
      }

    },
    [generalFilters, resourceTypes, setGeneralFilters, setResourceType],
  );
  const handleDateChange = useCallback(
    (newValue: Date | null, name: string): void => {
      const newFilters = { ...generalFilters };
      (newFilters as any)[name as keyof GeneralFilters] = newValue;
      setGeneralFilters(newFilters);
      window.sessionStorage.setItem('filters', JSON.stringify(newFilters));
    },
    [generalFilters, setGeneralFilters],
  );

  const handleFetchResources = useCallback(
    async (): Promise<void> => {
      let params : ResourceFilterParams = {
        zip_code: generalFilters?.workLocation,
        available_after: generalFilters?.startDate,
        available_before: generalFilters?.endDate,
      }

      if (!!resourceType && resourceTypeIsMachinery(resourceType.name)){
        const companies = equipmentFilters.companies.map((obj) => { return obj.id }).join();
        const subtype_ids = equipmentFilters.subtypes.map((obj) => { return obj.id }).join()
          params = {
            ...params,
            ...(!!resourceType && {resource_type: `${resourceType.id}`}),
            ...(!!subtype_ids && {subtype_ids}),
            ...(!!companies && {company_ids : companies}),
            ...(Number(equipmentFilters.dailyPriceMin) > 0 && {daily_price_min :`${equipmentFilters.dailyPriceMin}`}),
            ...(Number(equipmentFilters.dailyPriceMax) > 0 && {daily_price_max :`${equipmentFilters.dailyPriceMax}`}),
            ...(Number(equipmentFilters.distanceMin) > 0 && {distance_min :`${equipmentFilters.distanceMin}`}),
            ...(Number(equipmentFilters.distanceMax) > 0 && {distance_max :`${equipmentFilters.distanceMax}`})
          }
      }
      if (!!resourceType && resourceTypeIsPerson(resourceType.name)){
        const option_ids = getOptionIds();
        const subtype_ids = personnelFilters.subtypes.map((obj) => { return obj.id }).join()
        const companies = personnelFilters.companies.map((obj) => { return obj.id }).join();
          params = {
            ...params,
            ...(!!resourceType && {resource_type: `${resourceType.id}`}),
            ...(!!option_ids && {option_ids}),
            ...(!!subtype_ids && { subtype_ids }),
            ...(!!companies && {company_ids : companies}),
            ...(Number(personnelFilters.dailyPriceMin) > 0 && {daily_price_min :`${personnelFilters.dailyPriceMin}`}),
            ...(Number(personnelFilters.dailyPriceMax) > 0 && {daily_price_max :`${personnelFilters.dailyPriceMax}`}),
            ...(Number(personnelFilters.distanceMin) > 0 && {distance_min :`${personnelFilters.distanceMin}`}),
            ...(Number(personnelFilters.distanceMax) > 0 && {distance_max :`${personnelFilters.distanceMax}`})
          }
      }

      setLoading(true);
      const { data } = await Api.get<ResourceResponse>(endpoints.resources, {
        params: {
          ...params,
          offset,
          limit
        },
      });
      const { results, count } = data;
      setLoading(false);
      setResources(results);
      setTotalCount(count);
      setNoResults(count < 1);
    },
    [generalFilters, personnelFilters, equipmentFilters, resourceType, getOptionIds, offset, limit],
  );
  //TODO: Should the results be refetched if
  const handleClearFilters = useCallback(async (): Promise<void> => {
    if (!!resourceType && resourceTypeIsPerson(resourceType.name)) {
      setPersonnelFilters(initialPersonnelFilters)
    } else if (!!resourceType && resourceTypeIsMachinery(resourceType.name)) {
      setEquipmentFilters(initialEquipmentFilters)
    }
  },
    [resourceType, setEquipmentFilters, setPersonnelFilters],
  );

  const renderPersonnelFilters = useMemo(
    () => (
      <PersonnelFilters
        resourceTypes={resourceTypes}
        resourceSubTypes={allResourceSubTypes}
        companies={allCompanies} 
        resourceAttributeOptions={allResourceAttributeOptions} 
        personnelFilters={personnelFilters} 
        setFilters={setPersonnelFilters} 
      />
    ), [allCompanies, allResourceAttributeOptions, resourceTypes, allResourceSubTypes, personnelFilters]
  );

  const renderEquipmentFilters = useMemo(
    () => (
      <EquipmentFilters 
        resourceSubTypes={allResourceSubTypes} 
        resourceTypes={resourceTypes} 
        companies={allCompanies} 
        equipmentFilters={equipmentFilters} 
        setFilters={setEquipmentFilters} 
      />
    ),[allCompanies, equipmentFilters, allResourceSubTypes, resourceTypes]);

  const renderFilters = useMemo(
    () => (
      <>
        <Grid container mb={4} spacing={2} item>
          <Grid item xs={2}>
            <Typography variant="body2">{t('resourceSearch.filters.workLocation')} *</Typography>
            <TextField name="workLocation" size="small" value={generalFilters.workLocation} onChange={handleChange} />
          </Grid>

          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fiLocale}>
              <Typography variant="body2">{t('resourceSearch.filters.workStarts')} *</Typography>
              <DatePicker
                disableMaskedInput
                minDate={getEarliestPossibleOrderDate()}
                renderInput={(params): any => <TextField size="small" {...params} />}
                value={generalFilters.startDate}
                onChange={(date): void => handleDateChange(date, 'startDate')}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fiLocale}>
              <Typography variant="body2">{t('resourceSearch.filters.workEnds')} *</Typography>
              <DatePicker
                disableMaskedInput
                minDate={generalFilters.startDate ? generalFilters.startDate : getEarliestPossibleOrderDate()}
                renderInput={(params): any => <TextField size="small" {...params} />}
                value={generalFilters.endDate}
                onChange={(date): void => handleDateChange(date, 'endDate')}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={6}>
            <FormControl>
              <Typography variant="body2">{t('resourceSearch.filters.resourceType')}</Typography>
              <RadioGroup
                name="resource-type"
                value={generalFilters.resourceType}
                onChange={handleChange}
                row
              >
                {resourceTypes.map((option) => (
                  <FormControlLabel key={option.id} value={option.id} control={<Radio />} label={option.name} />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container mb={4} sx={{ ml: 2 }}>
          {(!!resourceType && resourceTypeIsPerson(resourceType.name)) && renderPersonnelFilters}
          {(!!resourceType && resourceTypeIsMachinery(resourceType.name)) && renderEquipmentFilters}
        </Grid>
      </>
    ),
    [t, generalFilters, resourceTypes, resourceType, handleChange, handleDateChange, renderPersonnelFilters, renderEquipmentFilters]
  );
  const fetchButtonDisabled = (
    generalFilters.workLocation === '' ||
    generalFilters.startDate === null ||
    generalFilters.endDate === null
  );
  return (
    <Grid container m={2} spacing={2}>
      {renderFilters}
      <Divider style={{width:'100%'}} />
      <Grid item xs={12}>
        <Button
          variant="contained"
          size="small"
          disabled={fetchButtonDisabled}
          onClick={handleFetchResources}
        >
          {t('resourceSearch.fetchResourcesButton')}
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={handleClearFilters}
          sx={{
            ml: 2,
          }}
        >
          {t('resourceSearch.clearFiltersButton')}
        </Button>
      </Grid>
      {loading && <StyledProgress size={24} />}
      {noResults && (
        <Grid container justifyContent="center" mt={10}>
          <Grid item>
            <Alert severity="info">{t('resourceSearch.noResultsInfoText')}</Alert>
          </Grid>
        </Grid>
      )}
      {resources.length > 0 && (
        <ResourceSearchList
          resources={resources}
          companies={allCompanies}
          filters={generalFilters}
          resourceSubTypes={allResourceSubTypes}
          resourceAttributeOptions={allResourceAttributeOptions}
          totalCount={totalCount}
        />
      )}
    </Grid>
  );
};
