import React from 'react';
import { ListItem, ListItemIcon, Link } from '@mui/material';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebase';

interface Props {
  href: string;
  text: string;
}

export const InstructionRow: React.FC<Props> = ({ href, text }) => {
  const fileRef = ref(storage, href);

  const showFile = async (): Promise<void> => {
    const downloadURL = await getDownloadURL(fileRef);
    window.open(downloadURL, '_blank');
  };

  return (
    <ListItem>
      <ListItemIcon sx={{ minWidth: 40 }}>
        <TextSnippetIcon />
      </ListItemIcon>
      <Link onClick={showFile} underline="hover">
        {text}
      </Link>
    </ListItem>
  );
};
