import { Button, FormControl, Grid, styled, TextField, Typography, Divider, FormHelperText } from '@mui/material';
import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext, useCompanyContext } from '../../context';
import { colors } from '../../theme';
import { CompanyFormValues, CompanyFormError, ContactPerson } from '../../types';
import Api from '../../api';
import { useSnackbar } from 'notistack';
import { endpoints, urls } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { useProgress } from '../../hooks';
const StyledButton = styled(Button)(() => ({
  '&:hover': {
    backgroundColor: colors.darkBlue,
  },
}));
const DEFAULT_FORM_VALUES: CompanyFormValues = {
  name: '',
  vat_id: '',
  street: '',
  zip_code: '',
  city: '',
  phone: '',
  email: '',
  licence_valid_until: '',
  max_work_distance: null
};
interface Props {
  companyId: number | string;
}

export const CompanyDetails: React.FC<Props> = ({ companyId }) => {
  const { t } = useTranslation();
  const { StyledProgress } = useProgress();
  const [formValues, setFormValues] = useState<CompanyFormValues>(DEFAULT_FORM_VALUES);
  const [formErrors, setFormErrors] = useState<CompanyFormError>({});
  const { companyContacts } = useCompanyContext();
  const [contactPerson, setContactPerson] = useState<ContactPerson | undefined>(undefined);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDefaultValueChanged, setIsDefaultValueChanged] = useState<boolean>(false);
  const {profileData} = useAuthContext();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setButtonDisabled(
      !isDefaultValueChanged ||
        loading ||
        ![formValues.name, formValues.vat_id, formValues.licence_valid_until].every((item) => item.length > 0),
    );
  }, [formValues.name, formValues.vat_id, formValues.licence_valid_until, loading, isDefaultValueChanged]);

  useEffect(() => {
    if (Number(companyId) > 0) {
      setIsEditMode(true);
    }
  }, [companyId]);

  useEffect(() => {
    const contact = companyContacts.find((contact) => Number(contact.id) === Number(formValues.contact_person));
    setContactPerson(contact);
  }, [companyContacts, formValues.contact_person]);

  // fetch company data
  useEffect(() => {
    if (Number(companyId) > 0) {
      (async (): Promise<void> => {
        setLoading(true);
        const { data } = await Api.get<CompanyFormValues>(endpoints.company(companyId));
        setLoading(false);
        setFormValues(data);
      })();
    }
  }, [companyId]);

  const hasError = useCallback(
    (key: string): boolean => {
      return Object.keys(formErrors).some((item) => item === key);
    },
    [formErrors],
  );
  const renderFormErrorHelperText = useMemo(
    () =>
      (key: string): JSX.Element | void => {
        if (hasError(key)) {
          return <FormHelperText>{formErrors[key]}</FormHelperText>;
        }
      },
    [formErrors, hasError],
  );

  const handleClick = useCallback(
    async (e: SyntheticEvent): Promise<void> => {
      e.preventDefault();
      try {
        const { data } = await Api.post<CompanyFormValues>(endpoints.companies, formValues);
        const companyId = data.id;
        if (companyId) {
          enqueueSnackbar(t('misc.createSuccess'), {
            variant: 'success',
          });
          navigate(`${urls.companySettings}/${companyId}`);
          setFormValues(DEFAULT_FORM_VALUES);
        }
      } catch (err: any) {
        setFormErrors(err.response.data);
      }
    },
    [formValues, navigate, enqueueSnackbar, t],
  );

  const updateCompanyInfo = useCallback(
    async (e: SyntheticEvent): Promise<void> => {
      e.preventDefault();
      try {
        await Api.patch<CompanyFormValues>(endpoints.company(companyId), formValues);
      } catch (err: any) {
        setFormErrors(err.response.data);
      }
    },
    [formValues, companyId],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const newFormValues = { ...formValues };
      const { name, value } = e.target;

      if (value !== newFormValues[name as keyof CompanyFormValues]) {
        setIsDefaultValueChanged(true);
      }

      if (name === 'max_work_distance') {
        setFormValues({ ...formValues, [e.target.name]: value === '' ? null : value });
      }
      else {
        setFormValues({ ...formValues, [e.target.name]: value});
      }
    },
    [formValues],
  );

  const renderCompanyForm = useMemo(
    () => (
      <form data-testid="company">
        <Grid container item >
          {loading && <StyledProgress size={24} />}
          <Grid container item m={2}>
            <Grid item xs={10}>
              <Typography variant="h4"> {t('companyDetails.subTitle')}</Typography>
            </Grid>
            <Grid item xs={2} container>
              <Grid item xs={12}>
                <StyledButton
                  variant="contained"
                  disabled={buttonDisabled}
                  size="small"
                  onClick={isEditMode ? updateCompanyInfo : handleClick}
                >
                  {` ${t('companyDetails.save')}`}
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
          <FormControl error={hasError('name')}>
            <Grid container item m={2} display="flex">
              <Grid item xs={3} alignItems="start">
                <Typography variant="body1">{t('companyDetails.name')}</Typography>
              </Grid>
              <Grid item xs={6} alignItems="end">
                <>
                  <TextField
                    data-testid="name-wrapper"
                    name="name"
                    size="small"
                    required
                    onChange={handleChange}
                    value={formValues.name}
                    inputProps={{
                      'data-testid': 'name',
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  {renderFormErrorHelperText('name')}
                </>
              </Grid>
            </Grid>
            <Divider variant="fullWidth" />
          </FormControl>
          <FormControl error={hasError('vat_id')}>
            <Grid container item m={2}>
              <Grid item xs={3} alignItems="start">
                <Typography variant="body1">{t('companyDetails.vatId')}</Typography>
              </Grid>
              <Grid item xs={6} alignItems="end">
                <>
                  <TextField
                    data-testid="vat_id-wrapper"
                    name="vat_id"
                    size="small"
                    required
                    onChange={handleChange}
                    value={formValues.vat_id}
                    inputProps={{
                      'data-testid': 'vat_id',
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  {renderFormErrorHelperText('vat_id')}
                </>
              </Grid>
            </Grid>
            <Divider variant="fullWidth" />
          </FormControl>
          <FormControl error={hasError('street') || hasError('zip_code' || hasError('city'))}>
            <Grid container item m={2}>
              <Grid item xs={3} alignItems="start">
                <Typography variant="body1">{t('companyDetails.street')}</Typography>
              </Grid>
              <Grid item xs={6} alignItems="end">
                <>
                  <TextField
                    data-testid="street-wrapper"
                    name="street"
                    size="small"
                    onChange={handleChange}
                    value={formValues.street}
                    inputProps={{
                      'data-testid': 'street',
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  {renderFormErrorHelperText('street')}
                </>
                <Grid container item mt={3} spacing={3}>
                  <Grid item xs={6} alignItems="start">
                    <>
                      <TextField
                        data-testid="zip_code-wrapper"
                        name="zip_code"
                        size="small"
                        onChange={handleChange}
                        value={formValues.zip_code}
                        label={t('companyDetails.zipCode')}
                        inputProps={{
                          'data-testid': 'zip_code',
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                      {renderFormErrorHelperText('zip_code')}
                    </>
                  </Grid>
                  <Grid item xs={6} alignItems="end">
                    <>
                      <TextField
                        data-testid="city-wrapper"
                        name="city"
                        size="small"
                        onChange={handleChange}
                        value={formValues.city}
                        label={t('companyDetails.city')}
                        inputProps={{
                          'data-testid': 'city',
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                      {renderFormErrorHelperText('city')}
                    </>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider variant="fullWidth" />
          </FormControl>
          <FormControl error={hasError('phone')}>
            <Grid container item m={2}>
              <Grid item xs={3} alignItems="start">
                <Typography variant="body1">{t('companyDetails.phone')}</Typography>
              </Grid>
              <Grid item xs={6} alignItems="end">
                <>
                  <TextField
                    data-testid="phone-wrapper"
                    name="phone"
                    size="small"
                    onChange={handleChange}
                    value={formValues.phone}
                    inputProps={{
                      'data-testid': 'phone',
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  {renderFormErrorHelperText('phone')}
                </>
              </Grid>
            </Grid>
            <Divider variant="fullWidth" />
          </FormControl>
          <FormControl error={hasError('email')}>
            <Grid container item m={2}>
              <Grid item xs={3} alignItems="start">
                <Typography variant="body1">{t('companyDetails.email')}</Typography>
              </Grid>
              <Grid item xs={6} alignItems="end">
                <>
                  <TextField
                    data-testid="email-wrapper"
                    name="email"
                    size="small"
                    onChange={handleChange}
                    value={formValues.email}
                    type="email"
                    inputProps={{
                      'data-testid': 'email',
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  {renderFormErrorHelperText('email')}
                </>
              </Grid>
            </Grid>
            <Divider variant="fullWidth" />
          </FormControl>
          <FormControl error={hasError('licence_valid_until')}>
            <Grid container item m={2}>
              <Grid item xs={3} alignItems="start">
                <Typography variant="body1">{t('companyDetails.licenceValidUntil')}</Typography>
              </Grid>
              <Grid item xs={6} alignItems="end">
                <>
                  <TextField
                    data-testid="licence_valid_until-wrapper"
                    name="licence_valid_until"
                    size="small"
                    onChange={handleChange}
                    placeholder="YYYY-MM-DD"
                    value={formValues.licence_valid_until}
                    inputProps={{
                      'data-testid': 'licence_valid_until',
                    }}
                    InputLabelProps={{ shrink: true }}
                    disabled={(Number(companyId)> 0 && !profileData.user.is_staff)}
                  />
                  {renderFormErrorHelperText('licence_valid_until')}
                </>
              </Grid>
            </Grid>
            <Divider variant="fullWidth" />
          </FormControl>
          {Number(companyId) > 0 && (
            <FormControl error={hasError('contact_person')}>
              <Grid container item m={2}>
                <Grid item xs={3} alignItems="start">
                  <Typography variant="body1">{t('companyDetails.contactPerson')}</Typography>
                </Grid>
                <Grid item xs={6} alignItems="end">
                  <>
                    <TextField
                      data-testid="contact_person-wrapper"
                      name="contact_person"
                      select
                      size="small"
                      onChange={handleChange}
                      value={formValues.contact_person || ''}
                      inputProps={{
                        'data-testid': 'contact_person',
                      }}
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{
                        native: true,
                        displayEmpty: true,
                      }}
                    >
                      <option value={''}>-- {t('myResources.selectContactPersonPlaceholder')} --</option>
                      {companyContacts.map((option) => (
                        <option key={option.id} value={option.id}>
                          {`${option.first_name} ${option.last_name}`}
                        </option>
                      ))}
                    </TextField>
                    {renderFormErrorHelperText('contact_person')}
                    {contactPerson && (
                      <>
                        <Grid item mt={3}>
                          <TextField
                            data-testid="contact_name-wrapper"
                            name="contact_name"
                            label={t('companyDetails.contactName')}
                            size="small"
                            value={`${contactPerson?.first_name} ${contactPerson?.last_name}`}
                            inputProps={{ readOnly: true }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item mt={3}>
                          <TextField
                            data-testid="contact_email-wrapper"
                            name="contact_email"
                            label={t('companyDetails.contactEmail')}
                            size="small"
                            value={contactPerson.email}
                            inputProps={{ readOnly: true }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item mt={3}>
                          <TextField
                            data-testid="contact_phone-wrapper"
                            name="contact_phone"
                            label={t('companyDetails.contactPhoneNumber')}
                            size="small"
                            value={contactPerson.phone}
                            inputProps={{ readOnly: true }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                </Grid>
              </Grid>
              <Divider variant="fullWidth" />
            </FormControl>
          )}
          <FormControl error={hasError('max_work_distance')}>
            <Grid container item m={2}>
              <Grid item xs={3} alignItems="start">
                <Typography variant="body1">{t('companyDetails.maxWorkDistance')}</Typography>
              </Grid>
              <Grid item xs={6} alignItems="end">
                <>
                  <TextField
                    type="number"
                    data-testid="max_work_distance-wrapper"
                    name="max_work_distance"
                    size="small"
                    onChange={handleChange}
                    value={formValues.max_work_distance === null ? '' : formValues.max_work_distance}
                    inputProps={{
                      'data-testid': 'max_work_distance',
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  {renderFormErrorHelperText('max_work_distance')}
                </>
              </Grid>
            </Grid>
            <Divider variant="fullWidth" />
          </FormControl>
        </Grid>
      </form>
    ),
    [
      companyId,
      contactPerson,
      buttonDisabled,
      companyContacts,
      formValues,
      handleChange,
      handleClick,
      hasError,
      renderFormErrorHelperText,
      t,
      isEditMode,
      updateCompanyInfo,
      StyledProgress,
      loading,
      profileData.user.is_staff
    ],
  );
  return <>{renderCompanyForm}</>;
};
