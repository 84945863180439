import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import {
  Grid,
  Box,
  TextField,
  InputAdornment,
  TableBody,
  TableRow,
  TableCell,
  Button,
  TableFooter,
} from '@mui/material';
import { Resource, FilterFunc, Record } from '../../types';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { useTable } from '../../hooks';
import { useAllResourceSubTypesContext, useCompanyContext, useResourceContext } from '../../context';
import { numberToLocale } from '../../utils';


interface Props {
  resources: Resource[];
  loading: boolean;
  totalCount : number;
}

export const ResourceList: React.FC<Props> = ({ resources, loading, totalCount }) => {
  const { t } = useTranslation();
  const { setOpen, setSelectedResourceId } = useResourceContext();
  const { allResourceSubTypes } = useAllResourceSubTypesContext();
  const { companyContacts } = useCompanyContext();
  
  const getSubTypeName = useCallback(
    (subTypeId: string | number): string => {
      const subType = allResourceSubTypes.find((item) => Number(item.id) === Number(subTypeId));
      return subType?.name ?? '';
    },
    [allResourceSubTypes],
  );
  const getContactPersonName = useCallback(
    (contactPersonId: string | number): string => {
      const contact = companyContacts.find((item) => Number(item.id) === Number(contactPersonId));
      return contact ? contact.first_name + ' ' + contact.last_name : '';
    },
    [companyContacts],
  );
  const [filterFunction, setFilterFunction] = useState<FilterFunc>({
    filterRecord: (items: Record[]): Record[] => items,
  });
  const headCells = [
    { id: 'internal_name', label: `${t('myResources.internalName')}`, sortable: true },
    { id: 'product.title', label: `${t('myResources.displayName')}`, sortable: true },
    { id: 'subtype', label: `${t('myResources.category')}`, sortable: true },
    { id: 'location', label: `${t('myResources.location')}`, sortable: true },
    { id: 'contact_person', label: `${t('myResources.foreman')}`, sortable: true },
    { id: 'status', label: `${t('myResources.status')}`, sortable: true },
    { id: 'product.price', label: `${t('myResources.price')}`, sortable: true },
    { id: 'edit', label: `${t('misc.edit')}`, sortable: false },
  ];
  const { TableContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } = useTable(
    resources,
    headCells,
    filterFunction,
    totalCount
  );
  const handleEdit = useCallback(
    (resourceId: string): void => {
      setSelectedResourceId(resourceId);
      setOpen(true);
    },
    [setSelectedResourceId, setOpen],
  );

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const searchText = event.target.value;
      setFilterFunction({
        filterRecord: (items: Record[]): Record[] => {
          if (!searchText.length) {
            return items;
          } else {
            return items.filter((item) => {
              return (
                `${item.internal_name}`.toLocaleLowerCase().includes(searchText.toLowerCase()) ||
                `${item.product.title}`.toLocaleLowerCase().includes(searchText.toLowerCase()) ||
                `${getSubTypeName(item.subtype)}`.toLocaleLowerCase().includes(searchText.toLowerCase()) ||
                `${item.status}`.toLocaleLowerCase().includes(searchText.toLowerCase()) ||
                `${item.location}`.toLocaleLowerCase().includes(searchText.toLowerCase()) ||
                `${item.product.price}`.toLocaleLowerCase().includes(searchText.toLowerCase())
              );
            });
          }
        },
      });
    },
    [getSubTypeName],
  );
 
  const renderTable = useMemo(
    () => (
      <Grid item xs={12} container mt={2}>
        <Grid item xs={12}>
          <Box sx={{ mb: 2 }}>
            <TextField
              data-testid="search-wrapper"
              name="search"
              size="small"
              disabled={loading}
              fullWidth={false}
              placeholder={t('misc.search')}
              onChange={handleSearch}
              inputProps={{
                'data-testid': 'search',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: '50%',
              }}
            />
          </Box>
        </Grid>
        <TableContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item: Resource) => (
              <TableRow key={item.id} hover>
                <TableCell>{item.internal_name}</TableCell>
                <TableCell>{item.product.title}</TableCell>
                <TableCell>{getSubTypeName(item.subtype)}</TableCell>
                <TableCell>{item.location}</TableCell>
                <TableCell>{getContactPersonName(item.contact_person)}</TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>{numberToLocale(item.product.price)}</TableCell>
                <TableCell>
                  <Button onClick={(): void => handleEdit(`${item.id}`)} size="small" variant="contained">
                    <EditIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TblPagination />
            </TableRow>
          </TableFooter>
        </TableContainer>
      </Grid>
    ),
    [
      TableContainer,
      TblHead,
      TblPagination,
      handleEdit,
      handleSearch,
      loading,
      recordsAfterPagingAndSorting,
      t,
      getSubTypeName,
      getContactPersonName,
    ],
  );
  return <>{renderTable}</>;
};
