import { SelectChangeEvent, Grid, FormControl, Select, MenuItem, Checkbox, ListItemText, TextField, FormLabel } from "@mui/material";//eslint-disable-line import/named
import { ChangeEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ResourceType, ResourceSubType, ResourceAttributeOption, CompanyInfo} from "../../types";
import { useAllResourceAttributesContext } from '../../context';

export interface PersonnelFiltersType {
    languages: ResourceAttributeOption[];
    qualifications: ResourceAttributeOption[];
    skills: ResourceAttributeOption[];
    companies: CompanyInfo[];
    subtypes: ResourceSubType[];
    driversLicenseClasses: ResourceAttributeOption[];
    dailyPriceMax: number;
    dailyPriceMin: number;
    distanceMax: number;
    distanceMin: number;
}
interface Props {
    resourceTypes: ResourceType[];
    resourceSubTypes: ResourceSubType[];
    companies: CompanyInfo[];
    resourceAttributeOptions: ResourceAttributeOption[];
    personnelFilters: PersonnelFiltersType
    setFilters: React.Dispatch<React.SetStateAction<PersonnelFiltersType>>;
}

export const PersonnelFilters: React.FC<Props> = ({ resourceTypes, resourceSubTypes, companies, resourceAttributeOptions, personnelFilters, setFilters }) => {
  const { t } = useTranslation();
  const personTypeId = resourceTypes.find((type) => ["Henkilö", "Montör", "Person"].includes(type.name))?.id
  const filteredResourceSubTypes = resourceSubTypes.filter((subtype) => subtype.resource_type === personTypeId)
  const {allResourceAttributes} = useAllResourceAttributesContext();

  const handlePersonnelFilterChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const newFilters = { ...personnelFilters };
      (newFilters as any)[e.target.name as keyof PersonnelFiltersType] = Number(e.target.value);
      setFilters(newFilters);
    },
    [personnelFilters, setFilters],
  )

  const handlePersonnelSelectFilterChange = useCallback(
    (e: SelectChangeEvent<string[]>): void => {
      const newFilters = { ...personnelFilters };
      if (e.target.name === 'language') {
        const languagesArray: ResourceAttributeOption[] = []
        // From database ResourceAttributeOptions find the ones that match current filter selection
        for (const language of e.target.value) {
          languagesArray.push(resourceAttributeOptions.find((option) => option.value === language) as ResourceAttributeOption)
        }
        newFilters.languages = languagesArray
      } else if (e.target.name === 'qualification') {
        const qualificationsArray: ResourceAttributeOption[] = []
        // From database ResourceAttributeOptions find the ones that match current filter selection
        for (const qualification of e.target.value) {
          qualificationsArray.push(resourceAttributeOptions.find((option) => option.value === qualification) as ResourceAttributeOption)
        }
        newFilters.qualifications = qualificationsArray
      }
      else if (e.target.name === 'skills') {
        const skillsArray: ResourceAttributeOption[] = []
        // From database ResourceAttributeOptions find the ones that match current filter selection
        for (const skill of e.target.value) {
          skillsArray.push(resourceAttributeOptions.find((option) => option.value === skill) as ResourceAttributeOption)
        }
        newFilters.skills = skillsArray
      }
      else if (e.target.name === 'company') {
        const companiesArray: CompanyInfo[] = []
        // From database CompanyInfos find the ones that match current filter selection
        for (const companyName of e.target.value) {
          companiesArray.push(companies.find((company) => company.name === companyName) as CompanyInfo)
        }
        newFilters.companies = companiesArray
      }
      else if (e.target.name === 'subtype') {
        const subtypeArray: ResourceSubType[] = []
        for (const subtypeName of e.target.value) {
          subtypeArray.push(resourceSubTypes.find((subtype) => subtype.name === subtypeName) as ResourceSubType)
        }
        newFilters.subtypes = subtypeArray
      }
      else if (e.target.name === 'driverslicense') {
        const driversLicenseClassArray: ResourceAttributeOption[] = []
        // From database ResourceAttributeOptions find the ones that match current filter selection
        for (const driversLicenseClass of e.target.value) {
          driversLicenseClassArray.push(resourceAttributeOptions.find((option) => option.value === driversLicenseClass) as ResourceAttributeOption)
        }
        newFilters.driversLicenseClasses = driversLicenseClassArray
      }
      setFilters(newFilters);
    },
    [personnelFilters, resourceSubTypes, resourceAttributeOptions, companies, setFilters],
  )
  const getOptions = useCallback((attributeName: string): string[] => {
    const attribute = allResourceAttributes.find((attr) => attr.name === attributeName)
    const options = []
    for (const option of resourceAttributeOptions) {
      if (option.attribute === attribute?.id) {
        options.push(option.value)
      }
    }
    return options
  }, [allResourceAttributes, resourceAttributeOptions]);
  
  return (
    <Grid container spacing={2} columns={{ xs: 2, sm: 4, md: 12}}>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <FormLabel id="subtype-label">{t('resourceSearch.filters.subType')}</FormLabel>
          <Select
            labelId="subtype-select-label"
            id="subtype-select"
            label="subtype"
            onChange={handlePersonnelSelectFilterChange}
            name="subtype"
            value={personnelFilters.subtypes.map((subtype) => { return subtype.name as string })}
            multiple
            renderValue={(selected): string => selected.join(", ")}
            size="small"
          >
            {filteredResourceSubTypes.map((subtype) => (
              <MenuItem key={subtype.name} value={subtype.name}>
                <Checkbox checked={personnelFilters.subtypes.indexOf(subtype) !== -1} />
                <ListItemText primary={subtype.name} disableTypography={true} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <FormLabel id="qualification-label">{t('resourceSearch.filters.qualifications')}</FormLabel>
          <Select
            labelId="qualification-select-label"
            id="qualification-select"
            label="qualification"
            onChange={handlePersonnelSelectFilterChange}
            name="qualification"
            value={personnelFilters.qualifications.map((option) => { return option.value as string })}
            multiple
            renderValue={(selected): string => selected.join(", ")}
            size="small"
          >
            {getOptions("Pätevyydet").map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox checked={personnelFilters.qualifications.map((option) => { return option.value }).indexOf(option) !== -1} />
                <ListItemText primary={option} disableTypography={true} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <FormLabel id="skills-label">{t('resourceSearch.filters.skills')}</FormLabel>
          <Select
            labelId="skills-select-label"
            id="skills-select"
            label="skills"
            onChange={handlePersonnelSelectFilterChange}
            name="skills"
            value={personnelFilters.skills.map((option) => { return option.value as string })}
            multiple
            renderValue={(selected): string => selected.join(", ")}
            size="small"
          >
            {getOptions("Osaaminen").map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox checked={personnelFilters.skills.map((option) => { return option.value }).indexOf(option) !== -1} />
                <ListItemText primary={option} disableTypography={true} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <FormLabel id="language-label">{t('resourceSearch.filters.languages')}</FormLabel>
          <Select
            labelId="language-select-label"
            id="language-select"
            label="language"
            onChange={handlePersonnelSelectFilterChange}
            name="language"
            value={personnelFilters.languages.map((option) => { return option.value as string })}
            multiple
            renderValue={(selected): string => selected.join(", ")}
            size="small"
          >
            {getOptions("Kielitaito").map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox checked={personnelFilters.languages.map((option) => { return option.value }).indexOf(option) !== -1} />
                <ListItemText primary={option} disableTypography={true} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <FormLabel id="company-label">{t('resourceSearch.resourceList.company')}</FormLabel>
          <Select
            labelId="company-select-label"
            id="company-select"
            label="company"
            onChange={handlePersonnelSelectFilterChange}
            name="company"
            value={personnelFilters.companies.map((company) => { return company.name as string })}
            multiple
            renderValue={(selected): string => selected.join(", ")}
            size="small"
          >
            {companies.map((company) => (
              <MenuItem key={company.name} value={company.name}>
                <Checkbox checked={personnelFilters.companies.indexOf(company) !== -1} />
                <ListItemText primary={company.name} disableTypography={true} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <FormLabel id="driverslicense-label">{t('resourceSearch.filters.driversLicenceClasses')}</FormLabel>
          <Select
            labelId="driverslicense-select-label"
            id="driverslicense-select"
            label="driverslicense"
            onChange={handlePersonnelSelectFilterChange}
            name="driverslicense"
            value={personnelFilters.driversLicenseClasses.map((option) => { return option.value as string })}
            multiple
            renderValue={(selected): string => selected.join(", ")}
            size="small"
          >
            {getOptions("Ajokorttiluokka").map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox checked={personnelFilters.driversLicenseClasses.map((option) => { return option.value }).indexOf(option) !== -1} />
                <ListItemText primary={option} disableTypography={true} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={1.5}>
        <FormControl fullWidth>
          <FormLabel id="daily-price-min-label">{t('resourceSearch.filters.dailyPriceMin')}</FormLabel>
          <TextField
            id="daily-price-min-textfield"
            onChange={handlePersonnelFilterChange}
            name="dailyPriceMin"
            value={(personnelFilters.dailyPriceMin > 0) ? personnelFilters.dailyPriceMin : ""}
            size="small"
        />
        </FormControl>
      </Grid>
      <Grid item xs={1.5}>
        <FormControl fullWidth>
          <FormLabel id="daily-price-max-label">{t('resourceSearch.filters.dailyPriceMax')}</FormLabel>
          <TextField
            id="daily-price-max-textfield"
            onChange={handlePersonnelFilterChange}
            name="dailyPriceMax"
            value={(personnelFilters.dailyPriceMax > 0) ? personnelFilters.dailyPriceMax : ""}
            size="small"
          />
        </FormControl>
      </Grid>
      <Grid item xs={1.5}>
        <FormControl fullWidth>
          <FormLabel id="distance-min-label">{t('resourceSearch.filters.distanceMin')}</FormLabel>
          <TextField
            id="distance-min-textfield"
            onChange={handlePersonnelFilterChange}
            name="distanceMin"
            value={(personnelFilters.distanceMin > 0) ? personnelFilters.distanceMin : ""}
            size="small"
          />
        </FormControl>
      </Grid>
      <Grid item xs={1.5}>
        <FormControl fullWidth>
          <FormLabel id="distance-max-label">{t('resourceSearch.filters.distanceMax')}</FormLabel>
          <TextField
            id="distance-max-textfield"
            onChange={handlePersonnelFilterChange}
            name="distanceMax"
            value={(personnelFilters.distanceMax > 0) ? personnelFilters.distanceMax : ""}
            size="small"
        />
        </FormControl>
      </Grid>
    </Grid>
  )
}
