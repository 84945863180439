import { Typography, Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { Main } from '../layout';
import { OrderList } from '../components/orders/'
import { useTranslation } from 'react-i18next';
export const Orders: React.FC = () => {
  const { t } = useTranslation();
  const renderOrders = useMemo(() => (
    <OrderList />
  ), []);
  return (
    <Main>
      <Grid container display="flex" m={2} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">{t('orders.title')}</Typography>
          <Typography variant="subtitle1">{t('orders.subTitle')}</Typography>
        </Grid>
        <Grid item xs={12} mt={3}>
          {renderOrders}
        </Grid>
      </Grid>
    </Main>
  )
};
