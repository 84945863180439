import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import Api from '../api';
import { ResourceAttributeOptionResponse, ResourceAttributeOption} from '../types';
import { endpoints } from '../utils';

const initialAllResourceAttributeOptions : ResourceAttributeOption[] = [];

const initialState = {
  allResourceAttributeOptions: initialAllResourceAttributeOptions,
  setResourceAttributeOptions: (): void => {},
  fetchAllResourceAttributeOptions: (): void => { },
  allResourceAttributeOptionsLoading: false,
  setResourceAttributeOptionsLoading: (): void => { },
}

interface AllResourceAttributeOptionsContextType {
  allResourceAttributeOptions: ResourceAttributeOption[] ;
  setResourceAttributeOptions: Dispatch<SetStateAction<ResourceAttributeOption[]>>;
  fetchAllResourceAttributeOptions: () => void;
  allResourceAttributeOptionsLoading: boolean;
  setResourceAttributeOptionsLoading: Dispatch<SetStateAction<boolean>>;
}

interface Props {
  children: React.ReactNode;
}
const AllResourceAttributeOptionsContext = createContext<AllResourceAttributeOptionsContextType>(initialState);

export const useAllResourceAttributeOptionsContext = (): AllResourceAttributeOptionsContextType => useContext(AllResourceAttributeOptionsContext);

export const AllResourceAttributeOptionsContextProvider: React.FC<Props> = ({ children }) => {
  const [allResourceAttributeOptions,setResourceAttributeOptions] = useState<ResourceAttributeOption[]>(initialAllResourceAttributeOptions);
  const [allResourceAttributeOptionsLoading, setResourceAttributeOptionsLoading] = useState<boolean>(false);

  const fetchAllResourceAttributeOptions = useCallback(
    ()=> {
      (async (): Promise<void> => {
        setResourceAttributeOptionsLoading(true);
        const { data } = await Api.get<ResourceAttributeOptionResponse>(endpoints.resourceAttributeOptions,{
          params:{
            limit:2000
          }
        });
        const {results} = data;
        setResourceAttributeOptions(results);
        setResourceAttributeOptionsLoading(false);
      })();
  },[]);

  return (
    <AllResourceAttributeOptionsContext.Provider
      value={{
        setResourceAttributeOptions,
        allResourceAttributeOptions,
        fetchAllResourceAttributeOptions,
        allResourceAttributeOptionsLoading,
        setResourceAttributeOptionsLoading
      }}
    >
      {children}
    </AllResourceAttributeOptionsContext.Provider>
  );

}
