import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid, Tab, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ResourceList, ResourceCreateDialog } from './index';
import { useAuthContext, useCompanyContext, usePaginationContext, useResourceContext } from '../../context';

import { useProgress } from '../../hooks';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { colors } from '../../theme';
import {
  CompanyContactsResponse,
  CompanyLocationsResponse,
  ResourceTypesResponse,
} from '../../types';
import Api from '../../api';
import { endpoints, initialPagingOffset } from '../../utils';
export const Resources: React.FC = () => {
  const { t } = useTranslation();
  const { StyledProgress } = useProgress();
  const { resources, selectedResourceId, setResourceTypes, resourceTypes, 
  fetchResources, totalCount, resourceLoading } = useResourceContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>('1');
  const { setCompanyContacts, setCompanyLocations } = useCompanyContext();
  const { profileData } = useAuthContext();
  const defaultCompany = profileData.company;
  const { offset, limit, setOffset } = usePaginationContext();
  const [selectedMyResourceType, setSelectedMyResourceType] = useState<number>(0);

  const handleTabChange = useCallback(
    (event: SyntheticEvent, newValue: string): void => {
      const selectedResourceType = resourceTypes[Number(newValue) - 1];
      const { id } = selectedResourceType;
      setValue(newValue);
      setOffset(initialPagingOffset);  
      setSelectedMyResourceType(Number(id))
    },
    [setOffset, resourceTypes],
  );

  // fetch resources
  useEffect(()=>{
    //setLoading(true);
    if(!!defaultCompany){      
      fetchResources(Number(defaultCompany), offset, limit,selectedMyResourceType);
    }
  },[defaultCompany, limit, offset, selectedMyResourceType, fetchResources]); 
  
  // get all company contacts
  useEffect(() => {
    (async (): Promise<void> => {
      setLoading(true);
      const { data } = await Api.get<CompanyContactsResponse>(endpoints.contacts, {
        params: {
          company: `${defaultCompany}`,
        },
      });
      const { results } = data;
      setLoading(false);
      setCompanyContacts(results);
    })();
  }, [defaultCompany, setCompanyContacts]);

  // get CompanyLocations
  useEffect(() => {
    (async (): Promise<void> => {
      setLoading(true);
      const { data } = await Api.get<CompanyLocationsResponse>(endpoints.companyLocations, {
        params: {
          company: `${defaultCompany}`,
        },
      });
      const { results } = data;
      setLoading(false);
      setCompanyLocations(results);
    })();
  }, [defaultCompany, setCompanyLocations]);

  // get resourceTypes
  useEffect(() => {
    (async (): Promise<void> => {
      setLoading(true);
      const { data } = await Api.get<ResourceTypesResponse>(endpoints.resourceTypes);
      const { results } = data;
      const combinedResourceTypes = [{ id: 0, name: t('myResources.allResources') }, ...results];
      setLoading(false);
      setResourceTypes(combinedResourceTypes);
    })();
  }, [t, setResourceTypes]);

  const renderResourceTab = useMemo(
    () => (
      <TabContext value={value}>
        <Box>
          <TabList
            onChange={handleTabChange}
            aria-label="resource tabs"
            TabIndicatorProps={{
              style: {
                backgroundColor: colors.darkBlue,
              },
            }}
          >
            {resourceTypes.map((item, index) => (
              <Tab key={`${item.id}`} label={item.name} value={`${index + 1}`} />
            ))}
          </TabList>
        </Box>
        {resourceTypes.map((item, index) => (
          <TabPanel key={`${item.id}`} value={`${index + 1}`} sx={{pl:0, pr:0}}>
            <ResourceList resources={resources} loading={loading || resourceLoading} totalCount={totalCount} />
          </TabPanel>
        ))}
      </TabContext>
    ),
    [resourceTypes, handleTabChange, value, loading, resources, totalCount, resourceLoading],
  );
 
  const renderResources = useMemo(
    () => (
      <Grid container display="flex" m={2} spacing={2}>
        {(loading || resourceLoading) && <StyledProgress size={24} />}
        <Grid item xs={10}>
          <Typography variant="h3"> {t('myResources.title')}</Typography>
          <Typography variant="subtitle1"> {t('myResources.subTitle')}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Grid item xs={12} container justifyContent="flex-end">
            <ResourceCreateDialog
              resourceId={`${selectedResourceId}`}
              resourceTypes={resourceTypes.filter((item) => item.id !== 0)}
              selectedMyResourceType={selectedMyResourceType}
              company={Number(defaultCompany)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} mt={5}>
          {renderResourceTab}
        </Grid>
      </Grid>
    ),
    [t, selectedResourceId, StyledProgress, loading, renderResourceTab, resourceTypes, resourceLoading, defaultCompany, selectedMyResourceType],
  );

  return <>{renderResources}</>;
};
