import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Button, Container, FormControl, Grid, Link, Paper, styled, TextField, Typography } from '@mui/material';
import { LanguageNavbar } from '../components/common';
import { sendPasswordResetLink } from '../firebase';
import { urls } from '../utils';

export const ResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const submitButtonDisabled = email.length < 1;

  const handleSubmit = useCallback(
    async (e: SyntheticEvent): Promise<void> => {
      e.preventDefault();
      sendPasswordResetLink(email)
        .then(() => setSuccess(true))
        .catch(error => {
          setSuccess(false);
          setError(error.message);
        });
    },
    [email],
  );

  const handleEmailAddressChange = (e: string): void => {
    setEmail(e.toLowerCase());
  };

  const StyledLink = styled(Link)(({ }) => ({
    fontSize: 14,
    fontWeight: 500,
  }));

  const renderErrorMessage = useMemo(
    () =>
      <Grid item xs={12}>
        <Box marginBottom={3}>
          <Alert severity="error">{t('misc.resetPasswordErrorMessage')}</Alert>
        </Box>
      </Grid>
    ,[t],
  );

  const renderSuccessMessage = useMemo(
    () =>
      <Paper>
        <Grid container p={4}>
          <Grid item xs={12} textAlign='center'>
            <Typography>{t('misc.resetPasswordSuccessMessage')}:</Typography>
            <Typography mt={2} variant="h5">{email}</Typography>
          </Grid>
        </Grid>
      </Paper>
    ,[t, email],
  );

  const renderForm = useMemo(
    () =>
      <Paper>
        <Box p={4} pb={2}>
          <Typography variant="h1">{t('misc.resetPasswordFormTitle')}</Typography>
        </Box>
        <Box p={4} pt={2} pb={2}>
          <Typography variant="body2">{t('misc.resetPasswordFormText')}</Typography>
        </Box>
        <form data-testid="login">
          <Grid container spacing={4} p={4}>
            {error && renderErrorMessage}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <>
                  <TextField
                    fullWidth
                    data-testid="login-email"
                    label={t('misc.email')}
                    name="email"
                    required
                    placeholder={t('misc.resetPasswordEmailFieldPlaceholder')}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e): void => handleEmailAddressChange(e.target.value)}
                  />
                </>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                disabled={submitButtonDisabled}
                onClick={handleSubmit}
                variant="contained"
              >
                {t('misc.resetPasswordSubmitButtonLabel')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    ,[t, handleSubmit, submitButtonDisabled, error, renderErrorMessage],
  );

  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Container maxWidth="sm" sx={{ maxWidth: 'sm', padding: 3, alignItems: 'center', justifyContent: 'center' }}>
        <Box component="img" alt="Logo" src="/images/3Pool_logo.png" sx={{ mt: 10, mb: 6 }}/>

        {success ? renderSuccessMessage : renderForm}

        <Grid container mt={3} mb={3}>
          <Grid item xs={12} textAlign='center'>
            <StyledLink href={urls.login} underline="hover">
              {t('misc.resetPasswordBackToLoginLink')}
            </StyledLink>
          </Grid>
        </Grid>
        <LanguageNavbar />
      </Container>
    </Box>
  );
};
