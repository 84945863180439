import { createTheme } from '@mui/material';
import "@fontsource/montserrat"
export const colors = {
  white: '#ffffff',
  black: '#000000',
  gray: '#808080',
  lightgray: '#EEEEEE',
  darkBlue: '#123274',
  textBlack: '#1b1924',
  primaryTextGray: '#101828',
  secondaryTextGray: '#344054',
  textWhite: '#FFFFFF',
  primary: '#f45c61',
  primaryGradient: 'linear-gradient(to right, #f45c61, #e86a3c)',
  primaryLight: '#f67c80',
  primaryDark: '#aa4043',
  secondary: '#15cbae',
  secondaryGradient: 'linear-gradient(to left, #15cbae, #34c163)',
  secondaryLight: '#43d5be',
  secondaryDark: '#0e8e79',
  background: '#FCFCFD',
  alert: '#931318',
  alertDark: '#751417',
  asteriskColor: '#ff0000',
  border: '#0c9deb',
  lightBlue:'#0062cc',
};

export const borderRadius = {
  borderRadiusDefault: 4,
  borderRadiusSmall: 2,
  borderRadiusLarge: 15,
};

export const spacing = (factor: number): string => `${0.5 * factor}rem`;

const _palette = {
  common: {
    black: colors.black,
    white: colors.white,
  },
  primary: {
    light: colors.primaryLight,
    main: colors.primary,
    dark: colors.primaryDark,
    contrastText: colors.white,
  },
  secondary: {
    light: colors.secondaryLight,
    main: colors.secondary,
    dark: colors.secondaryDark,
    contrastText: colors.white,
  },
  text: {
    primary: colors.primaryTextGray,
    secondary: colors.secondaryTextGray,
  },
  background: {
    paper: colors.white,
    default: colors.background,
  },
  error: {
    main: colors.alert,
  },
};
const _font = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
};
export const theme = createTheme({
  palette: _palette,
  typography: {
    fontSize: 16,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightBold: 600,
    fontFamily: _font.fontFamily,
    h1: {
      fontSize: 26,
      fontWeight: 600,
      letterSpacing: '0em',
      fontStyle: _font.fontStyle,
      color: colors.primaryTextGray,
    },
    h2: {
      fontSize: 22,
      fontWeight: 600,
      letterSpacing: '0em',
      fontStyle: _font.fontStyle,
    },
    h3: {
      fontSize: 20,
      fontWeight: 600,
      fontStyle: _font.fontStyle,
    },
    h4: {
      fontSize: 18,
      fontWeight: 600,
      fontStyle: _font.fontStyle,
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
      fontStyle: _font.fontStyle,
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,
      fontStyle: _font.fontStyle,
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: 13,
      fontWeight: 400,
    },
    body1: {
      fontSize: 18,
      fontWeight: 400,
    },
    body2: {
      fontSize: 16,
      fontWeight: 400,
    },
    button: {
      fontWeight: 400,
      fontSize: 18,
      textTransform: 'none',
    },
    caption: {
      fontSize: 15,
      fontWeight: 400,
    },
    overline: {
      fontSize: 16,
      fontWeight: 400,
    },
  },
  shape: {
    borderRadius: borderRadius.borderRadiusDefault,
  },
  spacing,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          gap: 8,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          fontFamily: _font.fontFamily,
          fontStyle: _font.fontStyle,
          fontWeight: 500,
          fontSize: 16,
          '&:hover': {
            backgroundColor: colors.darkBlue,
          },
        },
        text: {
          color: colors.secondaryTextGray,
          '&:hover': {
            backgroundColor: colors.lightgray,
            color: colors.darkBlue,
          },
        },
        contained: {
          backgroundColor: colors.darkBlue,
          boxShadow: '0px 1px 2px',
          padding: '10px 18px',
          disabled: {
            backgroundColor: colors.darkBlue,
          },
        },
        sizeSmall: {
          padding: '3px 10px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          fontFamily: _font.fontFamily,
          fontStyle: _font.fontStyle,
          color: colors.secondaryTextGray,
          backgroundColor: colors.white,
          fontWeight: 500,
          fontSize: 14,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: _font.fontFamily,
          fontStyle: _font.fontStyle,
          color: colors.darkBlue,
          cursor: 'pointer',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: colors.background,
            color: colors.primaryTextGray,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&$focused': {
            color: colors.textBlack,
          },
        },
        asterisk: {
          color: colors.asteriskColor,
        },
        shrink: {
          paddingLeft: spacing(1),
          paddingRight: spacing(1),
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
        variant: 'outlined',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
          '&$focused': {
            color: colors.textBlack,
          },
        },
        asterisk: {
          color: colors.asteriskColor,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize:16
        }
      }
    },
    MuiListItemText: {
      styleOverrides:{
        root: {
          fontSize:16
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: colors.darkBlue,
          fontSize: 14
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: colors.lightgray,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderColor: colors.darkBlue,
          borderRadius: borderRadius.borderRadiusLarge,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: colors.darkBlue,
        },
      },
    },
  },
});
