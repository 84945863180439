import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import Api from '../api';

import { Resource, ResourceType, ResourceAttributeOption, ResourceResponse } from '../types';
import { endpoints } from '../utils';
import { useTranslation } from 'react-i18next';

const initialResources: Resource[] = [];
const initialSelectedResourceId: string | number = '0';
const initialIsResourceFormOpen = false;
const initialResourceTypes: ResourceType[] = [];
const intitalSelectedResourceTypeId: string | number = '0';
const intitalSelectedResourceSubTypeId: string | number = '0';
const initialAttributeOptions: ResourceAttributeOption[] = [];
const initialDisableAttrTab= true;
const initialDisableCalTab= true;
const initialTotalCount = 30;
const initialResourceLoading = false;
const initialState = {
  resources: initialResources,
  setResources: (): void => {},
  selectedResourceId: initialSelectedResourceId,
  isResourceFormOpen: initialIsResourceFormOpen,
  setOpen: (): void => {},
  setSelectedResourceId: (): void => {},
  resourceTypes: initialResourceTypes,
  selectedResourceTypeId: intitalSelectedResourceTypeId,
  selectedResourceSubTypeId: intitalSelectedResourceSubTypeId,
  setResourceTypes: (): void => {},
  setSelectedResourceTypeId: (): void => {},
  setSelectedResourceSubTypeId: (): void => {},
  attributeOptions: initialAttributeOptions,
  setAttributeOptions: (): void => {},
  setDisableAttrTab: (): void => {},
  setDisableCalTab: (): void => {},
  disableAttrTab: initialDisableAttrTab,
  disableCalTab: initialDisableCalTab,
  createResource: (): void => { },
  removeResource: (): void => { },
  fetchResources: (): void => { },
  updateResource: (): void => { },
  totalCount: initialTotalCount,
  setTotalCount: (): void => {},
  resourceLoading: initialResourceLoading,
  setResourceLoading: (): void => { },
};

interface ResourceContextType {
  resources: Resource[];
  resourceTypes: ResourceType[];
  selectedResourceTypeId: string | number;
  selectedResourceSubTypeId: string | number;
  setResources: Dispatch<SetStateAction<Resource[]>>;
  selectedResourceId: string | number;
  isResourceFormOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedResourceId: Dispatch<SetStateAction<string | number>>;
  setResourceTypes: Dispatch<SetStateAction<ResourceType[]>>;
  setSelectedResourceTypeId: Dispatch<SetStateAction<string | number>>;
  setSelectedResourceSubTypeId: Dispatch<SetStateAction<string | number>>;
  attributeOptions: ResourceAttributeOption[];
  setAttributeOptions: Dispatch<SetStateAction<ResourceAttributeOption[]>>;
  disableAttrTab: boolean;
  disableCalTab: boolean;
  setDisableAttrTab: Dispatch<SetStateAction<boolean>>;
  setDisableCalTab: Dispatch<SetStateAction<boolean>>;
  createResource: (resource: Resource, enqueueSnackbar: any) => void;
  removeResource: (resourceId: number, company: number, offset: number, limit: number, resourceType: number, enqueueSnackbar: any) => void;
  fetchResources: (company: number, offset: number, limit: number, resourceType: number) => void;
  updateResource: (resourceId: number, resource: Resource, company: number, offset: number, limit: number, resourceType: number) => void;
  totalCount: number;
  setTotalCount: Dispatch<SetStateAction<number>>;
  resourceLoading: boolean;
  setResourceLoading: Dispatch<SetStateAction<boolean>>;
}

interface Props {
  children: React.ReactNode;
}
const ResourceContext = createContext<ResourceContextType>(initialState);

export const useResourceContext = (): ResourceContextType => useContext(ResourceContext);

export const ResourceContextProvider: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const [resources, setResources] = useState<Resource[]>(initialResources);
  const [resourceTypes, setResourceTypes] = useState<ResourceType[]>(initialResourceTypes);
  const [isResourceFormOpen, setOpen] = useState<boolean>(initialIsResourceFormOpen);
  const [selectedResourceId, setSelectedResourceId] = useState<string | number>(initialSelectedResourceId);
  const [selectedResourceTypeId, setSelectedResourceTypeId] = useState<string | number>(intitalSelectedResourceTypeId);
  const [selectedResourceSubTypeId, setSelectedResourceSubTypeId] = useState<string | number>(
    intitalSelectedResourceSubTypeId,
  );
  const [attributeOptions, setAttributeOptions] = useState<ResourceAttributeOption[]>(initialAttributeOptions);
  const [disableAttrTab, setDisableAttrTab] = useState<boolean>(initialDisableAttrTab);
  const [disableCalTab, setDisableCalTab] = useState<boolean>(initialDisableCalTab);
  const [totalCount, setTotalCount] = useState<number>(initialTotalCount);
  const [resourceLoading, setResourceLoading] = useState<boolean>(initialResourceLoading);

  const fetchResources = useCallback(
    (company: number, offset: number, limit: number, resourceType: number)=> {
      (async (): Promise<void> => {
        setResourceLoading(true);
        const { data } = await Api.get<ResourceResponse>(endpoints.resources,{
          params:{
            company: `${company}`,
            offset,
            limit,
            ...(resourceType > 0 && {_type: resourceType})
          }
        });
        const {results, count} = data;
        setResources(results);
        setTotalCount(count);
        setResourceLoading(false);
      })();
    },[]);
    
  const removeResource = useCallback(
    (resourceId: number , company: number, offset: number, limit: number, resourceType: number, enqueueSnackbar: any)=> {
      (async (): Promise<void> => {
        try{
          setResourceLoading(true);
          await Api.delete<Resource>(endpoints.resource(Number(resourceId)));
          fetchResources(company, offset, limit, resourceType);
          setResourceLoading(false);
          enqueueSnackbar(t('myResources.resourceDeleteSuccess'), {
            variant: 'success',
          });
        }catch(err:any){
          enqueueSnackbar(t('myResources.resourceDeleteError'), {
            variant: 'error',
          });
          setResourceLoading(false);
        }    
      })();
    },[fetchResources, t]);

  const updateResource = useCallback(
    (resourceId: number , resource: Resource, company: number, offset: number, limit: number, resourceType: number)=> {
      (async (): Promise<void> => {
        setResourceLoading(true);
        const {data} = await Api.patch<Resource>(endpoints.resource(Number(resourceId)), resource);
        setSelectedResourceId(data.id);
        fetchResources(company, offset, limit, resourceType);
        setResourceLoading(false);
      })();
    },[fetchResources]);

  const createResource = useCallback(
    (resource: Resource, enqueueSnackbar:any)=> {
      (async (): Promise<void> => {
        try{
          setResourceLoading(true);
          const {data} = await Api.post<Resource>(endpoints.resources, resource);
          setSelectedResourceId(data.id);
          setSelectedResourceSubTypeId(data.subtype);
          setResources([...resources, data]);
          setDisableAttrTab(false);
          setDisableCalTab(false);  
          setResourceLoading(false);
          enqueueSnackbar(t('myResources.resouceCreateSuccess'), {
            variant: 'success',
          });
        }catch(err: any){
          enqueueSnackbar(t('myResources.resourceCreateError'), {
            variant: 'error',
          });
        }  
      })();
    },[t, resources]);

  return (
    <ResourceContext.Provider
      value={{
        setResources,
        resources,
        isResourceFormOpen,
        setOpen,
        selectedResourceId,
        setSelectedResourceId,
        resourceTypes,
        selectedResourceSubTypeId,
        selectedResourceTypeId,
        setResourceTypes,
        setSelectedResourceTypeId,
        setSelectedResourceSubTypeId,
        attributeOptions,
        setAttributeOptions,
        disableAttrTab,
        disableCalTab,
        setDisableAttrTab,
        setDisableCalTab,
        createResource,
        removeResource,
        fetchResources,
        updateResource,
        totalCount,
        setTotalCount,
        resourceLoading,
        setResourceLoading
      }}
    >
      {children}
    </ResourceContext.Provider>
  );
};
