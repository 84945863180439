import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

import { CompanyInfo } from '../types';
const initialCompanies: CompanyInfo[] = [];

const initialState = {
  companies: initialCompanies,
  setCompanies: (): void => {},
};

interface CompaniesContextType {
  companies: CompanyInfo[];
  setCompanies: Dispatch<SetStateAction<CompanyInfo[]>>;
}
  
interface Props {
  children: React.ReactNode;
}
const CompaniesContext = createContext<CompaniesContextType>(initialState);

export const useCompaniesContext = (): CompaniesContextType => useContext(CompaniesContext);

export const CompaniesContextProvider: React.FC<Props> = ({ children }) => {
  const [companies, setCompanies] = useState<CompanyInfo[]>(initialCompanies);
  return (
    <CompaniesContext.Provider
      value={{
        companies,
        setCompanies
      }}
    >
      {children}
    </CompaniesContext.Provider>
  );
};