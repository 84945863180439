import { useCallback, useMemo } from 'react';
import i18n from '../i18n';
interface Countries {
  FI: string;
  SE: string;
  GB: string;
}
interface Languages {
  finnish: string;
  swedish: string;
  english: string;
}
interface UseLanguageTypes {
  toggleLanguage: (locale: string) => void;
  countryToLocaleMapper: Countries;
  languages: Languages;
  getSelectedLocale: (country: string) => string;
}
export const useLanguage = (): UseLanguageTypes => {
  const countryToLocaleMapper = useMemo(
    () => ({
      FI: 'fi',
      SE: 'sv',
      GB: 'en',
    }),
    [],
  );
  const languages = useMemo(
    () => ({
      finnish: 'fi',
      swedish: 'sv',
      english: 'en',
    }),
    [],
  );
  const getSelectedLocale = useCallback(
    (selectedCountry: string) => {
      const selectedLocale = Object.entries(countryToLocaleMapper).find((item) => item[0] === selectedCountry);
      if (selectedLocale) {
        return selectedLocale[1];
      } else {
        return 'fi';
      }
    },
    [countryToLocaleMapper],
  );
  const toggleLanguage = useCallback((locale: string) => i18n.changeLanguage(locale), []);
  return {
    toggleLanguage,
    countryToLocaleMapper,
    languages,
    getSelectedLocale,
  };
};
