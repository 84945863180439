import React, { useMemo, useCallback } from 'react';
import { Box, Grid } from '@mui/material';
import ReactFlagsSelect, { Fi, Gb, Se } from 'react-flags-select';
import { useTranslation } from 'react-i18next';
interface Props {
  selectedLanguage: string;
  setLaguage: (language: string) => void;
}
export const LanguageSelector: React.FC<Props> = ({ selectedLanguage, setLaguage }) => {
  const { t } = useTranslation();
  const flags = useMemo(
    () => ({
      fi: <Fi />,
      sv: <Se />,
      en: <Gb />,
    }),
    [],
  );
  const getDefaultFlag = useCallback(
    (selectedLanguage: string) => {
      const defaultFlag = Object.entries(flags).find((item) => item[0] === selectedLanguage);
      return defaultFlag && defaultFlag[1];
    },
    [flags],
  );

  return (
    <Grid container>
      <Grid item xs>
        <Box borderRadius={20}>
          <ReactFlagsSelect
            countries={['FI', 'SE', 'GB']}
            customLabels={{ FI: t('lang.fi'), GB: t('lang.en'), SE: t('lang.sv') }}
            placeholder={getDefaultFlag(selectedLanguage)}
            onSelect={(c: string): void => setLaguage(c)}
            selected={selectedLanguage}
            showSelectedLabel={false}
            showOptionLabel={false}
            fullWidth={false}
            selectedSize={24}
            optionsSize={24}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
