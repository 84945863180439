import { Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Main } from '../layout';
export const UserSetting: React.FC = () => {
  const renderUserSetting = useMemo(
    () => <Typography variant="h5"> {`Usersetting page comming soon ....`}</Typography>,
    [],
  );
  return <Main>{renderUserSetting}</Main>;
};
