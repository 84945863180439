import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  styled,
  Link,
  FormHelperText,
  FormControl,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuthContext, useAdvertsContext } from '../context';
import { urls } from '../utils/constants';
import { LanguageNavbar, Slider } from '../components/common';
import * as zod from 'zod';
import { loginSchema } from '../utils';
import { useProgress } from '../hooks';
import LoginIcon from '@mui/icons-material/Login';
import { colors } from '../theme';

const StyledBox = styled(Box)(({}) => ({
  display: 'flex',
  justifyContent: 'flex-end'
}));
const StyledLink = styled(Link)(({}) => ({
  fontSize: 14,
  fontWeight: 500,
}));

const StyledContainer = styled(Box)(({}) => ({
  width: "auto",
  height: "auto",
  margin: "0 auto",
}));
export const Login: React.FC = () => {
  const { t } = useTranslation();
  const { StyledProgress } = useProgress();
  const { loading, error, login } = useAuthContext();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [formErrors, setFormErrors] = useState<zod.ZodIssue[]>([]);
  const {adverts,fetchAdverts} = useAdvertsContext();
  
  // fetch front adverts
  useEffect(()=>{
    fetchAdverts(true, false);
  },[fetchAdverts]);

  useEffect(() => {
    setButtonDisabled(loading || !(email.length > 0 && password.length > 0));
  }, [loading, email.length, password.length]);

  const hasError = (key: string): boolean => {
    if (formErrors.length > 0 && !error) {
      return formErrors.some((e) => e.path[0] === key);
    } else {
      return false;
    }
  };

  const renderFormErrorHelperText = (key: string, translationID: string): JSX.Element | void => {
    if (hasError(key)) {
      return <FormHelperText><Alert severity="error">{t(translationID)}</Alert></FormHelperText>;
    }
  };

  const handleLogin = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    try {
      loginSchema.parse({
        email,
        password,
      });
      login(email, password);
    } catch (err) {
      if (err instanceof zod.ZodError) {
        setFormErrors(err.issues);
      }
    }
  };

  const handleEmailChange = (e: string): void => {
    setEmail(e.toLowerCase());
    setButtonDisabled(!(email.length > 0 && password.length > 0));
  };

  const handlePasswordChange = (e: string): void => {
    setPassword(e);
    setButtonDisabled(!(email.length > 0 && password.length > 0));
  };
  const renderLoginError = error && (
    <Grid item xs={12} data-testid="login-error">
      <Box marginBottom={3}>
        <Alert severity="error">{t('misc.loginError')}</Alert>
      </Box>
    </Grid>
  );

  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Container sx={{ maxWidth:"1140px", p:0 }} disableGutters>
        <Grid container sx={{backgroundColor: colors.darkBlue}}>
          <Grid item xs={12} display="flex"  justifyContent="flex-end">
            <LanguageNavbar />
          </Grid>
        </Grid>
        <Grid container display="flex" sx={{ pl:3, pb:3, pr:2}}>
          <Grid item xs={4}>
            <Box
              component="img"
              alt="Logo"
              src="/images/3Pool_logo.png"
              sx={{
                padding: 3
              }}
            /> 
          </Grid>
          <Grid item xs={8} container>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <form data-testid="login">
                <Box display="flex" sx={{ gap: 2, pt:3}}>
                  <FormControl error={hasError('email')}>
                    <TextField
                      size='small'
                      data-testid="login-email"
                      name="email"
                      required
                      disabled={loading}
                      placeholder={t('misc.emailPlaceHolder')}
                      error={hasError('email')}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        'data-testid': 'email',
                      }}
                      onChange={(e): void => handleEmailChange(e.target.value)}
                    />   
                  </FormControl>
                  <FormControl error={hasError('password')}>  
                    <TextField
                      size='small'
                      data-testid="login-password"
                      name="password"
                      type="password"
                      required
                      disabled={loading}
                      InputLabelProps={{ shrink: true }}
                      placeholder={t('misc.password')}
                      error={hasError('password')}
                      inputProps={{
                        'data-testid': 'password',
                      }}
                      onChange={(e): void => handlePasswordChange(e.target.value)}
                    />
                  </FormControl>
                  <Button
                    size='small'
                    data-testid="login-button"
                    type="submit"
                    disabled={buttonDisabled}
                    onClick={handleLogin}
                    variant="contained"
                  >
                    <LoginIcon />
                    {loading && <StyledProgress size={24} />}
                  </Button>
                </Box>
                <Box sx={{pl:3, pr:3}}>
                  <>
                  {renderFormErrorHelperText('email', 'formValidation.invalidEmail')}
                  {renderFormErrorHelperText('password', 'formValidation.passwordLength')}
                  {renderLoginError}
                  </>
                </Box>
              </form>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <StyledBox>
                <StyledLink href={urls.resetPassword} underline="hover">
                  {t('misc.resetPassword')}
                </StyledLink>
              </StyledBox>
            </Grid>   
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography 
            display="flex" 
            justifyContent="center"
            variant='h6'
            sx={{mb:3}}
          >
          </Typography>
          <StyledContainer>
            <Slider slides={adverts}/>
          </StyledContainer>
        </Grid>
        <Grid item xs={12} container spacing={0.5} mt={3} sx={{pb:3, pt:3}}>
          <Grid item xs={4}>
            <Card sx={{height: "100%"}}>
              <CardHeader title={t('misc.newCustomerTitle')} />
              <CardContent>
                <Typography
                  variant='body2'
                  sx={{
                    mb:1
                  }}
                >
                {t('misc.newCustomerContent.freeText')}
                </Typography>
                <Typography
                  variant='body2'
                  sx={{
                    mb:1,
                    ml:3
                  }}
                >
                {t('misc.newCustomerContent.subHeader')}
                </Typography>
                <Typography
                  variant='body2'
                  sx={{
                    mb:1,
                    ml:3
                  }}
                >
                {t('misc.newCustomerContent.contactName')}
                </Typography>
                <Typography
                  variant='body2'
                  sx={{
                    mb:1,
                    ml:3
                  }}
                >
                {t('misc.newCustomerContent.phoneNumber')}
                </Typography>
                <Typography
                  variant='body2'
                  sx={{
                    mb:1,
                    ml:3
                  }}
                >
                {t('misc.newCustomerContent.email')}
                </Typography>
                <Typography
                  variant='body2'
                  sx={{
                    ml:3
                  }}
                >
                {t('misc.newCustomerContent.linkFreeText')}: <Link 
                  href={t('misc.newCustomerContent.link')} 
                  underline="hover" 
                  target="_blank" 
                  rel="noreferrer"
                >
                  {t('misc.newCustomerContent.linkName')}
                </Link>
                </Typography>
              </CardContent>
            </Card>            
          </Grid>
          <Grid item xs={4}>
            <Card sx={{height: "100%"}}>
              <CardHeader title={t('misc.supportContactTitle')} />
              <CardContent>
                <Typography
                  variant='body2'
                  sx={{
                    mb:1
                  }}
                >
                {t('misc.supportContactContent.freeText')}
                </Typography>
                <Typography
                  variant='body2'
                  sx={{
                    mb:1,
                    ml:3
                  }}
                >
                {t('misc.supportContactContent.phoneNumber')}
                </Typography>
                <Typography
                  variant='body2'
                  sx={{
                    ml:3
                  }}
                >
                {t('misc.supportContactContent.email')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card sx={{height: "100%"}}>
              <CardHeader title={t('misc.otherInfoTitle')} />
              <CardContent>
                <Typography
                  variant='body2'
                  sx={{
                    mb:1
                  }}
                >
                {t('misc.otherInfoContent.freeText')}
                </Typography>
                <Typography
                  variant='body2'
                  sx={{
                    mb:1
                  }}
                >
                  <ul>
                    <li>{t('misc.otherInfoContent.visionOne')}</li>
                    <li>{t('misc.otherInfoContent.visionTwo')}</li>
                  </ul>
                </Typography>
                <Typography
                  variant='body2'
                >
                {t('misc.otherInfoContent.moreInfoText')} <Link 
                  href={t('misc.otherInfoContent.moreInfoLink')} 
                  underline="hover" 
                  target="_blank" 
                  rel="noreferrer">
                    {t('misc.otherInfoContent.linkName')}
                </Link>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container sx={{backgroundColor: colors.darkBlue}}>
          <Grid item xs={12} display="flex"  justifyContent="center">
            <Typography variant='subtitle2' sx={{color: colors.white}}>Copyright © 3pool Oy 2022</Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
