import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  Grid,
  styled,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import { colors } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { ContactPerson, User, Error, CompanyContactsResponse } from '../../../types';
import Api from '../../../api';
import { endpoints, urls } from '../../../utils';
import { useCompanyContext, usePaginationContext } from '../../../context';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { useSnackbar } from 'notistack';
import { DeleteDialog } from '../../common/DeleteDialog';
import { DeleteButton } from '../../common/DeleteButton';

const StyledButton = styled(Button)(() => ({
  '&:hover': {
    backgroundColor: colors.darkBlue,
  },
}));

interface Props {
  companyId: number | string;
  loading: boolean;
  setLoading: any;
  setTotalCount: any;
}

export const ContactCreateDialog: React.FC<Props> = ({ companyId, loading, setLoading, setTotalCount }) => {
  const DEFAULT_FORM_VALUES: ContactPerson = useMemo(
    () => ({
      id: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      company: companyId,
      user: {
        id: '',
        is_company_admin: undefined,
        is_staff: undefined,
      },
    }),
    [companyId],
  );

  const { t } = useTranslation();
  const [formValues, setFormValues] = useState<ContactPerson>(DEFAULT_FORM_VALUES);
  const [formErrors, setFormErrors] = useState<Error>({});
  const { companyContacts, setCompanyContacts, setOpen, setSelectedContactId, isContactFormOpen, selectedContactId } =
    useCompanyContext();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const [isDefaultValueChanged, setIsDefaultValueChanged] = useState<boolean>(false);
  const [deleteButtonVisible, setDeleteButtonVisible] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const { limit, offset } = usePaginationContext();
  // check field values before submit button is enabled
  useEffect(() => {
    setButtonDisabled(
      !isDefaultValueChanged ||
        loading ||
        ![formValues.first_name, formValues.last_name, formValues.email, formValues.phone].every(
          (item) => item.length > 0,
        ),
    );
  }, [formValues, loading, isDefaultValueChanged]);

  useEffect(() => {
    if (Number(selectedContactId) > 0) {
      const selectedContact = companyContacts.find((item) => Number(item.id) === Number(selectedContactId));
      setFormValues(selectedContact ?? DEFAULT_FORM_VALUES);
      setDeleteButtonVisible(true);
    }else{
      setDeleteButtonVisible(false);
    }
  }, [companyContacts, selectedContactId, DEFAULT_FORM_VALUES, setOpen]);

  const hasError = useCallback(
    (key: string): boolean => {
      return Object.keys(formErrors).some((item) => item === key);
    },
    [formErrors],
  );

  const renderFormErrorHelperText = useMemo(
    () =>
      (key: string): JSX.Element | void => {
        if (hasError(key)) {
          return <FormHelperText>{formErrors[key]}</FormHelperText>;
        }
      },
    [formErrors, hasError],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      // Take a deep copy to ensure the initial values are not modified
      const newFormValues = JSON.parse(JSON.stringify(formValues));

      if (e.target.value !== newFormValues[e.target.name as keyof ContactPerson]) {
        setIsDefaultValueChanged(true);
      }
      newFormValues[e.target.name as keyof ContactPerson] = e.target.value;
      if (newFormValues.user !== null) {
        // Handle User related boolean fields (is_company_admin, is_staff etc.)
        newFormValues.user[e.target.name as keyof User] = e.target.checked;
      }
      setFormValues(newFormValues);
    },
    [formValues],
  );

  const handleClose = (): void => {
    setSelectedContactId(0);
    setFormValues(DEFAULT_FORM_VALUES);
    setFormErrors({});
    setOpen(false);
  };

  const handleInvite = useCallback(
    async (e: SyntheticEvent): Promise<void> => {
      e.preventDefault();
      setFormErrors({});
      const selectedContact = companyContacts.find((item) => Number(item.id) === Number(selectedContactId)) ?? {
        email: '',
      };
      try {
        const {
          data: { invited },
        } = await Api.post(endpoints.invite(Number(selectedContactId)), {
          contact_id: selectedContactId,
          registration_url: `https://${window.location.host}${urls.register(selectedContact.email)}`,
        });
        if (Boolean(invited)) {
          enqueueSnackbar(t('misc.invitationEmailSuccess'), {
            variant: 'success',
          });
          setOpen(false);
        } else {
          enqueueSnackbar(t('misc.invitationEmailFail'), {
            variant: 'error',
          });
        }
      } catch (err: any) {
        setOpen(false);
        setFormErrors(err.response.data);
      }
    },
    [selectedContactId, setOpen, companyContacts, enqueueSnackbar, t],
  );

  const handleClickCreateNew = (): void => {
    setSelectedContactId(0);
    setFormValues(DEFAULT_FORM_VALUES);
    setOpen(true);
  };
  
  const handleDeleteDialogOpen = (): void => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = (): void => {
    setDeleteDialogOpen(false);
  };
  
  const handleUserDelete = useCallback(
    async ()=>{
      setDeleteDialogOpen(false);
      try{
        setLoading(true);
        await Api.delete(endpoints.contact(selectedContactId));
        const { data } = await Api.get<CompanyContactsResponse>(endpoints.contacts, {
          params: {
            company: `${companyId}`,
            limit,
            offset
          },
        });
        const { results, count } = data;
        setCompanyContacts(results);
        setTotalCount(count);
        setLoading(false);
        enqueueSnackbar(t('companyContacts.contactDeleteSuccess'), {
          variant: 'success',
        });
      }catch(err: any){
        enqueueSnackbar(t('companyContacts.contactDeleteError'), {
          variant: 'error',
        });
      }   
  },[selectedContactId, enqueueSnackbar, t, companyId, limit, offset, setCompanyContacts, setLoading, setTotalCount]);

  const handleSubmit = useCallback(
    async (e: SyntheticEvent): Promise<void> => {
      e.preventDefault();
      setFormErrors({});
      try {
        if (Number(selectedContactId) > 0) {
          const { data } = await Api.patch<ContactPerson>(endpoints.contact(Number(selectedContactId)), formValues);
          const updatedCompanyContacts = companyContacts.map((elm) =>
            Number(elm.id) === Number(selectedContactId) ? data : elm,
          );
          setCompanyContacts(updatedCompanyContacts);
          setOpen(false);
        } else {
          const { data } = await Api.post<ContactPerson>(endpoints.contacts, formValues);
          const { id } = data;
          if (Number(id) > 0) {
            enqueueSnackbar(t('misc.createSuccess'), {
              variant: 'success',
            });
            setCompanyContacts([...companyContacts, data]);
            setOpen(false);
          }
        }
      } catch (err: any) {
        setFormErrors(err.response.data);
      }
    },
    [formValues, companyContacts, setCompanyContacts, selectedContactId, setOpen, t, enqueueSnackbar],
  );

  const dialogTitle = useCallback(
    ():string => {
      return Number(selectedContactId) > 0  ? ` ${t('companyContacts.editDialogTitle')}`: ` ${t('companyContacts.createDialogTitle')}`;
  },[selectedContactId, t]);

  return (
    <div>
      <StyledButton
        variant="contained"
        size="small"
        disabled={loading}
        onClick={handleClickCreateNew}
        sx={{
          ml: 5,
        }}
      >
        {<AddBoxOutlinedIcon />} {` ${t('companyContacts.createButtonLabel')}`}
      </StyledButton>
      <Dialog open={isContactFormOpen}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>{dialogTitle()}</DialogTitle>
          <Divider variant="fullWidth" />
          <DialogContent sx={{ pb: 4, overflowY: 'visible' }}>
            <Grid container sx={{ p: 2 }} spacing={2}>
              <Grid item xs={6}>
                <FormControl error={hasError('first_name')}>
                  <>
                    <Typography variant="body1">{t('companyContacts.firstName')}</Typography>
                    <TextField
                      name="first_name"
                      size="small"
                      onChange={handleChange}
                      autoFocus
                      value={formValues.first_name}
                    />
                    {renderFormErrorHelperText('first_name')}
                  </>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl error={hasError('last_name')}>
                  <>
                    <Typography variant="body1">{t('companyContacts.lastName')}</Typography>
                    <TextField name="last_name" size="small" onChange={handleChange} value={formValues.last_name} />
                    {renderFormErrorHelperText('last_name')}
                  </>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl error={hasError('email')}>
                  <>
                    <Typography variant="body1">{t('companyContacts.email')}</Typography>
                    <TextField
                      type="email"
                      name="email"
                      size="small"
                      onChange={handleChange}
                      value={formValues.email}
                    />
                    {renderFormErrorHelperText('email')}
                  </>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl error={hasError('phone')}>
                  <>
                    <Typography variant="body1">{t('companyContacts.phoneNumber')}</Typography>
                    <TextField name="phone" size="small" onChange={handleChange} value={formValues.phone} />
                    {renderFormErrorHelperText('phone')}
                  </>
                </FormControl>
              </Grid>
              {formValues.user && formValues.user.id && (
                <Grid item xs={12}>
                  <FormControl>
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="is_company_admin"
                            size="small"
                            color="default"
                            onChange={handleChange}
                            checked={formValues.user.is_company_admin ? true : false}
                          />
                        }
                        label={t('companyContacts.company_admin')}
                      />
                    </>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <Divider variant="fullWidth" />
          <DialogActions sx={{ p: 2 }}>
            {deleteButtonVisible && (
                <Grid container>
                  <Grid item alignContent="flex-start" xs={9}>
                    <DeleteButton variant="contained" size="small" onClick={handleDeleteDialogOpen}>
                      {` ${t('misc.deleteButtonLabel')}`}
                    </DeleteButton>
                    {!!formValues.email && !!!formValues.user && (
                      <StyledButton variant="contained" size="small" onClick={handleInvite} sx={{ml:1}}>{` ${t(
                        'companyContacts.inviteAsSystemUser',
                      )}`}</StyledButton>
                    )}
                  </Grid>
                </Grid>
            )} 
            <StyledButton variant="contained" size="small" onClick={handleClose}>{` ${t(
              'companyContacts.cancelButtonLabel',
            )}`}</StyledButton>
            <StyledButton variant="contained" size="small" type="submit" disabled={buttonDisabled}>{` ${t(
              'companyContacts.saveButtonLabel',
            )}`}</StyledButton>
          </DialogActions>
        </form>
      </Dialog>
      <DeleteDialog
        isOpen={deleteDialogOpen}
        text={`${t('companyContacts.deleteConfirmation')}`}
        handleClose={handleDeleteDialogClose}
        handleOk={handleUserDelete}
        severity="error"
      />
    </div>
  );
};
