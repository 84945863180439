import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import Api from '../api';
import { ResourceAttributeResponse, ResourceAttribute} from '../types';
import { endpoints } from '../utils';

const initialAllResourceAttributes : ResourceAttribute[] = [];

const initialState = {
  allResourceAttributes: initialAllResourceAttributes,
  setAllResourceAttributes: (): void => {},
  fetchAllResourceAttributes: (): void => { },
  allResourceAttributesLoading: false,
  setAllResourceAttributesLoading: (): void => { },
}

interface AllResourceAttributesContextType {
  allResourceAttributes: ResourceAttribute[] ;
  setAllResourceAttributes: Dispatch<SetStateAction<ResourceAttribute[]>>;
  fetchAllResourceAttributes: () => void;
  allResourceAttributesLoading: boolean;
  setAllResourceAttributesLoading: Dispatch<SetStateAction<boolean>>;
}

interface Props {
  children: React.ReactNode;
}
const AllResourceAttributesContext = createContext<AllResourceAttributesContextType>(initialState);

export const useAllResourceAttributesContext = (): AllResourceAttributesContextType => useContext(AllResourceAttributesContext);

export const AllResourceAttributesContextProvider: React.FC<Props> = ({ children }) => {
  const [allResourceAttributes,setAllResourceAttributes] = useState<ResourceAttribute[]>(initialAllResourceAttributes);
  const [allResourceAttributesLoading, setAllResourceAttributesLoading] = useState<boolean>(false);

  const fetchAllResourceAttributes = useCallback(
    ()=> {
      (async (): Promise<void> => {
        setAllResourceAttributesLoading(true);
        const { data } = await Api.get<ResourceAttributeResponse>(endpoints.resourceAttributes,{
          params:{
            limit:2000
          }
        });
        const {results} = data;
        const sortedResults = results.sort((prev, next) => Number(next.ordering_weight) - Number(prev.ordering_weight));
        setAllResourceAttributes(sortedResults);
        setAllResourceAttributesLoading(false);
      })();
  },[]);

  return (
    <AllResourceAttributesContext.Provider
      value={{
        setAllResourceAttributes,
        allResourceAttributes,
        fetchAllResourceAttributes,
        allResourceAttributesLoading,
        setAllResourceAttributesLoading
      }}
    >
      {children}
    </AllResourceAttributesContext.Provider>
  );

}
