import { CircularProgress, styled } from '@mui/material';

export const useProgress = (): any => {
  const StyledProgress = styled(CircularProgress)(({}) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: '-12px',
    marginTop: '-12px',
  }));
  return {
    StyledProgress,
  };
};
