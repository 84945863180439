import {
  Box,
  Divider,
  FormControl,
  Grid,
  Typography,
  TextField,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Resource, Error, ContactPerson, ResourceType, ResourceSubType } from '../../types';
import { formatToCommaSeparated, resourceTypeIsPerson, resourceTypeIsMachinery } from '../../utils';
import { useAllResourceSubTypesContext, useCompanyContext } from '../../context';

interface Props {
  formValues: Resource;
  formErrors: Error;
  renderFormErrorHelperText: any;
  handleChange: any;
  hasError: any;
  resourceTypes: ResourceType[];
  resourceId: string | number;
}

export const BasicDetail: React.FC<Props> = ({
  formValues,
  renderFormErrorHelperText,
  handleChange,
  hasError,
  resourceTypes,
  resourceId
}) => {
  const { t } = useTranslation();
  const {allResourceSubTypes} = useAllResourceSubTypesContext();
  const { companyContacts, companyLocations } = useCompanyContext();
  const [selectedResourceType, setSelectedResourceType] = useState<ResourceType| undefined>(undefined);
  const [contactPerson, setContactPerson] = useState<ContactPerson | undefined>(undefined);
  const [resourceSubTypesForSelectedType, setResourceSubTypesForSelectedType] = useState<ResourceSubType[]>([]);

  //set contact person
  useEffect(() => {
    const selectedContact = companyContacts.find((item) => item.id === formValues.contact_person);
    setContactPerson(selectedContact);
  }, [companyContacts, formValues.contact_person]);

  //set selected resource type
  useEffect(() => {
    const selectedType = resourceTypes.find((item) => item.id === Number(formValues._type));
    setSelectedResourceType(selectedType);
  }, [resourceTypes, formValues._type]);

  // get resource subtypes by selected type
  useEffect(() => {
    (async (): Promise<void> => {
      const resourceSubtypesForSelectedType = allResourceSubTypes.filter(
        (item) => Number(item.resource_type) === Number(formValues._type),
      );
      setResourceSubTypesForSelectedType(resourceSubtypesForSelectedType);
    })();
  }, [formValues._type, allResourceSubTypes, setResourceSubTypesForSelectedType]);

  const handleMaxDistanceChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const { name, value } = e.target;

      handleChange({
        target: {
          name,
          value: value === '' ? null : value,
        },
      });
    },
    [handleChange],
  );

  const renderBasicDetail = useMemo(
    () => (
      <>
        <FormControl error={hasError('type')}>
          <Grid container display="flex" m={1} spacing={3}>
            <Grid item xs={3} alignItems="start">
              <Typography variant="body1">{t('myResources.resourceType')}</Typography>
            </Grid>
            <Grid item xs={9} alignItems="center">
              <TextField
                data-testid="_type-wrapper"
                name="_type"
                select
                size="small"
                onChange={handleChange}
                value={formValues._type}
                inputProps={{
                  'data-testid': '_type',
                }}
                InputLabelProps={{ shrink: true }}
                SelectProps={{
                  native: true,
                  displayEmpty: true,
                }}
                disabled={Number(resourceId)>0}
              >
                <option value={''}>-- {t('myResources.selectResourceTypePlaceholder')} --</option>
                {resourceTypes.map((option) => (
                  <option key={option.id} value={option.id}>
                    {`${option.name}`}
                  </option>
                ))}
              </TextField>
            </Grid>
            {renderFormErrorHelperText('type')}
          </Grid>
          <Divider variant="fullWidth" />
        </FormControl>
        <FormControl error={hasError('subtype')}>
          <Grid container display="flex" m={1} spacing={3}>
            <Grid item xs={3} alignItems="start">
              <Typography variant="body1">{t('myResources.resourceSubType')}</Typography>
            </Grid>
            <Grid item xs={9} alignItems="center">
            <TextField
                data-testid="subtype-wrapper"
                name="subtype"
                select
                size="small"
                onChange={handleChange}
                value={formValues.subtype}
                inputProps={{
                  'data-testid': 'subtype',
                }}
                InputLabelProps={{ shrink: true }}
                SelectProps={{
                  native: true,
                  displayEmpty: true,
                }}
                disabled={Number(resourceId)>0}
              >
                <option value={''}>-- {t('myResources.selectResourceSubTypePlaceholder')} --</option>
                {resourceSubTypesForSelectedType.map((option) => (
                  <option key={option.id} value={option.id}>
                    {`${option.name}`}
                  </option>
                ))}
              </TextField>
            </Grid>
            {renderFormErrorHelperText('subtype')}
          </Grid>
          <Divider variant="fullWidth" />
        </FormControl>
        <FormControl error={hasError('title')}>
          <Grid container display="flex" m={2} spacing={2}>
            <Grid item xs={3} alignItems="start">
              <Typography variant="body1">{t('myResources.displayName')}</Typography>
            </Grid>
            <Grid item xs={9} container alignItems="end">
              <TextField name="product__title" size="small" onChange={handleChange} value={formValues.product.title} />
              {renderFormErrorHelperText('title')}
              <Typography variant="subtitle2">{t('myResources.displayNameHelpText')}</Typography>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" />
        </FormControl>
        <FormControl error={hasError('internal name')}>
          <Grid container display="flex" m={2} spacing={2}>
            <Grid item xs={3} alignItems="start">
              <Typography variant="body1">{t('myResources.internalName')}</Typography>
            </Grid>
            <Grid item xs={9} container alignItems="end">
              <TextField name="internal_name" size="small" onChange={handleChange} value={formValues.internal_name} />
              {renderFormErrorHelperText('internal name')}
            </Grid>
          </Grid>
          <Divider variant="fullWidth" />
        </FormControl>
        {(!!selectedResourceType && resourceTypeIsPerson(selectedResourceType.name)) && (
          <>
          <FormControl error={hasError('email')}>
            <Grid container display="flex" m={2} spacing={2}>
              <Grid item xs={3} alignItems="start">
                <Typography variant="body1">{t('myResources.email')}</Typography>
              </Grid>
              <Grid item xs={9} container alignItems="end">
                <TextField name="email" size="small" onChange={handleChange} value={formValues.email} />
                {renderFormErrorHelperText('email')}
              </Grid>
            </Grid>
            <Divider variant="fullWidth" />
          </FormControl>
          <FormControl error={hasError('phone')}>
            <Grid container display="flex" m={2} spacing={2}>
              <Grid item xs={3} alignItems="start">
                <Typography variant="body1">{t('myResources.phone')}</Typography>
              </Grid>
              <Grid item xs={9} container alignItems="end">
                <TextField name="phone" size="small" onChange={handleChange} value={formValues.phone} />
                {renderFormErrorHelperText('phone')}
              </Grid>
            </Grid>
            <Divider variant="fullWidth" />
          </FormControl>
          <FormControl error={hasError('tax_number')}>
            <Grid container display="flex" m={2} spacing={2}>
              <Grid item xs={3} alignItems="start">
                <Typography variant="body1">{t('myResources.taxNumber')}</Typography>
              </Grid>
              <Grid item xs={9} container alignItems="end">
                <TextField name="tax_number" size="small" onChange={handleChange} value={formValues.tax_number} />
                {renderFormErrorHelperText('tax_number')}
              </Grid>
            </Grid>
            <Divider variant="fullWidth" />
          </FormControl>
          </>
        )}
        <FormControl error={hasError('price')}>
          <Grid container display="flex" m={2} spacing={2}>
            <Grid item xs={3} alignItems="start">
              <Typography variant="body1">{t('myResources.pricePerDay')}</Typography>
            </Grid>
            <Grid item xs={9} container alignItems="end">
              <>
                <TextField
                  type="text"
                  name="product__price"
                  size="small"
                  onChange={handleChange}
                  autoFocus
                  value={formatToCommaSeparated(formValues.product.price)}
                />
                {renderFormErrorHelperText('price')}
                <Typography variant="subtitle2">{t('myResources.pricePerDayHelpText')}</Typography>
              </>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" />
        </FormControl>
        {(!!selectedResourceType && resourceTypeIsMachinery(selectedResourceType.name)) && (
          <FormControl error={hasError('cost_per_km')}>
            <Grid container display="flex" m={2} spacing={2}>
              <Grid item xs={3} alignItems="start">
                <Typography variant="body1">{t('myResources.costPerKm')}</Typography>
              </Grid>
              <Grid item xs={9} container alignItems="end">
                <TextField 
                  name="cost_per_km"
                  size="small"
                  onChange={handleChange}
                  value={formatToCommaSeparated(formValues.cost_per_km)} />
                {renderFormErrorHelperText('cost_per_km')}
              </Grid>
            </Grid>
            <Divider variant="fullWidth" />
          </FormControl>
        )}
        <FormControl error={hasError('contact_person')}>
          <Grid container display="flex" m={2} spacing={2}>
            <Grid item xs={3} alignItems="start">
              <Typography variant="body1">{t('myResources.foreman')}</Typography>
            </Grid>
            <Grid item xs={9} alignItems="end">
              <>
                <TextField
                  data-testid="contact_person-wrapper"
                  name="contact_person"
                  select
                  size="small"
                  onChange={handleChange}
                  value={formValues.contact_person}
                  inputProps={{
                    'data-testid': 'contact_person',
                  }}
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{
                    native: true,
                    displayEmpty: true,
                  }}
                >
                  <option value={''}>-- {t('myResources.selectContactPersonPlaceholder')} --</option>
                  {companyContacts.map((option) => (
                    <option key={option.id} value={option.id}>
                      {`${option.first_name} ${option.last_name}`}
                    </option>
                  ))}
                </TextField>
                {renderFormErrorHelperText('contact_person')}
                {!!contactPerson && (
                  <>
                    <Box mt={3}>
                      <Typography variant="body2">{t('companyDetails.contactName')}</Typography>
                      <TextField
                        data-testid="contact_name-wrapper"
                        name="contact_name"
                        size="small"
                        value={`${contactPerson?.first_name} ${contactPerson?.last_name}`}
                        inputProps={{ readOnly: true }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Box>
                    <Box mt={3}>
                      <Typography variant="body2">{t('companyDetails.contactEmail')}</Typography>
                      <TextField
                        data-testid="contact_email-wrapper"
                        name="contact_email"
                        size="small"
                        value={contactPerson.email}
                        inputProps={{ readOnly: true }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Box>
                    <Box mt={3}>
                      <Typography variant="body2">{t('companyDetails.contactPhoneNumber')}</Typography>
                      <TextField
                        data-testid="contact_phone-wrapper"
                        name="contact_phone"
                        size="small"
                        value={contactPerson.phone}
                        inputProps={{ readOnly: true }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Box>
                  </>
                )}
              </>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" />
        </FormControl>
        <FormControl error={hasError('company_location')}>
          <Grid container display="flex" m={2} spacing={2}>
            <Grid item xs={3} alignItems="start">
              <Typography variant="body1">{t('myResources.company_location')}</Typography>
            </Grid>
            <Grid item xs={9} alignItems="end">
              <TextField
                data-testid="company_location-wrapper"
                name="company_location"
                select
                size="small"
                onChange={handleChange}
                value={formValues.company_location}
                inputProps={{
                  'data-testid': 'company_location',
                }}
                InputLabelProps={{ shrink: true }}
                SelectProps={{
                  native: true,
                  displayEmpty: true,
                }}
              >
                <option value={''}>-- {t('myResources.selectCompanyLocationPlaceholder')} --</option>
                {companyLocations.map((option) => (
                  <option key={option.id} value={option.id}>
                    {`${option.name}`}
                  </option>
                ))}
              </TextField>
              {renderFormErrorHelperText('company_location')}
            </Grid>
          </Grid>
          <Divider variant="fullWidth" />
        </FormControl>
        <FormControl error={hasError('max_work_distance')}>
          <Grid container display="flex" m={2} spacing={2}>
            <Grid item xs={3} alignItems="start">
              <Typography variant="body1">{t('myResources.maxWorkDistance')}</Typography>
            </Grid>
            <Grid item xs={9} container alignItems="end">
              <TextField
                  type="number"
                  name="max_work_distance"
                  size="small"
                  onChange={handleMaxDistanceChange}
                  value={formValues.max_work_distance === null ? '' : formValues.max_work_distance}
              />
              {renderFormErrorHelperText('max_work_distance')}
            </Grid>
          </Grid>
          <Divider variant="fullWidth" />
        </FormControl>
      </>
    ),
    [
      hasError,
      t,
      renderFormErrorHelperText,
      handleChange,
      companyContacts,
      companyLocations,
      contactPerson,
      resourceTypes,
      selectedResourceType,
      formValues,
      resourceSubTypesForSelectedType,
      resourceId,
      handleMaxDistanceChange,
    ],
  );
  return <>{renderBasicDetail}</>;
};
