import { Table, TableCell, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';
import lodash from 'lodash';
import { t } from 'i18next';
import React, { useCallback, useState } from 'react';
import { Record, FilterFunc } from '../types';
import { usePaginationContext } from '../context';
interface HeadCells {
  id: string;
  label: string;
  sortable: boolean;
}
interface Props {
  children: React.ReactNode;
}

type Order = 'desc' | 'asc';

export const useTable = (records: any, headCells: HeadCells[], filterFunction: FilterFunc, totalCount:number, orderByColumn=""): any => {
  const pages = [30, 60, 90];
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(pages[page]);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>(orderByColumn);
  const {setOffset, setLimit, limit} = usePaginationContext();

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setOffset(Number(newPage)*limit);
    setPage(newPage);
  };

  const handleRowPerPageChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setLimit(parseInt(event.target.value, 10))
    setPage(0);
  };

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, property: keyof Record): void => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(`${property}`);
    },
    [order, orderBy],
  );

  const createSortHandler = (property: keyof Record) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  const descendingComparator = useCallback(<T,>(a: T, b: T, orderBy: keyof T) => {
    // Use lodash.get in case of nested properties
    // Convert to number if string contains a numeric value
    const aVal = Number(lodash.get(a, orderBy)) || lodash.get(a, orderBy);
    const bVal = Number(lodash.get(b, orderBy)) || lodash.get(b, orderBy);

    if (bVal < aVal) {
      return -1;
    }
    if (bVal > aVal) {
      return 1;
    }
    return 0;
  }, []);

  const getComparator = useCallback(
    <Key extends keyof any>(order: Order, orderBy: Key) => {
      return order === 'desc'
        ? (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }): number =>
          descendingComparator(a, b, orderBy)
        : (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }): number =>
          -descendingComparator(a, b, orderBy);
    },
    [descendingComparator],
  );

  const stableSort = useCallback(<T,>(array: T[], comparator: (a: T, b: T) => number) => {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }, []);

  const TableContainer: React.FC<Props> = ({ children }) => <Table>{children}</Table>;
  const TblHead: React.FC = () => {
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
              {!headCell.sortable ? (
                headCell.label
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const recordsAfterPagingAndSorting = useCallback(() => {
    return stableSort(filterFunction.filterRecord(records), getComparator(order, orderBy));
  }, [records, filterFunction, getComparator, order, orderBy, stableSort]);

  const TblPagination: React.FC = () => {
    return (
      <TablePagination
        rowsPerPageOptions={pages}
        rowsPerPage={rowsPerPage}
        count={totalCount}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowPerPageChange}
        page={page}
        labelRowsPerPage={`${t('misc.rowsPerPage')}`}
      />
    );
  };
  return {
    TableContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    handlePageChange,
    handleRowPerPageChange
  };
};
