import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState} from 'react';
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import Api from "../api";
import { useCartContext } from "../context";
import { Main } from "../layout";
import { Cart, CartItem, CartItemsResponse, ResourceType, ResourceTypesResponse } from "../types";
import { endpoints, numberToLocale, urls } from "../utils";
import { Box, Button, Divider, Grid, Paper, styled, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { colors } from '../theme';

const StyledNavLink = styled(NavLink)(() => ({
  textDecoration: 'none',
  color: colors.secondaryTextGray,
}));

export const OrderSummaryPage: React.FC = () => {
  const { cart, cartItems, setCartItems,fetchCart } = useCartContext();
  const [resourceTypes, setResourceTypes] = useState<ResourceType[]>([]);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const confirmButtonEnabled = cartItems.length > 0 && Object.values(cart).every((v) => v != '')
  const accommodation = cart.accommodation_by == 'seller' ? t('cart.orderDetails.sellerOrganizes') : t('cart.orderDetails.buyerOrganizes');
  const sumTotal = cartItems.reduce((accumulator, item) => {
    return accumulator + item.total_price;
  }, 0);

  // Fetch the cart items
  useEffect(() => {
    (async (): Promise<void> => {
      const { data } = await Api.get<CartItemsResponse>(endpoints.cartItems);
      const { results } = data;
      setCartItems(results);
    })();
  }, [setCartItems]);

  // Fetch the resource types
  useEffect(() => {
    (async (): Promise<void> => {
      const { data } = await Api.get<ResourceTypesResponse>(endpoints.resourceTypes);
      const { results } = data;
      setResourceTypes(results);
    })();
  }, [setResourceTypes]);

  const handleConfirmOrder = useCallback(
    async (e: SyntheticEvent): Promise<void> => {
      e.preventDefault();
      try {
        await Api.patch<Cart>(endpoints.cart, {'state': 'waiting'});
        fetchCart();
        enqueueSnackbar(t('cart.orderConfirmed'), {
          variant: 'success',
        });
        navigate(urls.orders);
      } catch (err: any) {
        enqueueSnackbar(t('cart.failedToConfirmOrder'), {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, t, navigate, fetchCart],
  );

  const render = useMemo(
    () => (
      <>
        <Grid container direction="row" justifyContent="space-between" pr={1}>
          <Grid item mt={1}>
            <StyledNavLink to={urls.cart}>
              <Grid container direction="row" alignItems="center">
                <ArrowBackIosIcon sx={{ fontSize: "0.8em", marginRight: "0.5em" }} ></ArrowBackIosIcon>
                <Typography variant="h6">
                  {t('cart.orderSummary.backButtonLabel')}
                </Typography>
              </Grid>
            </StyledNavLink>
          </Grid>
        </Grid>
        <Grid container pb={0.5} pr={1}>
          <Grid item xs={12}>
            <Paper>
              <Box p={1} pt={3} pb={3} m={2}>
                <Grid container direction="row" justifyContent="space-between" pb={2}>
                  <Grid item>
                    <Typography variant="h5">{t('cart.orderSummary.title')}</Typography>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" disabled={!confirmButtonEnabled} onClick={handleConfirmOrder} size="small">
                      {t('cart.orderSummary.confirmButtonLabel')}
                    </Button>
                  </Grid>
                </Grid>

                <Grid container pt={2} pb={2}>
                  <Grid item xs={3} pr={4}>
                    <Typography variant="h5">{cart.title}</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Grid container direction="column">
                      <Grid item xs={12} mb={3}>
                        <Typography variant="h6" mb={1}>{t('cart.orderSummary.workDescription')}</Typography>
                        {cart.work_description}
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography variant="h6" mb={1}>{t('cart.orderSummary.workLocation')}</Typography>
                            {cart.work_location}
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" mb={1}>{t('cart.orderSummary.dates')}</Typography>
                            {cart.work_start_date && new Date(cart.work_start_date).toLocaleDateString('fi-FI')}
                            &nbsp;&ndash;&nbsp;
                            {cart.work_end_date && new Date(cart.work_end_date).toLocaleDateString('fi-FI')}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography variant="h6" mb={1}>{t('cart.orderSummary.accommodation')}</Typography>
                            {accommodation}
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" mb={1}>{t('cart.orderSummary.startTime')}</Typography>
                            {cart.start_time}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Divider light variant="fullWidth" />

                      <Grid item xs={12} mt={2} mb={3}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography variant="h6" mb={1}>{t('cart.orderSummary.contactName')}</Typography>
                            {cart.contact_name}
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" mb={1}>{t('cart.orderSummary.contactPhone')}</Typography>
                            {cart.contact_phone}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography variant="h6" mb={1}>{t('cart.orderSummary.streetAddress')}</Typography>
                            {cart.street}
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" mb={1}>{t('cart.orderSummary.postOffice')}</Typography>
                            {cart.zip_code} {cart.municipality}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Divider variant="fullWidth" />

                <Grid container mt={4} mb={1}>
                  <Grid item xs={3}>
                    <Typography variant="h5">{t('cart.resources.title')}</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    {resourceTypes.map((res_type: ResourceType) => (
                      (cartItems.some(item => item.resource_type == res_type.id)) && (
                        <Grid container direction="column" key={res_type.id}>
                          <Grid item>
                            <Grid container>
                              <Grid item xs={3}>
                                <Typography variant="h6">{res_type.name}</Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant="h6">{t('cart.resources.company')}</Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant="h6">{t('cart.resources.location')}</Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography variant="h6">{t('cart.resources.dates')}</Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant="h6">{t('cart.resources.totalPrice')} (€)</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            {cartItems.filter((item: CartItem) => (item.resource_type == res_type.id)).map(item => (
                              <Grid container mb={1} key={item.id}>
                                <Grid item xs={3}><Typography variant="subtitle1">{item.product_name}</Typography></Grid>
                                <Grid item xs={2}><Typography variant="subtitle1">{item.seller_company}</Typography></Grid>
                                <Grid item xs={2}><Typography variant="subtitle1">{item.location}</Typography></Grid>
                                <Grid item xs={3}>
                                  <Typography variant="subtitle1">
                                    {item.reservation?.begin && new Date(item.reservation?.begin).toLocaleDateString('fi-FI')}
                                    &nbsp;&ndash;&nbsp;
                                    {item.reservation?.end && new Date(item.reservation?.end).toLocaleDateString('fi-FI')}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}><Typography variant="subtitle1">{numberToLocale(item.total_price)}</Typography></Grid>
                              </Grid>
                            ))}
                            <Divider light variant="fullWidth" />
                            <Grid container justifyContent="flex-end" mb={1} mt={2}>
                              <Grid item xs={2}>
                                <Typography variant="h6">
                                  {numberToLocale(cartItems
                                    .filter(item => item.resource_type == res_type.id)
                                    .map(item => item.total_price)
                                    .reduce((prev, next) => prev + next))}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    ))}
                  </Grid>
                </Grid>
                <Grid container pt={4} pb={2} justifyContent="flex-end">
                  <Typography variant="h5">{t('cart.resources.orderTotalPrice')}: {numberToLocale(sumTotal)}</Typography>
                  <Typography variant="h5" sx={{ marginLeft: 1.5, fontWeight: "normal" }}> + {t('cart.resources.extraCosts')}</Typography>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </>
    ),
    [handleConfirmOrder, confirmButtonEnabled, cart, cartItems, resourceTypes, accommodation, t, sumTotal],
  );
  return <Main>{render}</Main>;
};
