export const urls = {
  login: '/login',
  resourceSearch: '/',
  resetPassword: '/resetPassword',
  offers: '/offers',
  offer: (id: string | number): string => `/offers/${id}`,
  offerEdit: (id: string | number): string => `/offers/${id}/edit`,
  orders: '/orders',
  requestsForProposal: '/requestsForProposal',
  requestForProposal: (id: string | number): string => `/requestsForProposal/${id}`,
  requestForProposalEdit: (id: string | number): string => `/requestsForProposal/${id}/edit`,
  requestForProposalCreate: '/requestsForProposal/new',
  myResources: '/myResources',
  companySettings: '/company',
  instructions: '/instructions',
  userSettings: '/user',
  companies: '/companies',
  cart: '/cart',
  orderSummary: '/cart/summary',
  register: (email: string): string => `/register/email/${email}`,
  order: (id: string | number): string => `/orders/${id}`,
};

export const endpoints = {
  profile: '/profile/',
  cart: '/cart/',
  cartItems: '/cart/items/',
  cartItem: (id: string | number): string => `/cart/items/${id}/`,
  companies: '/companies/',
  companyLocations: '/company_locations/',
  companyLocation: (id: string | number): string => `/company_locations/${id}/`,
  company: (id: string | number): string => `/companies/${id}/`,
  contacts: '/contacts/',
  contact: (id: string | number): string => `/contacts/${id}/`,
  invite: (id: string | number): string => `/contacts/${id}/invite/`,
  resourceTypes: '/resource_types/',
  resourceType: (id: string | number): string => `/resource_types/${id}/`,
  resources: '/resources/',
  resource: (id: string | number): string => `/resources/${id}/`,
  resourceSubTypes: '/resource_subtypes/',
  resourcesSubType: (id: string | number): string => `/resource_subtypes/${id}/`,
  resourceAttributes: '/resource_attributes/',
  resourceAttribute: (id: string | number): string => `/resource_attributes/${id}/`,
  resourceAttributeOptions: '/resource_attribute_options/',
  resourceAttributeOption: (id: string | number): string => `/resource_attribute_options/${id}/`,
  resourceAttributeTemplates: '/resource_attribute_templates/',
  resourceAttributeTemplate: (id: string | number): string => `/resource_attribute_templates/${id}/`,
  resourceAttributeLines: '/resource_attribute_lines/',
  requestsForProposal: '/requests_for_proposal/',
  requestForProposal: (id: string | number): string => `/requests_for_proposal/${id}/`,
  requestForProposalCancel: (id: string | number): string => `/requests_for_proposal/${id}/cancel/`,
  offers: '/offers/',
  offer: (id: string | number): string => `/offers/${id}/`,
  offerAccept: (id: string | number): string => `/offers/${id}/accept/`,
  offerReject: (id: string | number): string => `/offers/${id}/reject/`,
  offerCancel: (id: string | number): string => `/offers/${id}/cancel/`,
  orders: '/orders/',
  ordersRequiringAttention: '/orders_requiring_attention/',
  order: (id: string | number): string => `/orders/${id}/`,
  orderReject: (id: string | number): string => `/orders/${id}/reject/`,
  orderLine: (id: string | number): string => `/order_lines/${id}/`,
  replacementProducts: (id: string | number): string => `/order_lines/${id}/replacements_products/`,
  expenseProducts: `/expense_products/`,
  adverts: `/adverts/`,
  adConversions:`/ad_conversions/`
};

export const sideNavLinks = {
  resourceSearch: 'resourceSearch',
  offers: 'offers',
  orders: 'orders',
  requestsForProposal: 'requestsForProposal',
  myResources: 'myResources',
  companySettings: 'companySettings',
  companies: 'companies',
  instructions: 'instructions',
  userSettings: 'userSettings',
};

export const topSideBarNavLinks = [
  {
    name: 'resourceSearch',
    linkTo: urls.resourceSearch,
  },
  {
    name: 'orders',
    linkTo: urls.orders,
  },
  {
    name: 'requestsForProposal',
    linkTo: urls.requestsForProposal,
  },
  {
    name: 'offers',
    linkTo: urls.offers,
  },
  {
    name: 'myResources',
    linkTo: urls.myResources,
  },
  {
    name: 'companySettings',
    linkTo: urls.companySettings,
  },
];
export const restrictedTopSideNavLinks = [
  {
    name: 'companies',
    linkTo: urls.companies,
  },
];
export const bottomSideBarNavLinks = [
  {
    name: 'instructions',
    linkTo: urls.instructions,
  }
];

export const initialPagingOffset = 0;
export const initialPagingLimit = 30;
export const orderStatuses = [
  "waiting",
  "cancelled",
  "rejected",
  "confirmed",
  "work_in_progress",
  "pending_seller_action",
  "invoiceable",
  "invoiced",
  "complete"
]

export const requestForProposalStates = {
  OPEN: "open",
  CLOSED: "closed",
  EXPIRED: "expired",
  WAITING_FOR_BUYER: "waiting_for_buyer"
};

export const offerStates = {
  OPEN: "open",
  CANCELLED: "cancelled",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
  EXPIRED: "expired",
  FAULTY: "faulty",
  DRAFT: "draft",
  WAITING_FOR_BUYER: "waiting_for_buyer"
};
