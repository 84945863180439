import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

import { CompanyLocation, ContactPerson } from '../types';

const initialCompanyLocations: CompanyLocation[] = [];
const initialCompanyContacts: ContactPerson[] = [];
const initialSelectedContactId: string | number = '0';
const initialIsContactFormOpen = false;

const initialState = {
  companyLocations: initialCompanyLocations,
  companyContacts: initialCompanyContacts,
  setCompanyLocations: (): void => {},
  setCompanyContacts: (): void => {},
  selectedContactId: initialSelectedContactId,
  isContactFormOpen: initialIsContactFormOpen,
  setOpen: (): void => {},
  setSelectedContactId: (): void => {},
};

interface CompanyContextType {
  companyLocations: CompanyLocation[];
  companyContacts: ContactPerson[];
  setCompanyLocations: Dispatch<SetStateAction<CompanyLocation[]>>;
  setCompanyContacts: Dispatch<SetStateAction<ContactPerson[]>>;
  selectedContactId: string | number;
  isContactFormOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedContactId: Dispatch<SetStateAction<string | number>>;
}

interface Props {
  children: React.ReactNode;
}
const CompanyContext = createContext<CompanyContextType>(initialState);

export const useCompanyContext = (): CompanyContextType => useContext(CompanyContext);

export const CompanyContextProvider: React.FC<Props> = ({ children }) => {
  const [companyLocations, setCompanyLocations] = useState<CompanyLocation[]>(initialCompanyLocations);
  const [companyContacts, setCompanyContacts] = useState<ContactPerson[]>(initialCompanyContacts);
  const [isContactFormOpen, setOpen] = useState<boolean>(initialIsContactFormOpen);
  const [selectedContactId, setSelectedContactId] = useState<string | number>(initialSelectedContactId);
  return (
    <CompanyContext.Provider
      value={{
        setCompanyLocations,
        companyLocations,
        setCompanyContacts,
        companyContacts,
        isContactFormOpen,
        setOpen,
        selectedContactId,
        setSelectedContactId,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};
