import { Button, styled } from "@mui/material";
import { colors } from "../../theme";


export const DeleteButton = styled(Button)(() => ({
    backgroundColor: colors.alert,
    '&:hover': {
        backgroundColor: colors.alertDark,
    },
}));
