import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  setPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
} from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const config = {
  apiKey: process.env.REACT_APP_AUTH_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
};
//initialize firebase
const app = initializeApp(config);
// initialize firebase authentication
export const auth = getAuth(app);
export const storage = getStorage(app);
// eslint-disable-next-line
export const getAccessToken = async (email: string, password: string): Promise<any> => {
  return setPersistence(auth, browserSessionPersistence)
    .then(async () => {
      // Existing and future Auth states are now persisted in the current
      // session only. Closing the window would clear any existing state even
      // if a user forgets to sign out.
      // ...
      // New sign-in will be persisted with session persistence.
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      const { token, expirationTime } = await user.getIdTokenResult();
      return {
        token,
        expirationTime: new Date(expirationTime), // changes the time zone
      };
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      return {
        message: errorMessage,
        code: errorCode,
      };
    });
};

export const signOutUser = async (): Promise<any> => {
  try {
    await signOut(auth);
  } catch (err: any) {
    return {
      message: err.message,
      code: err.code,
    };
  }
};

export const sendPasswordResetLink = async (email: string): Promise<any> => {
  await sendPasswordResetEmail(auth, email);
};

export const createUser = async (email: string, password: string): Promise<any> => {
  try {
    const { user } = await createUserWithEmailAndPassword(auth, email, password);
    // send email about registration
    await sendEmailVerification(user);
    return { user };
  } catch (err: any) {
    return {
      message: err.message,
      code: err.code,
    };
  }
};
