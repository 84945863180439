import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import Api from '../../api';
import { OrderListResponse , OrderListItem, FilterFunc, Record, CompanyResponse, ResourceTypesResponse } from '../../types';
import { endpoints, numberToLocale, urls } from '../../utils';
import { useAuthContext, useCompaniesContext, useOrderContext, usePaginationContext, useResourceContext } from '../../context';
import { useTranslation } from 'react-i18next';
import { 
    Grid,
    Tab,
    Typography,
    TableBody,
    TableCell,
    TableRow,
    Button,
    TableFooter,
    Chip,
    TextField,
    InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { colors } from '../../theme';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useProgress, useTable } from '../../hooks';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';


export const OrderList: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState<string>('1');
  const { orders, setOrders, totalOrders, setTotalOrders, setSelectedOrder } = useOrderContext();
  const { ordersRequiringAttention, ordersRequiringAttentionCount } = useOrderContext();
  const {companies, setCompanies} = useCompaniesContext();
  const { profileData } = useAuthContext();
  const { limit, offset } = usePaginationContext();
  const [totalPurchased, setTotalPurchased] = useState<number>(0);
  const [totalSold, setTotalSold] = useState<number>(0);
  const {setResourceTypes} = useResourceContext();
  const [loading, setLoading] = useState<boolean>(false);
  const { StyledProgress } = useProgress();
  const [purchasedOrders, setPurchasedOrders] = useState<OrderListItem[]>([]);
  const [soldOrders, setSoldOrders] = useState<OrderListItem[]>([]);

  useEffect(() => {
    (async (): Promise<void> => {
      setLoading(true);
      const { data } = await Api.get<OrderListResponse>(endpoints.orders, {
        params:{
          offset,
          limit
        }
      });
      const { results, count } = data;
      const ordersCopy = JSON.parse(JSON.stringify(results));

      const sold = ordersCopy.filter((item: OrderListItem) => Number(item.seller) === Number(profileData.company));
      const purchased = ordersCopy.filter((item: OrderListItem) => Number(item.project.user) === Number(profileData.user.id));

      setOrders(results);
      setSoldOrders(sold);
      setPurchasedOrders(purchased);

      setTotalOrders(count);
      setTotalSold(sold.length);
      setTotalPurchased(purchased.length);

      setLoading(false);
    })();
  }, [profileData, setOrders, setSoldOrders, setPurchasedOrders, setTotalOrders, offset, limit]);

  useEffect(() => {
    (async (): Promise<void> => {
      setLoading(true);
      const { data } = await Api.get<CompanyResponse>(endpoints.companies);
      const { results } = data;
      setCompanies(results);
      setLoading(false);
    })();
  }, [setCompanies]);

  // Fetch the resource types
  useEffect(() => {
    (async (): Promise<void> => {
      setLoading(true);
      const { data } = await Api.get<ResourceTypesResponse>(endpoints.resourceTypes);
      const { results } = data;
      setResourceTypes(results);
      setLoading(false);
    })();
  }, [setResourceTypes]);

  const handleTabChange = useCallback(
    (event: SyntheticEvent, newValue: string): void => {
      setTabValue(newValue);
    },
    [setTabValue],
  );

  const headCells = [
    { id: 'order_date', label: t('orders.orderDate'), sortable: true },
    { id: 'seller', label: t('orders.seller'), sortable: true },
    { id: 'buyer_company_name', label: t('orders.buyer'), sortable: true },
    { id: 'project.title', label: t('orders.projectTitle'), sortable: true },
    { id: 'type', label: t('orders.type'), sortable: true },
    { id: 'status', label: t('orders.status'), sortable: true },
    { id: 'work_start_date', label: t('orders.workPeriod'), sortable: true },
    { id: 'total_price', label: t('orders.price'), sortable: true },
    { id: 'open', label: t('orders.open'), sortable: false },
  ];

  const [filterFunction, setFilterFunction] = useState<FilterFunc>({
    filterRecord: (items: Record[]): Record[] => items,
  });

  const orderTable = useTable(
    orders,
    headCells,
    filterFunction,
    totalOrders
  );

  const soldTable = useTable(
    soldOrders,
    headCells,
    filterFunction,
    totalSold
  );

  const purchasedTable = useTable(
    purchasedOrders,
    headCells,
    filterFunction,
    totalPurchased
  );

  const requiringAttentionTable = useTable(
    ordersRequiringAttention,
    headCells,
    filterFunction,
    ordersRequiringAttentionCount
  );

  const getCompanyName = useCallback(
    (companyId: string | number): string => {
      const company = companies.find((item) => Number(item.id) === Number(companyId));
      return String(company?.name) ?? '';
    },  
    [companies],
  );
  
  const handleOpenOrder = useCallback(
    (orderId: string | number):void => {
      setSelectedOrder(Number(orderId));
      navigate(urls.order(orderId));
  },[setSelectedOrder, navigate]);

  const renderOrderStatus = useCallback(
    (status: string) => {
      switch(status) {
        case 'confirmed':
          return <Chip label={t(`orders.statuses.${status}`)} color="success"/>
        case 'work_in_progress':
          return <Chip label={t(`orders.statuses.${status}`)} color="success" />
        case 'pending_seller_action':
          return <Chip label={t(`orders.statuses.${status}`)} color="primary" />
        case 'waiting':
          return <Chip label={t(`orders.statuses.${status}`)} color="primary"/>
        case 'rejected':
          return <Chip label={t(`orders.statuses.${status}`)} color="error"/>
        case 'expired':
          return <Chip label={t(`orders.statuses.${status}`)} color="warning"/>
        case 'invoiceable':
          return <Chip label={t(`orders.statuses.${status}`)} color="primary" />
        case 'invoiced':
          return <Chip label={t(`orders.statuses.${status}`)} color="success" />
        case 'complete':
          return <Chip label={t(`orders.statuses.${status}`)} color="success" />
        default:
          return <Chip label={t(`orders.statuses.${status}`)} />
      }
    },
    [t],
  );

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const searchText = event.target.value;
      setFilterFunction({
        filterRecord: (items: Record[]): Record[] => {
          if (!searchText.length) {
            return items;
          } else {
            return items.filter((item) => {
              return (
                `${item.project.title}`.toLocaleLowerCase().includes(searchText.toLowerCase()) ||
                `${getCompanyName(Number(item.seller))}`.toLocaleLowerCase().includes(searchText.toLowerCase())
              );
            });
          }
        },
      });
    },
    [getCompanyName],
  );
  
  const renderTable = useCallback(  
    (orders: OrderListItem[], table: any) => {
      return (
        <>
        {loading && <StyledProgress size={24} />}
        <table.TableContainer>
          <table.TblHead />
          <TableBody>   
            {orders.map((item: OrderListItem) => (
              <TableRow key={item.id} hover>
                <TableCell>{format(new Date(item.order_date), 'dd.MM.yyyy')}</TableCell>
                <TableCell>{getCompanyName(Number(item.seller))}</TableCell>
                <TableCell>{item.buyer_company_name}</TableCell>
                <TableCell>{item.project.title}</TableCell>
                <TableCell>
                  {item.seller === profileData.company ? t('orders.sale') : t('orders.purchase')}
                </TableCell>
                <TableCell>{renderOrderStatus(item.status)}</TableCell>
                <TableCell>{!!item.work_start_date && format(new Date(item.work_start_date), 'dd.MM.yyyy')} - {!!item.work_end_date && format(new Date(item.work_end_date), 'dd.MM.yyyy')}</TableCell>
                <TableCell>{!!item.total_price && numberToLocale(item.total_price)}</TableCell>
                <TableCell>
                  <Button onClick={(): void => handleOpenOrder(`${item.id}`)} size="small" variant="contained">
                    {t('orders.open')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <table.TblPagination />
            </TableRow>
          </TableFooter>
        </table.TableContainer>
        </>
      )
    },
    [t, getCompanyName, renderOrderStatus, profileData, handleOpenOrder, loading, StyledProgress],
  );
 const renderAllTabs = useMemo(
  ()=>(
    <TabList
      onChange={handleTabChange}
      aria-label="order tabs"
      TabIndicatorProps={{
          style: {
          backgroundColor: colors.darkBlue,
        },
      }}
    >
       <Tab key={1} value="1" label={t('orders.allOrders')} icon={<Chip label={totalOrders} variant="filled" />} iconPosition="end" />
       <Tab key={2} value="2" label={t('orders.sold')} icon={<Chip label={totalSold} variant="filled" />} iconPosition="end" />
       <Tab key={3} value="3" label={t('orders.purchased')} icon={<Chip label={totalPurchased} variant="filled" />} iconPosition="end" />
       <Tab key={4} value="4" label={t('orders.requiringAttention')} icon={<Chip label={ordersRequiringAttentionCount} variant="filled" />} iconPosition="end" />
    </TabList>
   ), [t, handleTabChange, totalOrders, totalSold, totalPurchased, ordersRequiringAttentionCount]
 );
 
  return (
    <TabContext value={tabValue}>
      <Grid>
        {renderAllTabs}
      </Grid>
      <Grid container mt={2}>
        <Grid item xs>
          <TextField
            data-testid="search-wrapper"
            name="search"
            size="small"
            fullWidth={false}
            placeholder={t('misc.search')}
            onChange={handleSearch}
            inputProps={{
              'data-testid': 'search',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              width: '50%',
            }}
          /> 
        </Grid>
      </Grid>

      {/* All orders */}
      <TabPanel key="1" value="1" sx={{pl:0, pr:0}}>
        <Grid container mt={2}>
          <Grid item xs>
            <Typography variant="h4" mb={2}>{t('orders.allOrdersHeader')}</Typography>
            {renderTable(orderTable.recordsAfterPagingAndSorting(), orderTable)}
          </Grid>
        </Grid>
      </TabPanel>

      {/* Sold orders */}
      <TabPanel key="2" value="2" sx={{pl:0, pr:0}}>
        <Grid container mt={2}>
          <Grid item xs>
            <Typography variant="h4" mb={2}>{t('orders.soldOrdersHeader')}</Typography>
            {renderTable(soldTable.recordsAfterPagingAndSorting(), soldTable)}
          </Grid>
        </Grid>
      </TabPanel>

      {/* Purchased orders */}
      <TabPanel key="3" value="3" sx={{pl:0, pr:0}}>
        <Grid container mt={2}>
          <Grid item xs>
            <Typography variant="h4" mb={2}>{t('orders.purchasedOrdersHeader')}</Typography>
            {renderTable(purchasedTable.recordsAfterPagingAndSorting(), purchasedTable)}
          </Grid>
        </Grid>
      </TabPanel>

      {/* Orders that require attention */}
      <TabPanel key="4" value="4" sx={{ pl: 0, pr: 0 }}>
        <Grid container mt={2}>
          <Grid item xs mb={8}>
            <Typography variant="h4" mb={2}>{t('orders.attentionOrdersHeader')}</Typography>
            {renderTable(requiringAttentionTable.recordsAfterPagingAndSorting(), requiringAttentionTable)}
          </Grid>
        </Grid>
      </TabPanel>

    </TabContext>
  )
}