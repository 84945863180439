import { Typography, Grid, Button } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import React, { useMemo } from 'react';
import { Main } from '../layout';
import { RFPList } from '../components/rfps/'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { urls } from '../utils';

export const RequestsForProposal: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const renderList = useMemo(() => (
    <RFPList />
  ), []);

  const handleAddClick = (): void => {
    navigate(urls.requestForProposalCreate);
  };

  return (
    <Main>
      <Grid container display="flex" m={2} spacing={2}>
        <Grid item xs={8}>
          <Typography variant="h3">{t('requestsForProposal.listViewTitle')}</Typography>
          <Typography variant="subtitle1">{t('requestsForProposal.listViewSubtitle')}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Grid item xs={12} container justifyContent="flex-end">
            <Button
              variant="contained"
              size="small"
              onClick={handleAddClick}
            >
              {<AddBoxOutlinedIcon />} {` ${t('requestsForProposal.addButtonLabel')}`}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={3}>
          {renderList}
        </Grid>
      </Grid>
    </Main>
  )
};
