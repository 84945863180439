import React, { useCallback, useEffect, useState } from 'react';
import {
  ResourceSearchPage,
  Login,
  NotFound,
  ResetPassword,
  UserSetting,
  Offers,
  OfferDetailView,
  Orders,
  CompanySetting,
  Instructions,
  MyResources,
  CompaniesAndUsers,
  Register,
  CartPage,
  OrderSummaryPage,
  OrderView,
  RequestsForProposal,
  RequestForProposalDetailView,
  RequestForProposalFormView,
} from './pages';
import { Routes, Route } from 'react-router-dom';
import { urls } from './utils';
import { useAuthContext, useCartContext } from './context';
import { useTranslation } from 'react-i18next';
import Api from './api';
import { SimpleDialog } from './components/common';
import {OfferFormView} from "./pages/OfferForm";

interface currentUser {
  is_staff: string;
  has_company: string;
  is_company_admin: string;
}
const defaultCurrentUser: currentUser = {
  is_company_admin: '0',
  is_staff: '0',
  has_company: '0'
}

const App: React.FC = () => {
  const { token, getProfileData, profileData } = useAuthContext();
  const { t } = useTranslation();
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const {cart, setCartExpired, setCartExpiring} = useCartContext();
  const currentUser = JSON.parse(sessionStorage.getItem('current_user') ?? JSON.stringify(defaultCurrentUser));
 
  // check if response has error (improve it if other errors happens in the future)
  useEffect(() => {
    Api.interceptors.response.use(
      (response) => response,
      (error) => {
        const location = window.location.href.split('//')[1];
        if (error.message.trim() === 'Network Error') {
          const connectionError = `${t('misc.connectionError')}`;
          setErrorDialogOpen(true);
          setError(connectionError);
          if(`${location}` !== `${window.location.host}${urls.login}`){ 
            window.location.href = `${urls.login}`;
          }
          return null;
        } else if (error?.response?.data?.detail) { // errors other than network error
          
          // unauthorized user redirect to login
          if(error.response.status === 401 && `${location}` !== `${window.location.host}${urls.login}`){
            window.location.href = `${urls.login}`;
          }else{ // other possible errors 
            setErrorDialogOpen(true);
            setError(error.response.data.detail);
          }
          return null;
        } else {
          // field errors
          return Promise.reject(error);
        }
      },
    );
  }, [setErrorDialogOpen, t, setError, token, error]);

  // fetch profile data
  useEffect(() => {
    if (token) {
      getProfileData();
    }
  }, [getProfileData, token]);
  
  // track cart expiring time
  useEffect(()=>{
    const interval = setInterval(() => {
      if(!!cart.expires_at){
        const [cartHours, cartMinutes] = cart.expires_at.split(' ')[0].split(':');
        const [currentHours, currentMinutes] = new Date().toLocaleTimeString().split(':');
        const cartTotalMinutes = (Number(cartHours) * 60) + Number(cartMinutes);
        const currentTotalMinutes = (Number(currentHours) * 60) + Number(currentMinutes);
        if((cartTotalMinutes - currentTotalMinutes) === 5){
          setCartExpiring(true);
          setCartExpired(false);
        }
        if(cartTotalMinutes <= currentTotalMinutes){
          setCartExpiring(false);
          setCartExpired(true);
        }
      }else{
        setCartExpiring(false);
        setCartExpired(false);
      }  
    }, 60000); // triggers every minute

    return () => clearInterval(interval); 
  },[cart.expires_at, setCartExpired, setCartExpiring]);

  const closeErrorDialog = useCallback((): void => {
    setErrorDialogOpen(false);
    setError('');
  }, [setErrorDialogOpen]);

  const shouldSeeCompanySettings = useCallback(
    ():boolean =>{
      const isStaff = profileData.user.is_staff || Boolean(Number(currentUser?.is_staff)),
            hasCompany = Boolean(Number(profileData.company)) || Boolean(Number(currentUser?.has_company)),
            isCompanyAdmin = profileData.user.is_company_admin || Boolean(Number(currentUser?.is_company_admin));
    return ((isStaff && hasCompany) || isCompanyAdmin) ?? false;
  },[profileData, currentUser?.has_company, currentUser?.is_company_admin, currentUser?.is_staff]);

  if (errorDialogOpen && !!error.length) {
    return <SimpleDialog text={error} isOpen={errorDialogOpen} handleClose={closeErrorDialog} severity="error" />;
  }

  return (
    <Routes>
      <Route path={urls.login} element={<Login />} />
      <Route path={urls.resetPassword} element={<ResetPassword />} />
      <Route path={urls.register(':email')} element={<Register />} />
      {/* protected routes */}
      {Boolean(token.length) && (
        <>
          <Route path={urls.resourceSearch} element={<ResourceSearchPage />} />
          <Route path={urls.cart} element={<CartPage />} />
          <Route path={urls.orderSummary} element={<OrderSummaryPage />} />
          <Route path={urls.userSettings} element={<UserSetting />} />
          <Route path={urls.orders} element={<Orders />} />
          <Route path={urls.order(':orderId')} element={<OrderView />} />
          <Route path={urls.requestsForProposal} element={<RequestsForProposal />} />
          <Route path={urls.requestForProposal(':id')} element={<RequestForProposalDetailView />} />
          <Route path={urls.requestForProposalEdit(':id')} element={<RequestForProposalFormView />} />
          <Route path={urls.requestForProposalCreate} element={<RequestForProposalFormView />} />
          <Route path={urls.offers} element={<Offers />} />
          <Route path={urls.offer(':id')} element={<OfferDetailView />} />
          <Route path={urls.offerEdit(':id')} element={<OfferFormView />} />
          {
            shouldSeeCompanySettings() && 
            <Route path={urls.companySettings} element={<CompanySetting />}>
              <Route path={':companyId'} element={<CompanySetting />} />
            </Route>
          }  
          <Route path={urls.instructions} element={<Instructions />} />
          <Route path={urls.myResources} element={<MyResources />} />
          
        </>
      )}
      {
        (Boolean(token.length) && (profileData.user.is_staff || Boolean(Number(currentUser?.is_staff)))) && <Route path={urls.companies} element={<CompaniesAndUsers />} />
      }
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
