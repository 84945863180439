import React, { ChangeEvent, useCallback, useState } from 'react';
import {
  Grid,
  Box,
  TextField,
  InputAdornment,
  TableBody,
  TableRow,
  TableCell,
  Button,
  TableFooter,
} from '@mui/material';
import { ContactPerson, FilterFunc, Record } from '../../../types';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { useTable } from '../../../hooks';
import { useCompanyContext } from '../../../context';
interface Props {
  contacts: ContactPerson[];
  loading: boolean;
  totalCount: number;
}

export const ContactList: React.FC<Props> = ({ contacts, loading, totalCount }) => {
  const { t } = useTranslation();
  const { setOpen, setSelectedContactId } = useCompanyContext();
  const [filterFunction, setFilterFunction] = useState<FilterFunc>({
    filterRecord: (items: Record[]): Record[] => items,
  });
  const headCells = [
    { id: 'first_name', label: `${t('companyContacts.firstName')}`, sortable: true },
    { id: 'last_name', label: `${t('companyContacts.lastName')}`, sortable: true },
    { id: 'email', label: `${t('companyContacts.email')}`, sortable: true },
    { id: 'phone', label: `${t('companyContacts.phoneNumber')}`, sortable: true },
    { id: 'is_user', label: `${t('companyContacts.user')}`, sortable: false },
    { id: 'is_company_admin', label: `${t('companyContacts.company_admin')}`, sortable: false },
    { id: 'edit', label: `${t('companyContacts.edit')}`, sortable: false },
  ];
  const { TableContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } = useTable(
    contacts,
    headCells,
    filterFunction,
    totalCount
  );
  const handleEdit = useCallback(
    (contactId: string): void => {
      setSelectedContactId(contactId);
      setOpen(true);
    },
    [setSelectedContactId, setOpen],
  );

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    const searchText = event.target.value;
    setFilterFunction({
      filterRecord: (items: Record[]): Record[] => {
        if (!searchText.length) {
          return items;
        } else {
          return items.filter((item) => {
            return (
              `${item.first_name}`.toLocaleLowerCase().includes(searchText.toLowerCase()) ||
              `${item.last_name}`.toLocaleLowerCase().includes(searchText.toLowerCase()) ||
              `${item.email}`.toLocaleLowerCase().includes(searchText.toLowerCase()) ||
              `${item.phone}`.toLocaleLowerCase().includes(searchText.toLowerCase())
            );
          });
        }
      },
    });
  }, []);
  return (
    <Grid item xs={12}>
      <Box sx={{ mb: 2 }}>
        <TextField
          data-testid="search-wrapper"
          name="search"
          size="small"
          disabled={loading}
          fullWidth={false}
          placeholder={t('companies.search')}
          onChange={handleSearch}
          inputProps={{
            'data-testid': 'search',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            width: '50%',
          }}
        />
      </Box>
      <TableContainer>
        <TblHead />
        <TableBody>
          {recordsAfterPagingAndSorting().map((item: ContactPerson) => (
            <TableRow key={item.id} hover>
              <TableCell>{item.first_name}</TableCell>
              <TableCell>{item.last_name}</TableCell>
              <TableCell>{item.email}</TableCell>
              <TableCell>{item.phone}</TableCell>
              <TableCell>{item.user ? `${t('misc.yes')}` : `${t('misc.no')}`}</TableCell>
              <TableCell>
                {item.user && item.user.is_company_admin ? `${t('misc.yes')}` : `${t('misc.no')}`}
              </TableCell>
              <TableCell>
                <Button onClick={(): void => handleEdit(`${item.id}`)} size="small" variant="contained">
                  <EditIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TblPagination />
          </TableRow>
        </TableFooter>
      </TableContainer>
    </Grid>
  );
};
