import { Grid, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState, SyntheticEvent, useEffect } from 'react';
import { Main } from '../layout';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useTranslation } from 'react-i18next';
import { CompanyDetails, InvoiceDetails, Contacts, CompanyLocations } from '../components/company';
import { colors } from '../theme';
import { useParams } from 'react-router-dom';
import { CompanyLocation, ContactPerson } from '../types';
import Api from '../api';
import { endpoints, initialPagingOffset } from '../utils';
import { useAuthContext, useCompanyContext, usePaginationContext } from '../context';
import { useProgress } from '../hooks';

export const CompanySetting: React.FC = () => {
  const { companyId } = useParams();
  const { StyledProgress } = useProgress();
  const { profileData } = useAuthContext();
  const userCompanyId = profileData.company;
  const currentCompanyId = companyId || userCompanyId;
  const { t } = useTranslation();
  const [value, setValue] = useState<string>('1');
  const [loading, setLoading] = useState<boolean>(false);
  const { setCompanyContacts, setCompanyLocations } = useCompanyContext();
  const { setOffset } = usePaginationContext();
  
  const getContacts = useCallback(async (companyId: number | string): Promise<ContactPerson[]> => {
    setLoading(true);
    const { data } = await Api.get(endpoints.contacts, {
      params: {
        company: `${companyId}`,
      },
    });
    setLoading(false);
    return data.results;
  }, []);

  const getLocations = useCallback(async (companyId: number | string): Promise<CompanyLocation[]> => {
    setLoading(true);
    const { data } = await Api.get(endpoints.companyLocations, {
      params: {
        company: `${companyId}`,
      },
    });
    setLoading(false);
    return data.results;
  }, []);

  useEffect(() => {
    if (Number(currentCompanyId) > 0) {
      getContacts(currentCompanyId).then((contacts) => {
        setCompanyContacts(contacts);
      });
      getLocations(currentCompanyId).then((locations) => {
        setCompanyLocations(locations);
      });
    }
  }, [getLocations, getContacts, setCompanyLocations, setCompanyContacts, currentCompanyId]);

  const handleChange = useCallback((event: SyntheticEvent, newValue: string): void => {
    setValue(newValue);
    //reset offset
    setOffset(initialPagingOffset);
  }, [setOffset]);
  const allTabLists = useMemo(
    () => [
      {
        label: t('companySettingTabs.companyDetails'),
        value: '1',
        component: <CompanyDetails companyId={currentCompanyId} />,
      },
      {
        label: t('companySettingTabs.invoiceDetails'),
        value: '2',
        component: <InvoiceDetails companyId={currentCompanyId} />,
      },
      {
        label: t('companySettingTabs.contactsList'),
        value: '3',
        component: <Contacts companyId={currentCompanyId} />,
      },
      {
        label: t('companySettingTabs.companyLocations'),
        value: '4',
        component: <CompanyLocations companyId={currentCompanyId} />,
      },
    ],
    [t, currentCompanyId],
  );
  const renderAllTabs = useMemo(
    () => (
      <TabContext value={value}>
        <TabList
          onChange={handleChange}
          aria-label="company setting tabs"
          TabIndicatorProps={{
            style: {
              backgroundColor: colors.darkBlue,
            },
          }}
        >
          {allTabLists.map((item) => (
            <Tab key={item.label} label={item.label} value={item.value}/>
          ))}
        </TabList>
        {allTabLists.map((item) => (
          <TabPanel key={item.label} value={item.value} sx={{pl:0, pr:0}}>
            {item.component}
          </TabPanel>
        ))}
      </TabContext>
    ),
    [allTabLists, handleChange, value],
  );

  const renderCompanyDetailsTab = useMemo(
    () => (
      <TabContext value={value}>
        <Box>
          <TabList
            onChange={handleChange}
            aria-label="company setting tabs"
            TabIndicatorProps={{
              style: {
                backgroundColor: colors.darkBlue,
              },
            }}
          >
            <Tab label={allTabLists[0].label} value={allTabLists[0].value} />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{pl:0, pr:0}}>
          <CompanyDetails companyId={currentCompanyId} />
        </TabPanel>
      </TabContext>
    ),
    [allTabLists, currentCompanyId, handleChange, value],
  );
  const renderCompanySetting = useMemo(
    () => (
      <Grid container display="flex" m={2} spacing={2}>
        <Grid item xs={12} mb={5}>
          <Typography variant="h3">{t('companySettings.title')}</Typography>
          <Typography variant="subtitle1">{t('companySettings.subTitle')}</Typography>
        </Grid>
        <Grid item xs={12}>
        {Number(currentCompanyId) > 0 ? renderAllTabs : renderCompanyDetailsTab}
        </Grid>
      </Grid>
    ),
    [t, currentCompanyId, renderAllTabs, renderCompanyDetailsTab],
  );
  return (
    <Main>
      {loading && <StyledProgress size={24} />}
      {renderCompanySetting}
    </Main>
  );
};
