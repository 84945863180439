import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { initialPagingLimit, initialPagingOffset } from '../utils';

const initialState = {
  offset: initialPagingOffset,
  limit: initialPagingLimit,
  setOffset: (): void => {},
  setLimit: (): void => {},
  resetOffset: (): void => {},
};

interface PaginationContextType {
  offset: number;
  limit: number;
  setOffset: Dispatch<SetStateAction<number>>;
  setLimit: Dispatch<SetStateAction<number>>;
}

interface Props {
  children: React.ReactNode;
}
const PaginationContext = createContext<PaginationContextType>(initialState);

export const usePaginationContext = (): PaginationContextType => useContext(PaginationContext);

export const PaginationContextProvider: React.FC<Props> = ({ children }) => {
  const [offset, setOffset] = useState<number>(initialPagingOffset);
  const [limit, setLimit] = useState<number>(initialPagingLimit);

  return (
    <PaginationContext.Provider
      value={{
        setLimit,
        setOffset,
        limit,
        offset
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};
