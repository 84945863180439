import React, { ChangeEvent, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import {
  FormControl,
  FormHelperText,
  Grid,
  styled,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';

import { colors } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { CompanyLocation, Error } from '../../../types';
import Api from '../../../api';
import { endpoints } from '../../../utils';
import { useCompanyContext } from '../../../context';
import { useSnackbar } from 'notistack';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

const StyledButton = styled(Button)(() => ({
  '&:hover': {
    backgroundColor: colors.darkBlue,
  },
}));

interface Props {
  companyId: number | string;
}

export const CompanyLocationCreateDialog: React.FC<Props> = ({ companyId }) => {
  const DEFAULT_FORM_VALUES: CompanyLocation = {
    id: '',
    name: '',
    street: '',
    zip_code: '',
    municipality: '',
    country: 'FI',
    company: companyId,
  };

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState<CompanyLocation>(DEFAULT_FORM_VALUES);
  const [formErrors, setFormErrors] = useState<Error>({});
  const { companyLocations, setCompanyLocations } = useCompanyContext();
  const { enqueueSnackbar } = useSnackbar();

  const hasError = useCallback(
    (key: string): boolean => {
      return Object.keys(formErrors).some((item) => item === key);
    },
    [formErrors],
  );

  const renderFormErrorHelperText = useMemo(
    () =>
      (key: string): JSX.Element | void => {
        if (hasError(key)) {
          return <FormHelperText>{formErrors[key]}</FormHelperText>;
        }
      },
    [formErrors, hasError],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const newFormValues = { ...formValues };
      newFormValues[e.target.name as keyof CompanyLocation] = e.target.value;
      setFormValues(newFormValues);
    },
    [formValues],
  );

  const handleClose = (): void => {
    setFormValues(DEFAULT_FORM_VALUES);
    setFormErrors({});
    setOpen(false);
  };

  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const handleSubmit = useCallback(
    async (e: SyntheticEvent): Promise<void> => {
      e.preventDefault();
      setFormErrors({});
      try {
        const { data } = await Api.post<CompanyLocation>(endpoints.companyLocations, formValues);
        const { id } = data;
        if (Number(id) > 0) {
          enqueueSnackbar(t('misc.createSuccess'), {
            variant: 'success',
          });
          setCompanyLocations([...companyLocations, data]);
          setOpen(false);
        }
      } catch (err: any) {
        setFormErrors(err.response.data);
      }
    },
    [formValues, companyLocations, setCompanyLocations, t, enqueueSnackbar],
  );

  return (
    <div>
      <StyledButton
        variant="contained"
        size="small"
        onClick={handleClickOpen}
      >
        {<AddBoxOutlinedIcon />} {` ${t('companyLocations.createButtonLabel')}`}
      </StyledButton>
      <Dialog open={open}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>{` ${t('companyLocations.createDialogTitle')}`}</DialogTitle>
          <Divider variant="fullWidth" />
          <DialogContent sx={{ pb: 4, overflowY: 'visible' }}>
            <Grid container sx={{ p: 2 }} spacing={2}>
              <Grid item xs={12}>
                <FormControl error={hasError('name')}>
                  <>
                    <Typography variant="body1">{t('companyLocations.name')}</Typography>
                    <TextField name="name" size="small" onChange={handleChange} autoFocus />
                    {renderFormErrorHelperText('name')}
                  </>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl error={hasError('street')}>
                  <>
                    <Typography variant="body1">{t('companyLocations.street')}</Typography>
                    <TextField name="street" size="small" onChange={handleChange} />
                    {renderFormErrorHelperText('street')}
                  </>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl error={hasError('zip_code')}>
                  <>
                    <Typography variant="body1">{t('companyLocations.zip_code')}</Typography>
                    <TextField name="zip_code" size="small" onChange={handleChange} />
                    {renderFormErrorHelperText('zip_code')}
                  </>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl error={hasError('municipality')}>
                  <>
                    <Typography variant="body1">{t('companyLocations.municipality')}</Typography>
                    <TextField name="municipality" size="small" onChange={handleChange} />
                    {renderFormErrorHelperText('municipality')}
                  </>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl error={hasError('country')}>
                  <>
                    <Typography variant="body1">{t('companyLocations.country')}</Typography>
                    <ReactFlagsSelect
                      countries={['FI', 'SE']}
                      customLabels={{ FI: t('countries.FI'), SE: t('countries.SE') }}
                      selected={formValues.country}
                      onSelect={(code): void => setFormValues({ ...formValues, country: code })}
                      placeholder={t('companyLocations.countrySelectPlaceholder')}
                    />
                    {renderFormErrorHelperText('country')}
                  </>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider variant="fullWidth" />
          <DialogActions sx={{ p: 2 }}>
            <StyledButton variant="contained" size="small" onClick={handleClose}>{` ${t(
              'companyLocations.cancelButtonLabel',
            )}`}</StyledButton>
            <StyledButton variant="contained" size="small" type="submit">{` ${t(
              'companyLocations.saveButtonLabel',
            )}`}</StyledButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
