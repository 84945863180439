import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import Api from '../api';
import { CompanyResponse, CompanyInfo} from '../types';
import { endpoints } from '../utils';

const initialAllCompanies : CompanyInfo[] = [];

const initialState = {
  allCompanies: initialAllCompanies,
  setAllCompanies: (): void => {},
  fetchAllCompanies: (): void => { },
  allCompaniesLoading: false,
  setAllCompaniesLoading: (): void => { },
}

interface AllCompaniesContextType {
  allCompanies: CompanyInfo[] ;
  setAllCompanies: Dispatch<SetStateAction<CompanyInfo[]>>;
  fetchAllCompanies: () => void;
  allCompaniesLoading: boolean;
  setAllCompaniesLoading: Dispatch<SetStateAction<boolean>>;
}

interface Props {
  children: React.ReactNode;
}
const AllCompaniesContext = createContext<AllCompaniesContextType>(initialState);

export const useAllCompaniesContext = (): AllCompaniesContextType => useContext(AllCompaniesContext);

export const AllCompaniesContextProvider: React.FC<Props> = ({ children }) => {
  const [allCompanies,setAllCompanies] = useState<CompanyInfo[]>(initialAllCompanies);
  const [allCompaniesLoading, setAllCompaniesLoading] = useState<boolean>(false);

  const fetchAllCompanies = useCallback(
    ()=> {
      (async (): Promise<void> => {
        setAllCompaniesLoading(true);
        const { data } = await Api.get<CompanyResponse>(endpoints.companies,{
          params:{
            limit:2000
          }
        });
        const {results} = data;
        setAllCompanies(results);
        setAllCompaniesLoading(false);
      })();
  },[]);

  return (
    <AllCompaniesContext.Provider
      value={{
       setAllCompanies,
       allCompanies,
       fetchAllCompanies,
       allCompaniesLoading,
       setAllCompaniesLoading
      }}
    >
      {children}
    </AllCompaniesContext.Provider>
  );

}
